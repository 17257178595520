import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils

class NewMorakotSubjectIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
      { name: 'Home Page', href: '/' },
      { name: 'Morakot Typhoon', href: '/statistics/morakot-en' },
      { name: 'Typhoon Morakot Infographics', href: '/statistics/morakot-en/intro' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					{/* <title>減災動資料 - 撤離與收容評估</title> */}
				</Helmet>
				<div className="new-morakot-page">
					<div className="container">
						<div className="morakot-chart-title">
							<div className="title">Typhoon Morakot Infographics</div>
						</div>
						<div className="morakot-subject-selector">
							<div className="subject subject-grid -en">
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/disaster-reduction/">
										<p className="type-text -en -md">Disaster Risk Reduction</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/disaster-response/">
										<p className="type-text -en -md">Disaster Response</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/disaster-recover/">
										<p className="type-text -en -md">Disaster Recovery</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/respondent-demographics/">
										<p className="type-text -en -md">Respondent demographics</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/household-demographics/">
										<p className="type-text -en -md">Household Demographics</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/disability/">
										<p className="type-text enh -md">Disability</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/disaster-impact/">
										<p className="type-text -en -md">Disaster Impact</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot-en/resource-acquisition/">
										<p className="type-text -en -md">Resource acquisition</p>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default NewMorakotSubjectIntro;
