import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import './index.scss';

// Utils
import CustomInput from '../../utils/CustomInput';
import ModalBox from '../../utils/ModalBox';
import { apiHost } from '../../utils/Shared/constant';



class ResetPassword extends Component {
    constructor(params) {
        super(params);
        this.state = {
            modalOpen: false,
            inputDisabled: false,
            input_pw: '',
            input_repw: '',
            msg_pw: '',
        }
    }
    
    openModal() {
        this.setState({
            modalOpen: true,
        })
    }

    model = (state) => {

		const varToString = varObj => Object.keys(varObj)[0]
		const getValue = varObj => Object.values(varObj)[0]

		let rtn = {
			disabled: this.state.inputDisabled,
			value: getValue(state),
			onChange: v => {
				let newState = {};
				newState[varToString(state)] = v;
				this.setState(newState);
			},
		};

		return rtn;
	}

    handleUpdatePasswordClick = () => {
        const {input_pw, input_repw} = this.state;
        this.setState({inputDisabled: true});

        if (input_pw !== input_repw) {
            this.setState({
                msg_pw: '兩次密碼必須相同',
                inputDisabled: false,
            })
        } 
        else if (input_pw === '') {
            this.setState({
                msg_pw: '密碼不可為空白',
                inputDisabled: false,
            })
        } 
        else {
            Axios.post(
                `${apiHost}/api/Account/UpdateInfo`,
                {
                    LoginKey: input_pw
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.localStorage.getItem('user-token'),
                    }
                },
            )
            .then( res => {
                if (res.data.Outstring === "0000") {
                    window.localStorage.removeItem('user-token');
                    this.openModal();
                } else {
                    this.setState({
                        inputDisabled: false,
                    })
                }
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onLoadHeaderColor('YELLOW');
        this.props.onLoadHeaderTheme('DARK');
        this.props.onLoadShowLanguage('HIDE');
        this.props.onLoadShowBreadcrumb('HIDE');
    }
    render() {
        const {model} = this;
        const {input_pw, input_repw, msg_pw} = this.state;
        return (
            <React.Fragment>
                <ModalBox 
                    open={this.state.modalOpen}
                    theme="-yellow"
                    onRequestClose={ () => {
                        this.setState({ modalOpen: false })
                    } }
                >
                    <div className="modalbody">
                        <p className="type-text -ch -md">你的密碼已修改，請重新登入</p>
                    </div>
                    <div className="modalfooter">
                        <Link to="/member/login" className="fill-button -fit -yellow">立即登入</Link>
                    </div>
                </ModalBox>
                <div className="member-page">
                    <div className="container">
                        <div className="row">
                            <section className="member-login member-section col-lg-6 offset-lg-3">
                                <div className="heading">
                                    <span className="type-heading -ch -h3">重設密碼</span>
                                </div>
                                <div className="description -center">
                                    <p className="type-text -ch -xs">設定新密碼</p>
                                </div>
                                <form className="form login-form" action="">
                                    <div className="formitem">
                                        <CustomInput  status={msg_pw === '' ? 'normal' : 'danger'} id="i1" type="password" label="密碼*" {...model({input_pw})}/>
                                    </div>
                                    <div className="formitem">
                                        <CustomInput  status={msg_pw === '' ? 'normal' : 'danger'} msg={msg_pw === '' ? '' : msg_pw} id="i2" type="password" label="再次確認密碼*" {...model({input_repw})}/>
                                    </div>
                                    <div className="formitem -center">
                                        <button 
                                            className="submit fill-button -fit -yellow"
                                            onClick={this.handleUpdatePasswordClick}
                                            type="button"
                                            >
                                            <span className="type-text -ch -md">下一步</span>
                                        </button>
                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPassword;
