let init = {
  // TODO: 此Component的Reducer state初始值
  
  // someValue: 0
}

export default (state = init, action) => {
  switch (action.type) {

    // TODO: 在此新增與定義此Component的Reducer接收到Action後，所要回傳給Store的新State.

    // case 'SOME_ACTION_TYPE':
    //   return {...state, someValue: state.someValue + 1}

    default:
      return state
  }
}
