import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

// modules
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

class SideMenu extends Component {
  constructor(params) {
    super(params);
    this.state = {
      menuItems: [
        {
          primary: "調查統計",
          href: "",
          secondary: [
            {
              name: "莫拉克颱風社會衝擊與復原調查(2010~2019)",
              href: "/statistics/morakot",
            },
            {
              name: "社福機構自然災害風險調查(2011)",
              href: "/statistics/sfaa",
            },
            {
              name: "0206臺南震災天然災害社經影響家戶調查(2016)",
              href: "/statistics/TainanEarthquake",
            },
            {
              name: "0206花蓮震災地震感知與避難行為調查(2018)",
              href: "/statistics/HualienEarthquake",
            },
            {
              name: "0823水災撤離與收容調查(2018)",
              href: "https://drrstat.ncdr.nat.gov.tw/flood/index",
            }
          ],
        },
        {
          primary: "評估系統",
          href: "",
          secondary: [
            { name: "社會脆弱度評估系統", href: "/evaluation/svi" },
            { name: "撤離與收容評估系統", href: "/evaluation/evacuation" },
          ],
        },
        {
          primary: "會員系統",
          href: window.localStorage.getItem("user-token")
            ? "/member/edit"
            : "/member/login",
          secondary: null,
        },
        {
          primary: "最新消息",
          href: "/news",
          secondary: null,
        },
        {
          primary: "網站地圖",
          href: "/sitemap",
          secondary: null,
        },
      ],
    };
  }
  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }
  componentDidUpdate() {
    if (this.props.open) {
      disableBodyScroll(document.getElementById("root"));
    } else {
      enableBodyScroll(document.getElementById("root"));
    }
  }
  handleClickCloseMenu = () => {
    this.props.onClickCloseMenu();
  };

  renderSecondary(secondary) {
    let items = [];

    for (let i = 0; i < secondary.length; i++) {
      items.push(
        <li key={i} className="item">
          {secondary[i].href.indexOf("http") === -1 ? (
            <Link to={secondary[i].href} onClick={this.handleClickCloseMenu}>
              <span className="type-text -ch -md">{secondary[i].name}</span>
            </Link>
          ) : (
            <a href={secondary[i].href} onClick={this.handleClickCloseMenu}>
              <span className="type-text -ch -md">{secondary[i].name}</span>
            </a>
          )}
        </li>
      );
    }

    return items;
  }
  render() {
    return (
      <div className={"side-menu " + (this.props.open ? "-open" : "-close")}>
        <button
          onClick={this.handleClickCloseMenu}
          className="button round-button close -yellow -light"
        >
          <i className="icon-close"></i>
        </button>
        <div className="container list-wrapper">
          <div className="list">
            <ul className="primary-list">
              {this.state.menuItems &&
                this.state.menuItems.length > 0 &&
                this.state.menuItems.map((item, i) => {
                  const { href, primary, secondary } = item;
                  return (
                    <li key={i} className="item">
                      {href !== "" && (
                        <React.Fragment>
                          <Link
                            onClick={this.handleClickCloseMenu}
                            className="link"
                            to={href}
                          >
                            <span className="link type-text -ch -lg">
                              {primary}
                            </span>
                          </Link>
                        </React.Fragment>
                      )}
                      {href === "" && (
                        <React.Fragment>
                          <span className="link type-text -ch -lg">
                            {primary}
                          </span>
                        </React.Fragment>
                      )}
                      {secondary && (
                        <React.Fragment>
                          <ul className="secondary-list">
                            {this.renderSecondary(secondary)}
                          </ul>
                        </React.Fragment>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SideMenu;
