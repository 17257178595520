import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Utils
import chartCapture from '../../utils/Shared/chartCapture';
import CustomRadio from '../../utils/CustomRadio';
import CustomDropdown from '../../utils/CustomDropdown';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

// Resources
import TainanEarthquakePercentPic01 from "../../../images/TainanEarthquake/maintitle01.png";

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}

class TainanEarthquakeIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
			scrollSpyItems: [
				{
					name: 'content05',
					title: '地震當下，你知道只有15.4%的人做對了嗎？',
					show: false,
				},
				{
					name: 'content09',
					title: '77.8%的人不知道震後避難地點，那你知道嗎？',
					show: false,
				},
				{
					name: 'content11',
					title: '近8成的民眾沒有投保地震險，你投保了嗎？',
					show: false,
				},
				,
				{
					name: 'content12',
					title: '地震發生次數多，卻只有2成的人準備防災包...',
					show: false,
				},
				,
				{
					name: 'content14',
					title: '地震後損失大比較！停水停電虧多少？',
					show: false,
				},
			],
			valueType: [
				{
					name: 'pdf0'
				},
				{
					name: 'pdf1'
				},
				{
					name: 'pdf2'
				},
				{
					name: 'pdf3'
				},
				{
					name: 'pdf4'
				},
				{
					name: 'pdf5'
				},
				{
					name: 'pdf6'
				},
				{
					name: 'pdf7'
				},
				{
					name: 'pdf8'
				},
				{
					name: 'pdf9'
				},
				{
					name: 'pdf10'
				}
			]
		};
	}
	//檢查登入
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
		this.setState({ isMemberLogin: true });
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '0206臺南地震調查', href: '/statistics/TainanEarthquake' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	
	handleClickRidio(e) {
		let num    = e.currentTarget.dataset.num;
		let group = e.currentTarget.dataset.group;
		let valueType = this.state.valueType;

		valueType[num].name = group;
		// console.log(num)
		// console.log(group)
		this.setState({
			valueType: valueType,
		})
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}

	render() {
		const { isMemberLogin } = this.state;
		const { scrollSpyItems, valueType } = this.state;
	
		return (
			
<div className="TainanEarquake" id="home">
	<Helmet>
		<title>0206臺南地震給我們的一堂課</title>
	</Helmet>
	<div className="scroll-spy">
		{
			scrollSpyItems &&
			scrollSpyItems.map((item, index) => {
				return (
					<div key={index}
						className={"item " + item.name + (item.show ? " -show" : "")}
						onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true }) }}>
						<span>{item.title}</span>
					</div>
				)
			})
		}
	</div>

	<div className="scroll-up">
		<a href="#home" className="pc"> <img src={require("../../../images/TainanEarthquake/Union.svg")}/> </a>
		<a href="#menu" className="mobile"> <img src={require("../../../images/TainanEarthquake/Union_ph.svg")}/> </a>
	</div>

	<div className="header">
		<h1 className="maintitle">
			<img src={TainanEarthquakePercentPic01} alt="0206臺南地震給我們的一堂課"/>
		</h1>
		<div className="mass"></div>
		
		<ul className="slideshow">
			<li><span>Slide One</span> </li>
			<li> <span>Slide Two</span> </li>
			
		  </ul>
	</div>

	<section id="content01">
		<div className="container-c c640">
			<div className="infon">
				<p>臺灣地處斷層帶上，地震頻繁，政府和學界也已累積許多研究結果，包括對活動斷層的評估、地震災害潛勢的資訊等。但或許值得我們關注的是——臺灣民眾們是否已具備足夠的防災意識？</p>
				<p>這不是第一次地震，也不會是最後一次</p>
				<p>時間回到2016年2月6日凌晨3時57分...當時臺灣發生芮氏規模6.6的地震，震央位於高雄美濃，深度為16.7公里的極淺層地震。此地震全臺有感，更導致臺南市維冠金龍大樓倒塌、幸福大樓與京城銀行大樓傾斜、400,030 家戶停水、173,163家戶停電、117人罹難、551位民眾受傷等災情...</p>
			</div>
			
		</div>

	</section>
	<section id="content02">
		<div className="container-c c640">
			<div className="pic pic01">
				<img src={require("../../../images/TainanEarthquake/pic01.gif")} width="100%"/>
			</div>
			<div className="infon">
				<div className="title">
					<h3>地震警報響起！考驗你的臨機應變能力</h3>
				</div>
				<p>中央氣象局利用「災防告警細胞廣播訊息系統（Public Warning System, PWS）」發布地震速報通告民眾，目的是提供民眾及早掌握災害訊息以及應變相關訊息，PWS的發布條件是<b>”當偵測地震規模5.0以上，且預估震度達4級 ”</b>，同時會依據<b>民眾所在位置若達預估震度4級以上</b>，進行發布。為了避免民眾忽略其告警訊息，手機收到訊息時，會同步發出聲響與跳出示警文字，期望民眾能夠提早進行應變或降低地震所致的傷害，因此更加考驗民眾平時對地震當下的臨機應變能力。</p>
			</div>
			
		</div>
	</section>

	<section id="content03">
		<div className="container-c c640">
			
			<div className="pic map02">
				<img src={require("../../../images/TainanEarthquake/map02.gif")} width="100%"/>
			</div>
		</div>
	</section>

	<section id="content04">
		<div className="container-c c640" id="menu">
			<div className="btn-g">
				<a href="#content05" className="btn">
				地震當下，你知道只有15.4%的人做對了嗎？
				</a>
				<a href="#content09" className="btn">
				77.8%的人不知道震後避難地點，那你知道嗎？
				</a>
				<a href="#content11" className="btn">
				近8成的民眾沒有投保地震險，你投保了嗎？
				</a>
				<a href="#content12" className="btn">
				地震發生次數多，卻只有2成的人準備防災包...
				</a>
				<a href="#content14" className="btn">
				地震後損失大比較！停水停電虧多少？
				</a>
			</div>
		</div>
	</section>

	<hr></hr>

	<section id="content05">
		<div className="container-c c640">
		
			<div className="infon">
				<div className="title">
					<h2>地震當下，< br/>你知道只有15.4%的人做對了嗎？</h2>
				</div>
				<p>「嗶～嗶～嗶～」，手機發出國家級警報聲響，隨之而來的便是劇烈的天搖地動，這時我們應該怎麼做呢？<br /><br />

					臺灣人多半從出生就習慣或大或小的地震，也正因如此，具備正確的防災知識就顯得很重要。<br /><br />
					
					我們來看看公部門推廣的地震防災行動，是否符合大家的認知？</p>
			</div>
		</div>
		<div className="container-c c640">
			<div className="pic pic04">
				<img src={require("../../../images/TainanEarthquake/pic04.gif")} width="100%"/>
			</div>
		</div>	
		<div className="container-c c640">
			<div className="infon">
				
				<p>目前公部門的<a href="https://www.youtube.com/watch?v=dKnIvqH_FME" target="_blank">地震防災宣導</a>，建議一般民眾遇到地震時應採取「趴下、掩護、穩住」，簡稱「趴、掩、穩」。<br /><br />

				其原則是：<br />
				趴：維持低姿勢並穩住身體。<br />
				掩：尋找身邊的掩護物，如堅固的桌子。<br />
				穩：手握住桌腳以穩住自己，以及保護頭頸部，避免遭到掉落物砸傷。<br /><br />
					
					若是使用行動輔具者或輪椅者，建議應採取<b>「固定、掩護、穩住」</b>，務必鎖住或是固定輪椅，若有靠墊，在可行的狀況下，可以拿起來保護頭頸部。</p>
			</div>
			<div className="pic pic05">
				<img src={require("../../../images/TainanEarthquake/pic05.svg")} width="100%"/>
			</div>
			<div className="infon">
				
				<p>根據調查結果，0206地震當下民眾前三大反應，有29.8%為未有任何動作，另有15.4%的人做出趴、掩、穩相近行動、14.7%的人則為儘速起床，往建築物外跑。</p>
			</div>
		</div>
	</section>

	<section id="content06">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>民眾之臨震反應</h4>
				</div>

				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf0" 
								num="0"
								
								checked={ this.state.valueType[0].name === 'pdf0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical0" 
								num="0"
								
								checked={this.state.valueType[0].name === 'Statistical0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[0].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li>
									<Link to={require("../../../images/TainanEarthquake/download/民眾之臨震反應.png")} 
									target="_blank" 
									onClick={() => gaTracking('台南地震', '民眾之臨震反應', '圖表下載')} 
									download>下載PNG</Link>
								</li>
								<li>
									<Link to={require("../../../images/TainanEarthquake/download/民眾之臨震反應.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('台南地震', '民眾之臨震反應', '原始數據下載')} 
									download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li>
									<Link to={require("../../../images/TainanEarthquake/download/民眾之臨震反應.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('台南地震', '民眾之臨震反應', '原始數據下載')} 
									download>下載EXCEL</Link></li> }

								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
						
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c886">

			<div className={`${(valueType[0].name === 'pdf0' || valueType === null ? ' -active' : '')} statistics-charts chat01`}>
				<img src={require("../../../images/TainanEarthquake/chat01.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[0].name === 'Statistical0' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3" valign="middle">

				<p align="center" >民眾之臨震反應</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>項目</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>未有任何動作</p>
			</td>
			<td valign="middle">

				<p align="right" >628</p>
			</td>
			<td valign="middle">

				<p align="right" >29.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>「趴、掩、穩」相近行動</p>
			</td>
			<td valign="middle">

				<p align="right" >324</p>
			</td>
			<td valign="middle">

				<p align="right" >15.4%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>往建築物外跑</p>
			</td>
			<td valign="middle">

				<p align="right" >311</p>
			</td>
			<td valign="middle">

				<p align="right" >14.7%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>找家人</p>
			</td>
			<td valign="middle">

				<p align="right" >279</p>
			</td>
			<td valign="middle">

				<p align="right" >13.2%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>把門打開，避免變形受困</p>
			</td>
			<td valign="middle">

				<p align="right" >250</p>
			</td>
			<td valign="middle">

				<p align="right" >11.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>躲在床旁邊</p>
			</td>
			<td valign="middle">

				<p align="right" >112</p>
			</td>
			<td valign="middle">

				<p align="right" >5.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>躲在堅固家具旁</p>
			</td>
			<td valign="middle">

				<p align="right" >105</p>
			</td>
			<td valign="middle">

				<p align="right" >5.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>其他</p>
			</td>
			<td valign="middle">

				<p align="right" >100</p>
			</td>
			<td valign="middle">

				<p align="right" >4.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>說明：</p>

				<p>未有任何動作：沒有感覺地震或覺得地震很小，不需要反應、感覺地震很大想反應卻來不及動作、感覺地震很大但不做應變。</p>

				<p>「趴、掩、穩」相近行動：待在床上用枕頭或被子保護頭部、躲在堅固家具下、注意有無掉落物。</p>

				<p>其他：禱告、念佛號、往高樓層處跑、躲牆角、躲樑柱下、不知道/很難說。</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>

		</div>
		<div className="container-c c886">
			<blockquote>
				<p>
					<b>未有任何動作</b>：沒有感覺地震或覺得地震很小，不需要反應、感覺地震很大想反應卻來不及動作、感覺地震很大但不做應變。<br />
					<b>「趴、掩、穩」相近行動</b>：待在床上用枕頭或被子保護頭部、躲在堅固家具下、注意有無掉落物。<br /> 
					<b>其他</b>：禱告、唸佛號、往高樓層處跑、躲牆角、躲樑柱下、不知道/很難說。
				</p>
				
			</blockquote>
		</div>
		<div className="container-c c640">
			<div className="infon">
				
				<p>不過，自921大地震後，網路便流傳「黃金三角」一說，是指當建築物牆面或大型重物倒塌時，若人待在支撐力夠強大的物體旁，可以讓物體頂住倒塌的建築，因此形成一個安全三角空間。不過該理論後來受到很多救援單位的質疑，主要是地震發生的場所之空間設計及耐震結構各異，難以預估黃金三角產生的地點。且黃金三角的想法是避免樓地板垮下來，而不是避免較有機會發生的風險，如被掉落的擺設砸傷、家具位移等等。<br /><br />

					由於網路上至今仍有許多地震防災文會提到「黃金三角」說，因此本次調查亦調查民眾對「黃金三角」的想法。調查結果顯示相信「黃金三角」的民眾有57%，政府推廣的「趴、掩、穩」僅占26.9%，顯見如何普及「趴、掩、穩」觀念，為政府應持續努力的方向。</p>
			</div>
		</div>
	</section>	

	<section id="content07">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h4>民眾相信的臨震保命策略</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf1" 
								num="1"
								
								checked={ this.state.valueType[1].name === 'pdf1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical1" 
								num="1"
								
								checked={this.state.valueType[1].name === 'Statistical1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[1].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略.png")} target="_blank" onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略', '原始數據下載')} download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略', '原始數據下載')} download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div className="container-c c886">
			<div className={`${(valueType[1].name === 'pdf1' || valueType === null ? ' -active' : '')} statistics-charts chat02`}>
				<img src={require("../../../images/TainanEarthquake/chat02.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[1].name === 'Statistical1' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3" valign="middle">

				<p align="center" >民眾相信的臨震保命策略</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>項目</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>躲在黃金三角</p>
			</td>
			<td valign="middle">

				<p align="right" >1202</p>
			</td>
			<td valign="middle">

				<p align="right" >57.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>趴下、掩護、穩住</p>
			</td>
			<td valign="middle">

				<p align="right" >568</p>
			</td>
			<td valign="middle">

				<p align="right" >26.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >261</p>
			</td>
			<td valign="middle">

				<p align="right" >12.4%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>以上皆錯</p>
			</td>
			<td valign="middle">

				<p align="right" >51</p>
			</td>
			<td valign="middle">

				<p align="right" >2.4%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>以上皆對</p>
			</td>
			<td valign="middle">

				<p align="right" >27</p>
			</td>
			<td valign="middle">

				<p align="right" >1.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
			
		</div>
	</section>

	<section id="content08">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h3>地震發生時的第一反應，<br />頭腦想的跟身體做的一樣嗎？</h3>
				</div>
				<p>臨震行為可能是你保命的關鍵，依據調查結果分析，認為臨震時應該「趴下、掩護、穩住」的民眾，真的發生地震時，第一時間進行趴、掩、穩的比率(16.7%)，低於沒有任何動作（28.3%）。	</p>
			</div>
		</div>

		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>民眾相信的臨震保命策略 VS 臨震反應</h4>
				</div>
			</div>
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf2" 
								num="2"
								
								checked={ this.state.valueType[2].name === 'pdf2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical2" 
								num="2"
								
								checked={this.state.valueType[2].name === 'Statistical2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[2].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略 vs 臨震反應.png")} target="_blank" onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略 vs 臨震反應', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略 vs 臨震反應.xlsx")} onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略 vs 臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/民眾相信的臨震保命策略 vs 臨震反應.xlsx")} onClick={() => gaTracking('台南地震', '民眾相信的臨震保命策略 vs 臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			
		</div>
		<div className="container-c c1200">
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-1`}>
				<img src={require("../../../images/TainanEarthquake/chat03-1.svg")} width="100%"/>
			</div>
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-2`}>
				<div className="scrollbox">
					<img src={require("../../../images/TainanEarthquake/chat03-2.svg")} width="100%"/>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-3`}>
				<img src={require("../../../images/TainanEarthquake/chat03-3.svg")} width="100%"/>
			</div>
		</div>
		<div className="container-c c886">
			<div className={`${(valueType[2].name === 'Statistical2' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="2" rowSpan="3" valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="12" valign="middle">

				<p align="center" >臨震保命策略</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="middle">

				<p align="right" >趴下、掩護、穩住</p>
			</td>
			<td colSpan="2" valign="middle">

				<p align="right" >躲在黃金三角</p>
			</td>
			<td colSpan="2" valign="middle">

				<p align="right" >以上皆對</p>
			</td>
			<td colSpan="2" valign="middle">

				<p align="right" >以上皆錯</p>
			</td>
			<td colSpan="2" valign="middle">

				<p align="right" >不知道/很難說</p>
			</td>
			<td colSpan="2" valign="middle">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配數</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="9" valign="middle">

				<p align="center" >臨震反應</p>
			</td>
			<td valign="middle">

				<p>未有任何動作</p>
			</td>
			<td valign="middle">

				<p align="right" >161</p>
			</td>
			<td valign="middle">

				<p align="right" >28.3%</p>
			</td>
			<td valign="middle">

				<p align="right" >348</p>
			</td>
			<td valign="middle">

				<p align="right" >29.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >8</p>
			</td>
			<td valign="middle">

				<p align="right" >29.6%</p>
			</td>
			<td valign="middle">

				<p align="right" >12</p>
			</td>
			<td valign="middle">

				<p align="right" >23.5%</p>
			</td>
			<td valign="middle">

				<p align="right" >99</p>
			</td>
			<td valign="middle">

				<p align="right" >37.9%</p>
			</td>
			<td valign="middle">

				<p align="right" >628</p>
			</td>
			<td valign="middle">

				<p align="right" >29.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>「趴、掩、穩」相近行動</p>
			</td>
			<td valign="middle">

				<p align="right" >95</p>
			</td>
			<td valign="middle">

				<p align="right" >16.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >175</p>
			</td>
			<td valign="middle">

				<p align="right" >14.6%</p>
			</td>
			<td valign="middle">

				<p align="right" >7</p>
			</td>
			<td valign="middle">

				<p align="right" >25.9%</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td valign="middle">

				<p align="right" >11.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >41</p>
			</td>
			<td valign="middle">

				<p align="right" >15.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >324</p>
			</td>
			<td valign="middle">

				<p align="right" >15.4%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>往建築物外跑</p>
			</td>
			<td valign="middle">

				<p align="right" >78</p>
			</td>
			<td valign="middle">

				<p align="right" >13.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >168</p>
			</td>
			<td valign="middle">

				<p align="right" >14.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td valign="middle">

				<p align="right" >11.1%</p>
			</td>
			<td valign="middle">

				<p align="right" >17</p>
			</td>
			<td valign="middle">

				<p align="right" >33.3%</p>
			</td>
			<td valign="middle">

				<p align="right" >45</p>
			</td>
			<td valign="middle">

				<p align="right" >17.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >311</p>
			</td>
			<td valign="middle">

				<p align="right" >14.7%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>找家人</p>
			</td>
			<td valign="middle">

				<p align="right" >84</p>
			</td>
			<td valign="middle">

				<p align="right" >14.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >166</p>
			</td>
			<td valign="middle">

				<p align="right" >13.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td valign="middle">

				<p align="right" >11.1%</p>
			</td>
			<td valign="middle">

				<p align="right" >5</p>
			</td>
			<td valign="middle">

				<p align="right" >9.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >21</p>
			</td>
			<td valign="middle">

				<p align="right" >8.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >279</p>
			</td>
			<td valign="middle">

				<p align="right" >13.2%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>把門打開，避免變形受困</p>
			</td>
			<td valign="middle">

				<p align="right" >68</p>
			</td>
			<td valign="middle">

				<p align="right" >12.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >161</p>
			</td>
			<td valign="middle">

				<p align="right" >13.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >1</p>
			</td>
			<td valign="middle">

				<p align="right" >3.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td valign="middle">

				<p align="right" >11.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >14</p>
			</td>
			<td valign="middle">

				<p align="right" >5.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >250</p>
			</td>
			<td valign="middle">

				<p align="right" >11.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>躲在床旁邊</p>
			</td>
			<td valign="middle">

				<p align="right" >25</p>
			</td>
			<td valign="middle">

				<p align="right" >4.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >79</p>
			</td>
			<td valign="middle">

				<p align="right" >6.6%</p>
			</td>
			<td valign="middle">

				<p align="right" >2</p>
			</td>
			<td valign="middle">

				<p align="right" >7.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >0</p>
			</td>
			<td valign="middle">

				<p align="right" >0.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td valign="middle">

				<p align="right" >2.3%</p>
			</td>
			<td valign="middle">

				<p align="right" >112</p>
			</td>
			<td valign="middle">

				<p align="right" >5.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>躲在堅固家具旁</p>
			</td>
			<td valign="middle">

				<p align="right" >35</p>
			</td>
			<td valign="middle">

				<p align="right" >6.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >61</p>
			</td>
			<td valign="middle">

				<p align="right" >5.1%</p>
			</td>
			<td valign="middle">

				<p align="right" >2</p>
			</td>
			<td valign="middle">

				<p align="right" >7.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >0</p>
			</td>
			<td valign="middle">

				<p align="right" >0.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >7</p>
			</td>
			<td valign="middle">

				<p align="right" >2.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >105</p>
			</td>
			<td valign="middle">

				<p align="right" >5.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>其他</p>
			</td>
			<td valign="middle">

				<p align="right" >22</p>
			</td>
			<td valign="middle">

				<p align="right" >3.9%</p>
			</td>
			<td valign="middle">

				<p align="right" >44</p>
			</td>
			<td valign="middle">

				<p align="right" >3.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >1</p>
			</td>
			<td valign="middle">

				<p align="right" >3.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >5</p>
			</td>
			<td valign="middle">

				<p align="right" >9.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >28</p>
			</td>
			<td valign="middle">

				<p align="right" >10.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >100</p>
			</td>
			<td valign="middle">

				<p align="right" >4.7%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >568</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >1202</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >27</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >51</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >261</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="14"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="14" valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<p>
					事實上，不論民眾認為「臨震行為」應該是什麼，地震時「未有任何動作」的比率都是相對多的，可能顯示臨震時多數民眾嚇到忘記可以怎麼做，或是覺得地震沒有大到需要行動。有趣的是，相信臨震時應該「躲在黃金三角」的民眾，也有14.6%在0206地震當下選擇進行趴、掩、穩的動作，至少做對了！此外，認為兩者「皆對」的民眾，選擇進行「趴、掩、穩」（25.9%）的動作也比其他動作來得高。<br /><br />

					至於選擇「以上皆錯」的民眾，有33.3%臨震時選擇「盡速起床往建築物外跑」！但地震發生的當下，奔跑可能因為腳步不穩容易跌傷，也容易被掉落的物品砸傷！<br /><br />

					面對地震，第一時間的應變往往取決於平時是否具備足夠的防災知識、觀察常用生活空間（哪邊適合趴、掩、穩），以及心態上是否有所準備，唯有日常準備充足，關鍵時刻才能臨危不亂。

				</p>
			</div>
		</div>

	</section>

	<hr></hr>

	<section id="content09">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>77.8%的人不知道震後避難地點，< br/>那你知道嗎？</h2>
					<p>
					調查結果顯示，77.8%的受訪者並不知道自己住家附近哪裡可以避難，知道的民眾僅有22.2%，建議民眾平時多上網瀏覽消防署網站或公部門防災專區，藉此瞭解這類公開資訊與相關知識、多參與政府或民間舉辦的防災演習或教育訓練等活動，可讓自己於地震當下或災後不慌亂，知道該怎麼做！
					</p>
					<h4>是否知道住家附近的政府公告避難地點</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf3" 
								num="3"
								
								checked={ this.state.valueType[3].name === 'pdf3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical3" 
								num="3"
								
								checked={this.state.valueType[3].name === 'Statistical3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[3].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點.png")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點', '原始數據下載')}  download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[3].name === 'pdf3' || valueType === null ? ' -active' : '')} statistics-charts chat04`}>
				<img src={require("../../../images/TainanEarthquake/chat04.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[3].name === 'Statistical3' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p align="center" >是否知道住家附近的政府公告避難地點</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>項目</p>
			</td>
			<td valign="bottom">
				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">
				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>知道</p>
			</td>
			<td valign="bottom">
				<p align="right" >468</p>
			</td>
			<td valign="bottom">
				<p align="right" >22.2%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>不知道</p>
			</td>
			<td valign="bottom">
				<p align="right" >1641</p>
			</td>
			<td valign="bottom">
				<p align="right" >77.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>總計</p>
			</td>
			<td valign="bottom">
				<p align="right" >2109</p>
			</td>
			<td valign="bottom">
				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
			
		</div>
		<div className="container-c c640">
			<div className='infon'>
			<div className="title">
				<div className="title">
					<h3>平時花功夫 震後不馬虎</h3>
				</div>
				<p>
				依據0206地震調查發現，事前「曾參加」過防災演習或教育訓練的民眾，「知道」地震避難地點的比率就高達47.9%，而「未參加」過防災演習或教育訓練的民眾，僅有20％「知道」避難地點，這結果顯示災防演習或教育訓練的參與經驗帶來正向影響。
				</p>
					
				</div>
			</div>
		</div>
		
		<div className="container-c c640">
			<div className='infon'>
				<div className="title"><h4>是否參加過政府防災宣導、教育、演習 <br />VS<br /> 是否知道住家附近的政府公告避難地點</h4></div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf4" 
								num="4"
								
								checked={ this.state.valueType[4].name === 'pdf4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical4" 
								num="4"
								
								checked={this.state.valueType[4].name === 'Statistical4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點.png")} target="_blank" onClick={() => gaTracking('台南地震', '是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點', '原始數據下載')}  download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否參加過政府防災宣導、教育、演習 VS 是否知道住家附近政府公告的避難地點', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			</div>
			<div className="container-c c886">
				<div className={`${(valueType[4].name === 'pdf4' || valueType === null ? ' -active' : '')} statistics-charts chat05`}>
					<img src={require("../../../images/TainanEarthquake/chat05.svg")} width="100%"/>
				</div>
				<div className={`${(valueType[4].name === 'Statistical4' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" rowSpan="2" valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3" valign="middle">

				<p align="center" >是否知道住家附近的政府公告避難地點</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >知道</p>
			</td>
			<td valign="middle">

				<p align="right" >不知道</p>
			</td>
			<td valign="middle">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" rowSpan="6" valign="middle">

				<p align="center" >是否參加過</p>

				<p align="center" >政府防災宣導、教育、演習</p>
			</td>
			<td rowSpan="2"  valign="middle">

				<p>曾參加</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >79</p>
			</td>
			<td valign="middle">

				<p align="right" >86</p>
			</td>
			<td valign="middle">

				<p align="right" >165</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >47.9%</p>
			</td>
			<td valign="middle">

				<p align="right" >52.1%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>未參加</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >389</p>
			</td>
			<td valign="middle">

				<p align="right" >1555</p>
			</td>
			<td valign="middle">

				<p align="right" >1944</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >20.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >80.0%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >468</p>
			</td>
			<td valign="middle">

				<p align="right" >1641</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >22.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >77.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>
			
		</div>
		<div className="container-c c640">
			<div className="infon">
				<p>其次，平時家人間也可以相互討論地震的避難方法，就算震時身處不同地點，震後也不擔心找不到彼此。<br />
					依據調查顯示，家人間平時「有討論」地震的避難方法者，「知道」避難地點的比率也較高，占35.4%。「無討論」者「知道」避難地點的比率則較低，僅有18.6%。顯示家人間的討論也能促進彼此對避難地點的掌握，震後不失聯，安心不掛心！</p>
					
			</div>
		</div>
		<div className="container-c c640">
			<div className='infon'>
				<div className="title"><h4>有沒有討論地震災害避難方法 <br />VS<br /> 是否知道住家附近的政府公告避難地點</h4></div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf5" 
								num="5"
								
								checked={ this.state.valueType[5].name === 'pdf5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical5" 
								num="5"
								
								checked={this.state.valueType[5].name === 'Statistical5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.png")} target="_blank" onClick={() => gaTracking('台南地震', '有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.', '原始數據下載')}  download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '有沒有討論地震災害避難方法 VS 是否知道住家附近的政府公告避難地點.', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			</div>
			<div className="container-c c886">
				<div className={`${(valueType[5].name === 'pdf5' || valueType === null ? ' -active' : '')} statistics-charts chat06`}>
					<img src={require("../../../images/TainanEarthquake/chat06.svg")} width="100%"/>
				</div>
				<div className={`${(valueType[5].name === 'Statistical5' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" rowSpan="2"  valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3" valign="middle">

				<p align="center" >是否知道住家附近的政府公告避難地點</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >知道</p>
			</td>
			<td valign="middle">

				<p align="right" >不知道</p>
			</td>
			<td valign="middle">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" rowSpan="8"  valign="middle">

				<p align="center" >有沒有討論地震災害避難方法</p>
			</td>
			<td rowSpan="2"  valign="middle">

				<p>有討論</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >150</p>
			</td>
			<td valign="middle">

				<p align="right" >274</p>
			</td>
			<td valign="middle">

				<p align="right" >424</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >35.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >64.6%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>無討論</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >303</p>
			</td>
			<td valign="middle">

				<p align="right" >1328</p>
			</td>
			<td valign="middle">

				<p align="right" >1631</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >18.6%</p>
			</td>
			<td valign="middle">

				<p align="right" >81.4%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >15</p>
			</td>
			<td valign="middle">

				<p align="right" >39</p>
			</td>
			<td valign="middle">

				<p align="right" >54</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >27.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >72.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >468</p>
			</td>
			<td valign="middle">

				<p align="right" >1641</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>有效百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >22.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >77.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
				
				</div>
			
		</div>
	</section>

	<section id="content10">
		<div className="container-c c640">
			<div className="infon">
				
				<p>不論是防災教育、演習，或是與家人共同討論都能提升自己或家人對地震防災的認識，進而使得震後的避難行動更為順利，這一點由0206調查的結果可應驗：知道住家附近避難地點的民眾，震後去空曠處避難的比率（20.9%）比起不知道的民眾（11.2%）來得高！</p>
				
			</div>
		</div>
		<div className="container-c c640">
			<div className='infon'>
				<div className="title"><h4>是否知道住家附近的政府公告避難地點 <br />VS<br />有沒有至空曠處避難</h4></div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf6" 
								num="6"
								
								checked={ this.state.valueType[6].name === 'pdf6' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical6" 
								num="6"
								
								checked={this.state.valueType[6].name === 'Statistical6' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難.png")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難', '原始數據下載')}  download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否知道住家附近的政府公告避難地點 VS 有沒有至空曠處避難', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c886">
			<div className={`${(valueType[6].name === 'pdf6' || valueType === null ? ' -active' : '')} statistics-charts chat07`}>
				<img src={require("../../../images/TainanEarthquake/chat07.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[6].name === 'Statistical6' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" rowSpan="2"  valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3" valign="middle">

				<p align="center" >有沒有至空曠處避難</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >有</p>
			</td>
			<td valign="middle">

				<p align="right" >沒有</p>
			</td>
			<td valign="middle">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" rowSpan="6"  valign="middle">

				<p>是否知道住家附近的</p>

				<p>政府公告避難地點 </p>
			</td>
			<td rowSpan="2"  valign="middle">

				<p>知道</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >98</p>
			</td>
			<td valign="middle">

				<p align="right" >370</p>
			</td>
			<td valign="middle">

				<p align="right" >468</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >20.9%</p>
			</td>
			<td valign="middle">

				<p align="right" >79.1%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>不知道</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >183</p>
			</td>
			<td valign="middle">

				<p align="right" >1458</p>
			</td>
			<td valign="middle">

				<p align="right" >1641</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >11.2%</p>
			</td>
			<td valign="middle">

				<p align="right" >88.8%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p>次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >281</p>
			</td>
			<td valign="middle">

				<p align="right" >1828</p>
			</td>
			<td valign="middle">

				<p align="right" >2109</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >13.3%</p>
			</td>
			<td valign="middle">

				<p align="right" >86.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="7"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
	
		</div>
	</section>

	<hr></hr>

	<section id="content11">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>近8成的民眾沒有投保地震險，< br/>你投保了嗎？</h2>
				</div>
				<p>調查結果顯示有高達75.6%的民眾沒有投保地震險，雖然地震險現為火險的附加險，貸款房屋皆須強制加保。但臺南住宅多數年齡已過貸款年限至少20年以上，甚至更老。另外還有少數新屋購入時並無申請貸款。推測是以上原因導致較高比例的民眾沒有投保地震險。<br />
				<br />
				本次調查也發現，臺南的民眾經過0206地震後，原來未投保的家戶，有意願投保的比率為22.1％。
				可見地震對地震保險的影響也可能會增加。</p>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf7" 
								num="7"
								
								checked={ this.state.valueType[7].name === 'pdf7' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical7" 
								num="7"
								
								checked={this.state.valueType[7].name === 'Statistical7' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.png")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '原始數據下載')}  download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[7].name === 'pdf7' || valueType === null ? ' -active' : '')} statistics-charts chat08`}>
				<img src={require("../../../images/TainanEarthquake/chat08.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[7].name === 'Statistical7' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p align="center" >民眾是否有投保地震險</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>項目</p>
			</td>
			<td valign="bottom">
				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">
				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>有</p>
			</td>
			<td valign="bottom">
				<p align="right" >365</p>
			</td>
			<td valign="bottom">
				<p align="right" >17.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>沒有</p>
			</td>
			<td valign="bottom">
				<p align="right" >1594</p>
			</td>
			<td valign="bottom">
				<p align="right" >75.6%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>不知道/很難說</p>
			</td>
			<td valign="bottom">
				<p align="right" >149</p>
			</td>
			<td valign="bottom">
				<p align="right" >7.1%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>總計</p>
			</td>
			<td valign="bottom">
				<p align="right" >2108</p>
			</td>
			<td valign="bottom">
				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>

<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p align="center" >沒有投保之民眾，地震後，是否有投保意願</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>項目</p>
			</td>
			<td valign="bottom">
				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">
				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>有</p>
			</td>
			<td valign="bottom">
				<p align="right" >353</p>
			</td>
			<td valign="bottom">
				<p align="right" >22.1%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>沒有</p>
			</td>
			<td valign="bottom">
				<p align="right" >1092</p>
			</td>
			<td valign="bottom">
				<p align="right" >68.5%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>不知道/很難說</p>
			</td>
			<td valign="bottom">
				<p align="right" >149</p>
			</td>
			<td valign="bottom">
				<p align="right" >9.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>總計</p>
			</td>
			<td valign="bottom">
				<p align="right" >1594</p>
			</td>
			<td valign="bottom">
				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
	</section>

	<hr></hr>

	<section id="content12">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>地震發生次數多，< br/>卻只有2成的人準備防災包...</h2>
				</div>
				<p>在面臨突發的災害來襲時，除了提升上述防災知識的認識、多參與相關教育訓練、演習、投保保險外，也可以準備防災包，以備不時之需。<br /><br />

				防災包的目的是災害發生後，若家裡不安全，讓民眾能迅速帶走必需且重要的物品，在避難時就能發揮保命的關鍵作用。平時可放置在家中大門口或是玄關等方便取得之處，以便危急時刻可以立即取得。本次調查發現，地震發生前有準備防災包的比率約19%，原先沒有準備的民眾當中，有6.2%在震後開始準備，顯示出0206地震喚醒了部分民眾，願意開始為自己的避難多做一點準備。
				</p>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf8" 
								num="8"
								
								checked={ this.state.valueType[8].name === 'pdf8' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical8" 
								num="8"
								
								checked={this.state.valueType[8].name === 'Statistical8' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.png")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '原始數據下載')}  download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/是否有投保地震險.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '是否有投保地震險', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[8].name === 'pdf8' || valueType === null ? ' -active' : '')} statistics-charts chat09`}>
				<img src={require("../../../images/TainanEarthquake/chat09.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[8].name === 'Statistical8' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p align="center" >民眾是否有準備防災包</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>項目</p>
			</td>
			<td valign="bottom">
				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">
				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>有</p>
			</td>
			<td valign="bottom">
				<p align="right" >401</p>
			</td>
			<td valign="bottom">
				<p align="right" >19.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>沒有</p>
			</td>
			<td valign="bottom">
				<p align="right" >1702</p>
			</td>
			<td valign="bottom">
				<p align="right" >80.7%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>不知道/很難說</p>
			</td>
			<td valign="bottom">
				<p align="right" >6</p>
			</td>
			<td valign="bottom">
				<p align="right" >0.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">
				<p>總計</p>
			</td>
			<td valign="bottom">
				<p align="right" >2109</p>
			</td>
			<td valign="bottom">
				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>

<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" valign="bottom">				<p align="center" >【沒有準備防災包之民眾】地震後，是否有準備防災包</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">
				<p>項目</p>
			</td>
			<td valign="bottom">
				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">
				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="bottom">
				<p>有</p>
			</td>
			<td valign="bottom">
				<p align="right" >106</p>
			</td>
			<td valign="bottom">
				<p align="right" >6.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">
				<p>沒有</p>
			</td>
			<td valign="bottom">
				<p align="right" >1596</p>
			</td>
			<td valign="bottom">
				<p align="right" >93.7%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">
				<p>不知道/很難說</p>
			</td>
			<td valign="bottom">
				<p align="right" >1</p>
			</td>
			<td valign="bottom">
				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">
				<p>總計</p>
			</td>
			<td valign="bottom">
				<p align="right" >1702</p>
			</td>
			<td valign="bottom">
				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4" valign="bottom">				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
	</section>

	<section id="content13">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h3>防災包內容物大解析</h3>
				</div>
				<p>災害來襲時，當下很難有時間準備防災包，所以平時備妥格外重要。而防災包裡該準備哪些項目，以下清單提供參考。
					（參考<a href="https://www.tfdp.com.tw/cht/index.php?code=list&flag=detail&ids=83&article_id=203" target="_blank">消防署網頁</a>／<a href="https://www.youtube.com/watch?v=02Pc7pov2CI" target="_blank">國家災害防救科技中心「地震 ！別怕！家庭必備的防災互動書」</a>）
					</p>
			</div>
		</div>
		<div className="container-c c640">
			<div className="pic chat10">

				<a href="#popup1" className="popbtn p01"></a>
				<a href="#popup2" className="popbtn p02"></a>
				<a href="#popup3" className="popbtn p03"></a>
				<a href="#popup4" className="popbtn p04"></a>
				<a href="#popup5" className="popbtn p05"></a>
				<img src={require("../../../images/TainanEarthquake/pic06.svg")} width="100%"/>
			</div>
		</div>
	</section>

	<div id="popup1" className="overlay">
		<div className="popup">
			<a className="close" href="#popup:target">&times;</a>
			<h2>飲用水與緊急糧食</h2>
			<p className="content">
				可準備飲用水、餅乾、防災食品、真空速食食品等簡單的糧食，有嬰兒的家庭應另外準備奶粉、真空包裝或罐頭副食品等。
			</p>
		</div>
	</div>

	<div id="popup2" className="overlay">
		<div className="popup">
			<a className="close" href="#popup:target">&times;</a>
			<h2>醫療及清潔用品</h2>
			<p className="content">
				優碘、棉花棒、紗布等急救消毒用品、溫度計、口罩、乾洗手、面紙、濕紙巾、衛生棉、
				每日服用藥及常用藥（要注意藥品保存時效及方式）等。
			</p>
		</div>
	</div>

	<div id="popup3" className="overlay">
		<div className="popup">
			<a className="close" href="#popup:target">&times;</a>
			<h2>其他</h2>
			<p className="content">
				預先準備戶外用收音機、手電筒、電池、打火機、行動電源、充電器材、塑膠袋、防災地圖（可至內政部消防署或各直轄市、縣(市)政府網站下載）。<br />
				若家中有寵物，寵物的飲食也需要準備，一併放入緊急避難包中。
			</p>
		</div>
	</div>

	<div id="popup4" className="overlay">
		<div className="popup">
			<a className="close" href="#popup:target">&times;</a>
			<h2>禦寒衣物/用品</h2>
			<p className="content">
			可準備保暖外套（視季節而定）、雨衣、睡袋、小毛毯、襪子。<br />

			依照使用者的生活需求擇定，例如貼身衣物、衛生用品、手套等。<br />

			若家中有嬰幼兒，可準備數片尿布及備用嬰兒背帶。
			</p>
		</div>
	</div>

	<div id="popup5" className="overlay">
		<div className="popup">
			<a className="close" href="#popup:target">&times;</a>
			<h2>貴重物品</h2>
			<p className="content">
				可準備重要證件的影本（如身分證、健保卡），以及少許現金與零錢
			</p>
		</div>
	</div>
	
	<hr></hr>

	<section id="content14">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>地震後損失大比較！< br/>停水停電虧多少？</h2>
				</div>
				<p>0206地震造成多項維生系統毀損，例如維冠金龍大樓倒塌，壓毀附近管線，導致部分家戶停水。<br />
					本次調查有受到地震影響的家戶中，其中16.5%為停電，平均停電時數為10小時，因停電造成的平均損失金額為3,519元。另有50.5%家戶遭遇停水問題，平均停水的天數為7天，因停水造成額外花費的平均金額為1,555元。
					
				</p>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf9" 
								num="9"
								
								checked={ this.state.valueType[9].name === 'pdf9' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical9" 
								num="9"
								
								checked={this.state.valueType[9].name === 'Statistical9' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/TainanEarthquake/download/地震後損失大比較！停水停電虧多少.png")} target="_blank" onClick={() => gaTracking('台南地震', '地震後損失大比較！停水停電虧多少', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/TainanEarthquake/download/地震後損失大比較！停水停電虧多少.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '地震後損失大比較！停水停電虧多少', '原始數據下載')}  download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li><Link to={require("../../../images/TainanEarthquake/download/地震後損失大比較！停水停電虧多少.xlsx")} target="_blank" onClick={() => gaTracking('台南地震', '地震後損失大比較！停水停電虧多少', '原始數據下載')}  download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[9].name === 'pdf9' || valueType === null ? ' -active' : '')} statistics-charts chat10`}>
				<img src={require("../../../images/TainanEarthquake/chat10.svg")} width="100%"/>
			</div>
			<div className={`${(valueType[9].name === 'Statistical9' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="middle">

				<p align="center" >停水天數之次數</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>項目</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>1天</p>
			</td>
			<td valign="middle">

				<p align="right" >99</p>
			</td>
			<td valign="middle">

				<p align="right" >9.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>2-3天</p>
			</td>
			<td valign="middle">

				<p align="right" >255</p>
			</td>
			<td valign="middle">

				<p align="right" >24.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>4-5天</p>
			</td>
			<td valign="middle">

				<p align="right" >92</p>
			</td>
			<td valign="middle">

				<p align="right" >8.6%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>6-7天</p>
			</td>
			<td valign="middle">

				<p align="right" >271</p>
			</td>
			<td valign="middle">

				<p align="right" >25.5%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>8-14天</p>
			</td>
			<td valign="middle">

				<p align="right" >212</p>
			</td>
			<td valign="middle">

				<p align="right" >19.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>15-21天</p>
			</td>
			<td valign="middle">

				<p align="right" >85</p>
			</td>
			<td valign="middle">

				<p align="right" >8.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>22-28天</p>
			</td>
			<td valign="middle">

				<p align="right" >5</p>
			</td>
			<td valign="middle">

				<p align="right" >0.5%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>29天以上</p>
			</td>
			<td valign="middle">

				<p align="right" >20</p>
			</td>
			<td valign="middle">

				<p align="right" >1.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >25</p>
			</td>
			<td valign="middle">

				<p align="right" >2.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >1064</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="center" >平均天數</p>
			</td>
			<td colSpan="2"  valign="middle">

				<p align="center" >7天</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="middle">

				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>

<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" valign="middle">

				<p align="center" >因停水所致之每日額外花費</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>項目 &nbsp; &nbsp; &nbsp; &nbsp;</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1千元以下（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >370</p>
			</td>
			<td valign="middle">

				<p align="right" >34.8%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1千元（含）～2千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >106</p>
			</td>
			<td valign="middle">

				<p align="right" >10.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>2千元（含）～3千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >45</p>
			</td>
			<td valign="middle">

				<p align="right" >4.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>3千元（含）～4千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >20</p>
			</td>
			<td valign="middle">

				<p align="right" >1.9%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>4千元（含）～5千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >9</p>
			</td>
			<td valign="middle">

				<p align="right" >0.8%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>5千元（含）～6千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >10</p>
			</td>
			<td valign="middle">

				<p align="right" >0.9%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>6千元（含）～7千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >2</p>
			</td>
			<td valign="middle">

				<p align="right" >0.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>7千元（含）～8千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >5</p>
			</td>
			<td valign="middle">

				<p align="right" >0.5%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1萬元（含）～2萬元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >10</p>
			</td>
			<td valign="middle">

				<p align="right" >0.9%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>2萬元以上</p>
			</td>
			<td valign="middle">

				<p align="right" >9</p>
			</td>
			<td valign="middle">

				<p align="right" >0.8%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>沒有花費</p>
			</td>
			<td valign="middle">

				<p align="right" >423</p>
			</td>
			<td valign="middle">

				<p align="right" >39.8%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >55</p>
			</td>
			<td valign="middle">

				<p align="right" >5.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >1064</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>平均花費</p>
			</td>
			<td colSpan="2"  valign="middle">

				<p align="right" >$1,555</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4" valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4"  valign="middle">

				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>

<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="3"  valign="middle">

				<p align="center" >停電時數之次數</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>項目 &nbsp; &nbsp; &nbsp; &nbsp;</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>2小時以下</p>
			</td>
			<td valign="middle">

				<p align="right" >119</p>
			</td>
			<td valign="middle">

				<p align="right" >34.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>3-4小時</p>
			</td>
			<td valign="middle">

				<p align="right" >104</p>
			</td>
			<td valign="middle">

				<p align="right" >30.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>5-6小時</p>
			</td>
			<td valign="middle">

				<p align="right" >34</p>
			</td>
			<td valign="middle">

				<p align="right" >9.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>7-8小時</p>
			</td>
			<td valign="middle">

				<p align="right" >15</p>
			</td>
			<td valign="middle">

				<p align="right" >4.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>9-10小時</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td valign="middle">

				<p align="right" >1.7%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>11-12小時</p>
			</td>
			<td valign="middle">

				<p align="right" >11</p>
			</td>
			<td valign="middle">

				<p align="right" >3.2%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>13-18小時</p>
			</td>
			<td valign="middle">

				<p align="right" >1</p>
			</td>
			<td valign="middle">

				<p align="right" >0.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>19-24小時</p>
			</td>
			<td valign="middle">

				<p align="right" >21</p>
			</td>
			<td valign="middle">

				<p align="right" >6.1%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>25-36小時</p>
			</td>
			<td valign="middle">

				<p align="right" >0</p>
			</td>
			<td valign="middle">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>37-48小時</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td valign="middle">

				<p align="right" >0.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>48小時以上</p>
			</td>
			<td valign="middle">

				<p align="right" >8</p>
			</td>
			<td valign="middle">

				<p align="right" >2.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >25</p>
			</td>
			<td valign="middle">

				<p align="right" >7.2%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >347</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p>平均時數</p>
			</td>
			<td colSpan="2"  valign="middle">

				<p align="right" >10小時</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">
				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="middle">

				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>

<table cellpadding="0" cellspacing="0">
	<tbody>
		<tr>
			<td colSpan="4" valign="middle">

				<p align="center" >因停電所致之每日額外花費</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>項目 &nbsp; &nbsp; &nbsp; &nbsp;</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >有效百分比</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1千元以下（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >32</p>
			</td>
			<td valign="middle">

				<p align="right" >9.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1千元（含）～2千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >19</p>
			</td>
			<td valign="middle">

				<p align="right" >5.5%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>2千元（含）～3千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >14</p>
			</td>
			<td valign="middle">

				<p align="right" >4.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>3千元（含）～4千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >9</p>
			</td>
			<td valign="middle">

				<p align="right" >2.6%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>4千元（含）～5千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td valign="middle">

				<p align="right" >0.9%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>5千元（含）～6千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td valign="middle">

				<p align="right" >0.9%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>6千元（含）～7千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >0</p>
			</td>
			<td valign="middle">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>7千元（含）～8千元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >1</p>
			</td>
			<td valign="middle">

				<p align="right" >0.3%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>1萬元（含）～2萬元（不含）</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td valign="middle">

				<p align="right" >1.7%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>2萬元以上</p>
			</td>
			<td valign="middle">

				<p align="right" >4</p>
			</td>
			<td valign="middle">

				<p align="right" >1.2%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>沒有花費</p>
			</td>
			<td valign="middle">

				<p align="right" >220</p>
			</td>
			<td valign="middle">

				<p align="right" >63.4%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >36</p>
			</td>
			<td valign="middle">

				<p align="right" >10.4%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>總計</p>
			</td>
			<td valign="middle">

				<p align="right" >347</p>
			</td>
			<td valign="middle">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2"  valign="middle">

				<p>平均花費</p>
			</td>
			<td colSpan="2"  valign="middle">

				<p align="right" >$3,519</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4" valign="bottom">				<p>
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4"  valign="middle">

				<p>資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
	</section>

	<section id="content15">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h3>減輕天然災害的衝擊是你我的共同責任</h3>
				</div>
				<p>調查結果說明了防災宣導活動或演習教育訓練，對民眾有正面的幫助。但也發現民眾對於趴、掩、穩的認知、避難地點的掌握、防災包的準備、地震投保的意願、參與防災教育或演練的經驗皆偏少，是政府部門、全體國民與社區可以共同努力的方向 ! <br /><br />
					想要知道更多地震的防減災相關資訊，可參考
				</p>
			</div>
			<div className="btn-g">
				<a href="https://www.youtube.com/watch?v=02Pc7pov2CI"  target="_blank" className="btn">
					國家災害防救科技中心<br />
					<small>《地震!別怕!:家庭必備的防災互動書》</small>
				</a>
				<a href="https://www.tfdp.com.tw/cht/index.php" target="_blank" className="btn">
					內政部消防署 消防防災館
				</a>

				<a href="https://www.tfdp.com.tw/cht/index.php" target="_blank" className="btn">
					0206地震災害事件簿
				</a>
			</div>
			
		</div>
		
	</section>
	<section id="content15">
		<div className="container-c c886">
			<blockquote>
				<div className="title">
					<h3>0206臺南地震調查說明</h3>
				</div>
				<p>本次調查針對 0206 地震震度六級以上，因地震所造成建築物與相關設施倒塌、毀壞，並被技師工會評估危險建物且被貼上紅、黃單數多的行政區(南投竹山鎮、雲林古坑鄉、嘉義阿里山鄉與梅山鄉、臺南楠西區、玉井區、南化區、官田區、大內區、山上區、左鎮區、龍崎區、關廟區、新化區、新市區及善化區)進行電訪。由趨勢民意調查股份有限公司執行，調查時間為2016年8月，受訪者為25歲以上居民，有效樣本數為2,109筆，在95％信心水準下，抽樣誤差在正負2.1個百分點。</p>
				
			</blockquote>
		</div>
		

	</section>

	<section className="svi-section svi-articlelist">
		<div className="container">
			<h2 className="sectiontitle">你可能會想看</h2>
		
			<div className="row subject-list">
				
				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/HualienEarthquake">
							<img src={require("../../../images/HualienEarthquake/1201.jpg")} width="100%"/>

						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/90">2021.08.16</a> */}
							<a className="title" href="/statistics/HualienEarthquake">0206花蓮震災地震感知與避難行為調查(2018)</a>
							{/* <div className="author"><label>作者 </label> 莊睦雄、蕭宜軒 </div>
							<a className="summary" href="/evaluation/svi/article/90">銘傳大學都市規劃與防災學系已經將減災動資料平臺納入大學部三年級規劃設計與災害管理實習課程以及碩士在職專班的教材，本文採用社會脆弱度客製化功能分享教學成果</a> */}
						</div>
					</div>
				</div>

				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/AnalysisEarthquakeSurvey">
							<img src={require("../../../images/AnalysisEarthquakeSurvey/mainpic.jpg")} width="100%"/>
							
						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/112">2022.11.08</a> */}
							<a className="title" href="/statistics/AnalysisEarthquakeSurvey">同一天不同地點，臺南地震 VS 花蓮地震 調查大解析</a>
							{/* <div className="author"><label>作者 </label> 林美君/NCDR </div>
							<a className="summary" href="/evaluation/svi/article/112">社會脆弱度評估系統微整型</a> */}
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
	
	<section className="into">
		<div className="container">
			<div className="title">
				<h3>資料來源</h3>
				<h6>天然災害社經影響家戶調查：臺南0206地震 （2016）</h6>
			</div>
            
            <ul>
				<li>
					
					<div className="link">
						<a href="https://easy2do.ncdr.nat.gov.tw/easy2do/images/ncdr/socialsurvey/NCDR106T03.pdf" target="_blank">調查報告下載	</a>
						
					</div>
				</li>
				<li>
					
					<div className="link">
						<a href="https://datahub.ncdr.nat.gov.tw/dataset?q=2016%E5%A4%A9%E7%84%B6%E7%81%BD%E5%AE%B3%E7%A4%BE%E7%B6%93%E5%BD%B1%E9%9F%BF%E5%AE%B6%E6%88%B6%E8%AA%BF%E6%9F%A5%EF%BC%9A0206%E9%9C%87%E7%81%BD" target="_blank"> 調查資料申請</a>
						
					</div>
				</li>
            </ul>
        </div>
    </section>
				
				
			</div>
		);
	}
}

export default TainanEarthquakeIndex;
