let init = {
	open: false,
	
}

export default (state = init, action) => {
	switch (action.type) {
		case 'OPEN_SIDEMENU':
			return { ...state, open: true }

		case 'CLOSE_SIDEMENU':
			return { ...state, open: false }

		default:
			return state
	}
}
