import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import Helmet from "react-helmet";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import "./index.scss";
import ReCAPTCHA from "react-google-recaptcha";
import CryptoJS from "crypto-js";

// Utils
import ModalBox from "../../utils/ModalBox";
import CustomCheckbox from "../../utils/CustomCheckbox";
import CustomInput from "../../utils/CustomInput";
import {ReCAPTCHA_siteKey} from '../../utils/Shared/constant';
import { facebookAppId, googleClientId } from "../../utils/Shared/constant";
import { apiHost, loginApiHost } from "../../utils/Shared/constant";



// Resouces
import FacebookSVG from "../../../images/facebook.svg";
import GoogleSVG from "../../../images/google.svg";

class MemberLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passReCAPTCHA: false,
      isModalOpen: false,
      isKeepLogin: false,
      ReCAPTCHA_Token: '',
      loginBtnState: "登入",
      errorMsg: "登入資訊錯誤，請重新登入",
      inputEmail: "",
      inputPassword: "",
      loginFail: false,
      redirectUrl: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadHeaderColor("YELLOW");
    this.props.onLoadHeaderTheme("DARK");
    this.props.onLoadShowLanguage("HIDE");

    const breadcrumb = [
      { name: "首頁", href: "/" },
      { name: "會員登入", href: "/member/login" },
    ];
    this.props.onLoadShowBreadcrumb("SHOW", breadcrumb);
    this.props.setSocialNetworkResponse({});
    this.handleCheckKeepLogin();
  }
  handleCheckKeepLogin = () => {
    if (window.localStorage["keep-login"]) {
      this.setState({
        isKeepLogin: JSON.parse(window.localStorage["keep-login"]),
      });
    }
  };
  handleCheckReCAPTCHA = (value) => {
  	if (value !== null) {
  		this.setState({
  			passReCAPTCHA: true,
  			ReCAPTCHA_Token: value
  		})
  	}
  }
  encrypt = (word) => {
    var key = CryptoJS.enc.Utf8.parse("8080808080808080");
    var iv = CryptoJS.enc.Utf8.parse("8080808080808080");
    var encrypted = CryptoJS.AES.encrypt(word, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };
  handleClickLogin = () => {
    const {ReCAPTCHA_Token} = this.state;
    const { inputEmail, inputPassword } = this.state;
    const hash = this.encrypt(inputPassword);

    this.setState({ loginBtnState: "登入中" });

    Axios.post(
      `${apiHost}/api/Account/Login`,
      {
        LoginKey: hash,
        Email: inputEmail,
        ReCAPTCHA_Token: ReCAPTCHA_Token,
        LoginCategory: 0,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((res) => {
      if (res.data.Outstring === "0000") {
        this.handleLogined(res.data.Result);
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:1
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      } else if (res.data.Outstring === "3012") {
        this.handleLoginedForceEdit(res.data.Result);
      } else if (res.data.Outstring === "3009") {
        console.log("fb");
        this.setState({
          loginFail: true,
          inputEmail: "",
          inputPassword: "",
          loginBtnState: "登入",
          errorMsg: "Facebook或Gmail帳號已使用該電子郵件註冊",
        });
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:0
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      } else if (res.data.Outstring === "3011") {
        this.setState({
          loginFail: true,
          inputEmail: "",
          inputPassword: "",
          loginBtnState: "登入",
          errorMsg: "登入失敗3次請於15分鐘後再重新登入",
        });
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:0
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      } else {
        this.setState({
          loginFail: true,
          inputEmail: "",
          inputPassword: "",
          loginBtnState: "登入",
          errorMsg: "登入資訊錯誤，請重新登入",
        });
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:0
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      }
    });
	
  };

  handleFacebookLogin = (response) => {
    this.setState({ loginBtnState: "登入中" });
    Axios.post(
      `${apiHost}/api/Account/Login`,
      {
        AccessToken: response.accessToken,
        Email: response.email,
        LoginCategory: 1,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((res) => {
      if (res.data.Outstring === "0000") {
        this.handleLogined(res.data.Result);
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:1
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      } else if (res.data.Outstring === "3004") {
        response.sn_type = "facebook";
        this.props.setSocialNetworkResponse(response);
        this.setState({ redirectUrl: "/member/register/person/?sn=facebook" });
      } else {
        this.setState({
          loginFail: true,
          inputEmail: "",
          inputPassword: "",
          loginBtnState: "登入",
        });
        Axios.post(
          `${loginApiHost}/flood/Authorization`,
          {
            userlogin:0
          },
          {
            headers: { "Content-Type": "application/json" },
          }
          ).then((res) => {
            console.log(res);
          }).catch((err)=>{
            console.log(err);
          });
      }
    });
  };

  handleGoogleLogin = (response) => {
    this.setState({ loginBtnState: "登入中" });
    Axios.post(
      `${apiHost}/api/Account/Login`,
      {
        AccessToken: response.tokenId,
        Email: response.profileObj.email,
        LoginCategory: 2,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res.data.Outstring === "0000") {
          this.handleLogined(res.data.Result);
          Axios.post(
            `${loginApiHost}/flood/Authorization`,
            {
              userlogin:1
            },
            {
              headers: { "Content-Type": "application/json" },
            }
            ).then((res) => {
              console.log(res);
            }).catch((err)=>{
              console.log(err);
            });
        } else if (
          res.data.Outstring === "3004" ||
          res.data.Outstring === "3008"
        ) {
          response.sn_type = "google";
          this.props.setSocialNetworkResponse(response);
          this.setState({ redirectUrl: "/member/register/person/?sn=google" });
        } else {
          this.setState({
            loginFail: true,
            inputEmail: "",
            inputPassword: "",
            loginBtnState: "登入",
          });
          Axios.post(
            `${loginApiHost}/flood/Authorization`,
            {
              userlogin:0
            },
            {
              headers: { "Content-Type": "application/json" },
            }
            ).then((res) => {
              console.log(res);
            }).catch((err)=>{
              console.log(err);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleEGovernmentClick = () => {
    Axios('https://drrstat.ncdr.nat.gov.tw/Nat/api/account/GetCpUrl')
      .then(result =>{
        // window.open(result.data.Result, "_self")
        window.open(result.data.Result)
      })
  }
  handleEGovernmentLogin  = (response) => {
    this.setState({ loginBtnState: "登入中" });
    Axios.post(
      `${apiHost}/api/Account/Login`,
      {
        AccessToken: response.tokenId,
        Email: response.profileObj.email,
        LoginCategory: 3,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res.data.Outstring === "0000") {
          this.handleLogined(res.data.Result);
          Axios.post(
            `${loginApiHost}/flood/Authorization`,
            {
              userlogin:1
            },
            {
              headers: { "Content-Type": "application/json" },
            }
            ).then((res) => {
              console.log(res);
            }).catch((err)=>{
              console.log(err);
            });
        } else if (
          res.data.Outstring === "3004" ||
          res.data.Outstring === "3008"
        ) {
          response.sn_type = "google";
          this.props.setSocialNetworkResponse(response);
          this.setState({ redirectUrl: "/member/register/person/?sn=google" });
        } else {
          this.setState({
            loginFail: true,
            inputEmail: "",
            inputPassword: "",
            loginBtnState: "登入",
          });
          Axios.post(
            `${loginApiHost}/flood/Authorization`,
            {
              userlogin:0
            },
            {
              headers: { "Content-Type": "application/json" },
            }
            ).then((res) => {
              console.log(res);
            }).catch((err)=>{
              console.log(err);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLogined = (token) => {
    window.localStorage.setItem("user-token", token);
    window.localStorage.setItem("keep-login", this.state.isKeepLogin);
    const prevPage = window.localStorage.pageBeforeLogin || null;

    if (prevPage) {
      window.location = prevPage;
    } else {
      window.location = "/";
    }
  };

  handleLoginedForceEdit = (token) => {
    this.setState({ isModalOpen: true });
    window.localStorage.setItem("user-token", token);
    setTimeout(() => {
      window.location = "/member/edit";
    }, 3000);
  };

  handleChangeLoginStatus = (e) => {
    this.setState({ isKeepLogin: e });
  };

  render() {
    const {
      loginBtnState,
      inputEmail,
      inputPassword,
      loginFail,
      redirectUrl,
      errorMsg,
      isKeepLogin,
    } = this.state;
    return (
      <div className="member-page">
        <Helmet>
          <title>減災動資料 - 會員登入</title>
        </Helmet>

        <ModalBox
          open={this.state.isModalOpen}
          theme="yellow"
          onRequestClose={() => (window.location = "/member/edit")}
        >
          <div className="modalbody -center">
            <p className="type-text -ch -md">
              您的密碼超過三個月未變更，請至會員中心修改密碼
            </p>
          </div>
          <div className="modalfooter -center">
            <button
              className="fill-button -yellow"
              onClick={() => (window.location = "/member/edit")}
            >
              確定
            </button>
          </div>
        </ModalBox>

        {redirectUrl && <Redirect push to={redirectUrl} />}
        <div className="container">
          <div className="row">
            <section className="member-login member-section col-lg-6 offset-lg-3">
              <div className="heading">
                <span className="type-heading -ch -h3">會員登入</span>
              </div>
              <div className="social social-login row">
                <FacebookLogin
                  appId={facebookAppId}
                  callback={this.handleFacebookLogin}
                  fields="name,email,picture"
                  render={(renderProps) => (
                    <div className="col-6 button-width">
                      <button className="button" onClick={renderProps.onClick}>
                        <img src={FacebookSVG} alt="" />
                        <span className="type-text -en -md">FACEBOOK</span>
                      </button>
                    </div>
                  )}
                />

                <GoogleLogin
                  clientId={googleClientId}
                  onSuccess={this.handleGoogleLogin}
                  onFailure={(e) => {
                    console.log(e);
                  }}
                  render={(renderProps) => (
                    <div className="col-6 button-width">
                      <button className="button" onClick={renderProps.onClick}>
                        <img src={GoogleSVG} alt="" />
                        <span className="type-text -en -md">GOOGLE</span>
                      </button>
                    </div>
                  )}
                />
                <div className="col-6 button-width" onClick={this.handleEGovernmentClick}>
                  <button className="button" >
                    <span className="type-text -en -md">我的E政府</span>
                  </button>
                </div>
              </div>
              <div className="slash slash-or">
                <span className="type-text -en -md">OR</span>
              </div>
              <form
                className="form login-form"
                action={`${apiHost}/api/Account/Login`}
                method="POST"
              >
                <div className="formitem">
                  <CustomInput
                    status={loginFail ? "danger" : "normal"}
                    msg={loginFail ? "" : ""}
                    name="email"
                    id="useremail"
                    type="email"
                    label="Email*"
                    placehold="champion@ncdr.gov.tw"
                    value={inputEmail}
                    onChange={(t) => this.setState({ inputEmail: t })}
                  />
                </div>
                <div className="formitem">
                  <CustomInput
                    status={loginFail ? "danger" : "normal"}
                    msg={loginFail ? errorMsg : ""}
                    name="password"
                    id="password"
                    type="password"
                    label="密碼*"
                    value={inputPassword}
                    onChange={(t) => this.setState({ inputPassword: t })}
                  />
                </div>
                <div className="row">
                  <div className="formitem -left col-md-6">
                    <CustomCheckbox
                      id="allowlogin"
                      className="checkbox"
                      checked={isKeepLogin}
                      onChange={(e) => this.handleChangeLoginStatus(e)}
                    >
                      <span className="type-text -ch -xs">
                        保持我的登入狀態
                      </span>
                    </CustomCheckbox>
                  </div>
                  <div className="formitem -right col-md-6">
                    <Link
                      className="forgot link-text"
                      to="/member/password/forgot"
                    >
                      <span className="type-text -ch -xs">忘記密碼</span>
                    </Link>
                  </div>
                </div>
                <div className="formitem">
                  <ReCAPTCHA
										sitekey={ReCAPTCHA_siteKey}
										onChange={this.handleCheckReCAPTCHA}
									/>
                </div>
                <div className="formitem -center">
                  <button
                    type="button"
                    className="submit fill-button -yellow"
                    disabled={!(this.state.passReCAPTCHA && loginBtnState === '登入' && inputEmail !== '' && inputPassword !== '')}
                    onClick={this.handleClickLogin}>
                    <span className="type-text -ch -md">{loginBtnState}</span>
                  </button>
                  <p className="separate">或</p>
                  <Link
                    to="/member/register"
                    className="submit fill-button -dark"
                  >
                    <span className="type-text -ch -md">註冊</span>
                  </Link>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default MemberLogin;
