export function shareToFB(extra) {
    if(extra === undefined) {
        extra = ''
    }
    window.open('http://www.facebook.com/share.php?u='.concat(encodeURIComponent(window.location.href)));
}
export function shareToLine() {
    var link = "http://line.naver.jp/R/msg/text/?";
    link += "" + "%0D%0A" + encodeURIComponent(window.location.href);
    window.open(link);
    return false;
}
export function shareToTwitter() {
    var content = "";
    var locate = encodeURIComponent(window.location.href);
    var link = "https://twitter.com/intent/tweet?text=" + content + "&url=" + locate;
    window.open(link);
    return false;
}

export function emailValidation(value) {
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    return value.search(emailRule) !== -1;
};

export function passwordValidation(pw) {
    const letterChecker = (pw.match(/\D+/g) || []).length > 0;
    const numericChecker = (pw.match(/\d+/g) || []).length > 0;
    const spaceChecker = (pw.match(/\s/g) || []).length <= 0;
    const lengthChecker = pw.length >= 6;
    const fullChecker = pw.match(/^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/g);
    return letterChecker && numericChecker && spaceChecker && lengthChecker && fullChecker;
}

export function model(state) {
    const varToString = varObj => Object.keys(varObj)[0]
    const getValue = varObj => Object.values(varObj)[0]

    return {
        value: getValue(state),
        onChange: v => {
        let newState = {};
        newState[varToString(state)] = v;
        this.setState(newState)
        },
    }
}

export function transferHundredPoint(number) {
    return Number(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function loginRedirect() {
    const url = window.location.pathname;
    let redirect = url ? url : '/';
    localStorage.setItem('pageBeforeLogin', redirect);
    window.location = '/member/login';
}

export function registerRedirect() {
    // const url = window.location.pathname;
    // let redirect = url ? url : '/';
    // localStorage.setItem('pageBeforeLogin', redirect);
    localStorage.setItem('pageBeforeLogin', '/');
    window.location = '/member/register';
}

export function downloadTrigger(fileName, download) {
    const trigger = document.createElement('a');
    trigger.setAttribute('download', fileName);
    trigger.setAttribute('href', download);
    trigger.click();
    trigger.remove();
}

export function urlPushState(query) {
    const url = window.location.origin + window.location.pathname + query;
    window.history.pushState(null, null, url);
}