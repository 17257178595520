import React, { Component } from 'react';
import { transferHundredPoint } from '../../utils/Shared/methods';
import './index.scss';
import arrowUp from '../../../images/arrowUp.png'
import arrowDown from '../../../images/arrowDown.png'

class EvacuationChartTable extends Component {
	constructor(params) {
		super(params);
		this.state = {
			csvData: [],
			ascendingSort: {
				0:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				1:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				2:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				3:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				4:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				5:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				6:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				7:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				8:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				9:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
			}
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.handleLoadCsvData();
		}
	}
	componentDidMount() {
		this.handleLoadCsvData();
	}
	handleLoadCsvData() {
		if (this.props.csvData) {
			const { csvData } = this.props
			csvData.forEach((data) => {
				if (data) {
					data.table = data.csv.split('\r\n').map((row) => {
						return row.split(',');
					})
					data.table = data.table.filter(row => row.length > 1)
				}
			})
			this.setState({ csvData })
		}
		else {
			this.setState({
				csvData: [],
			})
		}
	}

	sortCsvData = (indicator, column) =>{
		const { csvData, ascendingSort } = this.state
		console.log(indicator, column)
		if(ascendingSort[indicator][column]){//升冪
			if(column === 0){
				csvData[indicator] && csvData[indicator].table.sort((a,b) =>{
					if(['行政區', '年份', '強度', '路徑'].includes(a[column])){
						return -1
					}else if(['行政區', '年份', '強度', '路徑'].includes(b[column])){
						return 1
					}
					if(a[column] && b[column]){
						return -a[column].localeCompare(b[column], "zh-Hant")
					}
					return 0
				})
			}else{
				csvData[indicator] && csvData[indicator].table.sort((a, b) =>{
					if(['事件', '人次', '累計收容人次', '平均收容人次', '事件數', '累計撤離人次', '平均撤離人次'].includes(a[column])){
						return -1
					}else if(['事件', '人次', '累計收容人次', '平均收容人次', '事件數', '累計撤離人次', '平均撤離人次'].includes(b[column])){
						return 1
					}
					if(Number(isNaN(a[column])) || isNaN(b[column])){
						return -a[column].localeCompare(b[column], "zh-Hant")
					}
					return Number(a[column])-Number(b[column])
				})
			}
		}else{//降冪
			if(column === 0){
				csvData[indicator] && csvData[indicator].table.sort((a,b) =>{
					if(['行政區', '年份', '強度', '路徑'].includes(a[column])){
						return -1
					}else if(['行政區', '年份', '強度', '路徑'].includes(b[column])){
						return 1
					}
					if(a[column] && b[column]){
						return a[column].localeCompare(b[column], "zh-Hant")
					}
					return 0
				})
			}else{
				csvData[indicator] && csvData[indicator].table.sort((a, b) =>{
					if(['事件', '人次', '累計收容人次', '平均收容人次', '事件數', '累計撤離人次', '平均撤離人次'].includes(a[column])){
						return -1
					}else if(['事件', '人次', '累計收容人次', '平均收容人次', '事件數', '累計撤離人次', '平均撤離人次'].includes(b[column])){
						return 1
					}
					if(Number(isNaN(a[column])) || isNaN(b[column])){
						return a[column].localeCompare(b[column], "zh-Hant")
					}
					return Number(b[column])-Number(a[column])
				})
			}
		}
		ascendingSort[indicator][column] = !ascendingSort[indicator][column]
		this.setState({csvData, ascendingSort});
	}

	render() {
		const { csvData, ascendingSort } = this.state
		return (
			<React.Fragment>
				{
					csvData &&
					csvData.map((indicator, indicatorIndex) => {
						return (
							indicator &&
							<React.Fragment key={indicatorIndex}>
								<p className="evacuation-datatitle">{ indicator.title }</p>
								<table className="evacuation-datatable">
									<tbody>
										{
											indicator &&
											indicator.table.map((tableRow, tableRowIndex) => {
												if(tableRowIndex === 0){
													return <React.Fragment key={tableRowIndex}>
																<tr>{ 
																	tableRow.length > 1 &&
																	tableRow.map((tableData, tableDataIndex) => {
																		return (
																			<td style={{cursor:"pointer"}} onClick={()=>this.sortCsvData(indicatorIndex ,tableDataIndex)}>
																				{ tableData }
																				{
																					!ascendingSort[indicatorIndex][tableDataIndex] &&
																					<img className="arrow" src={arrowDown} alt=""/>
																				}
																				{
																					ascendingSort[indicatorIndex][tableDataIndex] &&
																					<img className="arrow" src={arrowUp} alt=""/>
																				}
																			</td>
																		)
																	})
																}</tr>
															</React.Fragment>
												}
												return (
													<React.Fragment key={tableRowIndex}>
														<tr>{
															tableRow.length > 1 &&
															tableRow.map((tableData, tableDataIndex) => {
																return (
																	<React.Fragment key={tableDataIndex}>
																		{
																			(!Number(tableData) || tableDataIndex === 0 || tableRowIndex === 0) 
																			?<td>{ tableData }</td>
																			:<td>{ transferHundredPoint(tableData) }</td>
																		}
																	</React.Fragment>
																)
															})
														}</tr>
													</React.Fragment>
												)
											})
										}
									</tbody>
								</table>
							</React.Fragment>
						)
					})
				}
			</React.Fragment>
		);
	}
}

export default EvacuationChartTable;
