import { connect } from 'react-redux';
import SurveySideModal from './pure';

const mapStateToProps = (state, ownProps) => ({
  isModalOpen: state.surveySideModal.isModalOpen,
})

const mapDispatchToProps = (dispatch) => ({
  onClickOpenSideModal: (MODAL_NAME) => dispatch({ type: 'OPEN_' + MODAL_NAME + '_SIDEMODAL' }),
  onClickCloseModal: () => dispatch({ type: 'CLOSE_SURVEY_SIDEMODAL' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveySideModal);
