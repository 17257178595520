import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import './index.scss';

import SocialBlock from '../../utils/SocialBlock';
import { apiHost, sourceHost } from '../../utils/Shared/constant';

class NewsArticlePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      article: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.article !== null) {
      const breadcrumb = [
        { name: '首頁', href: '/' },
        { name: '最新消息', href: '/news' },
        { name: this.state.article.Title, href: window.location },
      ]
      this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
    }
  }

  
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
    this.loadArticle(this.props.match.params.id);
  }

  loadArticle = (id) => {
    axios.get(`${apiHost}/api/Content/News?data.iD=${id}`)
      .then((res)=>{
        if (res.status === 200) {
            this.setState({ 
              article: res.data.Result,
            })
        }
    })
  }
  
 	render() {
    const {color} = this.props;
    const {article} = this.state;
		return (
      <React.Fragment>
        <div className="news-article">
          {
            article &&
            <React.Fragment>
              <section className="article-heading">
                <div className="container">
                  <div className="row">
                    <div className="col-2 col-lg-1">
                      <div className={'datemonth ' + color}>
                        <span className="month type-text -en -lg">{dayjs(article.Published).format('MMM')}</span>
                        <span className="date type-heading -en -h1">{dayjs(article.Published).format('D')}</span>
                      </div>
                    </div>
                    <div className="col-10 col-lg-9 offset-lg-1">
                      {/* <div className="minititle type-text -ch -xs">{article.Classification}</div> */}
                      <h1 className="heading type-heading -ch -h4">{article.Title}</h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="article-content">
                <div className="container">
                  <div className="row">
                    <div className="col-10 col-lg-9 offset-2">
                      <div className="image">
                        <img src={`${sourceHost}/${article.ImageUrl}`} alt="" />
                      </div>
                      <article className="content type-text -ch -yellow" dangerouslySetInnerHTML={{__html: article.Content}}></article>
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          }
          {
            article === null &&
            <React.Fragment>
              <section className="article-heading">
                <div className="container">
                  <div className="row">
                    <div className="col-2 col-lg-1">
                      <div className={'datemonth ' + color}>
                        <span className="month type-text -en -lg ">{dayjs().format('MMM')}</span>
                        <span className="date type-heading -en -h1 ">{dayjs().format('D')}</span>
                      </div>
                    </div>
                    <div className="col-10 col-lg-9 offset-lg-1">
                      <div className="minititle type-text -ch -xs -loading-block">loading</div>
                      <h1 className="heading type-heading -ch -h4 -loading-block">Lorem ipsum dolor sit amet consectetur</h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="article-content">
                <div className="container">
                  <div className="row">
                    <div className="col-10 col-lg-9 offset-2">
                      <article className="content">
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                        <div className="-loading-block empty-content">loading</div>
                      </article>
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          }
        </div>
        <SocialBlock category="首頁" action="最新消息" theme="yellow" />
      </React.Fragment>
		);
  }
  
}

export default NewsArticlePage;
