import React, { Component } from 'react';
import './index.scss';

class CustomDropdown extends Component {
    constructor(params) {
        super(params);
        this.state = {
            openList: false,
        };
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickCloseList)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickCloseList)
    }

    handleClickCloseList = (e) => {
        if (e.target !== this.refs.dropdown.querySelector('.selected') ) {
            this.setState({
                openList: false,
            })
        }
    }

    handleClickToggleList = () => {
        let openList = this.state.openList;
        this.setState({
            openList: !openList,
        })
    }
    
    render() {
        return (
            <div ref="dropdown" className={"custom-dropdown " + (this.props.className ? this.props.className : '')}>
                <button className={"selected" + (this.state.openList ? " -active" : "")} onClick={ this.handleClickToggleList }>
                    { this.props.title }
                    <i className="icon-dropdown"></i>
                </button>
                <ul className={"list" + (this.state.openList ? " -active": "")}>
                    { this.props.children }
                </ul>
            </div>
        );
    }
}

export default CustomDropdown;
