import html2canvas from 'html2canvas';
import logo from '../../../images/logo.svg';

const capture = (captureArea, fileName, callback) => {
  const scale = 2;

  function generateAllCapture() {
    return new Promise((resolve, reject) => {
      let count = 0;
      const captureImages = [];
      captureArea.forEach((area, i) => {
        const hides = area.querySelectorAll('.capture-hide');
        const index = Number(area.dataset.capture) || i;
        hides.forEach(hide => hide.style.visibility = 'hidden');
        const captureOffsetTop = area.getBoundingClientRect().top + window.pageYOffset;
        const captureOffsetLeft = area.getBoundingClientRect().left;
        html2canvas(area, {
          x: captureOffsetLeft,
          y: captureOffsetTop,
          scale: scale,
          allowTaint: true,
        })
        .then(canvas => {
          const imgElement = new Image();
          imgElement.src = canvas.toDataURL();
          // imgElement.onload = function() {
            captureImages[index] = canvas;
            count ++;
            checkRenderFinish();
          // }
        })
      });

      function checkRenderFinish() {
        count === captureArea.length &&
        resolve(captureImages);
      };

    })
  }

  generateAllCapture().then(images => {
    const resultCanvas = document.createElement('canvas');
    const context = resultCanvas.getContext('2d');
    const canvasWidth = Math.max(...images.map(image => image.width));
    const canvasHeight = images.map(image => image.height).reduce((sum, value) => sum + value);

    const padding = 40;
    const resultCanvasWidth = canvasWidth + padding * scale;
    const resultCanvasHeight = canvasHeight + padding * scale;

    const bottomLogo = new Image();
    bottomLogo.src = logo;
    const logoWidth = 300 * scale;
    const logoHeight = 50 * scale;
    
    resultCanvas.width = resultCanvasWidth;
    resultCanvas.height = resultCanvasHeight + logoHeight;
    context.fillStyle = "white";
    context.fillRect(0, 0, resultCanvasWidth, resultCanvasHeight + logoHeight);

    bottomLogo.onload = function () {
      context.drawImage(bottomLogo, canvasWidth + padding - logoWidth, canvasHeight + padding, logoWidth, logoHeight);
    };
    
    let offsetHeight = 0;
    images.forEach((image) => {
      const imgElement = new Image();
      imgElement.src = image.toDataURL('image/png;base64');
      imgElement.onload = function() {
        context.drawImage(imgElement, padding, padding + offsetHeight, imgElement.width, imgElement.height);
        offsetHeight += imgElement.height;
      };
    });

    const resultImage = new Image();
    resultImage.src = resultCanvas.toDataURL('image/png;base64');
    
    function downloadTrigger() {
      setTimeout(() => {
        if (resultImage.complete) {
          const downloadTrigger = document.createElement('a');
          downloadTrigger.setAttribute('download', `${fileName}.png`);
          downloadTrigger.setAttribute('href', resultCanvas.toDataURL('image/png;base64'));
          downloadTrigger.click();
          downloadTrigger.remove();
          resultCanvas.remove();

          captureArea.forEach(area => {
            const hides = area.querySelectorAll('.capture-hide');
            hides.forEach(hide => hide.style = {});
          })

          callback && callback();
        }
        else {
          downloadTrigger();
        }
      }, 1000);
    }

    downloadTrigger();
    
  });
  
};

export default capture;
