import React, { Component } from 'react';
import Helmet from 'react-helmet';
import dayjs from 'dayjs';
import Axios from 'axios';
import './index.scss';

import SheltertMap from '../EvacuationSheltertMap';

// Utils
import CustomSelect from '../../utils/CustomSelect';
import LoadingDots from '../../utils/LoadingDots';
import LimitMask from '../../utils/LimitMask';

// Container
import { apiHost } from '../../utils/Shared/constant';
import { loginRedirect } from '../../utils/Shared/methods';

class EvacuationShelter extends Component {
	constructor(params) {
		super(params);
		this.state = {
			currentShelterIndex: null,
      updateDate: null,
			isMemberLogin: false,
			isDataLoading: false,
			isVillageShow: false,
			selectedCounty: null,
			selectedTown: null,
			selectedVillage: null,
			sheltersData: null,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('PINK');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
			{ name: '收容所查詢', href: '/evaluation/evacuation/shelter' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.getAvailableCounty();
    this.getUpdateDate();
		this.checkMemberLogin();
	}
	
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
			this.setState({ isMemberLogin: true });
		}
	}
	getAvailableCounty = () => {
		Axios.get('/config/CountiesTownsVillagesData.json')
		.then(result => {
			this.setState({ countiesTownsVillagesData: result.data });
		})
	}
  getUpdateDate = () => {
    Axios.get(`${apiHost}/api/Setting/GetShelterUpdateDatetime`)
    .then(res => {
      this.setState({ updateDate: res.data.update });
    })
  }
	handleCountyDistrictPickSelected = selectedCode => {
		const { countiesTownsVillagesData } = this.state;
		const selectedDistrict = countiesTownsVillagesData.counties.find(({ code }) => code === selectedCode);
		this.setState({ selectedCounty: selectedDistrict, selectedTown: null, selectedVillage: null});
	}
	handleTownDistrictPickSelected = selectedCode => {
		const { selectedCounty } = this.state;
		if (selectedCounty === null) return;
		const selectedDistrict = selectedCounty ? selectedCounty.towns.find(({ code }) => code === selectedCode) : null;
		this.setState({ selectedTown: selectedDistrict, selectedVillage: null, isVillageShow: false });
	}
	handleVillageDistrictPickSelected = selectedCode => {
		const { selectedTown, selectedCounty } = this.state;
		if (selectedCounty === null) return;
		if (selectedTown === null) return;
		const selectedDistrict = selectedTown.villages.find(({ code }) => code === selectedCode);
		this.setState({ selectedVillage: selectedDistrict,});
	}
	handleGetShelterLocate = () => {
		const { selectedCounty, selectedTown, selectedVillage, isMemberLogin, isVillageShow } = this.state;
		const countyCode = selectedCounty ? selectedCounty.code : 'all';
		const county = selectedCounty ? selectedCounty.county : '';
		const townCode = selectedTown ? selectedTown.code : 'all';
		const town = selectedTown ? selectedTown.town : '';
		const villageCode = selectedVillage ? selectedVillage.code : 'all';
		const village = selectedVillage ? selectedVillage.village : '';
		this.setState({ isDataLoading: true });
		const apiUrl = (selectedVillage) ?
								`${apiHost}/api/ShelterInfo/GetShelterInfoVillage?county=${countyCode}&town=${townCode}&village=${villageCode}&login=${isMemberLogin}` :
								`${apiHost}/api/ShelterInfo/GetShelterInfoTown?county=${countyCode}&town=${townCode}&login=${isMemberLogin}`;
		
		Axios.get(apiUrl)
			.then(res => {
				if (res.data.Result) {
					const data = res.data.Result;

					if (selectedTown && !isVillageShow) {
						selectedTown.villages.forEach(villageItem => {
							const compare = data.map(item => item['village_id'] === villageItem.code);
							villageItem.show = compare.includes(true);
						})
						this.setState({
						  isVillageShow: true,
							selectedTown: selectedTown,
						});
					}

					data.map(item => item.active = false);
					this.setState({
						isDataLoading: false,
						sheltersData: {
							county: county,
							town: town,
							village: village,
							data: data
						}
					})
				}
			})
	}
	handleClickListMarkShelter = (e) => {
		const { sheltersData } = this.state;
		const index = e.currentTarget.dataset.index;
		sheltersData.data.forEach(item => item.active = false);
		sheltersData.data[index].active = true;
		sheltersData.isSelected = true;
		this.setState({ isShelterMark: false, sheltersData: sheltersData });
	}
	handleClickPinMarkShelter = (shelter) => {
		const { sheltersData } = this.state;
		// const index = shelter.id;
		sheltersData.data.forEach(item => item.active = false);
		// sheltersData.data[index].active = true;
		this.setState({ isShelterMark: true, sheltersData: sheltersData });
	}
	render() {
		const { countiesTownsVillagesData } = this.state;
		const { isVillageShow, isMemberLogin, isDataLoading, isShelterMark } = this.state;
		const { selectedCounty, selectedTown, selectedVillage, sheltersData } = this.state;
    const { updateDate } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/evacuation" />
				<div className="evacuation-page">
					<div className="container">
						<div className="evacuation-chart-title">
							<div className="title">收容所查詢</div>
						</div>
						<section className="shelter-section">
							<div className="controller-bar">
								<div className="controller">
									<div className="step">
										<span>選擇查詢的地區</span>
									</div>
									<div className="select">
										<CustomSelect status="normal" name="county"
											value={selectedCounty ? selectedCounty.code : ''}
											onChange={(value) => { this.handleCountyDistrictPickSelected(value) }}>
											<option value="0">請選擇縣市</option>
											{
												countiesTownsVillagesData &&
												countiesTownsVillagesData.counties.map((item, index) =>
													<option key={index} value={item.code}>{item.county}</option>
												)
											}
										</CustomSelect>
									</div>
									<div className="select">
										<CustomSelect status="normal" name="town"
											value={ selectedTown ? selectedTown.code : '' }
											onChange={ value => this.handleTownDistrictPickSelected(value) }>
											<option value="0">請選擇鄉鎮</option>
											{
												selectedCounty &&
												selectedCounty.towns.map((item, index) =>
													<option key={index} value={item.code}>{item.town}</option>
												)
											}
										</CustomSelect>
									</div>
									{
										isVillageShow &&
										<div className="select">
											<CustomSelect status="normal" name="village"
												value={selectedVillage ? selectedVillage.code : ''}
												onChange={ value => this.handleVillageDistrictPickSelected(value) }>
												<option value="0">村里全選</option>
												{
													selectedTown &&
													selectedTown.villages.map((item, index) =>
														item.show && <option key={index} value={item.code}>{item.village}</option>
													)
												}
											</CustomSelect>
										</div>
									}
								</div>
								<div className="submit">
									<button disabled={!selectedCounty || !selectedTown}
										onClick={this.handleGetShelterLocate}>確定</button>
								</div>
								{
									sheltersData &&
									<div className="controller">
										<div className="step">
											<span>選擇收容所</span>
										</div>
										<div className="buttonbar">
											{
												sheltersData.data.length === 0 &&
												<span>暫無收容所資料</span>
											}
											{
												sheltersData.data.map((shelter, i) => 
													<button className={shelter.active ? '-active' : ''} key={i} data-index={i}
													onClick={ (e) => this.handleClickListMarkShelter(e) } >
														<p className="name">{ shelter['shelter_name'] }</p>
														<p className="info">{ shelter['shelter_address'] }</p>
													</button>
												)
											}
										</div>
									</div>
								}
							</div>
							<div className="shelter-result">
								{isDataLoading && <LoadingDots theme="red" />}
								<div className={`result ${sheltersData ? '-show': '-hide'}`}>
									<div className="map">
										<div className="title">收容所地圖</div>
										<SheltertMap 
											shelters={sheltersData} 
											isMemberLogin={isMemberLogin}
											isShelterMark={isShelterMark}
											handlePinClick={(shelter) => this.handleClickPinMarkShelter(shelter) }
											/>
									</div>
									<div className="note">
										<div className="title">點擊地圖看詳細資料</div>
										<p>資料取自衛福部「重大災害民生物資及志工人力整合網絡平台管理系統」資料庫，資料若有疑義，請洽各該地區鄉鎮公所。</p>
										{
											!isMemberLogin &&
											<p>欲查看收容所聯絡方式，請先<button onClick={ () => loginRedirect('/evaluation/evacuation/shelter') } href="#">登入會員</button></p>
										}
                    {
                      updateDate &&
                      <p>最後更新時間： { dayjs(updateDate).format('YYYY-MM-DD') }</p>
                    }
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default EvacuationShelter;
