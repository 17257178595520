import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils

class NewSfaaSubjectIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
			subject: 'elder'
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		this.getSubject();
	}
	getSubject() {
		const subject = window.location.pathname.split('/').slice(3, 4).join('');
		this.setState({
			subject: subject
		}, () => {
			this.getSubjectBreadcrumb();
		})
	}
	getSubjectBreadcrumb() {
		const { subject } = this.state;
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社福機構', href: '/statistics/sfaa' },
		];
		switch (subject) {
			case 'elder':
				breadcrumb.push({ name: '老人福利機構', href: '/statistics/sfaa/elder' });
				break;
			case 'disability':
				breadcrumb.push({ name: '身心障礙福利機構', href: '/statistics/sfaa/disability' });
				break;
		
			default:
				breadcrumb.push({ name: '老人福利機構', href: '/statistics/sfaa/elder' });
				break;
		};
		
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	
	render() {
		const { subject } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社福機構自然災害風險調查</title>
				</Helmet>
				<div className="new-sfaa-page">
					<div className="container">
						<div className="sfaa-chart-title">
							<div className="title">
							{ subject === 'elder' && '老人福利機構' }
							{ subject === 'disability' && '身心障礙福利機構' }
							</div>
						</div>
						<div className="sfaa-subject-selector">
							<div className="subject subject-grid">
								<div className="subjectitem">
									<Link className="button" to={`/statistics/sfaa-${subject}/disaster-experience/`}>
										<p className="type-text -ch -md">受災經驗</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to={`/statistics/sfaa-${subject}/building-features/`}>
										<p className="type-text -ch -md">建物特徵</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to={`/statistics/sfaa-${subject}/risk-perception/`}>
										<p className="type-text -ch -md">減災</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to={`/statistics/sfaa-${subject}/institutional-and-residential-characteristics/`}>
										<p className="type-text -ch -md">機構與院民特徵</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to={`/statistics/sfaa-${subject}/environmental-characteristics/`}>
										<p className="type-text -ch -md">環境特徵</p>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default NewSfaaSubjectIntro;
