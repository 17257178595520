import React, { Component } from 'react';
import Axios from 'axios';
import Helmet from 'react-helmet';
import './index.scss';

// utils
import LoadingDots from '../../utils/LoadingDots';
import ModalBox from '../../utils/ModalBox';
import CustomRadio from '../../utils/CustomRadio';
import CustomSelect from '../../utils/CustomSelect';
import SviChartMap from '../SviChartMap';
import SviChartLine from '../SviChartLine';
import CustomDropdown from '../../utils/CustomDropdown';
import LimitMask from '../../utils/LimitMask';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import chartCapture from '../../utils/Shared/chartCapture';
import { apiHost } from '../../utils/Shared/constant';
import { loginRedirect, downloadTrigger } from '../../utils/Shared/methods';

class SviAdvanceApp extends Component {
	constructor(params) {
		super(params);
		this.state = {
			itemsId: 1,
			currentType: 'z',
			sviData: null,
			mapData: null,
			currentTitle: '',
			currentLevel: null,
      		currentYear: null,
			currentYearStart: null,
			currentYearEnd: null,
			currentDistrictId: null,
			isModalOpen: false,
			isTitleFinish: false,
			isEnableSend: false,
			isResultShow: false,
			isDataLoaded: false,
			selectedCounty: null,
			selectedTown: null,
			countiesData: null,
			townsData: null,
			categoryItems: [
				{ name: '暴露量', id: 1, items: [] },
				{ name: '減災整備', id: 2, items: [] },
				{ name: '應變能力', id: 3, items: [] },
				{ name: '復原能力', id: 4, items: [] },
			],
      
		};
	}
	componentDidMount() {

		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('BLUE');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社會脆弱度評估', href: '/evaluation/svi' },
			{ name: '客製化社會脆弱度評估', href: '/evaluation/svi/advance' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.getAvailableYears();
		this.getAvailableCounty();
    	this.getCountiesTownsData()
		this.checkMemberLogin();
	}
	checkMemberLogin = () => {
		if (!window.localStorage.getItem('user-token')) {
			this.setState({ isModalOpen: true });
		}
	}
	getCountiesTownsData = () => {
		return new Promise(resolve => {
		const { countiesData } = this.state;
		if (countiesData === null) {
			Axios.get('/config/CountiesTownsData.json')
			.then(result => {
			const { counties } = result.data;
			this.setState({ countiesData: counties }, () => resolve());
			})
		}
		})
	}
	getAvailableYears = () => {
		const d = new Date();
		const yearStart = 1990;
		const yearEnd = d.getFullYear();

		const availableYears = [];

		for (let i = yearStart; i <= yearEnd; i++) {
			availableYears.push(i)
		}
		this.setState({ availableYears: availableYears });
	}
	getAvailableCounty = () => {
		Axios.get('/config/CountiesTownsData.json')
		.then(result => {
			this.setState({ availableCounty: result.data });
		})
	}
	handleFileUpload = (e) => {
		if (e.currentTarget.value !== '') {
			const categoryName = e.currentTarget.dataset.category;
			const itemId = e.currentTarget.dataset.id;
			const file = e.currentTarget.files[0]

			const { categoryItems } = this.state;
			categoryItems.forEach((category) => {
				if (category.name === categoryName) {
					category.items.forEach((item) => {
						if (item.id === Number(itemId)) {
							item.file = file;
							item.status = '';
						}
					})
				}
			})
			this.setState({
				categoryItems: categoryItems,
			})
		}
	}
	handleFormInput = (e) => {
		const categoryName = e.currentTarget.dataset.category;
		const itemId = e.currentTarget.dataset.id;
		const label = e.target.dataset.label;
		const value = e.target.value;

		const { categoryItems } = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				category.items.forEach((item) => {
					if (item.id === Number(itemId)) {
						item[label] = value;
					}
				})
			}
		})
		
		this.setState({
			categoryItems: categoryItems,
		})
	}
	handleFormSelect = (value, label, categoryName, itemId) => {
		const { categoryItems } = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				category.items.forEach((item) => {
					if (item.id === Number(itemId)) {
						item[label] = value;
					}
				})
			}
		})

		this.setState({
			categoryItems: categoryItems,
		})
	}
	handleFormRadio = (value, label, categoryName, itemId) => {
		const { categoryItems } = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				category.items.forEach((item) => {
					if (item.id === Number(itemId)) {
						item[label] = value;
						if (label === 'unit_type' && value === 'percent') {
							item.unit = '%';
						}
						if (label === 'unit_type' && value === 'count') {
							item.unit = '';
						}
					}
				})
			}
		})

		this.setState({
			categoryItems: categoryItems,
		})
	}
	handleEditStart = (e) => {
		const categoryName = e.currentTarget.dataset.category;
		const itemId = e.currentTarget.dataset.id;

		const { categoryItems } = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				category.items.forEach((item) => {
					if (item.id === Number(itemId)) {
						item.editMode = true;
					}
				})
			}
		})

		this.setState({
			categoryItems: categoryItems,
		}, () => { this.checkIndicatorVaild() })
	}
	handleClickSaveIndicator = (e) => {
		const categoryName = e.currentTarget.dataset.category;
		const itemId = e.currentTarget.dataset.id;
		const { currentType, categoryItems, currentLevel, currentYear, currentYearStart, currentYearEnd, selectedCounty, selectedTown } = this.state;
		
		if (currentType === 'z') {
			categoryItems.forEach((category) => {
				if (category.name === categoryName) {
					category.items.forEach((item) => {
						if (item.id === Number(itemId)) {
							const formData = new FormData();
							const indicator = {
								"name": item.name,
								"category_id": Number(category.id),
								"year": Number(currentYear),
								"related": Number(item.related),
								"unit_type": item.unit_type,
								"unit": item.unit,
								"file_name": item.file.name
							};

							if (currentLevel === 'county' && selectedCounty) {
								indicator.county = selectedCounty.code;
							}

							// loading
							item.isSending = true;
							this.setState({ categoryItems: categoryItems });

							formData.append('indicator', JSON.stringify(indicator));
							formData.append('file', item.file);

							Axios.interceptors.request.use(function (config) {
								config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('user-token');
								return config;
							});

              				const url = currentLevel !== 'county' ? `${apiHost}/api/WeakIndexUpload/CheckFileZ` : `${apiHost}/api/WeakIndexUpload/CheckDistrictFileZ`;

							Axios({
								method: 'post',
								url: url,
								data: formData,
								config: { headers: { 'Content-Type': 'multipart/form-data' } }
							})
							.then((res) => {
								if (res.status === 200) {
									item.isSending = false;
									if (res.data.Outstring === '0000') {
										item.status = 'success';
										item.statusMsg = '';
										item.editMode = false;
									}
									else {
										item.status = 'alert';
										item.statusMsg = res.data.Result;
									}

									this.setState({
										categoryItems: categoryItems,
									}, () => { 
										this.checkIndicatorVaild() 
									})
								}
							})
						}
					})
				}
			})
		}
		else if (currentType === 's') {
			categoryItems.forEach((category) => {
				if (category.name === categoryName) {
					category.items.forEach((item) => {
						if (item.id === Number(itemId)) {
							const formData = new FormData();
							const indicator = {
								"name": item.name,
								"category_id": Number(category.id),
								"year_start": Number(currentYearStart),
								"year_end": Number(currentYearEnd),
								"related": Number(item.related),
								"unit_type": item.unit_type,
								"unit": item.unit,
								"file_name": item.file.name,
							};

							if (currentLevel === 'county' && selectedCounty) {
												indicator.district = selectedCounty.code;
							};

							if (currentLevel === 'town' && selectedTown) {
								indicator.county = selectedCounty.code;
								indicator.town = selectedTown.code;
							};

							// loading
							item.isSending = true;
							this.setState({ categoryItems: categoryItems });

							formData.append('indicator', JSON.stringify(indicator));
							formData.append('file', item.file);

							Axios.interceptors.request.use(function (config) {
								config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('user-token');
								return config;
							});

              				const url = currentLevel === 'county' ? `${apiHost}/api/WeakIndexUpload/CheckFileS` : `${apiHost}/api/WeakIndexUpload/CheckDistrictFileS`;
							Axios({
								method: 'post',
								url: url,
								data: formData,
								config: { headers: { 'Content-Type': 'multipart/form-data' } }
							})
							.then((res) => {
								if (res.status === 200) {
									item.isSending = false;
									if (res.data.Outstring === '0000') {
										item.status = 'success';
										item.statusMsg = '';
										item.editMode = false;
									}
									else {
										item.status = 'alert';
										item.statusMsg = res.data.Result;
									}

									this.setState({
										categoryItems: categoryItems,
									}, () => { 
										this.checkIndicatorVaild() 
									})
								}
							})
						}
					})
				}
			})
		}
	}
	handleAddItem = (e) => {
		const categoryName = e.currentTarget.dataset.category;
		const {categoryItems, itemsId} = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				const item = {
					id: itemsId,
					name: '',
					unit_type: 'percent',
					unit: '%',
					year: '',
					yearStart: '',
					yearEnd: '',
					related: 1,
					file: null,
					editMode: true,
					statusMsg: ''
				}
				category.items.push(item);
			}
		})

		const newItemsId = itemsId +  1;

		this.setState({
			itemsId: newItemsId,
			categoryItems: categoryItems
		}, () => { this.checkIndicatorVaild() })
	}
	handleRemoveItem = (e) => {
		const categoryName = e.currentTarget.dataset.category;
		const itemId = e.currentTarget.dataset.id;

		const { categoryItems } = this.state;
		categoryItems.forEach((category) => {
			if (category.name === categoryName) {
				category.items.forEach((item) => {
					if (item.id === Number(itemId)) {
						const index = category.items.indexOf(item)
						category.items.splice(index, 1);
					}
				})
			}
		})

		this.setState({
			isEnableSend: false,
			categoryItems: categoryItems,
		}, () => { this.checkIndicatorVaild() })
	}
	checkIndicatorVaild = () => {
		const { categoryItems } = this.state;
		const token = window.localStorage.getItem('user-token');
		const itemsLengthVaild = categoryItems.every((category) => { return category.items.length > 0});
		const itemsEditFinish = categoryItems.every((category) => {
			return category.items.every((item) => {
				return item.editMode === false;
			})
		});

		if (itemsLengthVaild && itemsEditFinish && token) {
			this.setState({
				isEnableSend: true,
			});
		}
		else {
			this.setState({
				isEnableSend: false,
			});
		}
	}
	handleClickSendIndicator = () => {
		window.scrollTo(0, 0);
		const { categoryItems, currentType, currentLevel, currentYear, currentYearStart, currentYearEnd, selectedCounty, selectedTown } = this.state;
		const formData = new FormData();
		const indicators = [];

		let apiUrl;
		if (currentType === 'z') {
			apiUrl = currentLevel !== 'county' ? `${apiHost}/api/WeakIndexUpload/ZValueUpload` : `${apiHost}/api/WeakIndexUpload/ZValueDistrictUpload`;
			categoryItems.forEach((category) => {
				category.items.forEach((item) => {
					const indicatorItem = {
					"name": item.name,
					"related": Number(item.related),
					"year": Number(currentYear),
					"unit": item.unit,
					"unit_type": item.unit_type,
					"category_id": Number(category.id),
					"file_name": item.file.name
					};

					if (currentLevel === 'county' && selectedCounty) {
						indicatorItem.county = selectedCounty.code;
					};

					indicators.push(indicatorItem);

					// loading
					item.isSending = true;
					this.setState({
						categoryItems: categoryItems,
						sviData: null,
					});

					formData.append('file', item.file);
				})
			})
		}
		if (currentType === 's') {
			apiUrl = currentLevel === 'county' ? `${apiHost}/api/WeakIndexUpload/SValueUpload` : `${apiHost}/api/WeakIndexUpload/SValueDistrictUpload`;
			categoryItems.forEach((category) => {
				category.items.forEach((item) => {
          const indicatorItem = {
            "name": item.name,
            "related": Number(item.related),
            "year_start": Number(currentYearStart),
            "year_end": Number(currentYearEnd),
            "unit": item.unit,
            "unit_type": item.unit_type,
            "category_id": Number(category.id),
            "file_name": item.file.name,
          };

          if (currentLevel === 'county' && selectedCounty) {
            indicatorItem.district = selectedCounty.code;
          };

          if (currentLevel === 'town' && selectedTown) {
            indicatorItem.county = selectedCounty.code;
            indicatorItem.town = selectedTown.code;
          };

					indicators.push(indicatorItem);

					// loading
					item.isSending = true;
					this.setState({
						categoryItems: categoryItems,
						sviData: null,
					})

					formData.append('file', item.file);
				})
			})
		}
		formData.append('indicator', JSON.stringify(indicators));
		this.setState({
			isResultShow: true
		})
		Axios.interceptors.request.use(function (config) {
			config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('user-token');
			return config;
		});
		Axios({
			method: 'post',
			url: apiUrl,
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		})
		.then((res) => {
			if (res.status === 200) {
        		let county = selectedCounty ? selectedCounty.county : '';
				let csvData = typeof res.data.Result.csv === 'object' ? [res.data.Result.csv] : res.data.Result.csv;
				csvData.forEach((item) => {
					item.csv = JSON.parse(JSON.stringify(item.csv).replace(/,0,/g, ',-,'))
				});

				this.setState({
					isDataLoaded: true,
					sviData: res.data.Result.Result,
					categoryItems: categoryItems,
					mapData: {
						county: county,
						town: '',
						data: res.data.Result.Result,
					},
					csvData: csvData,
				}, () => { this.checkIndicatorVaild() })
			}
		})
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();
		const { currentTitle } = this.state;
		const fileName = `${currentTitle}`;
		const captureArea = document.querySelectorAll('.capture');
		chartCapture(captureArea, fileName);
		gaTracking('社會脆弱度', '客製化社會脆弱度評估', '圖表下載');
	}
	handleClickDownloadCSV = (e) => {
		const { csvData, currentTitle } = this.state;
		let csv = '';
		csvData.forEach((item) => {
			csv = csv + '\n' + item.csv;
		});

		const fileName = `${currentTitle}原始資料`;
		const copyright = '\n資料來源：國家災害防救科技中心減災動資料。';
		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;
		downloadTrigger(fileName + '.csv', csvContent);
		gaTracking('社會脆弱度', '客製化社會脆弱度評估', '原始數據下載');
	}
	handleClickResetAll = () => {
		window.scrollTo(0, 0);
    const resetCategoryItems = [ { name: '暴露量', id: 1, items: [] }, { name: '減災整備', id: 2, items: [] }, { name: '應變能力', id: 3, items: [] }, { name: '復原能力', id: 4, items: [] } ];

		this.setState({
			itemsId: 1,
			sviData: null,
			currentTitle: '',
			currentYear: null,
			currentYearStart: null,
			currentYearEnd: null,
			currentDistrictId: null,
			isTitleFinish: false,
			isEnableSend: false,
			isResultShow: false,
			categoryItems: resetCategoryItems,
		})
	}
	handleClickBackPrev = () => {
		window.scrollTo(0, 0);
		this.setState({
			sviData: null,
			mapData: null,
			isDataLoaded: false,
			isResultShow: false,
		})
	}
	handleChangeOptions = (value, stateName) => {
    const resetCategoryItems = [ { name: '暴露量', id: 1, items: [] }, { name: '減災整備', id: 2, items: [] }, { name: '應變能力', id: 3, items: [] }, { name: '復原能力', id: 4, items: [] } ];
		const obj = {};
		obj[stateName] = value;
		this.setState({
			itemsId: 1,
			sviData: null,
			isEnableSend: false,
			categoryItems: resetCategoryItems,
			...obj
		})
	}
  handleCountyDistrictPickSelected = selectedCode => {
    const { countiesData } = this.state;
    const selectedDistrict = countiesData.find( ({ code }) => code === selectedCode );
    this.setState({ 
      selectedCounty: selectedDistrict,
      selectedTown: null,
      townsData: selectedDistrict ? selectedDistrict.towns : null,
    });
  }
  handleTownDistrictPickSelected = selectedCode => {
    const { townsData } = this.state;
    if (townsData) {
      const selectedTown = townsData.find( ({ code }) => code === selectedCode );
      const resetCategoryItems = [ { name: '暴露量', id: 1, items: [] }, { name: '減災整備', id: 2, items: [] }, { name: '應變能力', id: 3, items: [] }, { name: '復原能力', id: 4, items: [] } ];
      this.setState({
        selectedTown: selectedTown,
        categoryItems: resetCategoryItems,
      });
    }
  }
	render() {
		const { currentLevel, currentYear, currentYearStart,  currentYearEnd, currentTitle, currentType } = this.state;
		const { isDataLoaded, isEnableSend, isResultShow, isTitleFinish } = this.state;
		const { categoryItems, availableYears, sviData, mapData } = this.state;
    const { countiesData, townsData, selectedCounty, selectedTown } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/svi" />
				<ModalBox
					open={this.state.isModalOpen}
					theme="-blue"
					onRequestClose={() => loginRedirect() }
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">請先登入再進行操作</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -blue" onClick={ () => loginRedirect() }>登入</button>
					</div>
				</ModalBox>
				<div className="svi-page">
					{
						!isResultShow &&
						<div className="container">
							<div className="svi-advance-title">
								上傳資料
							</div>
							<div className="svi-advance-controller">
								<div className="head">請選擇想要計算的資料格式</div>
								<div className="type">
									<div className="item">
										<button className={currentType === 'z' ? ' -active': ''}
										onClick={() => {
											this.handleClickResetAll();
											this.setState({
												currentType: 'z',
                        currentLevel: null,
                        selectedCounty: null,
                        selectedTown: null,
											})
										}}>單一年度行政區比較</button>
										<p className="description">每個分類需有至少一個指標項目的資料。</p>
									</div>
									<div className="item">
										<button className={currentType === 's' ? ' -active' : ''}
										onClick={() => {
											this.handleClickResetAll();
											this.setState({
												currentType: 's',
                        currentLevel: 'county',
                        selectedCounty: null,
                        selectedTown: null,
											})
										}}>單一行政區歷史趨勢</button>
										<p className="description">每個分類需有至少一個指標項目的資料，每個項目的數據資料需包含兩個以上年份的數據。</p>
									</div>
								</div>
								<div className="svi-advance-header">
									<div className="form">
										<span className="label">圖表名稱</span>
										<input className="input" type="text" placeholder="圖表名稱" value={currentTitle}
											onChange={(e) => { this.setState({ currentTitle: e.currentTarget.value})}} />
									</div>
									{
										currentType === 's' &&
										<React.Fragment>
											<div className="form">
												<span className="label">年份區間</span>
												<div className="select-group">
													<CustomSelect status="normal" name="yearStart" value={currentYearStart}
														onChange={(value) => this.handleChangeOptions(Number(value) < Number(currentYearEnd) ? value : (Number(currentYearEnd) ? Number(currentYearEnd) : value), 'currentYearStart')}>
														<option value="0">年份</option>
														{
															availableYears &&
															availableYears.map((year, index) => {
																return (
																	<option key={index} value={year}>{year}</option>
																)
															})
														}
													</CustomSelect>
													<div className="slice">至</div>
													<CustomSelect status="normal" name="yearEnd" value={currentYearEnd}
														onChange={(value) => this.handleChangeOptions(Number(value) > Number(currentYearStart) ? value : currentYearStart, 'currentYearEnd')}>
														<option value="0">年份</option>
														{
															availableYears &&
															availableYears.map((year, index) => {
																return (
																	<option key={index} value={year}>{year}</option>
																)
															})
														}
													</CustomSelect>
												</div>
											</div>
                        {
                          <div className="form">
                            <span className="label">調查層級</span>
                            <div className="radio-group">
                              <CustomRadio className="radio" color="blue" group="level"
                                idName="level-county"
                                checked={currentLevel === 'county' ? true : false}
                                onClick={() => { this.handleChangeOptions('county', 'currentLevel') }}>
                                <span className="text type-text -ch -xs">縣市</span>
                              </CustomRadio>
                              <CustomRadio className="radio" color="blue" group="level"
                                idName="level-county"
                                checked={currentLevel === 'town' ? true : false}
                                onClick={() => { this.handleChangeOptions('town', 'currentLevel') }}>
                                <span className="text type-text -ch -xs">鄉鎮</span>
                              </CustomRadio>
                            </div>
                          </div>
                        }
                        <div className="form">
                          <span className="label">選擇地區</span>
                          <div className="select-group">
                            <CustomSelect status="normal" name="year"
                              value={selectedCounty ? selectedCounty.code : ''}
                              onChange={value => this.handleCountyDistrictPickSelected(value)}>
                              <option value="0">縣市</option>
                              {
                                countiesData &&
                                countiesData.map((item, index) =>
                                  <option key={index} value={item.code}>{item.county}</option>
                                )
                              }
                            </CustomSelect>
                            <div className="slice"></div>
                            { 
                              currentLevel === 'town' &&
                              <div className="select">
                                <CustomSelect status="normal" name="county"
                                  value={selectedTown ? selectedTown.code : ''}
                                  onChange={(value) => { this.handleTownDistrictPickSelected(value) }}>
                                  <option value="0">鄉鎮</option>
                                  {
                                    townsData &&
                                    townsData.map((item, index) => 
                                      <option key={index} value={item.code}>{item.town}</option>
                                    )
                                  }
                                </CustomSelect>
                              </div>
                            }
                          </div>
                        </div>
											<p className="remark">{ isTitleFinish ?	'若再次修改年份或地區將會清空已填寫的資訊' : ''}</p>
											{
												this.state.isTitleFinish ?
												null :
												<div className="submit">
													<button
													disabled={currentTitle === '' || currentYearStart === '0' || currentYearEnd === '0' || 
                                    (currentLevel === 'county' && !selectedCounty)  ||
                                    (currentLevel === 'town' && !selectedTown)}
													onClick={() => { this.setState({ isTitleFinish: true }) }}>確定</button>
												</div>
											}
										</React.Fragment>
									}
									{
										currentType === 'z' &&
										<React.Fragment>
											<div className="form">
												<span className="label">年份</span>
												<div className="select-group">
													<CustomSelect status="normal" name="year" value={currentYear}
														onChange={(value) =>  this.handleChangeOptions(value, 'currentYear') }>
														<option value="0">年份</option>
														{
															availableYears &&
															availableYears.map((year, index) => {
																return (
																	<option key={index} value={year}>{year}</option>
																)
															})
														}
													</CustomSelect>
												</div>
											</div>
                      {
                        <div className="form">
                          <span className="label">調查層級</span>
                          <div className="radio-group">
                            <CustomRadio className="radio" color="blue" group="level"
                              idName="level-county"
                              checked={currentLevel === null ? true : false}
                              onClick={() => { this.handleChangeOptions(null, 'currentLevel') }}>
                              <span className="text type-text -ch -xs">全縣市</span>
                            </CustomRadio>
                            <CustomRadio className="radio" color="blue" group="level"
                              idName="level-county"
                              checked={currentLevel === 'county' ? true : false}
                              onClick={() => { this.handleChangeOptions('county', 'currentLevel') }}>
                              <span className="text type-text -ch -xs">全鄉鎮</span>
                            </CustomRadio>
                          </div>
                        </div>
                      }
                      {
                        currentLevel === 'county' &&
                        <div className="form">
                          <span className="label">選擇地區</span>
                          <div className="select-group">
                            <CustomSelect status="normal" name="year"
                              value={selectedCounty ? selectedCounty.code : ''}
                              onChange={value => this.handleCountyDistrictPickSelected(value)}>
                              <option value="0">縣市</option>
                              {
                                countiesData &&
                                countiesData.map((item, index) =>
                                  <option key={index} value={item.code}>{item.county}</option>
                                )
                              }
                            </CustomSelect>
                          </div>
                        </div>
                      }
											<p className="remark">{ isTitleFinish ?	'若再次修改年份、地區將會清空已填寫的資訊' : ''}</p>
											{
												this.state.isTitleFinish ?
												null :
												<div className="submit">
													<button
														disabled={ currentTitle === '' || currentYear === '0' || (currentLevel !== null && !selectedCounty) }
														onClick={() => { this.setState({ isTitleFinish: true }) }}>確定</button>
												</div>
											}
										</React.Fragment>
									}
								</div>
								{
									isTitleFinish &&
									categoryItems &&
									categoryItems.map((category, index) => {
										return (
											<div className="svi-advance-item" key={index}>
												<div className="head">
													<p className="title">{category.name}</p>
													<button data-category={category.name}
													onClick={this.handleAddItem}>
														<i className="icon-o-plus"></i>
														<span>加入指標項目</span>
													</button>
												</div>
												<ul className="list">
													{
														category.items &&
														category.items.map((item, subIndex) => {
															return (
																<li className="item" key={subIndex}>
																	<div className="head">
																		<div className="name">
																			{
																				item.status === 'success' &&
																				<i className="icon-success"></i>
																			}
																			<i className="icon-task"></i>
																			<span>{item.name}</span>
																		</div>
																		<div className="crud">
																			<button className={"edit" + (item.editMode ? ' -hide' : ' -show')}
																			data-category={category.name}
																			data-id={item.id}
																			onClick={this.handleEditStart}>編輯</button>
																			<button className="delete"
																			data-category={category.name}
																			data-id={item.id}
																			onClick={this.handleRemoveItem}>刪除</button>
																		</div>
																	</div>
																	<div className={"full" + (item.editMode ? ' -show' : ' -hide')}>
																		<div className="form">
																			<div className="flex">
																				<span className="label">指標名稱</span>
																				<input className="input" type="text" placeholder="輸入指標名稱，如：土石流保全人口"
																				data-label="name"
																				data-category={category.name}
																				data-id={item.id}
																				defaultValue={item.name}
																				onChange={this.handleFormInput} />
																			</div>
																		</div>
																		<div className="form">
																			<div className="flex">
																				<span className="label">單位類型</span>
																				<div className="radio-group">
																					<CustomRadio className="radio" color="blue" group={category.name + item.id + 'unit'}
																						idName={category.name + item.id + 'percent'}
																						checked={item.unit_type === 'percent' ? true : false}
																						onClick={() => { this.handleFormRadio('percent', 'unit_type', category.name, item.id) }}>
																						<span className="text type-text -ch -xs">百分比</span>
																					</CustomRadio>
																					<CustomRadio className="radio" color="blue" group={category.name + item.id + 'unit'}
																						idName={category.name + item.id + 'count'}
																						checked={item.unit_type === 'count' ? true : false}
																						onClick={() => { this.handleFormRadio('count', 'unit_type', category.name, item.id) }}>
																						<span className="text type-text -ch -xs">計數</span>
																					</CustomRadio>
																				</div>
																			</div>
																			<div className="remark">指標本身若是比率(%)者，請勾選百分比，其他請勾選計數</div>
																		</div>
																		{
																			item.unit_type === 'count' &&
																			<div className="form">
																				<div className="flex">
																					<span className="label">單位名稱</span>
																					<input className="input" type="text" placeholder="例如：人、萬元"
																					data-label="unit"
																					data-category={category.name}
																					data-id={item.id}
																					defaultValue={item.unit}
																					onChange={this.handleFormInput} />
																				</div>
																			</div>
																		}
																		<div className="form">
																			<div className="flex">
																				<span className="label">跟社會脆弱度的關係</span>
																				<div className="radio-group">
																					<CustomRadio className="radio" color="blue" group={category.name + item.id}
																						idName={category.name + item.id + 'plus'}
																						checked={item.related === 1 ? true : false}
																						onClick={() => { this.handleFormRadio(1, 'related', category.name, item.id) }}>
																						<span className="text type-text -ch -xs">正</span>
																					</CustomRadio>
																					<CustomRadio className="radio" color="blue" group={category.name + item.id}
																						idName={category.name + item.id + 'minus'}
																						checked={item.related === -1 ? true : false}
																						onClick={() => { this.handleFormRadio(-1, 'related', category.name, item.id) }}>
																						<span className="text type-text -ch -xs">負</span>
																					</CustomRadio>
																				</div>
																			</div>
																			<div className="remark">與社會脆弱度正相關，表示該指標項目數字越大，其環境越脆弱；反之則為負相關</div>
																		</div>
																		<div className="buttonbar">
																			<label htmlFor={category.name + item.id} className="upload">
																				<input id={category.name + item.id} type="file"
																				accept=".xlsx"
																				data-category={category.name}
																				data-id={item.id}
																				onChange={ this.handleFileUpload }/>
																				<span>{item.file === null ? "選擇檔案" : item.file.name}</span>
																			</label>
																			<button className={'submit' + (item.isSending ? ' -sending' : '')}
																			disabled={item.file === null || item.name === '' || item.unit === ''}
																			data-category={category.name}
																			data-id={item.id}
																			onClick={this.handleClickSaveIndicator}><span>確定</span></button>
																		</div>
																		<div className="status">{item.statusMsg}</div>
																	</div>
																</li>
															)
														})
													}
												</ul>
											</div>
										)
									})
								}
							</div>
							{
								isTitleFinish &&
								<div className="svi-advance-submit">
									<button 
									disabled={ !isEnableSend }
									onClick={this.handleClickSendIndicator}><span>看我的評估結果</span></button>
								</div>
							}
						</div>
					}
					{
						isResultShow &&
						<div className="container">
							<div className="svi-advance-result">
								<div className="svi-advance-title">您的社會脆弱度指標評估結果</div>
								<div className="result">
									{ 
										!sviData && 
										<LoadingDots theme="blue" >
											<p>系統處理中....請稍待</p>
										</LoadingDots>
									}
									<div className="head capture">
										{currentTitle}
										{ currentYear && `（${currentYear}）`}
										{ (currentYearStart && currentYearEnd) && `（${currentYearStart} - ${currentYearEnd}）`}
									</div>
									<div className="download">
										<CustomDropdown title="下載" className="capture-hide">
											<li><button onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
											<li><button onClick={this.handleClickDownloadCSV}>下載CSV</button></li>
										</CustomDropdown>
									</div>
									<div className="chart">
										{ (isDataLoaded && mapData && currentType === 'z') && <SviChartMap sviData={mapData} /> }
										{ (isDataLoaded && sviData && currentType === 's') && <div className="capture"><SviChartLine sviData={sviData} /></div> }
									</div>
									{
										isDataLoaded &&
										<div className="bottom capture">
											<div className="bottom-content">
												<p className="remark">此評估結果為使用者自行上傳之數據，本系統僅提供協助進行社會脆弱度指數之計算，對於評估結果之信效度須由使用者自行負責。</p>
												<div className="svi-advance-table">
													<ul className="head -row">
														<li>分類</li>
														<li>指標名稱</li>
														<li>跟社會脆弱度的關係</li>
														<li>統計單位</li>
													</ul>
													<ul className="body">
														{
															categoryItems.map((category, categoryIndex) =>
																<li className="svi-1 primary -row" key={categoryIndex}>
																	<div className="item">{category.name}</div>
																	<ul className="layer list">
																		{
																			category.items.map((item, itemIndex) => 
																				<React.Fragment key={itemIndex}>
																				{
																					<li className="secondary -row">
																						<div className="item">{item.name}</div>
																						<div className="item">{item.related > 0 ? '正' : '負'}</div>
																						<div className="item">{item.unit}</div>
																					</li>
																				}
																				</React.Fragment>
																			)
																		}
																	</ul>
																</li>
															)
														}
													</ul>
												</div>
											</div>
										</div>
									}
								</div>
								<div className="back">
									<button onClick={this.handleClickBackPrev}>回上一頁</button>
								</div>
							</div>
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default SviAdvanceApp;
