import React, { Component } from 'react';
import './index.scss';
import SvgZoomMap from '../../utils/SvgZoomMap';
import MapIsland from '../../utils/SvgZoomMapIsland';
import LoadingDots from '../../utils/LoadingDots';
import { range } from 'lodash';
import _ from 'lodash';




class SviChartMap extends Component {
	constructor(params) {
		super(params);
		this.state = {
			isMapShow: false,
			isDataLoad: false,
			isColorMarkHide: false,
			colorSet: ['#fd647b', '#fd7c8e', '#fd8a9b', '#fea2b0', '#febac4', '#fed4da', '#ffebed', '#ffffff', '#dee8fe', '#cad9fd', '#adc5fc', '#92b2fc', '#7ba1fb', '#6390fa', '#5284f9'],
			color1: '0, 43, 95',
			color2: '40, 129, 241',
			color3: '245, 255, 252',
			color4: '38, 202, 154',
			color5: '1, 74, 46',
			isBubbleShow: false,
			bubbleValue: '',
			bubbleName: '',
			bubbleTop: 0,
			bubbleLeft: 0,
			colorBar: '',
		};
	}

	componentDidMount() {
		// this.handleLoadData();
		this.getRange();
		this.setColor();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.getRange();
			// this.handleLoadData();
			if (this.props.sviData.length === 0) {
				this.setState({
					isDataLoad: false
				})
			}
			else {
				this.setState({
					isDataLoad: true
				})
			}
		}
	}
	getColorBar = (colorSet) => {
		let colorBar = ''
		colorBar = colorSet[0] + ',#FFFFFF,' + colorSet[colorSet.length-1]
		this.setState({colorBar})
	}
	getRange = () => {
		const { sviData } = this.props;
		if (sviData) {
			const { colorSet } = this.state;
			const num = colorSet.length + 1;
			const maximum = Math.max(...sviData.data.map(data => data.value));
			const minimum = Math.min(...sviData.data.map(data => data.value));
			const deltaP = 2 * maximum / (num - 2)
			const deltaN = 2 * (0 - minimum ) / (num - 1)
			const allDataIsNull = sviData.data.every(data => data.value === null);

			const ranges = [];
			for (let i = 0; i < num; i++) {
				const currentMin = Math.floor(minimum*100)
				const currentDeltaP = Math.floor(deltaP*100)
				const currentDeltaN = Math.floor(deltaN*100)
				let value;
				if(i < (num /2)) {
					value = currentMin + (currentDeltaN * i)
				}else {
					value = 0 + currentDeltaP * (i - (num-1)/2)
				}
				ranges.push(value/100);
			};
			ranges[0] = minimum
			ranges[ranges.length-1] = maximum

			this.setColor();
			this.setState({
				colors: colorSet,
				ranges: ranges,
				isColorMarkHide: allDataIsNull,
			}, () => this.handleLoadData());
		}
	}

	handleLoadData = () => {
		const { sviData } = this.props;
		if (sviData) {
			const { county, town } = sviData;
			const mapData = {
				layer: '',
				data: []
			};

			if (county === '' && town === '') {
				mapData.layer = 'county';
			}
			else if (county !== '' && town === '') {
				mapData.layer = 'town';
			}
			else {
				mapData.layer = 'village';
			}

			sviData.data.forEach(item => {
				const obj = {};
				obj.name = item['name'];
				obj.attribute = {
					'style': {
						'fill': this.handleTransferColor(item.value) ,
						'stroke': '#555555',
					},
					'data-value': item.value,
				}
				mapData.data.push(obj);
			});

			this.setState({
				mapData: mapData,
				county: county,
				town: town,
			})
		}
	}
	handleTransferColor = (value) => {
		const { ranges, colorSet } = this.state
		let color;
		for (let i = 0; i < ranges.length; i++) {
			if (value > ranges[i] && value <= ranges[i + 1]) {
				color = colorSet[i];
			}
		}
		if (value <= ranges[0]) {
			color = colorSet[0];
		}
		if (value > ranges[ranges.length - 1]) {
			color = colorSet[colorSet.length-1];
		}
		if (value === null || [...new Set(ranges)].length === 1) {
			color = '#eee';
		}
		return color;
	}
	handleMouseOverMap = (area, e) => {
		this.setState({
			isBubbleShow: true,
			bubbleTop: e.clientY,
			bubbleLeft: e.clientX,
			bubble: {
				name: e.target.dataset.name || area.filter(_ => _).slice(-1),
				value: e.target.dataset.value || null
			},
		})
	}
	handleMouseLeaveMap = () => {
		this.setState({
			isBubbleShow: false,
		})
	}
	setColor = () =>{
		const {related} = this.props
		let colorSet = ['#fd647b', '#fd7c8e', '#fd8a9b', '#fea2b0', '#febac4', '#fed4da', '#ffebed', '#ffffff', '#dee8fe', '#cad9fd', '#adc5fc', '#92b2fc', '#7ba1fb', '#6390fa', '#5284f9']
		if(![1,-1].includes(related)) {
			const positive = ['減災整備', '應變能力', '復原能力']
			const negative = ['', '暴露量']
			if(negative.includes(this.props.currentIndicatorName)){
				colorSet = colorSet.reverse()
			}
		} else {
			if(related === 1) {
				colorSet = colorSet.reverse()
			}
		}
		this.getColorBar(colorSet)
		this.setState({colorSet});
	}
	render() {
		const { isMapShow, isBubbleShow, isColorMarkHide, bubbleTop, bubbleLeft, bubble } = this.state;
		const { mapData, county, town } = this.state;
		const { colorSet, ranges, colorBar } = this.state;
		return (
			<div className={`svi-z-map`}>
				{
					isBubbleShow &&
					<div className="bubble-info"
						style={{
							'position': 'fixed',
							'top': `${bubbleTop}px`,
							'left': `${bubbleLeft}px`,
							'zIndex': 99
						}}>
						<div className="bubble">
							<p className="name">{bubble.name}</p>
							{ Boolean(Number(bubble.value)) && <p className="value"> {bubble.value}</p> }
							{ Boolean(!Number(bubble.value)) && <p className="value">查無資料</p> }
						</div>
					</div>
				}
				<div className="capture">
					{
						ranges &&
						<div className="range-mark">
							<div className="title">單位數</div>
							{
								!isColorMarkHide &&
								<React.Fragment>
									<div className="range-noData">
										<div className="colorBar"/>
										<div className="value">查無資料</div>
									</div>
									<div className="range">
										<div className="colorBar" style={{ background: `linear-gradient(${colorBar})` }}></div>
										<div className="valueBar">
											<div className="value type-text -en">{ranges[0].toFixed(2)}</div>
											<div className="value type-text -en zero">0</div>
											<div className="value type-text -en buttom">{ranges[ranges.length -1].toFixed(2)}</div>
										</div>
									</div>
								</React.Fragment>
							}
						</div>
					}
					<div className={`taiwan-map ${isMapShow && '-show'}`} onMouseLeave={this.handleMouseLeaveMap}>
						{
							mapData &&
							mapData.layer === 'county' &&
							<MapIsland
								onAreaHover={this.handleMouseOverMap}
								infoData={mapData} />
						}
						<SvgZoomMap
							countyJsonSrc="/MapJson/taiwan-county.json"
							townJsonSrc="/MapJson/taiwan-town.json"
							villageJsonSrc="/MapJson/taiwan-village.json"
							onAreaHover={this.handleMouseOverMap}
							county={county}
							town={town}
							infoData={mapData}
							zoomDelay={0}
							isMapInit={() => { this.setState({ isMapShow: true }) }}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default SviChartMap;
