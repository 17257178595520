import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Axios from 'axios';
import Helmet from 'react-helmet';
import QueryString from 'query-string';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import './index.scss';
// import ReCAPTCHA from "react-google-recaptcha";

// Utils
import CustomCheckbox from '../../utils/CustomCheckbox';
import CustomSelect from '../../utils/CustomSelect';
import CustomInput from '../../utils/CustomInput';
// import { facebookAppId, googleClientId, ReCAPTCHA_siteKey } from '../../utils/Shared/constant';
import { facebookAppId, googleClientId } from '../../utils/Shared/constant';
import { emailValidation, passwordValidation } from '../../utils/Shared/methods';
import {apiHost} from '../../utils/Shared/constant';

// Resouces
import FacebookSVG from "../../../images/facebook.svg";
import GoogleSVG from "../../../images/google.svg";

const organization = [
	'中央政府人員',
	'縣市政府人員',
	'鄉鎮市區公所人員',
	'研究人員',
	'社福機構從業人員',
	'村里長(幹事)或鄰長',
	'其他'
]

class MemberRegisterOrg extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// passReCAPTCHA: false,
			// ReCAPTCHA_Token: '',
			inputDisabled: false,
			input_orgsort: '',
			input_orgtitle: '',
			input_location: '',
			input_county: '',
			input_username: '',
			input_useremail: '',
			input_usertitle: '',
			input_password: '',
			input_repassword: '',
			checkbox_allowright: false,
			checkbox_allowemail: false,
			checkbox_allowjoin: false,

			input_orgsort_msg: '',
			input_orgtitle_msg: '',
			input_location_msg: '',
			input_county_msg: '',
			input_username_msg: '',
			input_useremail_msg: '',
			input_usertitle_msg: '',
			input_password_msg: '',
			input_repassword_msg: '',

			facebook_logined: false,
			facebook_accessToken: '',
			facebook_pictrue: '',

			google_logined: false,
			google_accessToken: '',
			google_picture: '',

			taiwan_area: [],
			taiwan_nowCity: -1,

      countiesData: null,
      townsData: null,
      selectedCounty: null,
      selectedTown: null,
		};
	}

	componentDidMount() {
		const {type} = this.props.match.params;
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: (type === 'organization' ? '機關' : '個人') + '會員註冊', href: '/member/register/' + type },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
    this.checkMemberLogin();

    Axios.get('/config/CountiesTownsData.json').then((res) => {
			this.setState({ countiesData: res.data.counties });
		})


		const searchs = QueryString.parse(this.props.location.search);
		if (searchs.sn === 'google') {
			this.handleGoogleLogin(this.props.sn_response);
		}
		else if (searchs.sn === 'facebook') {
			this.handleFacebookLogin(this.props.sn_response);
		}

	}
  checkMemberLogin = () => {
    if (window.localStorage.getItem('user-token')) {
      window.location = '/';
    }
  }

  handleCountyDistrictPickSelected = selectedCode => {
    const { countiesData } = this.state;
    const selectedDistrict = countiesData.find( ({ code }) => code === selectedCode );
    this.setState({ 
      selectedCounty: selectedDistrict,
      selectedTown: null,
      townsData: selectedDistrict.towns,
    });
  }

  handleTownDistrictPickSelected = selectedCode => {
      const { townsData } = this.state;
      const selectedTown = townsData.find( ({ code }) => code === selectedCode );
      this.setState({
        selectedTown: selectedTown,
      });
  }

	model = (state, msg = null) => {

		const varToString = varObj => Object.keys(varObj)[0]
		const getValue = varObj => Object.values(varObj)[0]

		let rtn = {
			disabled: this.state.inputDisabled,
			value: getValue(state),
			onChange: v => {
				let newState = {};
				newState[varToString(state)] = v;
				this.setState(newState);
			},
		};

		if (msg !== null) {
			rtn = {	...rtn,	status: msg ? 'danger' : 'normal', msg: msg }
		}

		return rtn;
	}



	formValidation = () => {
    const { selectedCounty, selectedTown } = this.state;
		const {
			input_orgsort, input_orgtitle, input_username, input_useremail, input_usertitle, input_password, input_repassword,
			checkbox_allowright, facebook_logined, google_logined, 
		} = this.state;

		const {type} = this.props.match.params;

		let allRequireResult = '';

		const isRequired = value => {
			allRequireResult += value === '' ? '-' : '';
			return value === '' ? '必須填寫' : '';
		};

		const emailCheck = value => {
			const result = value === '' ? '必須填寫' : (emailValidation(value) ? '' : 'email格式不符');
			allRequireResult += result === '' ? '' : '-';
			return result;
		};

		let input_repassword_msg = '';
		let input_password_msg = '';

		if (!facebook_logined && !google_logined) {
			input_repassword_msg = input_password !== input_repassword ? '兩次密碼不相同，請確認後再輸入' : '';
			input_password_msg = isRequired(input_password);
			if (input_password_msg === '') {
				if (!passwordValidation(input_password)) {
					input_password_msg = '密碼請輸入英數組合 6 個字元以上';
					allRequireResult += '-';
				}
			}
		}

		const input_username_msg = isRequired(input_username);
		const input_useremail_msg = emailCheck(input_useremail);

		const input_orgsort_msg = type === 'organization' ? isRequired(input_orgsort) : '';
		const input_orgtitle_msg = isRequired(input_orgtitle);
		const input_location_msg = isRequired(selectedTown.town);
		const input_county_msg = isRequired(selectedCounty.county);
		const input_usertitle_msg = isRequired(input_usertitle);
		const checkbox_allowright_msg = isRequired(checkbox_allowright);

		this.setState({
			input_repassword_msg: input_repassword_msg,
			input_username_msg: input_username_msg,
			input_useremail_msg: input_useremail_msg,
			input_password_msg: input_password_msg,
			input_orgsort_msg: input_orgsort_msg,
			input_orgtitle_msg: input_orgtitle_msg,
			input_location_msg: input_location_msg,
			input_county_msg: input_county_msg,
			input_usertitle_msg: input_usertitle_msg,
			checkbox_allowright_msg: checkbox_allowright_msg,
		})

		return (allRequireResult + input_repassword_msg) === '';
	}

	// handleCheckReCAPTCHA = (value) => {
	// 	if (value !== null) {
	// 		this.setState({ passReCAPTCHA: true, ReCAPTCHA_Token: value	})
	// 	}
	// }

	handleSubmitClick = () => {

		// const { input_orgsort, input_orgtitle, input_username, input_useremail, input_usertitle, input_password, checkbox_allowemail, checkbox_allowjoin, facebook_logined, google_logined, ReCAPTCHA_Token, facebook_accessToken, google_accessToken} = this.state;
		const { input_orgsort, input_orgtitle, input_username, input_useremail, input_usertitle, input_password, checkbox_allowemail, checkbox_allowjoin, facebook_logined, google_logined, facebook_accessToken, google_accessToken} = this.state;
    const { selectedCounty, selectedTown } = this.state;
		const {type} = this.props.match.params;

		this.setState({inputDisabled: true});

		if (this.formValidation()) 
		{
			const registerFormData = {
				Email: input_useremail,
				Name: input_username,
				LoginCategory: facebook_logined ? '1' : (google_logined ? '2' : '0'), // [0 : 一般登入(預設) / 1 : facebook 登入 / 2 : Gmail 登入]
				AccountType: type === 'organization' ? '1' : '0', // [0 : 個人帳號 /1 : 機關帳號]
				AccessToken: facebook_logined ? facebook_accessToken : (google_logined ? google_accessToken : ''),
				LoginKey: input_password,
				AddThirdpartyAccountFlag: checkbox_allowjoin,
				EmailNotificationFlag: checkbox_allowemail,
				CompanyType: input_orgsort,
				CompanyName: input_orgtitle,
				County: selectedCounty.county,
				Area: selectedTown.town,
				JobTitle: input_usertitle,
				// ReCAPTCHA_Token: ReCAPTCHA_Token
			};

			Axios.post(
				`${apiHost}/api/Account/Register`,
				registerFormData,
				{ headers: {'Content-Type': 'application/json'} },
			)
			.then( res => {
				
				if (res.data.Outstring === "0000") {

					window.localStorage.setItem("user-token", res.data.Result);
					const prevPage = window.localStorage.pageBeforeLogin || null;

					if (prevPage) {
						window.location = prevPage;
					} else {
						window.location = '/';
					}

				} else if (res.data.Outstring === "3001") {

					this.setState({
						inputDisabled: false,
						input_useremail_msg: '此email已被註冊，請改用其他email或者使用忘記密碼功能找回密碼',
					})

				} else if (res.data.Outstring === "3002") {
					this.setState({
						inputDisabled: false,
					})
				}
				
			});

		} else {
			this.setState({inputDisabled: false});
		}
	}

	handleFacebookLogin = (response) => {
    // debugger;
		if (response.accessToken !== undefined) {
			Axios.post(
				`${apiHost}/api/Account/Login`,
				{
					AccessToken: response.accessToken,
					Email: response.email,
				},
				{
					headers: {'Content-Type': 'application/json'}
				},
			)
			.then( res => {
				if (res.data.Outstring === "0000") {
					window.localStorage.setItem("user-token", res.data.Result);
					window.location = "/";
				} else {
					this.setState({
						facebook_accessToken: response.accessToken,
						facebook_pictrue: response.picture.data.url,
						facebook_logined: true,
						input_username: response.name,
						input_useremail: response.email,
					})
				}
			});
		}
	}


	handleGoogleLogin = (response) => {
		if (response.accessToken !== undefined) {
			Axios.post(
				`${apiHost}/api/Account/Login`,
				{
					AccessToken: response.accessToken,
					Email: response.profileObj.email,
				},
				{
					headers: {'Content-Type': 'application/json'}
				},
			)
			.then( res => {
				if (res.data.Outstring === "0000") {
					window.localStorage.setItem("user-token", res.data.Result);
					window.location = "/";
				} else {
					this.setState({
						google_accessToken: response.tokenId,
						google_pictrue: response.profileObj.imageUrl,
						google_logined: true,
						input_username: response.profileObj.givenName,
						input_useremail: response.profileObj.email,
					})
				}
			});
		}

	}


	render() {

		const {
			// passReCAPTCHA, 
			checkbox_allowright, checkbox_allowright_msg,
			facebook_logined, facebook_pictrue, google_logined, google_pictrue,
		} = this.state;

		const {type} = this.props.match.params;

		return (
			<div className="member-page">

				<Helmet>
					<title>減災動資料 - {type === 'organization' ? '機關' : '個人'}會員註冊</title>
				</Helmet>
				
				<div className="container">
					<div className="row">
						<section className="member-register member-section col-lg-6 offset-lg-3">

							<div className="heading">
								<span className="type-heading -ch -h3">{type === 'organization' ? '機關' : '個人'}註冊</span>
							</div>

							{
								type !== 'organization' &&
								<React.Fragment>
									<div className="social social-login row">

											{
												( !facebook_logined && !google_logined ) &&
												<React.Fragment>

													<FacebookLogin
														appId={facebookAppId}
														callback={this.handleFacebookLogin}
														cssClass="col-12"
														fields="name,email,picture"
														render={renderProps => (
															<div className="col-6">
																<button className="button" onClick={renderProps.onClick}>
																	<img src={FacebookSVG} alt=""/>
																	<span className="type-text -en -md">FACEBOOK</span>
																</button>
															</div>
														)}
													/>
														
													<GoogleLogin
														clientId={googleClientId}
														onSuccess={this.handleGoogleLogin}
														onFailure={(e)=>{console.log(e)}}
														render={renderProps => (
															<div className="col-6">
																<button className="button" onClick={renderProps.onClick}>
																	<img src={GoogleSVG} alt="" />
																	<span className="type-text -en -md">GOOGLE</span>
																</button>
															</div>
														)}
													/>

												</React.Fragment>
											}

											{
												facebook_logined &&
												<div className="col-12">
													<div className="social-logined">
														<img className="profile-pic" src={facebook_pictrue} alt=""/>
														<img src={FacebookSVG} alt=""/>
														<span className="type-text -en -md">已使用FACEBOOK登入</span>
													</div>
												</div>
											}

											{
												google_logined &&
												<div className="col-12">
													<div className="social-logined">
														<img className="profile-pic" src={google_pictrue} alt=""/>
														<img src={GoogleSVG} alt=""/>
														<span className="type-text -en -md">已使用GOOGLE登入</span>
													</div>
												</div>
											}
										
										{
											( !facebook_logined && !google_logined )&&
											<div className="col-12 hint">
												<p className="type-text -ch -xs">已經有帳號了嗎？ <Link className="link-text" to="/member/login">登入</Link></p>
											</div>
										}

									</div>
									{
										( !facebook_logined && !google_logined ) &&
										<div className="slash slash-or">
											<span className="type-text -en -md">OR</span>
										</div>
									}
								</React.Fragment>
							}

							<form className="form login-form" action="">

								{
									type === 'organization' &&
									<React.Fragment>
										{ this.render_orgsort() }
										{	this.render_orgtitle() }
										{	this.render_location() }
										{ this.render_username() }
										{	this.render_useremail() }
										{	this.render_usertitle() }
										{	this.render_password() }
										{	this.render_repassword() }
									</React.Fragment>
								}

								{
									(type !== 'organization' && !facebook_logined && !google_logined) &&
									<React.Fragment>
										{ this.render_username() }
										{	this.render_useremail() }
										{	this.render_usertitle() }
										{ this.render_password() }
										{	this.render_repassword() }
										{	this.render_orgtitle() }
										{	this.render_location() }
									</React.Fragment>
								}

								{
									(type !== 'organization' && (facebook_logined || google_logined)) &&
									<React.Fragment>
										{ this.render_username_sn() }
										{	this.render_useremail_sn() }
										{	this.render_usertitle() }
										{	this.render_orgtitle() }
										{	this.render_location() }
									</React.Fragment>
								}

								<div className="formitem -center">
									<div className="form-msg">
										<p className="title type-text -ch -md">會員權利義務</p>
										<p className="article type-text -ch -xs">
											<p>親愛的使用者，感謝您蒞臨國家災害防救科技中心「減災動資料」網站（以下簡稱本網站），為了讓您能夠安全的使用本網站提供之各項服務與資訊，請您詳細閱讀下列關於「本站資料使用聲明」、「個資使用聲明」以及「會員權益」等說明，以保障您的權益：</p>
											<p>一、本站資料使用聲明<br />1. 本網站所提供之資料屬於國家災害防救科技中心（以下簡稱本中心）擁有之部分，其受著作權保護之範圍，使用者得依著作權法第65條規定進行重製、改作、編輯、公開傳輸或為其他方式之利用，開發各種產品或服務（簡稱加值衍生物）；然使用時，應註明出處（國家災害防救科技中心減災動資料https://DRRstat.ncdr.nat.gov.tw）。<br />2. 本網站使用者複製轉載利用以後所產生的問題或疑慮皆與本站無關，須由各使用者自行負責。<br />3. 本網站提供之相關網站連結或資料文件，均為該網站或相關單位所提供，本網站不擔保其正確性、即時性或完整性。<br />4. 針對本網站提供之資料有任何疑義，歡迎隨時與我們聯絡。</p>
											<p>二、個資使用聲明<br />本中心非常重視您的個人資料保護，因此我們制訂了個資保護聲明，我們於運用您的個人資料均遵守中華民國「個人資料保護法」相關要求，請您閱讀本聲明，以瞭解您的相關權益：<br />1. 基於會員管理與其他服務項目（包括但不限於會員權益通知、滿意度調查、為了解服務狀況而進行的統計分析）進行會員資料的蒐集。當您提供您的個人資料（含姓名、電子郵件地址、服務單位等資料，以下稱個人資料）時，您的個人資料會被我們蒐集並受到安全的保護。<br />2. 本網站會將收集來的資料整理成統計報表，以了解提供服務的狀況。統計報表中不會呈現您的個人識別資料，亦無法根據報表結果追溯其他會員的個人資料。<br />3. 您瞭解本聲明書符合個人資料保護法及相關法規之要求，具有書面同意本中心得依本聲明內容蒐集、處理及利用您的個人資料之效果；您所提供的資料，本中心將嚴格的管理及保護。<br />4. 如無法提供正確的個人資料加入會員，本網站將無法進行會員服務之相關業務。</p>
											<p>三、會員權益<br />凡加入本網站會員者，方可使用本網站提供之各項會員服務：<br />1. 會員可使用調查統計、社會脆弱度評估系統中的「客製化社會脆弱度評估」功能，自行選取調查項目或上傳資料進行(空間)統計圖表的製作，並將結果下載。<br />2. 會員可以使用撤離與收容評估系統中的撤離、收容人數及物資試算功能，並將結果下載。<br />3. 會員可使用本站問與答功能。</p>
										</p>
										<CustomCheckbox id="allowright" className="checkbox" {...this.model({checkbox_allowright}, checkbox_allowright_msg)}>
											<span className="type-text -ch -xs">我同意會員服務條款</span>
										</CustomCheckbox>
									</div>
								</div>


								{/* <div className="formitem">
									<CustomCheckbox id="allowemail" className="checkbox" {...this.model({checkbox_allowemail})}>
										<span className="type-text -ch -xs">我想收到本站最新消息及新增文章的E-mail通知。</span>
									</CustomCheckbox>
									<CustomCheckbox id="allowjoin" className="checkbox" {...this.model({checkbox_allowjoin})}>
										<span className="type-text -ch -xs">我想同時加入 <Link className="link-text" to="">災防易起來</Link> 會員。</span>
									</CustomCheckbox>
								</div> */}


								<div className="formitem">
									{/* <ReCAPTCHA
										sitekey={ReCAPTCHA_siteKey}
										onChange={this.handleCheckReCAPTCHA}
									/> */}
								</div>


								<div className="formitem -center">
									{/* <button type="button" className="submit fill-button -yellow" disabled={!(passReCAPTCHA && checkbox_allowright)} onClick={this.handleSubmitClick}> */}
									<button type="button" className="submit fill-button -yellow" disabled={!(checkbox_allowright)} onClick={this.handleSubmitClick}>
										<span className="type-text -ch -md">提交</span>
									</button>
									{/* <p className="type-text -ch -xs">已經有帳號了嗎？ <Link className="link-text" to="/member/login">登入</Link></p> */}
								</div>


							</form>
						</section>
						
					</div>
				</div>
			</div>
		);
	}

	render_orgsort = () => {
		const {input_orgsort, input_orgsort_msg} = this.state;
		return (
			<div className="formitem">
				<CustomSelect id="orgsort" label="組織類別*" {...this.model({input_orgsort}, input_orgsort_msg)} >
					<option value="">請選擇</option>
					{
						organization.map(item =>
							<option value={item} key={item}>{item}</option>
						)
					}
				</CustomSelect>
			</div>
		)
	}

	render_orgtitle = () => {
		
		const {input_orgtitle, input_orgtitle_msg} = this.state;
		const {type} = this.props.match.params;
		return (
			<div className="formitem">
				<CustomInput id="orgtitle" type="input" label={type === 'organization' ? '單位名稱*' : '服務單位*'} placeholder="如：國家災害防救科技中心" {...this.model({input_orgtitle}, input_orgtitle_msg)} />
			</div>
		)
	}

	render_location = () => {
    const { countiesData, townsData, selectedCounty, selectedTown } = this.state;
		return (
			<div className="row">
				<div className="formitem col-md-6">
          <CustomSelect status="normal" name="year" label="單位所在縣市*"
            value={selectedCounty ? selectedCounty.code : ''}
            onChange={value => this.handleCountyDistrictPickSelected(value)}>
            <option value="0">請選擇</option>
            {
              countiesData &&
              countiesData.map((item, index) =>
                <option key={index} value={item.code}>{item.county}</option>
              )
            }
          </CustomSelect>
				</div>
				<div className="formitem col-md-6">
          <CustomSelect status="normal" name="county" label="單位所在鄉鎮市*" 
            value={selectedTown ? selectedTown.code : ''}
            onChange={(value) => { this.handleTownDistrictPickSelected(value) }}>
            <option value="0">請選擇</option>
            {
              townsData &&
              townsData.map((item, index) => 
                <option key={index} value={item.code}>{item.town}</option>
              )
            }
          </CustomSelect>
				</div>
			</div>
		)
	}

	render_username = () => {
		
		const {input_username, input_username_msg} = this.state;
		return (
			<div className="formitem">
				<CustomInput id="username" type="input" label="聯絡人姓名*" placeholder="張冠軍" {...this.model({input_username}, input_username_msg)}/>
			</div>
		)
	}

	render_username_sn = () => {
		
		const {input_username} = this.state;
		return (
			<div className="formitem">
				<div className="custom-input">
					<label className="type-text -ch -md" htmlFor="username">聯絡人姓名</label>
					<div className="display">{input_username}</div>
					
				</div>
			</div>
		)
	}

	render_useremail = () => {
		
		const {input_useremail, input_useremail_msg} = this.state;
		return (
			<div className="formitem">
				<CustomInput id="username" name="username" type="email" label="聯絡人E-mail*" placeholder="champion@ncdr.gov.tw" {...this.model({input_useremail}, input_useremail_msg)}/>
			</div>
		)
	}

	render_useremail_sn = () => {
		
		const {input_useremail, input_useremail_msg} = this.state;
		return (
			<div className="formitem">
				<div className="custom-input">
					<label className="type-text -ch -md" htmlFor="username">聯絡人E-mail</label>
					<div className="display">{input_useremail}</div>
					{input_useremail_msg && <div className='type-text -ch -xs msg -danger'>{input_useremail_msg}</div>}
				</div>
			</div>
		)
	}

	render_usertitle = () => {
		
		const {input_usertitle, input_usertitle_msg} = this.state;
		return (
			<div className="formitem">
				<CustomInput id="usertitle" type="input" label="職稱*" placeholder="如：研究員" {...this.model({input_usertitle}, input_usertitle_msg)}/>
			</div>
		)
	}

	render_password = () => {
		
		const {input_password, input_password_msg} = this.state;
		return (
			<div className="formitem">
				<CustomInput id="password" name="password" type="password" label="密碼* " {...this.model({input_password}, input_password_msg)}/>
        <p className="remark">密碼須包含英數組合 6 個字元以上</p>
			</div>
		)
	}

	render_repassword = () => {
		const {input_repassword, input_repassword_msg} = this.state;
		return (
			<div className="formitem">
				<CustomInput id="repassword" type="password" label="再次確認密碼*" {...this.model({input_repassword}, input_repassword_msg)}/>
			</div>
		)
	}

}

export default withRouter(MemberRegisterOrg);
