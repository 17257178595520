import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import './index.scss';

class PageNavigation extends Component {
	constructor(params) {
		super(params);
		this.state = {
			prev: window
		}
	}
	componentDidMount() {
		if (this.props) {
			this.handleLocation();
		}
	}
	t = (zhtw, en) => {
		if (!this.props.lang) {
			return zhtw;
		} else {
			return this.props.lang === 'zh-tw' ? zhtw : en;
		}
	}
	handleLocation = (link) => {
		let pathname = window.location.pathname;
		let index = pathname.lastIndexOf('/');
		pathname = pathname.slice(0, index + 1) + link;

		return pathname;
	}
	handleClickGoTop = () => {
		window.scrollTo(0, 0);
	}
	render() {
		const {t} = this;
		const {prev, toList, next, theme} = this.props;
		return (
			<section className={`article-pager page-navigation ${theme ? `-${theme}` : ''}`}>
				<div className="container">
					{
						prev !== undefined &&
						prev !== 0 &&
						prev !== "" &&
						<Link to={ this.handleLocation(prev) } className="prev direction-button -prev">
							<span className="icon"><i className="icon-arrow-l-left"></i></span>
							<span className={'text type-text -md' + t(' -ch', ' -en')}>{t('上一篇文章', 'Previous')}</span>
						</Link>
					}
					<Link to={toList || ""} className="back">
						<span className="icon"><i className="icon-grid"></i></span>
						<span className={'type-text -md' + t(' -ch', ' -en')}>{t('回到文章列表', 'Back to list')}</span>
					</Link>
					<button className="top" onClick={this.handleClickGoTop}>
						<span className="icon"><i className="icon-arrow-l-top"></i></span>
						<span className="type-text -en -md">TOP</span>
					</button>
					{
						next !== undefined &&
						next !== 0 &&
						next !== "" &&
						<Link to={ this.handleLocation(next) } className="next direction-button -next">
							<span className={'text type-text -md' + t(' -ch', ' -en')}>{t('下一篇文章', 'Next')}</span>
							<span className="icon"><i className="icon-arrow-l-right"></i></span>
						</Link>
					}
				</div>
			</section>
		);
	}
}

export default withRouter(PageNavigation);
