import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import SvgZoomMap from '../../utils/SvgZoomMap';
import LoadingDots from '../../utils/LoadingDots';

// config
// import Houses from '../../config/PermanentHouse';
import Axios from 'axios';

class MapHouse extends Component {
  constructor(props) {
    super(props);
    this.mapWrap = React.createRef();
    this.state = {
      selectedCount: 0,
      selected: [],
      isMapShow: false,
      isBubbleShow: false,
      bubbleLeft: 0,
      bubbleTop: 0,
      bubble: { title: '', description: ''},
      houses: []
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleSetHouseData();
  }
  handleSetHouseData() {
    Axios.get('/config/PermanentHouse.json')
    .then(result => {
      const housesData = result.data.data;
      housesData.map(data => data.active = false);
      this.setState({ housesData: housesData });
    })
  }
  handleClickRemoveHouse = (e) => {
    const { housesData } = this.state;
    const houseId = Number(e.currentTarget.dataset.id);
    const currentHouseIndex = housesData.findIndex(house => house.id === houseId);

    this.handleUpdateHousesData(currentHouseIndex);
  }
  handleSetArea = (area) => {
    if (area) { this.setState({ county: area[0], town: area[1] }) };
  }
  handleUpdateHousesData(index) {
    const { getSelected } = this.props;
    const { housesData } = this.state;

    const selectedCount = housesData.filter(house => house.active).length;
    if (housesData[index].active) {
      housesData[index].active = false
    }
    else if (selectedCount < 5) {
      housesData[index].active = true;
    }

    getSelected(housesData.filter(house => house.active).map(house => house.id));
    this.setState({ housesData: housesData });
  }
  handlePinClick = (houseItem) => {
    const { housesData } = this.state;
    const currentHouseIndex = housesData.findIndex(house => house.id === houseItem.id);

    this.handleUpdateHousesData(currentHouseIndex);
  }
  handlePinHover = (pin, e) => {
    const clientX = e.clientX;
    const clientY = e.clientY;

    this.setState({
      isBubbleShow: true,
      bubble: pin,
    }, () => this.handlePinPosition(clientX, clientY));
  }
  handlePinPosition = (clientX, clientY) => {
    const mapWrap = this.mapWrap.current;
    const boxWidth = 350;
    const boxHeight = 450;
    const posX = clientX - mapWrap.getBoundingClientRect().x;
    const posY = clientY - mapWrap.getBoundingClientRect().y;

    this.setState({
      bubbleTop: posY,
      bubbleLeft: posX,
      bubbleOppositeX: (clientX + boxWidth) > window.innerWidth,
      bubbleOppositeY: (window.scrollY + clientY + boxHeight) > document.body.offsetHeight
    })
  }
  render() {
    const { county, town, housesData } = this.state;
    const { isMapShow, isBubbleShow, bubbleTop, bubbleLeft, bubble } = this.state;
    const ignoreCounty = ['澎湖縣', '金門縣', '連江縣'];
    const disableCounty = ['臺北市', '新北市', '基隆市', '桃園市', '新竹縣', '新竹市', '苗栗縣', '臺中市', '彰化縣', '雲林縣', '嘉義市', '宜蘭縣', '花蓮縣'];
    return (
      <section className="map-selector">
        <div className="container">
          <div className="row">
            <div className="col-md-6 maps">
              <div className={`bubble-info ${isBubbleShow ? '-show' : ''}`}
                style={{
                  'position': 'absolute',
                  'top': `${bubbleTop}px`,
                  'left': `${bubbleLeft}px`,
                  'zIndex': 99
                }}>
                <div className={`bubble`}>
                  <p className="name">{bubble.title}</p>
                  <p className="description">{bubble.description}</p>
                </div>
              </div>

              {!isMapShow && <LoadingDots theme="green" />}
              <div className={`taiwan-map ${ isMapShow && '-show' }`} ref={this.mapWrap}>
                <SvgZoomMap
                  countyJsonSrc="/MapJson/taiwan-county.json"
                  townJsonSrc="/MapJson/taiwan-town.json"
                  villageJsonSrc="/MapJson/taiwan-village.json"
                  onAreaHover={ this.handleMouseOverMap }
                  onAreaClick={ (area) => this.handleSetArea(area) }
                  county={county}
                  town={town}
                  pins={housesData}
                  pinsLimit={true}
                  onPinClick={(pinItem) => this.handlePinClick(pinItem)}
                  onPinHover={(pinItem, e) => this.handlePinHover(pinItem, e)}
                  onPinLeave={() => this.setState({ isBubbleShow: false })}
                  zoomDelay={0}
                  ignoreArea={ignoreCounty}
                  disableArea={disableCounty}
                  isMapInit={() => { this.setState({ isMapShow: true }) }}
                // zoomDuration={0}
                />
              </div>
            </div>
            <div className="col-md-6 list">
                <div className="head type-text -ch -sm">
                  <p>已選擇</p>
                  <Link to={`${this.props.subjectSelected}?houses=0`} className="more">看全部永久屋</Link>
                </div>
                {
                  housesData &&
                  housesData.map((item, i) => {
                    return (
                      item.active &&
                      <div className="tag"
                        key={i}>
                        <span className="type-text -ch -xs">{item.name}</span>
                        <button onClick={this.handleClickRemoveHouse}
                          data-name={item.name}
                          data-id={item.id}
                        ><i className="icon-close"></i></button>
                      </div>
                    )
                  })
                }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MapHouse;
