import { connect } from 'react-redux';
import SideMenu from './pure';

const mapStateToProps = (state, ownProps) => ({
  open: state.sideMenu.open,
  menuItems: state.sideMenu.menuItems,
})

const mapDispatchToProps = (dispatch) => ({
  onClickOpenMenu: () => dispatch({type: 'OPEN_SIDEMENU'}),
  onClickCloseMenu: () => dispatch({type: 'CLOSE_SIDEMENU'}),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu);
