import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import './index.scss'

class NewsItem extends Component {
  reduceContent(text) {
    let result = text.length > 50 ? `${text.slice(0, 50)}...` : text;
    return result;
  }
  render() {
    const {title, content, href, date, className} = this.props;
    const month = dayjs(date).format('MMM');
    const day = dayjs(date).format('D');
    return (
      <div className={'news-item' + (className ? ' ' + className : '')}>
          <div className="datemonth">
              <span className="month type-text -en -xs">{month}</span>
              <span className="date type-heading -en -h5">{day}</span>
          </div>
          <div className="article">
              {/*<div className="sort type-text -ch -md">{sorting}</div>*/}
              <div className="title type-text -ch -lg">{title}</div>
              <article className="summary type-text -ch -md">{this.reduceContent(content)}</article>
              <div className="read"><Link to={href} className="text-button -light-yellow"><span className="type-text -en -sm">閱讀文章</span><i className="icon-arrow-right"></i></Link></div>
          </div>
      </div>
    );
  }
}

NewsItem.protoType = {
  date: PropTypes.string,
  sorting: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
}

export default NewsItem;