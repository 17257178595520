import React, { Component } from 'react';
import './index.scss';
import ReactEcharts from 'echarts-for-react'; 
import CustomRadio from '../../utils/CustomRadio';
class EvacuationChartDraw extends Component {
	constructor(params) {
		super(params);
		this.state = {
			valueType: 'Avarge',
			echartsOption: {},
		};
	}

	componentDidMount() {
		this.getChartType();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.valueType !== this.state.valueType ||
			prevProps.data !== this.props.data) {
			this.getChartType();
		}
	}
	getChartType() {
		const { category } = this.props;

		switch (category) {
			case "year":
				this.renderYearChart();
				break;
			case "level":
				this.renderLevelChart();
				break;
			case "path":
				this.renderPathChart();
				break;
			case "event":
				this.renderEventChart();
				break;

			default:
				break;
		}
	}
	renderYearChart = () => {
		const { data } = this.props;
		const { valueType } = this.state;
		// const data = [{ "Name": "2011", "Avarge": 170.33333333333334, "Sum": 2044, "Event": 12 }, { "Name": "2012", "Avarge": 165.24489795918367, "Sum": 8097, "Event": 49 }, { "Name": "2013", "Avarge": 147.1904761904762, "Sum": 6182, "Event": 42 }, { "Name": "2014", "Avarge": 148.5, "Sum": 3267, "Event": 22 }, { "Name": "2015", "Avarge": 166.03571428571428, "Sum": 4649, "Event": 28 }, { "Name": "2016", "Avarge": 233.72916666666666, "Sum": 11219, "Event": 48 }, { "Name": "2017", "Avarge": 453.07142857142856, "Sum": 12686, "Event": 28 }, { "Name": "2018", "Avarge": 251.65, "Sum": 5033, "Event": 20 }];

		const xAxisYears = data.map(item => item.Name);
		const dataSeries = data.map(item => [item.Name, item[valueType]]);

		const option = {
			color: ['#3690C0'],
			grid: {
				containLabel: true,
				left: '3%',
				right: '3%',
				top: '5%',
				bottom: '5%',
			},
			tooltip: {
				trigger: 'axis',
			},
			xAxis: {
				type: 'category',
				data: xAxisYears,
			},
			yAxis: {
				axisLine: { show: false },
				type: 'value',
				min: 0,
				minInterval: 2,
				splitNumber: 5,
				scale: true
			},
			series: {
				type: 'line',
				data: dataSeries
			}
		};

		this.setState({
			echartsOption: option
		})
		
	}
	renderLevelChart() {
		const { data } = this.props;
		const { valueType } = this.state;
		// const data = [{"Name":"中度","Avarge":173.08823529411765,"Sum":11770,"Event":68},{"Name":"強烈","Avarge":218.31746031746033,"Sum":13754,"Event":63},{"Name":"輕度","Avarge":178.82608695652175,"Sum":12339,"Event":69}];
		
		const xAxisYears = data.map(item => item.Name);
		const dataSeries = data.map(item => [item.Name, item[valueType]]);

		const option = {
			color: ['#3690C0'],
			grid: {
				containLabel: true,
				left: '3%',
				right: '3%',
				top: '3%',
				bottom: '5%',
			},
			tooltip: {
				trigger: 'item',
			},
			xAxis: {
				type: 'category',
				data: xAxisYears,
			},
			yAxis: {
				type: 'value',
				min: 0,
				scale: true
			},
			series: {
				type: 'bar',
				barMaxWidth: 140,
				data: dataSeries
			}
		};

		this.setState({
			echartsOption: option
		});

	}
	renderPathChart() {
		const { data } = this.props;
		const { valueType } = this.state;
		const color = ['#3690C0', '#A6BDDB'];
		const categoryData = data.map(item => item.Name);
		const dataSeries = data.map((item, i) => {
			return {
				value: item[valueType],
				itemStyle: {
					color: i % 2 === 0 ? color[0] : color[1]
				} 
			} 
		});

		const option = {
			color: ['#3690C0'],
			grid: {
				containLabel: true,
				left: '3%',
				right: '3%',
				top: '3%',
				bottom: '5%',
			},
			tooltip: {
				trigger: 'item',
			},
			xAxis: {
				type: 'category',
				data: categoryData,
				axisLabel: { interval: 0, rotate: 45 }
			},
			yAxis: {
				type: 'value',
				min: 0,
				scale: true
			},
			series: {
				type: 'bar',
				barMaxWidth: 40,
				data: dataSeries
			}
		};

		this.setState({
			echartsOption: option
		});
	}
	renderEventChart() {
		const { data } = this.props;
		const color = ['#3690C0', '#A6BDDB'];
		const dataSortByTop10 = data.slice().sort((a, b) => b['Sum'] - a['Sum']).slice(0, 10);
    const dataSortByDecrement = dataSortByTop10.sort((a, b) => a['Sum'] - b['Sum']);

		const categoryData = dataSortByDecrement.map(item => item.Name).slice(0, 10);
		const dataSeries = dataSortByDecrement.map((item, i) => {
			return {
				value: item['Sum'],
				itemStyle: {
					color: i % 2 === 0 ? color[0] : color[1]
				}
			}
		}).slice(0, 10);

		const option = {
			color: ['#3690C0'],
			grid: {
				containLabel: true,
				left: '3%',
				right: '3%',
				top: '3%',
				bottom: '5%',
			},
			tooltip: {
				trigger: 'item',
			},
			yAxis: {
				type: 'category',
				data: categoryData,
			},
			xAxis: {
				type: 'value',
			},
			series: {
				type: 'bar',
				barMaxWidth: 40,
				data: dataSeries
			}
		};

		this.setState({
			echartsOption: option
		});
	}
	render() {
		const { category, className } = this.props;
		const { valueType, echartsOption } = this.state;
		return (
			<div className={`evacuation-chart-draw ${className ? className : ''}`}>
				{
					category !== 'event' &&
					<div className="controller">
						<CustomRadio className="radio" color="pink" group="value-type"
							idName="value-avarge"
							checked={valueType === 'Avarge' ? true : false}
							onClick={ () => this.setState({ valueType: 'Avarge' }) }>
							<span className="text type-text -ch -xs">平均人次</span>
						</CustomRadio>
						<CustomRadio className="radio" color="pink" group="value-type"
							idName="value-sum"
							checked={valueType === 'Sum' ? true : false}
							onClick={ () => this.setState({ valueType: 'Sum' }) }>
							<span className="text type-text -ch -xs">總人次</span>
						</CustomRadio>
					</div>
				}
				<ReactEcharts
					className="draw"
					option={ echartsOption }
					notMerge={true}
					lazyUpdate={true}
					opts={{ renderer: 'svg' }}
					style={{ height: '450px' }}
				/>
			</div>
		);
	}
}

export default EvacuationChartDraw;
