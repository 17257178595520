import React, { Component } from 'react';
import './index.scss';

// modules
import Modal from 'react-modal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class ModalBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
		}

		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.open !== this.props.open) {
			if (this.props.open) {
				this.openModal();
				disableBodyScroll(document.getElementById('root'));
			} else {
				this.closeModal();
				enableBodyScroll(document.getElementById('root'));
			}
		}
	}

	afterOpenModal() {
	}

	closeModal() {
		if (this.props.onRequestClose) {
			this.props.onRequestClose();
		}
		this.setState({ modalIsOpen: false });
	}
	openModal() {
		this.setState({ modalIsOpen: true });
	}

	render() {
		const { theme } = this.props;
		return (
			<React.Fragment>
				<Modal
					appElement={document.getElementById('root')}
					className={`modal modal-content ${theme ? `-${theme}` : ''}`}
					overlayClassName="modal-container"
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
				>
					<button className={`close ${theme ? `-${theme}` : ''}`} onClick={this.closeModal}>
						<i className="icon-close"></i>
					</button>
					{this.props.children}
				</Modal>
			</React.Fragment>
		);
	}
}

export default ModalBox;
