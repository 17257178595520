import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// Utils
import ModalBox from '../../utils/ModalBox';

class Register extends Component {
	constructor(params) {
		super(params);
		this.state = {
			modalOpen: false,
		}
	}
	handleClickSendData = (e) => {
		e.preventDefault();
		this.openModal();
	}

	openModal() {
		this.setState({
			modalOpen: true,
		})
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '會員註冊', href: '/member/register' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 會員註冊</title>
				</Helmet>
				<ModalBox
					open={this.state.modalOpen}
					theme="-yellow"
					onRequestClose={() => {
						this.setState({ modalOpen: false })
					}}
				>
					<div className="modalbody">
						<p className="type-text -ch -md">你的密碼已修改，請重新登入</p>
					</div>
					<div className="modalfooter">
						<Link to="/member/login" className="fill-button -fit -yellow">立即登入</Link>
					</div>
				</ModalBox>
				<div className="member-page">
					<div className="container">
						<div className="row">
							<section className="member-register member-section col-lg-6 offset-lg-3">
								<div className="heading">
									<span className="type-heading -ch -h3">會員註冊</span>
								</div>
								<form className="form login-form" action="">
									<div className="formitem">
										<Link to="/member/register/organization" className="submit fill-button -yellow">
											<span className="type-text -ch -md">機關</span>
										</Link>
										<div className="description">
											<p className="type-text -ch -xs">因為工作需求，可用機關/組織的名義申請會員。</p>
										</div>
									</div>
									<div className="formitem">
										<Link to="/member/register/person" className="submit fill-button -dark">
											<span className="type-text -ch -md">個人</span>
										</Link>
										<div className="description">
											<p className="type-text -ch -xs">個人使用帳號，可利用 facebook 或 google 帳號快速登入。</p>
										</div>
									</div>
								</form>
							</section>

						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Register;
