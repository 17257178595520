import React, { Component } from 'react';
import './index.scss';
import ChartFilter from '../ChartFilter'
import ChartDraw from '../ChartDraw'
import ChartValidN from '../ChartValidN'

// Utils
import CustomDropdown from '../../utils/CustomDropdown';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import chartConverter from '../../utils/Shared/chartConverter';
import chartCapture from '../../utils/Shared/chartCapture';

class CitySubjectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: null,
      currentLocate: null,
      itemData: [],
      chartData: [],
    };
  }
  componentDidMount() {
    const { data } = this.props.subject;
    const itemData = data ? JSON.parse(data) : null;
    this.setState({
      itemData: itemData
    }, () => {
      this.initFilter();
    })
  }
  initFilter() {
    const { itemData } = this.state;
    if (itemData) {
      const allFilters = itemData.charts.map((chart) => chart.filter);
      const filtersCategory = [];
      allFilters.forEach((filter) => {
        if (filter) {
          (Object.keys(filter)).forEach((item) => {
            if (filtersCategory.indexOf(item) === -1) {
              filtersCategory.push(item);
            };
          });
        };
      });
      const filterData = filtersCategory.map((category) => {
        const data = {};
        data.label = category;
        data.items = [];
        allFilters.forEach((filter) => {
          if (data.items.indexOf(filter[category]) === -1) {
            data.items.push(filter[category]);
          }
        });
        data.value = data.items[0];
        return data;
      });
      const years = filterData.find((filter) => filter.label === '年份');
      const currentYear = years ? years.items[0] : null;
      const locates = filterData.find((item) => item.label === '縣市');
      const currentLocate = locates ? locates.items[0] : null;

      this.setState({
        filterData: filterData,
        currentYear: currentYear,
        currentLocate: currentLocate,
      }, () => {
        this.initSubject();
      });
    }
  }
  initSubject() {
    const { itemData } = this.state;
    const chartData = chartConverter(itemData, itemData.charts);
    this.setState({
      chartData: chartData,
    })
  }
  handleClickFilter({label, item}) {
    const { filterData } = this.state;
    switch (label) {
      case '縣市':
        filterData.forEach(data => {
          if (data.label === label) {
            data.value = item
          }
        })
        this.setState({
          currentLocate: item,
          filterData: filterData
        }, () => { this.initSubject(); })
        break;
      case '年份':
        filterData.forEach(data => {
          if (data.label === label) {
            data.value = item
          }
        })
        this.setState({
          currentYear: item,
          filterData: filterData
        }, () => { this.initSubject(); })
        break;
    
      default:
        break;
    }
  }
  handleClickDownloadPNG = (e) => {
    e.preventDefault();
    const { title } = this.props.subject;
    const { currentLocate, currentYear } = this.state;

    const captureArea = this.refs.subject.querySelectorAll('.capture');
    const fileName = `${currentYear || ''}${currentLocate || ''}${title}`
    
    chartCapture(captureArea, fileName);
    gaTracking('莫拉克颱風', '縣市專區', '圖表下載');
  }
  render() {
    const { id } = this.props;
    const { title, description } = this.props.subject;
    const { currentLocate, currentYear, chartData, filterData } = this.state;
    return (
      <div ref="subject" className={`subject-item`}>
        <div className="item-filter">
          {
            filterData &&
            filterData.map((filter, i) => {
              return (
                <div className="filter" key={i}>
                  <div className="label">{filter.label}</div>
                  <ChartFilter 
                    title={title}
                    label={filter.label} 
                    value={filter.value}
                    data={filter.items}
                    getRadio={(e) => { this.handleClickFilter(e)}}
                  ></ChartFilter>
                </div>
              )
            })
          }
        </div>
        <div className="item-title">
          <div className="title capture">
            { 
              (currentYear || '') + 
              (currentLocate ? (currentLocate === '全選' ? '各縣市' : currentLocate) : '') + title
            }
          </div>
          <div className="dropdown">
            <CustomDropdown title="下載">
              <li><button data-capture={id} onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
            </CustomDropdown>
          </div>
        </div>
        <div className="item-description capture">{description}</div>
        {
          chartData && chartData.map((item, i) => 
            item &&
            <React.Fragment key={i}>
              {
                (item.filter && item.filter['縣市'] ? item.filter['縣市'] === currentLocate : true) &&
                (item.filter && item.filter['年份'] ? item.filter['年份'] === currentYear : true) &&
                <React.Fragment>
                  <div className="chart">
                    <div className="capture">
                      <ChartDraw chart={item} id={id}></ChartDraw>
                    </div>
                  </div>
                  <div className="notes capture">
                    {
                      item.validNData &&
                      <ChartValidN validNData={item.validNData} />
                    }
                    {item.notes.map((note, k) => <p key={k}>{note}</p>)}
                  </div>
                </React.Fragment>
              }
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default CitySubjectItem;
