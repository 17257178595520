import { connect } from 'react-redux';
import SearchCity from './pure';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch) => ({
	onLoadHeaderColor: (COLOR) => dispatch({ type: 'HEADER_COLOR_' + COLOR }),
	onLoadHeaderTheme: (THEME) => dispatch({ type: 'HEADER_THEME_' + THEME }),
	onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
	onLoadShowBreadcrumb: (STATUS, data) => dispatch({ type: STATUS + '_BREADCRUMB', breadcrumbData: data }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchCity);
