import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

// Utils
import ArticleItem from '../../utils/ArticleItem';
import PageSelector from '../../utils/PageSelector';
import {apiHost, sourceHost} from '../../utils/Shared/constant';
import SearchBar from '../../utils/SearchBar';

// Resources
import morakotBanner from '../../../images/article-morakot.png';
import sfaaBanner from '../../../images/article-sfaa.png';
import sviBanner from '../../../images/article-svi.png';
import evacuateBanner from '../../../images/article-evacuate.png';

class ArticleListPage extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			totalPage: 0,
			nowPage: -1,
			nowCategoryID: 0,
			loading: true,
			banner: '',
			theme: '',
			pathname: '',
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let slug = window.location.pathname.split('/').slice(2, 3).join('');
		this.setState({
			slug: slug,
		});

		let breadcrumb = [];
		switch (slug) {
			case "morakot":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('SHOW');
				this.setState({ 
					theme: 'green',
					banner: morakotBanner,
			    	pathname: '/statistics/morakot',
					nowCategoryID: 1,
					title: '莫拉克颱風主題館',
					language: 'ch',
					subtitle: 'Social Impacts and Recovery Survey of Typhoon Morakot (2010~2019)',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
					{ name: '主題館', href: '/statistics/morakot/categories' },
				];
				break;
			case "morakot-en":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('SHOW');
				this.setState({
					theme: 'green',
			    	pathname: '/statistics/morakot',
					banner: morakotBanner,
					nowCategoryID: 5,
					title: 'Morakot Theme Pavilion',
					language: 'en',
					subtitle: 'Social Impacts and Recovery Survey of Typhoon Morakot (2010~2019)',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: 'Home Page', href: '/' },
					{ name: 'Morakot Typhoon', href: '/statistics/morakot-en' },
					{ name: 'Theme Pavilion', href: '/statistics/morakot-en/categories' },
				];
				break;
			case "sfaa":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'green',
			    	pathname: '/statistics/sfaa',
					banner: sfaaBanner,
					nowCategoryID: 2,
					title: '社福機構自然災害主題館',
					language: 'ch',
					subtitle: 'Disaster Vulnerability Survey of Social Welfare Facilities for elderly and Disabled',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '社福機構', href: '/statistics/sfaa' },
					{ name: '主題館', href: '/statistics/sfaa/categories' },
				];
				break;
			case "svi":
				this.props.onLoadHeaderColor('BLUE');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'blue',
			    	pathname: '/evaluation/svi',
					banner: sviBanner,
					nowCategoryID: 4,
					title: '社會脆弱度主題館',
					language: 'ch',
					subtitle: '',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '社會脆弱度', href: '/evaluation/svi' },
					{ name: '主題館', href: '/evaluation/svi/categories' },
				];
				break;
			case "evacuation":
				this.props.onLoadHeaderColor('PINK');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'pink',
			    	pathname: '/evaluation/evacuation',
					banner: evacuateBanner,
					nowCategoryID: 9,
					title: '撤離與收容主題館',
					language: 'ch',
					subtitle: '',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
					{ name: '主題館', href: '/evaluation/evacuation/categories' },
				];
				break;
			case "home":
				this.props.onLoadHeaderColor('YELLOW');
				this.props.onLoadHeaderTheme('LIGHT');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({ 
					theme: 'yellow',
					banner: morakotBanner,
					pathname: '/statistics/home',
					nowCategoryID: null,
					title: '主題館',
					language: 'ch',
					subtitle: '',
				}, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' }
				];
				break;
			default: 
				this.setState({ nowCategoryID: 1 }, () => this.loadPage(1));
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
					{ name: '主題館', href: '/statistics/morakot/categories' },
				];
		}
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	loadPage = (page) => {
		const tag = window.location.search.replace('?', '') || null;
		if (tag) {
			this.handleSortByTag(tag)
		}
		else {
			this.setState(
				{loading: true},
				() =>
				axios.get(`${apiHost}/api/Content/ArticleList?data.currentPage=${page}&data.categoryID=${this.state.nowCategoryID}&data.perPage=10`)
					.then((res) => {
						if (res.status === 200) {
							this.setState({
								articleItems: res.data.Result ? res.data.Result : [],
								totalPage: res.data.TotalPage,
								nowPage: page,
								loading: false,
							})
						}
					})
			)
		}
	}

	handleCategoryButtonClick = (categoryID) => {
		this.setState({
			nowCategoryID: categoryID,
			nowPage: -1,
		}, () => {
			this.loadPage(1);
		})
	}

	handleSearchBarSubmit = (keyword) => {
		
		if (this.state.keyword === keyword) return;

		this.setState(
			{loading: true, keyword: keyword},
			() =>
			axios.get(`${apiHost}/api/Content/ArticleList?data.currentPage=${1}&data.categoryID=${this.state.nowCategoryID}&data.perPage=10&data.keyWord=${keyword}`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						articleItems: res.data.Result ? res.data.Result : [],
						totalPage: res.data.TotalPage,
						nowPage: 1,
						loading: false,
					})
				}
			})
		)
	}

	handleSortByTag = (tag) => {
		this.setState(
			{ loading: true },
			() =>
			axios.get(`${apiHost}/api/Content/ArticleList?data.categoryID=${this.state.nowCategoryID}&data.currentPage=${1}&data.perPage=10&data.tag=${tag.replace('#','')}`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						articleItems: res.data.Result ? res.data.Result : [],
						totalPage: res.data.TotalPage,
						nowPage: 1,
						loading: false,
					})
				}
			})
		)
	}

	handleSearchBarCancel = () => {
		this.setState(
			{ loading: true, keyword: '' },
			() => this.loadPage(this.state.nowPage)
		);
	}
	handleSelectSlug = (event) =>{
		const slug = event.target.value
		switch (slug) {
			case "morakot":
				this.setState({ nowCategoryID: 1}, () => this.loadPage(1));
				break;
			case "sfaa":
				this.setState({ nowCategoryID: 2}, () => this.loadPage(1));
				break;
			case "svi":
				this.setState({ nowCategoryID: 4}, () => this.loadPage(1));
				break;
			case "evacuation":
				this.setState({ nowCategoryID: 9}, () => this.loadPage(1));
				break;
			case "home":
				this.setState({ nowCategoryID: null}, () => this.loadPage(1));
				break;
			default: 
			this.setState({ nowCategoryID: null}, () => this.loadPage(1));
				break;
		}
	}
	render() {
		const { banner, theme, nowPage, totalPage, title, subtitle, language, articleItems, loading, pathname} = this.state;
		return (
			<div className="article-list-page">
				<section className={"heading article-section article-heading " + theme} style={{ backgroundImage: 'url(' + banner + ')' }}>
					<div className={"mainheading type-heading -h4 -" + language }>{ title }</div>
					<div className="subheading type-text -en -lg">{ subtitle }</div>
				</section>
				<section className="article-section article-subject">
					<div className="container">
						<div className="sectiontitle subtitle-block">
							<span className={"ch type-text -lg -" + language}>{ language === 'ch' ? "主題館" : "Theme Pavilion" }</span>
							<div className="custom-select-rd">
								<div className="wrap">	
									<i className="icon icon-dropdown"/>
									<select className="select" onChange={(event)=>this.handleSelectSlug(event)}>
										<option value="" selected disabled>請選擇主題</option>
										<option value="home">主館</option>
										<option value="morakot">莫拉克颱風主題館</option>
										<option value="sfaa">社福機構自然災害主題館</option>
										<option value="svi">社會脆弱度主題館</option>
										<option value="evaluation">撤離與收容主題館</option>
									</select>
								</div>
							</div>
							
			
							<p className="en type-text -en -xs">SUBJECT</p>
							<SearchBar className="type-text -ch -md" onSubmit={this.handleSearchBarSubmit} onCancel={this.handleSearchBarCancel}/>
						</div>
						<div className="row subject-list">
							{
								(!loading && articleItems && articleItems.length > 0) &&
								articleItems.map((item, i) => {
									const { Author, ID, ImageUrl, Summary, Title, Published } = item;
									return (
										<div key={i} className="col-lg-6">
											<ArticleItem 
											color={theme} 
											author={Author} 
											image={`${sourceHost}/${ImageUrl}`} 
											title={Title} 
											content={Summary} 
											href={`${pathname}/article/${ID}`}
											date={Published} 
											key={i} 
											selectedTag={(word) => { this.handleSortByTag(word)} }
											/>
										</div>
									)
								})
							}
							{
								(!loading && articleItems && articleItems.length <= 0) &&
								<div className="query-indicator type-text -ch -lg">抱歉，找不到符合條件的資料</div>
							}
							{
								loading &&
								<div className="query-indicator type-text -ch -lg">文章讀取中</div>
							}

						</div>
						{ !loading && <PageSelector className="pagination" theme={theme} nowPage={nowPage} maxPage={totalPage} onPageClick={(i) => this.loadPage(i)} />}
					</div>
				</section>
			</div>
		);
	}
}

export default ArticleListPage;
