import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import './index.scss';
import Scroll from 'react-scroll'
import SubjectItem from "../CitySubjectItem";

// Utils
import SocialBlock from '../../utils/SocialBlock';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { sourceHost, apiHost } from '../../utils/Shared/constant';

class CitySubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicID: 1,
      subjectData: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadHeaderColor('GREEN');
    this.props.onLoadHeaderTheme('DARK');
    this.props.onLoadShowLanguage('HIDE');
    const breadcrumb = [
      { name: '首頁', href: '/' },
      { name: '莫拉克風災災後調查', href: '/statistics/morakot' },
      { name: '縣市專區', href: '/statistics/morakot/morakot-city' },
    ]
    this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
    this.setSubject();
    
  }
  setSubject = () => {
    const pathname = window.location.pathname.split('/');
    const topics = [
      { id: 1, name: 'disaster-reduction' },
      { id: 2, name: 'disaster-response' },
      { id: 3, name: 'disaster-recover' },
      { id: 4, name: 'respondent-demographics' },
      { id: 5, name: 'household-demographics' },
      { id: 6, name: 'disability' },
      { id: 7, name: 'disaster-impact' },
      { id: 8, name: 'resource-acquisition' }
    ];
    topics.forEach((topic) => {
      if (pathname[3] === topic.name) {
        this.setState({
          topicId: topic.id
        }, () => {
            this.loadTopic();
        })
      }
    });
  }
  loadTopic = () => {
    const { topicId } = this.state;
    Axios.get(`${apiHost}/api/Survey/CountyTopic?topic_id=${topicId}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            subjectData: res.data.Result[0]
          })
        }
      })
  }
  render() {
    const { title, raw_data_url, resource, description, subjects } = this.state.subjectData;
    return (
      <div className="morakot-area-page">
        <Helmet>
          <title>減災動資料 - 莫拉克風災災後調查</title>
        </Helmet>
        <div className="container">
          <div className="morakot-area-heading">縣市專區 - { title }</div>
          <div className="morakot-area-subject">
            <div className="description">{ description }</div>
            <div className="subject-buttonbar">
              {
                subjects &&
                subjects.map((subject, i) => {
                  return (
                    <button className="button ghost-button -green" key={i}
                    onClick={() => { Scroll.scroller.scrollTo(subject.title, { smooth: true })}}>{subject.title}</button>
                  )
                })
              }
            </div>
            {
              subjects &&
              subjects.map((subject, i) => {
                return (
                  <Scroll.Element name={subject.title} key={i}>
                    <section className="subject-section">
                      <div className="subject-title">{subject.title}</div>
                      <div className="subject-description">{subject.description}</div>
                      {
                        subject.items.map((item, j) => {
                          return (
                            <SubjectItem key={j} subject={item} id={i}></SubjectItem>
                          )
                        })
                      }
                    </section>
                  </Scroll.Element>
                )
              })
            }
            <div className="subject-resource">{ resource }</div>
            <div className="subject-download">
              <a href={`${sourceHost}${raw_data_url}`} onClick={() => gaTracking('莫拉克颱風', '縣市專區', '下載六縣市原始數據')} className="button fill-button -dark">下載六縣市原始數據</a>
            </div>
          </div>
        </div>
        <SocialBlock category="莫拉克颱風" action="縣市專區" theme="green" />
        <section className="subject-selector">
          <div className="container">
          <div className="title type-text -lg -ch">瀏覽其他主題</div>
          <div className="subjects">
            <div className="item"><Link to="/statistics/morakot-city/disaster-reduction" className="button">減災</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/disaster-response" className="button">應變</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/disaster-recover" className="button">復原與重建</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/respondent-demographics" className="button">受訪者特徵</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/household-demographics" className="button">家戶特徵</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/disability" className="button">行動不便者</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/disaster-impact" className="button">災害衝擊</Link></div>
            <div className="item"><Link to="/statistics/morakot-city/resource-acquisition" className="button">資源取得</Link></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CitySubject;
