import React, { Component } from 'react';
import './index.scss';

class ChartValidN extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { validNData } = this.props;
    return (
      validNData &&
      validNData.map((data, i) =>
          <p key={i}>{data.name}有效 N：
            {
              data.items.map((validN, j) =>
              <span key={j}>{j !== 0 ? '、' : ''}{validN.name}＝{validN.value}</span>)
            }
          </p>
        )
    );
  }
}

export default ChartValidN;
