import React, { Component } from 'react';
import { Link } from "react-router-dom";
import queryString from "query-string";
import Axios from 'axios';
import Helmet from 'react-helmet';
import './index.scss';

// utils
import EvacuationChartTable from '../EvacuationChartTable';
import EvacuationChartDraw from '../EvacuationChartDraw';
import EvacuationChartMap from '../EvacuationChartMap';
import chartCapture from '../../utils/Shared/chartCapture';
import CustomSelect from '../../utils/CustomSelect';
import CustomDropdown from '../../utils/CustomDropdown';
import LoadingDots from '../../utils/LoadingDots';
import LimitMask from '../../utils/LimitMask';
import SocialBlock from '../../utils/SocialBlock';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { apiHost } from '../../utils/Shared/constant';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

class EvacuationChart extends Component {
	constructor(params) {
		super(params);
		this.state = {
			drawType: null,
      		isMemberLogin: false,
			isBasicLoading: false,
			isAdvanceLoading: false,
			isTopicLoading: false,
			isAdvance: false,
			isDataLoading: false,
			isDataLoaded: false,
			csvData: [],
			selectedCounty: null,
			selectedTown: null,
			selectedTopic: '0',
      		currentTopic: null,
			topics: [],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('PINK');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

    this.checkMemberLogin();
		this.getSubject();
	}
	handleSetBreadcurmb() {
		const { subjectTitle } = this.state;
		let title;

		switch (subjectTitle) {
			case 'evacuate': title = '歷史撤離人數查詢'; break;
			case 'contain': title = '歷史收容人數查詢'; break;
			case 'alert': title = '歷史警戒熱區查詢'; break;
			default: title = '歷史撤離人數查詢'; break;
		}
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
			{ name: '歷史資料查詢與展示', href: '/evaluation/evacuation/intro' },
			{ name: title, href: window.location.pathname },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
  checkMemberLogin = () => {
    if (window.localStorage.getItem('user-token')) {
      this.setState({ isMemberLogin: true });
    }
  	}
	getSubject() {
		const subject = window.location.pathname.split('/').pop();
		let title;
		switch (subject) {
			case 'evacuate': title = '歷史撤離人數查詢'; break;
			case 'contain': title = '歷史收容人數查詢'; break;
			case 'alert': title = '歷史警戒熱區查詢'; break;
			default: title = '歷史撤離人數查詢'; break;
		}
		
		this.setState({
			subjectTitle: subject,
			subjectTitleCH: title,
		}, () => {
			this.getTopicInfos();
			this.getCountiesData();
			this.handleSetBreadcurmb();
			this.getQuery();
		});
	}
	getQuery() {
		const report = queryString.parse(window.location.search);
		if (report.county && report.town && report.topic) {
			const countyCode = report.county;
			const townCode = report.town;
			const topic = report.topic;
			this.setState({ isDataLoading: true });
			this.getCountiesData().then(() => {
				this.handleCountyDistrictPickSelected(countyCode);
				this.getAvailiableTownsData().then(() => {
					this.setState({ isAdvance: true });
					this.handleTownDistrictPickSelected(townCode);
					this.getVaildTopic().then(() => {
						this.setState({ selectedTopic: topic }, () => {
							this.handleClickAdvanceSubmit();
						})
					})
				})
			})
		}
		else if (report.county) {
			const countyCode = report.county;
			this.setState({ isDataLoading: true });
			this.getCountiesData().then(() => {
				this.handleCountyDistrictPickSelected(countyCode);
				this.handleClickBasicSubmit();
			})
		}
	}
	getCountiesData() {
		return new Promise(resolve => {
			const { subjectTitle } = this.state;
			switch (subjectTitle) {
				case 'evacuate':
				case 'contain':
						Axios.get('/config/CountiesTownsData.json')
						.then(result => {
							this.setState({ countiesData: result.data.counties }, () => resolve());
						})
					break;
				case 'alert':
					Axios.get(`${apiHost}/api/Serverity/GetServerityEventCounty`)
					.then((res) => {
						if (res.data.Result) {
							Axios.get('/config/CountiesTownsData.json')
							.then(result => {
								const allData = result.data.counties;
								const countiesData = res.data.Result.map(county => {
									return { 
										county: county["County"],
										code: county["Code"],
										towns: allData.find(item => item.county === county["County"]).towns
									}
								});
								this.setState({ countiesData: countiesData }, () => resolve());
							})
							
							
						}
					})
					break;
				default:
					break;
			}
		})
	}
	getAvailiableTownsData() {
		return new Promise(resolve => {
			const { subjectTitle } = this.state;
			const { selectedCounty } = this.state;
			this.setState({ townsData: selectedCounty ? selectedCounty.towns : null }, () => resolve());
			// if (selectedCounty) {
			// 	let apiUrl;
			// 	switch (subjectTitle) {
			// 		case 'evacuate':
			// 		case 'contain':
			// 			apiUrl = `${apiHost}/api/Evacuate/GetEventDistrict?county=${selectedCounty.code}`; break;
			// 		case 'alert':
			// 			apiUrl = `${apiHost}/api/Serverity/GetServerityEventDistrict?county=${selectedCounty.code}`; break;
			// 		default:
			// 			break;
			// 	}
			// 	Axios.get(apiUrl)
			// 	.then((res) => {
			// 		const townsData = res.data.Result['Towns'].map(town => {
			// 			return { town: town["Town"], code: town["Code"] }
			// 		});
			// 		this.setState({ townsData: townsData }, () => resolve());
			// 	})
			// }
		})
	}
	// getTopicInfos() {
	// 	const { subjectTitle } = this.state;
	// 	Axios.get(`${apiHost}/api/Evacuate/GetEvacuateDescription`)
	// 	.then((res) => {
	// 		if (res.data.Result) {
	// 			const infos = res.data.Result.find(item => item.category === subjectTitle);
	// 			const infoDefault = infos.subjects.find(info => info.name === 'all' || info.name === 'all_alert');
	// 			console.log(infos,infoDefault)
	// 			this.setState({
	// 				topicInfosData: infos.subjects,
	// 				topicInfo: infoDefault
	// 			});
	// 		}
	// 	})
	// }	
	getTopicInfos() {
		const { subjectTitle } = this.state;
		let categoryId = 2
		if(subjectTitle === 'contain'){
			categoryId = 1
		}else if(subjectTitle === 'evacuate'){
			categoryId = 2
		}else if(subjectTitle === 'alert'){
			categoryId = 3
		}
		Axios.get(`${apiHost}/api/Evacuate/GetEvacuateDescriptionByCategoryId?categoryId=${categoryId}`)
		.then((res) => {
			if (res.data.Result) {
				const infoDefault = res.data.Result.subjects.find(info => info.name === '全部');
				this.setState({
					topicInfosData: res.data.Result.subjects,
					topicInfo: infoDefault
				});
			}
		})
	}
	getTopicInfoUpdate() {
		const { subjectTitle, topicInfosData, selectedTopic } = this.state;
		let topicInfo;
		if (topicInfosData) {
			if (subjectTitle === 'alert') {
				switch (selectedTopic) {
					case '0': topicInfo = topicInfosData.find(data => data.name === '歷年總覽'); break;
					case '1': topicInfo = topicInfosData.find(data => data.name === '村里土石流紅色警戒'); break;
					case '2': topicInfo = topicInfosData.find(data => data.name === '村里土石流黃色警戒'); break;
					case '3': topicInfo = topicInfosData.find(data => data.name === '水災一級警戒'); break;
					case '4': topicInfo = topicInfosData.find(data => data.name === '水災二級警戒'); break;
					default: break;
				}
			}
			else {
				switch (selectedTopic) {
					case '0'    : topicInfo = topicInfosData.find(data => data.name === '全部'); break;
					case 'year' : topicInfo = topicInfosData.find(data => data.name === '年份'); break;
					case 'level': topicInfo = topicInfosData.find(data => data.name === '颱風強度'); break;
					case 'event': topicInfo = topicInfosData.find(data => data.name === '颱風豪雨事件'); break;
					case 'path' : topicInfo = topicInfosData.find(data => data.name === '颱風路徑'); break;
					default: break;
				}
			}

			this.setState({
				topicInfo: topicInfo || null,
			})
		}
	}

	getHistoryBasicData() {
		const { subjectTitle, selectedCounty } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';

		Axios.get(`${apiHost}/api/Evacuate/Get${countyCode !== 'all' ? 'District' : ''}EvacuatePeople?county=${countyCode}&town=all&value=${subjectTitle}`)
		.then( res => {
			let csvData = typeof res.data.csv === 'object' ? [res.data.csv] : res.data.csv;
			res.data.Result &&
			this.setState({
				chartTitle: res.data.csv.title,
				isDataLoading: false,
				isAdvance: true,
				resultData: {
					unit: '人次',
					transferValue: 'Avarge',
					county: county,
					town: '',
					data: res.data.Result,
				},
				csvData: csvData,
				drawType: 'map'
			}, () => urlPushState(`?county=${countyCode}`));
		})
	}
	getHistoryAdvanceData() {
		const { subjectTitle, selectedCounty, selectedTown, selectedTopic } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let townCode = selectedTown ? selectedTown.code : 'all';
		let category = selectedTopic;

		Axios.get(`${apiHost}/api/Evacuate/GetEvacuatePeopleByCategory?county=${countyCode}&town=${townCode}&value=${subjectTitle}&sortby=${category}`)
		.then(res => {
			const csvData = typeof res.data.csv === 'object' ? [res.data.csv] : res.data.csv;
      if (res.data.Result) {
				this.setState({
					isDataLoading: false,
          currentTopic: category,
					chartTitle: res.data.csv.title,
					resultData: res.data.Result,
					csvData: csvData,
					drawType: 'chart'
				}, () => urlPushState(`?county=${countyCode}&town=${townCode}&topic=${category}`));
      }
      else {
        this.setState({
          isDataLoading: false,
          chartTitle: '',
          currentTopic: null,
          resultData: null,
          csvData: null,
          drawType: 'chart'
        }, () => urlPushState(`?county=${countyCode}&town=${townCode}&topic=${category}`));
      }
		})
	}
	getAlertBasicData() {
		const { selectedCounty } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';

		Axios.get(`${apiHost}/api/Serverity/GetServerityData?county=${countyCode}&town=all`)
		.then(res => {
			const csvData = typeof res.data.csv === 'object' ? [res.data.csv] : res.data.csv;
			res.data.Result &&
			this.setState({
				chartTitle: res.data.csv.title,
				isDataLoading: false,
				isAdvance: true,
				resultData: {
					unit: '次數',
					transferValue: 'Value',
					county: county,
					town: '',
					data: res.data.Result.data,
				},
				csvData: csvData || null,
				drawType: 'map'
			}, () => urlPushState(`?county=${countyCode}`));
		})
	}
	getAlertAdvanceData() {
		const { selectedCounty, selectedTown, selectedTopic } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';
		let townCode = selectedTown ? selectedTown.code : 'all';
		let town = selectedTown ? selectedTown.town : '';
		let category = selectedTopic;

		Axios.get(`${apiHost}/api/Serverity/GetServerityDataByTopics?county=${countyCode}&town=${townCode}&subject=${category}`)
		.then(res => {
			const csvData = typeof res.data.csv === 'object' ? [res.data.csv] : res.data.csv;
			res.data.Result &&
			this.setState({
				isDataLoading: false,
				chartTitle: res.data.csv.title,
				resultData: {
					unit: '次數',
					transferValue: 'Value',
					county: county,
					town: town,
					data: res.data.Result.data,
				},
				csvData: csvData || null,
				drawType: 'map'
			}, () => urlPushState(`?county=${countyCode}&town=${townCode}&topic=${category}`));

		})
	}

	handleClickBasicSubmit = () => {
		const { subjectTitle } = this.state;
		switch (subjectTitle) {
			case 'evacuate':
			case 'contain':
				this.getHistoryBasicData();
				break;

			case 'alert':
				this.getAlertBasicData();
				break;
		
			default:
				break;
		}
		this.getAvailiableTownsData();
		this.getVaildTopic();
		this.getTopicInfoUpdate();
		this.setState({
			isDataLoading: true,
		})
	}
	handleClickAdvanceSubmit = () => {
		const { subjectTitle } = this.state;
		switch (subjectTitle) {
			case 'evacuate':
			case 'contain':
				this.getHistoryAdvanceData();
				break;

			case 'alert':
				this.getAlertAdvanceData();
				break;

			default:
				break;
		}
		this.getTopicInfoUpdate();
		this.setState({
			isDataLoading: true,
		})
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();
		const { chartTitle } = this.state;
		const fileName = `${chartTitle}`;
		const captureArea = document.querySelectorAll('.capture');
		chartCapture(captureArea, fileName);
		this.handleDownloadTracking('圖表下載');
	}
	handleClickDownloadCSV = (e) => {
		const { csvData, chartTitle } = this.state;
		let csv = '';
		csvData.forEach((item) => {
			csv = csv + '\n' + item.csv;
		});

		const fileName = `${chartTitle}原始資料`;
		const copyright = '\n資料來源：國家災害防救科技中心減災動資料。';
		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;

		downloadTrigger(fileName + '.csv', csvContent);
		this.handleDownloadTracking('原始數據下載');

	}
	handleCountyDistrictPickSelected = selectedCode => {
		const { countiesData } = this.state;
		const selectedDistrict = countiesData.find( ({ code }) => code === selectedCode );
		this.setState({
			isAdvance: false,
			selectedCounty: selectedDistrict,
			townsData: null,
			selectedTown: null,
			selectedTopic: '0',
		}, () => { this.getVaildTopic() });
	}
	handleTownDistrictPickSelected = selectedCode => {
		const { townsData } = this.state;
		const selectedTown = townsData.find( ({ code }) => code === selectedCode );
		
		this.setState({
			selectedTown: selectedTown,
			selectedTopic: '0'
		}, () => { this.getVaildTopic() });
	}
	getVaildTopic() {
		return new Promise(resolve => {
			const { subjectTitle, selectedCounty, selectedTown } = this.state;
			let countyCode = selectedCounty ? selectedCounty.code : 'all';
			let townCode = selectedTown ? selectedTown.code : 'all';
	
			this.setState({ isTopicLoading: true });
			switch (subjectTitle) {
				case 'evacuate':
				case 'contain' :
					Axios.get(`${apiHost}/api/Evacuate/GetValidTopics?county=${countyCode}&town=${townCode}&value=${subjectTitle}`)
					.then((res) => {
						const topics = res.data.Result.Topics.map(topic => { 
							let item;
							switch (topic) {
								case 'year' : item = { Id: 'year', Name: '年份' }; break;
								case 'level': item = { Id: 'level', Name: '颱風強度' }; break;
								case 'event': item = { Id: 'event', Name: '颱風豪雨事件' }; break;
								case 'path' : item = { Id: 'path', Name: '颱風路徑' }; break;
								default:
									break;
							}
							return item;
						});
						this.setState({ topics: topics, isTopicLoading: false }, () => resolve() );
					})
					break;
	
				case 'alert':
					Axios.get(`${apiHost}/api/Serverity/GetServerityValidTopics?county=${countyCode}&town=${townCode}`)
					.then((res) => {
						const topics = [];
						res.data.Result.forEach(topic => {
							if (topic["Id"] === 3 || topic["Id"] === 4) {
								if (!selectedTown) {
									topics.push(topic);
								}
							}
							else {
								topics.push(topic);
							}
						});
						this.setState({ topics: topics, isTopicLoading: false }, () => resolve() );
					})
					break;
			
				default:
					break;
			}
		})

	}
	handleDownloadTracking(label) {
		const { subjectTitleCH } = this.state;
		gaTracking('撤離與收容', subjectTitleCH, label);
	}
	render() {
    const { isMemberLogin } = this.state;
		// District Data
		const { countiesData, townsData } = this.state;
		// status
		const { subjectTitle, subjectTitleCH, resultData, csvData, isDataLoading, isAdvance, isTopicLoading } = this.state;
		// request data
		const { chartTitle, drawType, selectedCounty, selectedTown, selectedTopic, topicInfo, currentTopic, topics } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/evacuation" />
				<div className="evacuation-page">
					<div className="container">
						<div className="evacuation-chart-title">
							<div className="title">
								{ subjectTitle === 'evacuate' && '歷史撤離人數查詢' }
								{ subjectTitle === 'contain'  && '歷史收容人數查詢' }
								{ subjectTitle === 'alert'    && '歷史警戒熱區查詢' }
							</div>
						</div>

						<div className="page-link">
							{
							subjectTitle === 'evacuate' &&
							<React.Fragment>
								<Link  className="item" to="/evaluation/evacuation/contain">
									<span>歷史收容人次查詢</span>
								</Link>
								<Link  className="item" to="/evaluation/evacuation/alert">
									<span>歷史警戒熱區查詢</span>
								</Link>
							</React.Fragment>
							}
							{
							subjectTitle === 'contain' &&
							<React.Fragment>
								<Link  className="item" to="/evaluation/evacuation/evacuate">
									<span>歷史撤離人次查詢</span>
								</Link>
								<Link  className="item" to="/evaluation/evacuation/alert">
									<span>歷史警戒熱區查詢</span>
								</Link>
							</React.Fragment>
							}
							{
							subjectTitle === 'alert' &&
							<React.Fragment>
								<Link  className="item" to="/evaluation/evacuation/evacuate">
									<span>歷史撤離人次查詢</span>
								</Link>
								<Link  className="item" to="/evaluation/evacuation/contain">
									<span>歷史收容人次查詢</span>
								</Link>
							</React.Fragment>
							}
							<Link  className="item" to="/evaluation/evacuation/estimate">
								<span>撤離與收容人數及物資估計</span>
							</Link>
						</div>

						<div className="evacuation-chart row">
							<div className="col-md-3 chart-controller">
								<div className="basic">
									<div className="type">
										<div className="step">
											<div className="title">
												<span className="en">Step 1</span>
												<span>請選擇縣市（單選）</span>
											</div>
											<div className="select">
												<CustomSelect status="normal" name="county"
													value={selectedCounty ? selectedCounty.code : ''}
													onChange={(value) => { this.handleCountyDistrictPickSelected(value) }}>
													<option value="0">全選</option>
													{
														countiesData &&
														countiesData.map((item, index) =>
															<option key={index} value={item.code}>{item.county}</option>
														)
													}
												</CustomSelect>
											</div>
										</div>
									</div>
									<div className="submit">
										<button className={"button" + (this.state.isBasicLoading ? ' -loading' : '')}
											onClick={this.handleClickBasicSubmit}><span>看結果</span></button>
									</div>
								</div>
								{
									isAdvance &&
									<div className="advance">
										<div className="step">
											<div className="title">
												<span className="en">Step 2</span>
												<span>請選擇鄉鎮（單選）</span>
											</div>
												{
													subjectTitle === 'alert' &&
													<div className="info"><p>淹水警戒發布單位為鄉鎮市區層級，若想看淹水警戒熱區，請保持全選。</p></div>
												}
											<div className="select">
												<CustomSelect status="normal" name="county"
													value={selectedTown ? selectedTown.code : ''}
													onChange={(value) => { this.handleTownDistrictPickSelected(value) }}>
													<option value="0">全選</option>
													{
														townsData &&
														townsData.map((item, index) => 
															<option key={index} value={item.code}>{item.town}</option>
														)
													}
												</CustomSelect>
											</div>
										</div>
										<div className="step">
											<div className="title">
												<span className="en">Step 3</span>
												<span>選擇統計主題（單選）</span>
											</div>
											<div className="info">
												{
													subjectTitle === 'evacuate' &&
													<p>請選擇統計資料的分類依據，如選擇「年份」，系統即提供歷年撤離人次統計圖表。</p>
												}
                        {
                          subjectTitle === 'contain' &&
                          <p>請選擇統計資料的分類依據，如選擇「年份」，系統即提供歷年收容人次統計圖表。</p>
                        }
												{
													subjectTitle === 'alert' &&
													<p>請選擇想瀏覽之淹水或土石流警戒層級進行熱區查詢。</p>
												}
											</div>
											<div className="select">
												<CustomSelect status="normal" name="district" value={ selectedTopic }
													disabled={isTopicLoading}
													onChange={value => this.setState({ selectedTopic: value})}>
													{ (topics.length === 0 && subjectTitle === 'alert') && <option value="0">無土石流警戒資料</option> }
                          { (topics.length === 0 && subjectTitle !== 'alert') && <option value="0">無可用資料</option> }
													{ topics.length !== 0 && <option value="0">請選擇</option>}
													{
														topics.map((topic, i) => 
															<option key={i} value={topic['Id']}>{topic['Name']}</option>
														)
													}
												</CustomSelect>
											</div>
										</div>
										<div className="submit">
											<button className={"button" + (this.state.isBasicLoading ? ' -loading' : '')}
											disabled={ selectedTopic === '0' }
											onClick={this.handleClickAdvanceSubmit}><span>看結果</span></button>
										</div>
									</div>
								}
							</div>
							<div className="col-md-9 chart-result">
								{ isDataLoading && <LoadingDots theme="red" /> }
								{
									resultData &&
									<React.Fragment>
										<div className="heading capture">
											<div className="title">
												{
													subjectTitle === 'evacuate' &&
													(chartTitle.indexOf('撤離人次') !== -1 &&
                          currentTopic !== 'event' ?
													chartTitle.replace('撤離人次', '平均撤離人次')
													:
													chartTitle)
												}
												{
													subjectTitle === 'contain' &&
													(chartTitle.indexOf('收容人次') !== -1 &&
                          currentTopic !== 'event' ?
													chartTitle.replace('收容人次', '平均收容人次')
													:
													chartTitle)
												}
												{ subjectTitle === 'alert' && chartTitle }
											</div>
											<div className="dropdown" data-html2canvas-ignore>
												<CustomDropdown title="下載">
                          <li><button onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
												</CustomDropdown>
											</div>
										</div>
										<div className="chart">
											{ drawType === 'map' && <EvacuationChartMap evacuationData={resultData}/> }
											{ drawType === 'chart' && <EvacuationChartDraw className="capture" category={currentTopic} data={resultData} /> }
										</div>
										<div className="info capture">
											<div className="title">說明</div>
											<div className="subtitle">{ topicInfo && topicInfo.formula }</div>
											<div className="content" dangerouslySetInnerHTML={{ __html: topicInfo.description }}></div>
										</div>
                    {
                      csvData &&
  										<div className="origin">
  											<div className="title">
  												<div className="text">原始數據</div>
  												<CustomDropdown title="下載">
                            { isMemberLogin && <li><button onClick={this.handleClickDownloadCSV}>下載CSV</button></li> }
                            { !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載CSV</button></li> }
  												</CustomDropdown>
  											</div>
  											<div className="table">
  												<EvacuationChartTable csvData={csvData} />
  											</div>
  										</div>
                    }
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
				<SocialBlock category="撤離與收容" action={subjectTitleCH} theme="pink" />
			</React.Fragment>
		);
	}
}

export default EvacuationChart;
