import React, { Component } from 'react';
import './index.scss';
import Scroll from 'react-scroll'
import ChartFilter from '../ChartFilter';
import ChartDraw from '../ChartDraw';
import ChartValidN from '../ChartValidN';

// Utils
import CustomDropdown from '../../utils/CustomDropdown';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import chartConverter from '../../utils/Shared/chartConverter';
import chartCapture from '../../utils/Shared/chartCapture';


class CitySubjectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: null,
      currentLocate: null,
      itemData: [],
      charts: [],
      chartData: [],
    };
  }
  componentDidMount() {
    const { data } = this.props;
    const itemData = JSON.parse(data);
    this.setState({
      itemData: itemData
    }, () => {
      this.initChartItems();
    })
  }
  initChartItems = () => {
    const { itemData } = this.state;
    const { houses } = this.props;
    if (itemData && houses) {
      const chartItems = itemData.items.slice();
      const replacers = [];
      if (itemData.merge) {
        chartItems.forEach((item) => {
          const temp = Object.assign({ '@houses': houses }, item);
          replacers.push(temp);
        });
      }
      else {
        houses.forEach((house) => {
          chartItems.forEach((item) => {
            const temp = Object.assign({ '@house': house }, item);
            replacers.push(temp);
          });
        });
      };

      const charts = [];
      replacers.forEach((replacer) => {
        let obj = JSON.stringify(itemData.template);
        const itemKeys = Object.keys(replacer);
        itemKeys.forEach((key) => {
          const variable = new RegExp(`"${key}"`, "g");
          const replaceItem = replacer[key];
          obj = obj.replace(variable, JSON.stringify(replaceItem));
        });
        const item = JSON.parse(obj);
        item.info = {
          house: replacer['@house']
        };
        charts.push(item);
      });

      this.setState({
        charts: charts
      }, () => {
        this.initFilter();
      });
    }
  }
  initFilter() {
    const { charts } = this.state;
    if (charts) {
      const allFilters = charts.map((chart) => chart.filter);
      const filtersCategory = [];
      allFilters.forEach((filter) => {
        if (filter) {
          (Object.keys(filter)).forEach((item) => {
            if (filtersCategory.indexOf(item) === -1) {
              filtersCategory.push(item);
            };
          });
        };
      });
      const filterData = filtersCategory.map((category) => {
        const data = {};
        data.label = category;
        data.items = [];
        allFilters.forEach((filter) => {
          if (data.items.indexOf(filter[category]) === -1) {
            data.items.push(filter[category]);
          }
        });
        return data;
      });
      const years = filterData.find((filter) => filter.label === '年份');
      const currentYear = years ? years.items[0] : null;
      const locates = filterData.find((item) => item.label === '縣市');
      const currentLocate = locates ? locates.items[0] : null;

      this.setState({
        filterData: filterData,
        currentYear: currentYear,
        currentLocate: currentLocate,
      }, () => {
        this.initSubject();
      });
    }
  }
  initSubject() {
    const { itemData, charts } = this.state;
    const chartData = itemData ? chartConverter(itemData, charts) : null;
    this.setState({
      chartData: chartData,
    });
  }

  handleClickFilter({label, item}) {
    switch (label) {
      case '縣市':
        this.setState({currentLocate: item}, () => { this.initSubject(); })
        break;
      case '年份':
        this.setState({currentYear: item}, () => { this.initSubject(); })
        break;
    
      default:
        break;
    }
  }
  handleClickDownloadPNG = (e) => {
    
    e.preventDefault();
    const { title } = this.props;
    const { currentLocate, currentYear } = this.state;

    const subjectId = e.currentTarget.dataset.capture;
    const targetSubject = this.refs.subject.querySelector(`.subject${subjectId}`);
    const captureArea = targetSubject.querySelectorAll('.capture');
    
    const fileName = `${currentYear || ''}${currentLocate || ''}${title}`
    
    chartCapture(captureArea, fileName);
    gaTracking('莫拉克颱風', '永久屋專區', '圖表下載');
  }
  render() {
    const { title, id, description } = this.props;
    const { currentLocate, currentYear, chartData, filterData } = this.state;
    return (
      <div ref="subject" className={`subject-item`}>
        <div className="item-filter">
          {
            filterData &&
            filterData.map((filter, i) => {
              return (
                <React.Fragment key={i}>
                  {
                    <div className="filter">
                      <div className="label">{filter.label}</div>
                      <ChartFilter 
                        title={title}
                        label={filter.label} 
                        data={filter.items}
                        value={currentYear}
                        getRadio={(e) => { this.handleClickFilter(e)}}
                      ></ChartFilter>
                    </div>
                  }
                </React.Fragment>
              )
            })
          }
        </div>
        {
          chartData &&
          chartData.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {
                  (item.filter && item.filter['縣市'] ? item.filter['縣市'] === currentLocate : true) &&
                  (item.filter && item.filter['年份'] ? item.filter['年份'] === currentYear : true) &&
                  <Scroll.Element name={item.info.house}>
                  <div className={`house-chart subject${i}`}>
                    <div className="head">
                      <div className="subject-title capture">
                        {currentYear ? currentYear : ''}
                        {item.info.house}
                        {title}
                      </div>
                      <div className="dropdown">
                        <CustomDropdown title="下載">
                          <li><button data-capture={i} onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
                        </CustomDropdown>
                      </div>
                    </div>
                    <div className="subject-description">
                      {description}
                    </div>
                    <div className="chart">
                      <div className="capture">
                        <ChartDraw chart={item} id={id}></ChartDraw>
                      </div>
                    </div>
                    <div className="notes capture">
                      {
                        item.validNData &&
                        <ChartValidN validNData={item.validNData} />
                      }
                      {item.notes.map((note, k) => <p key={k}>{note}</p>)}
                    </div>
                  </div>
                  </Scroll.Element>
                }
              </React.Fragment>
            )
          })
        }
      </div>
    );
  }
}

export default CitySubjectItem;
