import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

import SearchBar from '../utils/SearchBar';
import ArticleItem from '../utils/ArticleItem';
import PageSelector from '../utils/PageSelector';
import {apiHost, sourceHost} from '../utils/Shared/constant';

class News extends Component {

  constructor(params) {
    super(params);
    this.state = {
        newsItems: null,
        totalPage: 0,
        nowPage: -1,
        nowCategoryID: 0,
    };
  }

  componentDidMount() {
		window.scrollTo(0, 0);
    this.props.handleEnterNewsPage();
    const breadcrumb = [
      { name: '首頁', href: '/' },
      { name: '最新消息', href: '/news' },
    ]
    this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);

    this.loadPage(1);
  }

  searchNews = (keyword) => {
    
    axios.get(`${apiHost}/api/Content/NewsList?data.keyWord=${encodeURIComponent(keyword)}`)
      .then((res)=>{
        if (res.status === 200) {
            this.setState({ 
              newsItems: res.data.Result ? res.data.Result : [],
              totalPage: res.data.TotalPage,
              nowPage: 0,
              loading: false,
            })
        }
    })
  }

  loadPage = (page) => {
    if (page === this.state.nowPage) {
      return;
    }

    let url = `${apiHost}/api/Content/NewsList?data.currentPage=${page}&data.perPage=10`;

    if (this.state.nowCategoryID) {
        url += `&data.categoryID=${this.state.nowCategoryID}`;
    }

    axios.get(url)
      .then((res)=>{
        if (res.status === 200) {
            this.setState({ 
              newsItems: res.data.Result ? res.data.Result : [],
              totalPage: res.data.TotalPage,
              nowPage: page,
              loading: false,
            })
        }
    })
  }

  handleCategoryButtonClick = (categoryID) => {
    this.setState({
      nowCategoryID: categoryID,
      nowPage: -1,
      loading: true,
    },() => {
      this.loadPage(1);
    })
  }

  handleSearchSubmit = (keyword) => {
    this.setState({
      nowCategoryID: -1,
      loading: true,
    },() => {
      this.searchNews(keyword);
    })
  }

  render() {
    const {nowPage, totalPage, nowCategoryID, loading} = this.state;
    return (
      <div className="news-page">
        <div className="container">
          <div className="row">
            <section className={'news-section col-lg-12 offset-lg-0'}>
            
              <div className="heading">
                <span className="type-heading -ch -h3">最新消息</span>
              </div>

              <div className="news-block">
                <div className="filter filter-bar"> 
                    <button className={'filterbutton ghost-button -yellow' + (nowCategoryID === 0 ? ' -active' : '')} onClick={() => { this.handleCategoryButtonClick(0) }} >
                      <span className="type-text -ch -md">全部</span>
                    </button>
                    <button className={'filterbutton ghost-button -yellow' + (nowCategoryID === 6 ? ' -active' : '')} onClick={() => { this.handleCategoryButtonClick(6) }} >
                      <span className="type-text -ch -md">調查統計</span>
                    </button>
                    <button className={'filterbutton ghost-button -yellow' + (nowCategoryID === 7 ? ' -active' : '')} onClick={() => { this.handleCategoryButtonClick(7) }} >
                      <span className="type-text -ch -md">社會脆弱度評估</span>
                    </button>
                    <button className={'filterbutton ghost-button -yellow -last' + (nowCategoryID === 8 ? ' -active' : '')} onClick={() => { this.handleCategoryButtonClick(8) }} >
                      <span className="type-text -ch -md">撤離與收容評估</span>
                    </button>

                    <SearchBar className="type-text -ch -md" onSubmit={this.handleSearchSubmit} onCancel={() => this.handleCategoryButtonClick(0)}/>

                </div>
                <div className={'news-container' + (loading ? ' -loading' : '')}>
                  <div className="row">
                    {
                      this.state.newsItems &&
                      this.state.newsItems.length > 0 &&
                      this.state.newsItems.map((item, i) => {
                          const {Summary, ID, Title, Published, ImageUrl} = item;
                          return (
                            <div key={i} className="col-lg-6">
                              <ArticleItem title={Title} content={Summary} image={`${sourceHost}/${ImageUrl}`} href={'news/' + ID} sorting={''} date={Published} color={'yellow'}/>
                            </div>
                          )
                      })
                    }
                    {
                      this.state.newsItems &&
                      this.state.newsItems.length === 0 &&
                      <div className="query-indicator type-text -ch -lg">抱歉，找不到符合條件的資料</div>
                    }
                    {
                      !this.state.newsItems &&
                      <div className="query-indicator type-text -ch -lg">內容讀取中</div>
                    }
                  </div>
                </div>
                

              </div>

            </section>

            <PageSelector className="pagination" theme="yellow" nowPage={nowPage} maxPage={totalPage} onPageClick={(i)=>this.loadPage(i)}/>

          </div>
        </div>
      </div>
    );
  }
}

export default News;
