import React, { Component } from 'react';
import './index.scss';
import SvgZoomMap from '../../utils/SvgZoomMap';
import MapIsland from '../EvacuationChartMapIsland';
import LoadingDots from '../../utils/LoadingDots';

// methods
import { transferHundredPoint } from '../../utils/Shared/methods';

class EvacuationChartMap extends Component {
	constructor(params) {
		super(params);
		this.state = {
			isMapShow: false,
			isDataLoad: false,
			gap: 6,
			colorSet: [
				{ unit: "人次", gap: 6, colors: ['#FBE9F2', '#EEE0F4', '#D0D1E6', '#A6BDDB', '#67AACF', '#3690C0', '#337BB6'] },
				{ unit: "次數", gap: 7, colors: ['#FCF0B7', '#FCE298', '#FCC779', '#FDAC6F', '#FC8063', '#E75858', '#C84864', '#A73B58'] },
			],
			colors: [],
			ranges: [],
			isBubbleShow: false,
			bubble: {
				name: null,
				avarge: null,
				sum: null,
				event: null,
				count: null
			},
			bubbleTop: 0,
			bubbleLeft: 0,
			county: '',
			town: ''
		};
	}

	componentDidMount() {
		this.getRanges();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.getRanges();
		}
	}
	getRanges() {
		const { evacuationData } = this.props;
		if (evacuationData) {
			const { unit, transferValue } = evacuationData;
			const set = this.state.colorSet.find(item => item.unit === unit);
			const { gap, colors } = set;
			const maximum = Math.max(...evacuationData.data.map(data => data[transferValue]));
			const minimum = Math.min(...evacuationData.data.map(data => data[transferValue]));
			const dataGap = (maximum - minimum) <= gap*2 ? Math.max(Math.floor((maximum - minimum)/2), 1) : gap; 
			const min = (maximum - minimum) / dataGap;
			const digits = Math.ceil(min).toString().length - 1;
			let delta;
			const base = Math.floor(minimum / Math.pow(10, digits)) * Math.pow(10, digits);
			if (digits <= 1)  { delta = Math.ceil(min) };
			if (digits > 1) { delta = Math.ceil(min / Math.pow(10, digits)) * Math.pow(10, digits) };

			const ranges = [];
			for (let i = 0; i < dataGap; i ++) {
				ranges.push(base + (delta * (i+1)));
			};

			this.setState({
				unit: unit,
				colors: colors,
				ranges: ranges,
				rangeBase: base
			}, () => this.handleLoadData());
		}
	}
	handleLoadData = () => {
		const { evacuationData } = this.props;
		const { transferValue } = evacuationData;
		if (evacuationData) {
			const { county, town } = evacuationData;
			const mapData = {
				layer: '',
				data: []
			};

			if (county === '' && town === '') {
				mapData.layer = 'county';
			}
			else if (county !== '' && town === '') {
				mapData.layer = 'town';
			}
			else {
				mapData.layer = 'village';
			}
			
			evacuationData.data.forEach(item => {
				const obj = {};
				obj.name = item.Name;
				obj.attribute = {
					'style': {
						'fill': this.handleTransferColor(item[transferValue]),
						'stroke': '#555555',
					},
					'data-sum': item.Sum,
					'data-avarge': item.Avarge,
					'data-event': item.Event,
					'data-count': item.Value,
				}
				mapData.data.push(obj);
			});

			this.setState({
				mapData: mapData,
				county: county,
				town: town,
			})
		}
	}
	handleTransferColor = (value) => {
		const { ranges, colors } = this.state;
		let color;

		for (let i = 0; i < ranges.length; i++) {
			if ( value >= ranges[i] && value < ranges[i+1]) {
				color = colors[i + 1];
			}
		}
		if ( value <= ranges[0]) {
			color = colors[0];
		}
		if (value >= ranges[ranges.length - 1]) {
			color = colors[ranges.length];
		}
		if (value === 0) {
			color = '#eee';
		}
		return color;
	}
	handleMouseOverMap = (area, e) => {
		this.setState({
			isBubbleShow: true,
			bubbleTop: e.clientY,
			bubbleLeft: e.clientX,
			bubble: {
				name: e.target.dataset.name || area.filter(_ => _).slice(-1),
				avarge: e.target.dataset.avarge  || null,
				sum: e.target.dataset.sum  || null,
				event: e.target.dataset.event  || null,
				count: e.target.dataset.count  || null,
			},
		})
	}
	handleMouseLeaveMap = () => {
		this.setState({
			isBubbleShow: false,
		})
	}
	render() {
		const { className } = this.props;
		const { isMapShow, isBubbleShow, bubble, bubbleTop, bubbleLeft } = this.state;
		const { colors, ranges, rangeBase, mapData, county, town, unit } = this.state;
		return (
			<div className={`evacuation-map ${className ? className : ''}`}>
				{
					isBubbleShow &&
					<div className="bubble-info"
					style={{
						'position': 'fixed',
						'top': `${bubbleTop}px`,
						'left': `${bubbleLeft}px`,
						'zIndex': 99
					}}>
						<div className="bubble">
							<p className="name">{ bubble.name }</p>
							{ bubble.avarge && <p className="value">平均： { transferHundredPoint(bubble.avarge) }</p> }
							{ bubble.sum    && <p className="value">總數： { transferHundredPoint(bubble.sum) }</p> }
							{ bubble.event  && <p className="value">事件： { transferHundredPoint(bubble.event) }</p> }
							{ bubble.count  && <p className="value">次數： { transferHundredPoint(bubble.count) }</p> }
							{
								!bubble.avarge && !bubble.sum && !bubble.event && !bubble.count && 
								<p className="value">查無資料</p>
							}
						</div>
					</div>
				}
				<div className="capture">
					<div className="range-mark">
						<div className="title">{ unit }</div>
						<div className="range">
							<div className="color" style={{ background: '#eee' }}></div>
							<div className="value">無可用資料</div>
						</div>
						<div className="range">
							<div className="color" style={{ background: colors[0] }}></div>
							<div className="value type-text -en">{ rangeBase } - { ranges[0]-1 }</div>
						</div>
						{
							ranges.map((range, i) => 
							<div className="range" key={i}>
								<div className="color" style={{ background: colors[i+1] }}></div>
								<div className="value type-text -en">
										{ range } - { ranges[i + 1]-1 || '' }
								</div>
							</div>
							)
						}
					</div>
					
					{!isMapShow && <LoadingDots theme="red" />}
					<div className={`taiwan-map ${isMapShow && '-show'}`} onMouseLeave={this.handleMouseLeaveMap}>
						{
							mapData &&
							mapData.layer === 'county' &&
							<MapIsland
								onAreaHover={this.handleMouseOverMap}	
								infoData={mapData}/>
						}
						<SvgZoomMap
							countyJsonSrc="/MapJson/taiwan-county.json"
							townJsonSrc="/MapJson/taiwan-town.json"
							villageJsonSrc="/MapJson/taiwan-village.json"
							onAreaHover={this.handleMouseOverMap}
							county={county}
							town={town}
							infoData={mapData}
							zoomDelay={0}
							isMapInit={() => { this.setState({ isMapShow: true }) }}
							// zoomDuration={0}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default EvacuationChartMap;
