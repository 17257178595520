import React, { Component } from 'react';
import './index.scss';

// utils
import SideModal from '../../utils/SideModal';

class HomePageSideModal extends Component {

	handleClickCloseModal = () => {
		this.props.onClickCloseModal();
	}
	renderHeader() {
		return (
			<div className="subtitle-block">
				<p className="ch type-heading -ch -h5">
					本站概述
				</p>
				<p className="en type-text -en -md">
					About us
				</p>
			</div>
		)
	}
	renderBody() {
		return (
			<ul className="homepage-overview">
				<li>
					<p className="type-text -ch -lg">減災 (Disaster Risk Reduction, DRR)</p>
					<p className="type-text -ch -md">本站由國家災害防救科技中心建置，提供減災相關數據。此網站之減災較為廣義，意指DRR，包含較狹義的減災(mitigation)、整備、應變、復原。</p>
				</li>
				<li>
					<p className="type-text -ch -lg">統計 (Stat)</p>
					<p className="type-text -ch -md">所提供之資料皆為減災相關統計資料，希望使用者能快速閱讀，掌握趨勢。</p>
				</li>
				<li>
					<p className="type-text -ch -lg">加值</p>
					<p className="type-text -ch -md">除提供統計圖表的解說與瀏覽外，也供使用者自行繪製圖表，加值應用。</p>
				</li>
				<li>
					<p className="type-text -ch -lg">分享</p>
					<p className="type-text -ch -md">為方便各類使用者應用圖表，本站所有統計圖表皆可下載，但請記得註明出處。</p>
				</li>
				<li>
					<p className="type-text -ch -lg">資料應用</p>
					<p className="type-text -ch -md">在「主題館」不定期分享使用者對統計資料的應用成果。</p>
				</li>
			</ul>
		)
	}

	renderFooter() {
		return (
			<>
				<p className="type-text -ch -md">封面照片/鯉魚潭</p>
				<p className="type-text -ch -md">感謝花東縱谷國家風景區管理處熱情提供。</p>
			</>
		)
	}

	render() {
		return (
			<SideModal
				isModalOpen={ this.props.isModalOpen }
				closeModal={ this.handleClickCloseModal }
				modalStyle={'white'}
				direction={'right'}
				headerContent={this.renderHeader()}
				bodyContent={this.renderBody()}
				footerContent={this.renderFooter()}
			></SideModal>
		);
	}
}

export default HomePageSideModal;
