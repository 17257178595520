import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import './index.scss';

class ChartDraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      echartHeight: 0,
      echartOption: {}
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getChartType();
    }
  }
  
  componentDidMount() {
    this.getChartType();
  }
  getChartType() {
    const { chart } = this.props;
    switch (chart.type) {
      case "Bar":
        this.renderBarChart();
        break;
      case "Stack":
        this.renderStackChart();
        break;
      case "Pie":
        this.renderPieChart();
        break;
      case "Line":
        this.renderLineChart();
        break;

      default:
        break;
    }
  }
  getAxis() {
    const { chart } = this.props;
    const { unit, direction, source } = chart;
    var xAxis, yAxis;
    switch (direction) {
      case "xAxis":
        yAxis = { type: 'value', name: unit,
          min: chart.range ? chart.range[0] : null,
          max: chart.range ? chart.range[1] : null
        };
        xAxis = { type: 'category' };
        if (source.some(line => line[0].length > 5)) {
          xAxis.boundaryGap = [0, '50%'];
          xAxis.axisLabel = { rotate: 45 };
        }
        break;

      case "yAxis":
        yAxis = { type: 'category' };
        xAxis = { 
          type: 'value', name: unit,
          min: chart.range ? chart.range[0] : null,
          max: chart.range ? chart.range[1] : null
        };
        break;

      default:
        break;
    }
    return { xAxis, yAxis };
  }
  getBasic() {
    const { id, chart } = this.props;
    const colors = ['#2FC172', '#FDC100', '#68AAFF', '#FF6F6E', '#CDA374', '#00B5BC', '#B8B8B8'];
    const shuffleColors = (arr, i) => [...arr.slice(i), ...arr.slice(0, i)];
    return {
      color: chart.type === 'Bar' ? shuffleColors(colors, id) : colors,
      label: {},
    };
  }
  getGrid() {
    const grid = {
      containLabel: true,
      right: '5%',
      top: '5%',
      bottom: '5%',
      left: '5%',
    };
    return { grid };
  }
  getTooltip(trigger) {
    const { chart } = this.props;
    const tooltip = {
      trigger: trigger,
      formatter: function (params) {
        let htmlContent = '';
        if (Array.isArray(params)) {
          params.forEach((item) => {
            const { color, seriesName, componentIndex, name, data } = item;
            htmlContent +=
              `<div>
                <p><i class="icon-oino" style="color:${color}"></i>${seriesName}</p>
                <p>${name}：${data[componentIndex + 1]}${chart.unit || ''}</p>
              </div>
              `
            }
          )
        }
        else {
          const { color, seriesName, componentIndex, name, data } = params;
          const tooltipName = seriesName !== "undefined" ? seriesName + '<br>' : '';
          htmlContent +=
            `<div>
              <p><i class="icon-oino" style="color:${color}"></i>${tooltipName}
              ${name}：${data[componentIndex + 1]}${chart.unit || ''}</p>
            </div>
            `
        }
        return htmlContent;
      }
    }
    return { tooltip };
  }
  getLegend() {
    const { chart } = this.props;
    const { dimensions } = chart;
    const legend = {};
    if (dimensions.length < 3) {
      legend.show = false
    }
    else {
      legend.show = true
    }
    return { legend };
  }
  getMarkLine() {
    const { chart } = this.props;
    const { mark, direction, unit } = chart;
    const markLine = {};
    markLine.tooltip = { formatter: `{b}：{c}${unit}` };
    markLine.data = [{}];

    markLine.data[0]['name'] = mark.name;
    if (direction === "xAxis") {
      markLine.data[0]['yAxis'] = mark.value;
    }
    else if (direction === "yAxis"){
      markLine.data[0]['xAxis'] = mark.value;
    }

    return markLine;
  }
  getChartHeight(barWidth) {
    const { chart } = this.props;
    const { source, direction } = chart;
    let chartHeight;
    if (direction === 'yAxis') {
      chartHeight = source.length * barWidth;
    }
    else {
      chartHeight = 400;
    }

    const head = 120;
    const bottom = 120;
    chartHeight += (head + bottom);
    return Math.min(chartHeight, 680);
  }
  renderBarChart() {
    const { chart } = this.props;
    const itemsLength = chart.dimensions.length - 1;
    const axis = this.getAxis();
    const option = this.getBasic();
    const grid = this.getGrid();
    const tooltip = this.getTooltip('axis');
    const legend = this.getLegend();
    const echartHeight = this.getChartHeight(120);
    const source = chart.sorting ? chart.source.sort((a,b) => a[1] > b[1] ? 1 : -1) : chart.source;
    
    const dataset = {
      dimensions: chart.dimensions,
      source: source
    };

    // series
    const series = [];
    for (let i = 0; i < itemsLength; i++) {
      const temp = {};
      temp.markLine = chart.mark ? this.getMarkLine() : null;
      temp.type = 'bar';
      temp.barMaxWidth = 80;
      temp.barMinWidth = 28;
      series.push(temp);
    }
    
    const echartOption = Object.assign(option, grid, tooltip, legend, axis, {dataset}, {series})
    this.setState({
      echartHeight: echartHeight,
      echartOption: echartOption
    })
    
  }
  renderStackChart() {
    const { chart } = this.props;
    const itemsLength = chart.dimensions.length - 1;
    const option = this.getBasic();
    const axis = this.getAxis();
    const grid = this.getGrid();
    const tooltip = this.getTooltip('item');
    const legend = this.getLegend();
    const echartHeight = this.getChartHeight(60);

    const dataset = {
      dimensions: chart.dimensions,
      source: chart.source
    }

    // series
    const series = [];
    for (let i = 0; i < itemsLength; i++) {
      const temp = {};
      temp.type = 'bar';
      temp.stack = "stack";
      temp.barMaxWidth = 80;
      temp.barMinWidth = 28;
      temp.barCategoryGap = '10%';
      temp.markLine = chart.mark ? this.getMarkLine() : null;
      series.push(temp);
    }
    
    const echartOption = Object.assign(option, grid, tooltip, legend, axis, {dataset}, {series})
    this.setState({
      echartHeight: echartHeight,
      echartOption: echartOption
    })
    
  }
  renderPieChart() {
    const { chart } = this.props;
    const option = this.getBasic();
    const echartHeight = 680;
    const dataset = {
      dimensions: chart.dimensions,
      source: chart.source
    };

    // series
    const series = {};
    series.type = 'pie';
    series.radius = [0, '65%'];
    series.itemStyle = {
      borderWidth: 2,
      borderColor: '#FFF'
    };
    series.label = {
      show: true,
      formatter: `{b}： {d} ${chart.unit}`,
      color: '#000',
      fontSize: 18
    };

    const echartOption = Object.assign(option, { dataset }, { series })
    this.setState({
      echartHeight: echartHeight,
      echartOption: echartOption
    })
  }
  renderLineChart() {
    const { chart } = this.props;
    const itemsLength = chart.source[0].length - 1;
    const option = this.getBasic();
    const axis = {
      yAxis: {
        type: 'value',
        name: chart.unit
      },
      xAxis: {
        type: 'category',
      },
    };
    const tooltip = this.getTooltip('axis');
    const legend = this.getLegend();
    const grid = this.getGrid();
    const echartHeight = this.getChartHeight();
    const dataset = {
      dimensions: chart.dimensions,
      source: chart.source
    };

    // series
    const series = [];
    for (let i = 0; i < itemsLength; i++) {
      const temp = {};
      temp.type = 'line';
      series.push(temp);
    }

    const echartOption = Object.assign(option, grid, tooltip, legend, axis, { dataset }, { series })
    this.setState({
      echartHeight: echartHeight,
      echartOption: echartOption
    });

  }
  render() {
    const { echartHeight, echartOption} = this.state;
    return (
      <ReactEcharts
        option={echartOption}
        notMerge={true}
        lazyUpdate={true}
        opts={{ renderer: 'svg' }}
        style={
          { height: echartHeight }
        }
      />
    );
  }
}

export default ChartDraw;
