import { connect } from 'react-redux';
import HomePageSideModal from './pure';

const mapStateToProps = (state, ownProps) => ({
	isModalOpen: state.homePageSideModal.isModalOpen,
})

const mapDispatchToProps = (dispatch) => ({
	onClickCloseModal: () => dispatch({ type: 'CLOSE_HOMEPAGE_SIDEMODAL' }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePageSideModal);
