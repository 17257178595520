import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Container
import ArticleList from '../../Series/ArticleList';
import ResourceLinks from '../../Series/ResourceLinks';

// Resources
import SfaaBackground1 from '../../../images/sfaa-bg1.png';
import SfaaBackground2 from '../../../images/sfaa-bg2.png';

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}

class SfaaIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
			scrollSpyItems: [
				{
					name: 'info',
					title: '社福機構自然災害風險調查',
					show: false,
				},
				{
					name: 'articles',
					title: '主題館',
					show: false,
				},
				{
					name: 'resource',
					title: '資料來源',
					show: false,
				},
			]
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社福機構', href: '/statistics/sfaa' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}
	handleClickToggle = (e) => {
		const controller = e.currentTarget;
		const content = e.currentTarget.nextSibling;

		if (content.classList.contains('-show')) {
			controller.classList.remove('-open');
			content.classList.remove('-show');
		}
		else {
			controller.classList.add('-open');
			content.classList.add('-show');
		}
	}

	render() {
		const { scrollSpyItems } = this.state;
		return (
			<div className="new-sfaa-page">
				<Helmet>
					<title>減災動資料 - 社福機構自然災害風險調查</title>
				</Helmet>
				<div className="scroll-spy">
					{
						scrollSpyItems &&
						scrollSpyItems.map((item, index) => {
							return (
								<div key={index}
									className={"item " + item.name + (item.show ? " -show" : "")}
									onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true }) }}>
									<span>{item.title}</span>
								</div>
							)
						})
					}
				</div>
				<section className="sfaa-heading">
					<Link className="block" to="/statistics/sfaa/elder">
						<h2 className="heading">老人福利機構</h2>
						<img className="background" src={SfaaBackground1} alt="" />
					</Link>
					<Link className="block" to="/statistics/sfaa/disability">
						<h2 className="heading">身心障礙福利機構</h2>
						<img className="background" src={SfaaBackground2} alt="" />
					</Link>
				</section>
				<Scroll.Element name="info">
					<Waypoint onEnter={() => { this.handleWaypointEnter('info') }} {...waypointOption}>
						<section className="sfaa-section sfaa-info">
							<div className="container">
								<h1 className="sectiontitle">社福機構自然災害風險調查(2011)</h1>
								<div className="sectioncontent">
									<p className="strong">背景說明</p>
									<p className="">國家災害防救科技中心2011年與當時內政部社會司（現衛福部社家署）合作，對老人福利機構與身心障礙福利機構進行天然災害風險調查，項目包含：環境特徵、建物特徵、受災經驗、減災作為等，藉以瞭解機構的地震、淹水與坡地災害風險。</p>
									<br/>
									<div className="toggle sfaa-toggle">
										<button className="controller -open" onClick={this.handleClickToggle}>
											<span className="type-text -ch -md">完訪率</span>
											<div className="icon">
												<span className="open">展開<i className="icon-plus"></i></span>
												<span className="close">收合<i className="icon-minus"></i></span>
											</div>
										</button>
										<div className="content -show">
											<div className="sfaa-summarytable">
												<table>
													<thead>
														<tr>
															<th className="sort">2011</th>
															<th className="issue">老人福利機構</th>
															<th className="issue">身心障礙福利機構</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="subject">調查機構數</td>
															<td>1057</td>
															<td>285</td>
														</tr>
														<tr>
															<td className="subject">完訪數</td>
															<td>983</td>
															<td>266</td>
														</tr>
														<tr>
															<td className="subject">完訪率</td>
															<td>93%</td>
															<td>93%</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="toggle sfaa-toggle">
										<button className="controller -open" onClick={this.handleClickToggle}>
											<span className="type-text -ch -md">調查項目一覽表</span>
											<div className="icon">
												<span className="open">展開<i className="icon-plus"></i></span>
												<span className="close">收合<i className="icon-minus"></i></span>
											</div>
										</button>
										<div className="content -show">
											<div className="sfaa-summarytable">
												<table>
													<thead>
														<tr>
															<th className="sort">分類</th>
															<th className="issue">分類議題</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td rowSpan="2" className="subject">受災經驗</td>
															<td>淹水</td>
														</tr>
														<tr>
															<td>坡災</td>
														</tr>
														<tr>
															<td rowSpan="6" className="subject">建物特徵</td>
															<td>二樓以上避難空間</td>
														</tr>
														<tr>
															<td>正門道路寬度</td>
														</tr>
														<tr>
															<td>建物構造</td>
														</tr>
														<tr>
															<td>建造年份</td>
														</tr>
														<tr>
															<td>電梯或斜坡道及其寬度</td>
														</tr>
														<tr>
															<td>棟數</td>
														</tr>
														<tr>
															<td rowSpan="4" className="subject">減災</td>
															<td>收容地點</td>
														</tr>
														<tr>
															<td>耐震評估</td>
														</tr>
														<tr>
															<td>發電機</td>
														</tr>
														<tr>
															<td>減災</td>
														</tr>
														<tr>
															<td rowSpan="4" className="subject">機構與院民特徵</td>
															<td>床位類型及核定數</td>
														</tr>
														<tr>
															<td>院民主要活動空間</td>
														</tr>
														<tr>
															<td>院民屬性及人數</td>
														</tr>
														<tr>
															<td>機構人力</td>
														</tr>
														<tr>
															<td rowSpan="2" className="subject">環境特徵</td>
															<td>設施與地理特徵</td>
														</tr>
														<tr>
															<td>減災工程設施</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<ul className="sfaa-list">
										<li>
											<label className="type-text -ch -md" htmlFor="">瞭解更多</label>
											<p className="type-text -ch -md">詳情請見 防災易起來 > <a href="https://easy2do.ncdr.nat.gov.tw/ssa" target="_blank" rel="noopener noreferrer">長照機構防災</a></p>
										</li>
									</ul>
								</div>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
				<Scroll.Element name="articles">
					<Waypoint onEnter={() => { this.handleWaypointEnter('articles') }} {...waypointOption}>
						<section className="sfaa-section sfaa-subject -gray">
							<div className="container">
								<h1 className="sectiontitle">主題館</h1>
								<ArticleList color="green" categoryID={2} pathname="/statistics/sfaa"></ArticleList>
								<Link className="sectionbutton fill-button -green" to="/statistics/sfaa/categories">
									<span className="type-text -ch -md">進入主題館</span>
								</Link>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
				<Scroll.Element name="resource">
					<Waypoint onEnter={() => { this.handleWaypointEnter('resource') }} {...waypointOption}>
						<section className="sfaa-section sfaa-source">
							<div className="container">
								<h1 className="sectiontitle">資料來源</h1>
								<ResourceLinks category="sfaa" theme="green"></ResourceLinks>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
			</div>
		);
	}
}

export default SfaaIndex;
