import React, { Component } from 'react';
import './index.scss';

class MsgBox extends Component {
    render() {
        return (
            <div className="msg-box">
                <div className="msg">歡迎來到減災動資料網站，初次使用者建議您先瀏覽本站概述，了解本站用途幫助你更快上手。</div>
                <div className="controller">
                    <button onClick={() => this.props.showMsgBox(false)} className="hide">CLOSE</button>
                    <button className="more" onClick={() => this.props.showSideModal(true)}>LEARN MORE</button>
                </div>
            </div>
        );
    }
}

export default MsgBox;
