import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import './index.scss';

class LimitMask extends Component {
	constructor(params) {
		super(params);
		this.state = { isMaskShow: true };
	}
	render() {
		// const { prevPage } = this.props;
		return (
			<React.Fragment>
				{
					this.state.isMaskShow &&
					<div className="limit-mask">
						<button className="close" onClick={() => this.setState({ isMaskShow: false }) }><i className="icon-close"></i></button>
						<span className="type-text -ch -md">注意：此服務不支援寬度960px以下的螢幕裝置使用。</span>
					</div>
				}
			</React.Fragment>
		);
	}
}

export default LimitMask;
