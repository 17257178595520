import React, { Component } from 'react';
import queryString from "query-string";
import Axios from 'axios';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils
import SviChartMap from '../SviChartMap';
import SviChartTable from '../SviChartTable';
import CustomSelect from '../../utils/CustomSelect';
import CustomDropdown from '../../utils/CustomDropdown';
import SocialBlock from '../../utils/SocialBlock';
import chartCapture from '../../utils/Shared/chartCapture';
import LimitMask from '../../utils/LimitMask';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import {apiHost} from '../../utils/Shared/constant';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

class SviChart extends Component {
	constructor(params) {
		super(params);
		this.state = {
			type: 'z',
      		isMemberLogin: false,
			availableYears: null,
			availableIndicators: null,
			categoryDescription: null,
			allIndicators: [],
			currentYear: '0',
      		selectedCounty: null,
      		resultCounty: null,
			currentIndicatorId: '0',
			currentIndicatorName: '',
			currentIndicatorDescription: '',
			isChartShow: false,
			isBasicLoading: false,
			isAdvanceLoading: false,
			isDataLoaded: false,
      		countiesData: null,
			csvData: null,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('BLUE');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社會脆弱度評估', href: '/evaluation/svi' },
			{ name: '社會脆弱度指標查詢與展示', href: '/evaluation/svi/z' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);

    this.checkMemberLogin();
		this.getQuery();
		this.getCategoryDescription();
		this.getAvailableYears();
		this.getAllIndicators();
    this.getCountiesData();
	}
  checkMemberLogin = () => {
    if (window.localStorage.getItem('user-token')) {
      this.setState({ isMemberLogin: true });
    }
  }
	getQuery() {
		const report = queryString.parse(window.location.search);
		if (report.county && report.indicator && report.year) {
			const year = report.year;
			const indicatorId = report.indicator;
      		const county = report.county;
			this.setState({ isChartShow: true, isAdvanceLoading: true, isAdvance: true });
			this.getAvailableYears().then(() => {
				this.handleChangeYear(year).then(() => {
          this.handleCountyDistrictPickSelected(county).then(() => {
  					this.getAvailableIndicators().then(() => {
  						this.handleChangeIndicator(indicatorId).then(() => {
  							this.handleGetAdvanceData(year, indicatorId);
  						})
  					})
          })
				});
			});
		}
		else if (report.county && report.year) {
			const year = report.year;
      const county = report.county;
			this.setState({ isChartShow: true, isBasicLoading: true });
			this.getAvailableYears().then(() => {
				this.handleChangeYear(year).then(() => {
          this.handleCountyDistrictPickSelected(county).then(() => {
					 this.handleGetBasicData();
          })
				});
			});
		}
	}
	getCategoryDescription() {
		Axios.get(`${apiHost}/api/WeakIndex/GetCategoriesDetail`)
		.then(res => {
			res.status === 200 && this.setState({categoryDescription: res.data.Result})
		})
	}
	getAllIndicators() {
		Axios.get(`${apiHost}/api/WeakIndex/GetCompleteIndicator`)
		.then((res) => {
			if (res.status === 200) {
				const { categories, subcategories, indicators } = res.data.Result
				categories.forEach((item) => {
					item.name = item.category_name;
				});
				subcategories.forEach((item) => {
					item.name = item.subcategory_name;
				});
				const allIndicators = [].concat(categories, subcategories, indicators)
				this.setState({allIndicators})
			}
		})
	}
	getAvailableIndicators() {
		const { currentYear, selectedCounty } = this.state;
    	let countyCode = selectedCounty ? selectedCounty.code : 'all';
		return new Promise(resolve => {
			Axios.get(`${apiHost}/api/WeakIndex/ZIndicatorsByYear?year=${currentYear}&county=${countyCode}`)
			.then((res) => {
				if (res.status === 200) {
					const indicatorsData = res.data.Result.indicators;
					const categoriesData = res.data.Result.categories;
					const availableIndicators = [];
					categoriesData.forEach((category) => {
						category.indicators = [];
						indicatorsData.forEach((indicator) => {
							if (category.category_id === indicator.category_id) {
								category.indicators.push(indicator);
							}
						})
						availableIndicators.push(category);
					})

					this.setState({availableIndicators})
					resolve();
				}
			})
		})
	}
	getAvailableYears() {
		return new Promise(resolve => {
			Axios.get(`${apiHost}/api/WeakIndex/InitialZYear`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						availableYears: res.data.Result
					})
					resolve();
				}
			})
		})
	}
  getCountiesData = () => {
    Axios.get('/config/CountiesTownsData.json')
    .then(result => {
      const { counties } = result.data;
      counties.forEach(county => county.active = false);
      this.setState({ countiesData: counties });
    })
  }
	handleClickBasicSubmit = () => {
		this.setState({ isChartShow: true, });
		this.handleGetBasicData();
	}
	handleGetBasicData() {
		const { currentYear, selectedCounty } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';
		this.setState({ isBasicLoading: true });
		Axios.get(`${apiHost}/api/WeakIndex/ZValueComplete?year=${currentYear}&county=${countyCode}`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				this.getAvailableIndicators();
				const csvData = (typeof res.data.Result.csv === 'object' && res.data.Result.csv !== null) ? [res.data.Result.csv] : res.data.Result.csv;
				this.setState({
					isBasicLoading: false,
					isDataLoaded: true,
					isAdvance: true,
					currentIndicatorName: '',
					currentIndicatorDescription: '',
					currentIndicatorId : '',
					csvData: csvData,
         			resultCounty: selectedCounty,
					resultData: {
						year: currentYear,
						county: county,
						town: '',
						data: res.data.Result.data,
					}
				}, () => urlPushState(`?year=${currentYear}&county=${countyCode}`));
			}
		})
	}
	handleClickAdvanceSubmit = () => {
		this.handleGetAdvanceData();
	}
	handleGetAdvanceData() {
		const { currentYear, currentIndicatorId, selectedCounty } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';

		this.setState({ isAdvanceLoading: true });
		Axios.get(`${apiHost}/api/WeakIndex/ZValueSingle?year=${currentYear}&indicators=${currentIndicatorId}&county=${countyCode}`)
			.then((res) => {
				if (res.status === 200) {
					const { allIndicators, availableIndicators } = this.state;
					const currentIndicator = allIndicators.filter((item) => {
						return item.indicator_id === Number(currentIndicatorId);
					})
					let indicatorArray = availableIndicators[0].indicators.concat(availableIndicators[1].indicators, availableIndicators[2].indicators, availableIndicators[3].indicators) 
					const indicator = indicatorArray.find((item) => {
						return item.indicator_id === Number(currentIndicatorId);
					})
					let csvData = typeof res.data.Result.csv === 'object' ? [res.data.Result.csv] : res.data.Result.csv;
					this.setState({
						isAdvanceLoading: false,
						isDataLoaded: true,
						currentIndicatorName: currentIndicator[0] ? currentIndicator[0].name : '',
						// currentIndicatorDescription: currentIndicator[0] ? currentIndicator[0].description : '',
						currentIndicatorDescription: indicator ? indicator.indicatordes : '',
						csvData: csvData,
						resultData: {
							year: currentYear,
							county: county,
							town: '',
							data: res.data.Result.data,
						},
					}, () => urlPushState(`?year=${currentYear}&county=${countyCode}&indicator=${currentIndicatorId}`))
				}
			})
	}
	handleClickDownloadCSV = () => {
		const { csvData, resultCounty, currentYear, currentIndicatorName, allIndicators } = this.state;
		let csv = ''; 
		csvData.forEach((item) => {
			csv = csv + '\n' + item.csv;
		});

		const fileName = `${currentYear}年 ${resultCounty ? resultCounty.county : '全臺各縣市'}${currentIndicatorName}原始資料`;

		let allSource = {};
		allIndicators.forEach(indicator =>{
			if(indicator.source){
				allSource[indicator.name] = indicator.source[0];
			}else{
				allSource[indicator.name] = '';
			}
		})
		let source = '';
		if(allSource[currentIndicatorName]){
			source = allSource[currentIndicatorName];
		}
		let copyright = '\n資料來源：減災動資料(加值前的原始數據來源為';
		if(source.length === 0){
			copyright = '\n資料來源：國家災害防救科技中心減災動資料 ( 加值前的原始數據來源詳見社會脆弱度評估系統首頁「指標細項定義」)。' ;
		}else{
			copyright += source ;
		}
		copyright += ')';

		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;

		downloadTrigger(fileName + '.csv', csvContent);
		gaTracking('社會脆弱度', '社會脆弱度指標查詢與展示', '原始數據下載');

	}
	handleChangeYear(year) {
		return new Promise(resolve => {
			this.setState({ 
				currentYear: year,
				isAdvance: false,
				availableIndicators: null
			}, () => resolve());
		})
	}
  handleCountyDistrictPickSelected = selectedCode => {
    return new Promise(resolve => {
      const { countiesData } = this.state;
      const selectedDistrict = countiesData.find( ({ code }) => code === selectedCode );
      this.setState({ 
			selectedCounty: selectedDistrict,
			isAdvance: false,
        	availableIndicators: null
      }, () => resolve());
    })
  }
	handleChangeIndicator(indicator) {
		return new Promise(resolve => {
			this.setState({ currentIndicatorId: indicator }, () => resolve());
		})
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();
		const { currentYear, currentIndicatorName, resultCounty } = this.state;
		const fileName = `${currentYear}年 ${resultCounty ? resultCounty.county : '全臺各縣市'}${currentIndicatorName}圖表`;
		const captureArea = document.querySelectorAll('.capture');
		chartCapture(captureArea, fileName);
		gaTracking('社會脆弱度', '社會脆弱度指標查詢與展示', '圖表下載');
	}
	handleClickDownloadTextPNG = () => {
		const { currentYear, currentIndicatorId , selectedCounty } = this.state;
		let countyCode
		if( !selectedCounty || selectedCounty === null) {
			countyCode = 'all'
		} else {
			countyCode = selectedCounty.code
		}
		if(currentIndicatorId === '0') {
			window.open(`https://drrstat.ncdr.nat.gov.tw/map/?year=${currentYear}&county=${countyCode}`)
		} else {
			window.open(`https://drrstat.ncdr.nat.gov.tw/map/?year=${currentYear}&county=${countyCode}&indicator=${currentIndicatorId}`)
		}
	}
	render() {
    	const { isMemberLogin } = this.state;
		const { availableYears, availableIndicators, categoryDescription } = this.state;
		const { currentYear, currentIndicatorId, currentIndicatorName, currentIndicatorDescription } = this.state;
    	const { selectedCounty, resultCounty } = this.state;
		const { type, countiesData, csvData, resultData } = this.state;
		const { isDataLoaded, isBasicLoading, isAdvance, isAdvanceLoading } = this.state;
		const {allIndicators} = this.state
		const currentIndicator = allIndicators.find(data => data.name === currentIndicatorName)

		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/svi" />
				<div className="svi-page">
					<div className="container">
						<div className="page-link">
							<Link  className="item" to="/evaluation/svi/advance">
								<span>客製化社會脆弱度評估</span>
							</Link>
						</div>
						<div className="svi-charttitle">
							<div className="title">社會脆弱度指標查詢與展示</div>
							<div className="subtitle">Social Vulnerability Index for Disasters</div>
						</div>
						<div className="svi-chart row">
							<div className="col-md-3 chart-controller">
								<div className="type">
									<div className="step">
										<div className="title">
											<span className="en">Step 1 </span>
											<span>選擇想查看的資料主題</span></div>
										<div className="buttonbar">
											<Link to="/evaluation/svi/z" className="-active"
												onMouseEnter={() => this.setState({ type: 'z' })}
												onMouseOut={() => this.setState({ type: 'z' })}>單一年度行政區比較</Link>
											<Link to="/evaluation/svi/s"
												onMouseEnter={() => this.setState({ type: 's' })}
												onMouseOut={() => this.setState({ type: 'z' })}>單一行政區歷史趨勢</Link>
										</div>
										<div className="info">
											{ type === 'z' && <p>單一年度行政區比較：了解不同縣市某一年中社會脆弱度的差異</p> }
											{ type === 's' && <p>單一行政區趨勢：可了解如臺北市 1998 到 2016 年間的社會脆弱度趨勢。</p> }
										</div>
									</div>
								</div>
								<div className="basic">
									<div className="step">
										<div className="title">
											<span className="en">Step 2 </span>
											<span>選擇年份</span></div>
										<div className="select">
											<CustomSelect status="normal" name="year" value={ currentYear }
												onChange={(value) => this.handleChangeYear(value) }>
												<option value={'0'}>年份</option>
												{
													availableYears &&
													availableYears.map((year, index) => 
														<option key={index} value={year}>{year}</option>
													)
												}
											</CustomSelect>
										</div>
									</div>
                  {
                    <div className="step">
                      <div className="title">Step 3 選擇行政區</div>
                      <div className="select">
                        <CustomSelect status="normal" name="year"
                          value={selectedCounty ? selectedCounty.code : ''}
                          onChange={value => this.handleCountyDistrictPickSelected(value)}>
                          <option value="0">全選</option>
                          {
                            countiesData &&
                            countiesData.map((item, index) =>
                              <option key={index} value={item.code}>{item.county}</option>
                            )
                          }
                        </CustomSelect>
                      </div>
                    </div>
                  }
									<div className="submit">
										<button className={"button" + (isBasicLoading ? ' -loading' : '')}
										disabled={currentYear === '0' || isAdvanceLoading}
										onClick={this.handleClickBasicSubmit}><span>看結果</span></button>
									</div>
								</div>

								{
									isAdvance &&
									<div className="isDataLoaded">
										<div className="step">
											<div className="title">選擇指標項目</div>
											<div className="info">請選擇想要延伸查看的指標項目</div>
											<div className="select">
												<CustomSelect status="normal" name="year" value={currentIndicatorId}
												onChange={value => this.handleChangeIndicator(value)}>
												<option value={'0'}>指標項目</option>
												{
													availableIndicators &&
													availableIndicators.map((category, index) =>
													<React.Fragment key={index}>
														<option value={category.indicator_id}>{category.category_name}</option>
														{
														category.indicators.map((indicator, subindex) => 
															<React.Fragment key={subindex}>
															<option value={indicator.indicator_id}>&emsp;{indicator.name}</option>
															</React.Fragment>
														)
														}
													</React.Fragment>
													)
												}
												</CustomSelect>
											</div>
										</div>
										<div className="submit">
											<button className={"button" + (isAdvanceLoading ? ' -loading' : '')}
											disabled={currentIndicatorId === '0'}
											onClick={this.handleClickAdvanceSubmit}>確定</button>
										</div>
									</div>
								}
							</div>
							<div className="col-md-9 chart-result">
								{
									isDataLoaded &&
									<div className="heading capture">
										<div className="title">
											{ resultData.year }年 { resultCounty ? resultCounty.county : '全臺各縣市'}{ currentIndicatorName !== '' ? `${currentIndicatorName}比較`  : '社會脆弱度比較' }
										</div>
										<div className="dropdown" data-html2canvas-ignore>
											<CustomDropdown title="下載">
                              {!isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載PNG</button></li>}
                        	  {isMemberLogin && 
							    <React.Fragment>
									<li><button onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
									<li><button onClick={this.handleClickDownloadTextPNG}>下載PNG(含數值)</button></li>
								</React.Fragment>
								  }
											</CustomDropdown>
										</div>
									</div>
								}
								{
									<div className="chart">
										{
											// (isAdvanceLoading || isBasicLoading) && 
											// <LoadingDots theme="blue" >
												// <p>系統處理中....請稍待</p>
											// </LoadingDots>
										}
										{
											<SviChartMap isIndicator={currentIndicatorName !== ''} currentIndicatorName={currentIndicatorName} sviData={resultData} related={currentIndicator  ?currentIndicator.related :99}/>
										}
									</div>
								}
								{
									isDataLoaded &&
									<React.Fragment>
										{
											currentIndicatorId === '0' || currentIndicatorId === ''  &&
											<div className="info capture">
												<div className="title">說明</div>
												<div className="content">上圖展示各縣市社會脆弱度綜合指數（Z），依據本站所列之指標項目進行綜合計算而得（詳首頁說明），指數越大表示脆弱度越高。部分年度／縣市因缺乏資料，無法計算。</div>
												<div className="content">顏色辨識：紅色＝高社會脆弱度，藍色＝低社會脆弱度。</div>
											</div>
										}
										{
											categoryDescription && categoryDescription.map((category, i) =>
											<div key={i} className="info capture">
												{
													category.name ===	currentIndicatorName &&
													<React.Fragment>
														<div className="title">{category.name}</div>
														<div className="content" dangerouslySetInnerHTML={{ __html: category.description }}></div>
													</React.Fragment>
												}
											</div>
											)
										}
										{
											(currentIndicatorDescription && currentIndicatorDescription !== '') &&
											<div className="info capture">
												<div className="title">指標定義說明</div>
												<div className="content" dangerouslySetInnerHTML={{ __html: currentIndicatorDescription }}></div>
											</div>
										}
										<div className="origin">
											<div className="title">
												<div className="text">原始數據</div>
												<CustomDropdown title="下載">
                          { isMemberLogin && <li><button onClick={this.handleClickDownloadCSV}>下載CSV</button></li> }
                          { !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載CSV</button></li> }
												</CustomDropdown>
											</div>
                      { csvData && <div className="table"><SviChartTable csvData={csvData} /> </div> }
											<p className="remark">*標記為非公開資料；-標記為缺資料/無法計算。如有任何問題請 <a href="/#contact" target="_blank" rel="noopener noreferrer">聯絡我們</a></p>
										</div>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
				<SocialBlock theme="blue" category="社會脆弱度" action="社會脆弱度指標查詢與展示" />
			</React.Fragment>
		);
	}
}

export default SviChart;
