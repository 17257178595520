import React, { Component } from 'react';
import './index.scss';

class CustomSegment extends Component {
    componentDidMount = () => {
        const spy = this.refs.spy;
        let list = this.refs.list;
        let activeItem = list.querySelector('.-active')
        
        let activeItemOffset = activeItem.getBoundingClientRect();
        let activeItemWidth = activeItem.offsetWidth;
        let parentOffset = activeItem.offsetParent.getBoundingClientRect();

        spy.style.width = activeItemWidth + 'px';
        spy.style.left = activeItemOffset.left - parentOffset.left + 'px';
    }
    handleClickMoveSpy = (e) => {
        const currentTarget = e.currentTarget;
        const spy = this.refs.spy;
        const list = this.refs.list;

        let itemOffset = currentTarget.getBoundingClientRect();
        let itemWidth = currentTarget.offsetWidth;
        let parentOffset = currentTarget.offsetParent.getBoundingClientRect();

        let items = list.querySelectorAll('.segmentitem');
        
        items.forEach( (item) => {
            const className = item.getAttribute('class').replace('-active')
            item.setAttribute('class', className);
        } )

        let className = currentTarget.getAttribute('class');
        currentTarget.setAttribute('class', className + ' -active');
        
        spy.style.width = itemWidth + 'px';
        spy.style.left = itemOffset.left - parentOffset.left + 'px';
    }
    renderItem = () => {
        return (
            <React.Fragment>
                {
                    this.props.children &&
                    this.props.children.map((item, i) => (
                        <li key={i} id={i} className={"segmentitem" + (i === 0 ? " -active": "")}
                        onClick={ this.handleClickMoveSpy }>
                            { item }
                        </li>
                    ))
                }
            </React.Fragment>
        )
    }
    render() {
        return (
            <div ref="wrap" className={"custom-segment " + this.props.className}>
                <ul ref="list" className="list segment-list">
                    { this.renderItem() }
                </ul>
                <div ref="spy" className="spy"></div>
            </div>
        );
    }
}

export default CustomSegment;
