import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Header from './pure';

const mapStateToProps = (state, ownProps) => ({
	color: state.header.color,
	theme: state.header.theme,
	showLanguage: state.header.showLanguage,
	showBreadcrumb: state.header.showBreadcrumb,
	breadcrumbData: state.header.breadcrumbData,
})

const mapDispatchToProps = (dispatch) => ({
	onClickOpenMenu: () => dispatch({ type: 'OPEN_SIDEMENU' }),
	onClickOpenModal: () => dispatch({ type: 'OPEN_HOMEPAGE_SIDEMODAL' }),
	onLoadHeaderReset: () => dispatch({ type: 'HEADER_STYLE_DEFAULT' }),
	onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
	onLoadShowBreadcrumb: (STATUS) => dispatch({ type: STATUS + '_BREADCRUMB' }),
	onUserTokenIsExtend: () => dispatch({ type: 'SET_LOGIN_STATE', isLogin: true }),
})

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Header));
