import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './index.scss';

// utils
import SideModal from '../../utils/SideModal';

// Resouces
import EvaluationBackground from '../../../images/evaluation-background.jpg';

class EvaluationSideModal extends Component {
	handleClickCloseModal = () => {
		this.props.onClickCloseModal();
	}
	handleClickOtherModal = () => {
		this.props.onClickCloseModal();
		this.props.onClickOpenSideModal('SURVEY');
	}

	renderHeader() {
		return (
			<div className="subtitle-block evaluation-sidemodal-subtitle">
				<p className="ch type-heading -ch -h6">
					評估系統
				</p>
				<p className="en type-text -en -xs">
					Assessment Systems
				</p>
			</div>
		)
	}
	
	renderBody() {
		return (
			<div className="evaluation-sidemodal-links">
				<Link className="link -svi" to="/evaluation/svi" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">社會脆弱度評估</p>
					<p className="type-text -en -xs">Social Vulnerability Assessment for Disasters</p>
				</Link>
				<Link className="link -contain" to="/evaluation/evacuation" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">撤離與收容評估</p>
					<p className="type-text -en -xs">Evacuation and Shelter Preparedness Assessment for Disasters</p>
				</Link>
			</div>
		)
	}

	renderFooter() {
		return (
			<div className="evaluation-sidemodal-buttonbar">
				<button onClick={ this.handleClickOtherModal } className="direction-button -prev">
					<span className="icon"><i className="icon-arrow-l-left"></i></span>
					<span className="text type-text -ch -lg">調查統計</span>
				</button>
				<button onClick={ this.handleClickCloseModal } className="direction-button -next">
					<span className="text type-text -ch -lg">回首頁</span>
					<span className="icon"><i className="icon-arrow-l-right"></i></span>
				</button>
			</div>
		)
	}

	render() {
		return (
			<SideModal
				isModalOpen={ this.props.isModalOpen }
				closeModal={ this.handleClickCloseModal }
				modalStyle={ 'gray' }
				background={ EvaluationBackground }
				direction={ 'right' }
				headerContent={ this.renderHeader() }
				bodyContent={ this.renderBody() }
				footerContent={ this.renderFooter() }
			></SideModal>
		);
	}
}

export default EvaluationSideModal;