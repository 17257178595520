import React, { Component } from 'react';
import './index.scss';

class CustomCheckbox extends Component {
    render() {
        return (
            <React.Fragment>
                <label htmlFor={this.props.id} className={"custom-checkbox " + this.props.className}>
                    <input id={this.props.id} type="checkbox" checked={ this.props.checked } disabled={this.props.disabled} onChange={e=>{
                        e.persist();
                        if (this.props.onChange) {
                            this.props.onChange(e.target.checked);
                        }
                    }}/>
                    <span className="icon"></span>
                    { this.props.children }
                </label>
                <p className="msg">{this.props.msg}</p>
            </React.Fragment>
        );
    }
}

export default CustomCheckbox;
