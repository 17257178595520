import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Utils
import chartCapture from '../../utils/Shared/chartCapture';
import CustomRadio from '../../utils/CustomRadio';
import CustomDropdown from '../../utils/CustomDropdown';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

// Resources
import AnalysisEarthquakeSurveyPercentPic01 from "../../../images/AnalysisEarthquakeSurvey/mainpic.jpg";

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}

class AnalysisEarthquakeSurveyIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
			scrollSpyItems: [
				{
					name: 'content02',
					title: '「趴、掩、穩」不敵黃金三角？ ',
					show: false,
				},
				{
					name: 'content03',
					title: '花蓮人老神在在！臺南人反應不及？',
					show: false,
				},
				{
					name: 'content04',
					title: '較多花蓮人震後會去避難，約是臺南人的二倍',
					show: false,
				},
				,
				{
					name: 'content05',
					title: '花蓮人比較有準備防災包的習慣',
					show: false,
				},
				,
				{
					name: 'content06',
					title: '花蓮人八成不保地震險，臺南人無意願的也高達五成',
					show: false,
				},
			],
			valueType: [
				{
					name: 'pdf0'
				},
				{
					name: 'pdf1'
				},
				{
					name: 'pdf2'
				},
				{
					name: 'pdf3'
				},
				{
					name: 'pdf4'
				},
				{
					name: 'pdf5'
				}
			]
		};
	}
	//檢查登入
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
		this.setState({ isMemberLogin: true });
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '台南花蓮 震後調查大解析', href: '/statistics/AnalysisEarthquakeSurvey' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	
	handleClickRidio(e) {
		let num    = e.currentTarget.dataset.num;
		let group = e.currentTarget.dataset.group;
		let valueType = this.state.valueType;

		valueType[num].name = group;
		// console.log(num)
		// console.log(group)
		this.setState({
			valueType: valueType,
		})
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}

	render() {
		const { isMemberLogin } = this.state;
		const { scrollSpyItems, valueType } = this.state;
	
		return (
			
<div className="AnalysisEarthquakeSurvey" id="home">
	<Helmet>
		<title>0206臺南地震 VS 花蓮地震 震後調查比一比</title>
	</Helmet>
	<div className="scroll-spy">
		{
			scrollSpyItems &&
			scrollSpyItems.map((item, index) => {
				return (
					<div key={index}
						className={"item " + item.name + (item.show ? " -show" : "")}
						onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true }) }}>
						<span>{item.title}</span>
					</div>
				)
			})
		}
	</div>

	<div className="scroll-up">
		<a href="#home" className="pc"> <img src={require("../../../images/AnalysisEarthquakeSurvey/Union.svg")}/> </a>
		<a href="#menu" className="mobile"> <img src={require("../../../images/AnalysisEarthquakeSurvey/Union_ph.svg")}/> </a>
	</div>

	<div className="header">


	
		<div className="mass"></div>
		
		<ul className="slideshow">
			<li><span>Slide One</span> </li>
			<li> <span>Slide Two</span> </li>
		</ul>
	</div>

	<section id="content01">
		<div className="container-c c640">
			<div className="infon">
				<p>在2016年與2018年的2月6日，分別發生芮氏規模 6.6 地震（震央在高雄美濃）與發生芮氏 6.4 地震（震央在花蓮市），這兩起地震有日期上的巧合，地震規模相似，同時也造成建物倒塌與毀損、人命傷亡嚴重。 但兩處居民面對地震的反應跟平時的減災是否也類似呢？國家災害防救科技中心分別於兩次地震發生後半年左右，針對震度五級以上的地區，利用電訪調查民眾面臨地震災害的...</p>
				<p>
					1.第一時間反應< br/>
					2.是否知道哪個臨震策略較好< br/>
					3.震後會不會去空地避難< br/>
					4.會不會準備防災包、有沒有買地震保險 < br/>
					以下是我們的比較結果，請看...
				</p>
			</div>
			
		</div>
	
		<div className="container-c c640" id="menu">
			<div className="btn-g">
				<a href="#content02" className="btn">
				「趴、掩、穩」不敵黃金三角？ 
				</a>
				<a href="#content03" className="btn"> 
				花蓮人老神在在！臺南人反應不及？
				</a>
				<a href="#content04" className="btn">
				較多花蓮人震後會去避難，約是臺南人的二倍
				</a>
				<a href="#content05" className="btn">
				花蓮人比較有準備防災包的習慣
				</a>
				<a href="#content06" className="btn">
				花蓮人八成不保地震險，臺南人無意願的也高達五成
				</a>
			</div>
		</div>
	</section>

	<hr></hr>

	<section id="content02">
		<div className="container-c c640">
		
			<div className="infon">
				<div className="title">
					<h2>「趴、掩、穩」< br/>不敵黃金三角？</h2>
				</div>
				<p>2016年當時，臺南人有六成認為地震的時候要靠黃金三角保命，2018年的花蓮人也不遑多讓，將近五成。認為靠趴下、掩護、穩住較適當的民眾，臺南跟花蓮人的比率倒是相當一致，都僅約三成。另外要擔心的是花蓮人不知道地震時該怎麼做的人有兩成，而臺南人則是一成。</p>
				<p>再次呼籲，政府目前的臨震宣導中，強調一般民眾臨震時要放低重心、掩護自身並保護頭頸部、盡量於地震時穩住自己，這幾個要點變成了臨震口訣：「趴下、掩護、穩住」，記起來喔！</p>
			</div>
		</div>

		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>民眾相信的臨震保命策略</h4>
				</div>

				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf0" 
								num="0"
								
								checked={ this.state.valueType[0].name === 'pdf0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical0" 
								num="0"
								
								checked={this.state.valueType[0].name === 'Statistical0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[0].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li>
									<Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾相信的臨震保命策略.png")} 
									target="_blank" 
									onClick={() => gaTracking('台南花蓮 震後調查大解析', '01民眾相信的臨震保命策略', '圖表下載')} 
									download>下載PNG</Link>
								</li>
								<li>
									<Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾相信的臨震保命策略.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('台南花蓮 震後調查大解析', '01民眾相信的臨震保命策略', '原始數據下載')} 
									download>下載EXCEL</Link></li>
								{/* { isMemberLogin && <li>
									<Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾相信的臨震保命策略.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('台南花蓮 震後調查大解析', '01民眾相信的臨震保命策略', '原始數據下載')} 
									download>下載EXCEL</Link></li> }

								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
						
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">

			<div className={`${(valueType[0].name === 'pdf0' || valueType === null ? ' -active' : '')} statistics-charts chat01`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat01.png")} width="100%"/>
			</div>
			<div className={`${(valueType[0].name === 'Statistical0' ? ' -active' : '')} statistics-datatable`}>
				<table>
		<tbody>
			<tr>
				<td colspan="5" rowspan="1">民眾相信的臨震保命策略</td>
			</tr>
			<tr>
				<td colspan="1" rowspan="2">
					<div>項目</div>
				</td>
				<td colspan="2" rowspan="1" align="center">臺南</td>
				<td colspan="2" rowspan="1" align="center">花蓮</td>
			</tr>
			<tr>
				<td>次數分配表</td>
				<td>百分比</td>
				<td>次數分配表</td>
				<td>百分比</td>
			</tr>
			<tr>
				<td>趴下、掩護、穩住</td>
				<td>568</td>
				<td>26.9%</td>
				<td>530</td>
				<td>25.9%</td>
			</tr>
			<tr>
				<td>躲在黃金三角</td>
				<td>1,202</td>
				<td>57.0%</td>
				<td>996</td>
				<td>48.6%</td>
			</tr>
			<tr>
				<td>以上皆對</td>
				<td>27</td>
				<td>1.3%</td>
				<td>39</td>
				<td>1.9%</td>
			</tr>
			<tr>
				<td>以上皆錯</td>
				<td>51</td>
				<td>2.4%</td>
				<td>30</td>
				<td>1.5%</td>
			</tr>
			<tr>
				<td>不知道/很難說（含拒答）</td>
				<td>262</td>
				<td>12.4%</td>
				<td>453</td>
				<td>22.1%</td>
			</tr>
			<tr>
				<td>總計</td>
				<td>2,109</td>
				<td>100%</td>
				<td>2,049</td>
				<td>100%</td>
			</tr>
			<tr>
				<td colspan="5" rowspan="1"><br /></td>
			</tr>
			<tr>
				<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
			</tr>
		</tbody>
				</table>
			</div>

		</div>
		
	</section>	

	<hr></hr>

	<section id="content03">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h2>花蓮人老神在在！< br/>臺南人反應不及？</h2>
				</div>
				<p>兩個調查結果顯示，2018年時，花蓮民眾臨震當下老神在在，將近五成並不會有特別反應(未有任何動作)，再細分原因，最多的比率(37%)覺得不需要做應變；2016年時，臺南民眾則是約有29.8%的人不動作，細分原因有最多的比率(16%)來不及反應，或許可突顯出花蓮人相較臺南人對地震的習以為常。</p>
				<p>另外也發現，花蓮人有兩成會往外跑，臺南人除了不動作外，次之則是選擇類似「趴、掩、穩」的行動（15.4%）。但反觀花蓮人，選擇「趴、掩、穩」的比率卻僅是臺南人的一半不到（7.3%），也許跟臺南的臨震宣導成效有關。</p>
			</div>
		</div>

		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h4>民眾之臨震反應</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf1" 
								num="1"
								
								checked={ this.state.valueType[1].name === 'pdf1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical1" 
								num="1"
								
								checked={this.state.valueType[1].name === 'Statistical1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[1].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾之臨震反應.png")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '02 民眾之臨震反應', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾之臨震反應.xlsx")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '02 民眾之臨震反應', '原始數據下載')} download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/民眾之臨震反應.xlsx")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '02 民眾之臨震反應', '原始數據下載')} download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[1].name === 'pdf1' || valueType === null ? ' -active' : '')} statistics-charts chat02`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat02.png")} width="100%"/>
			</div>
			<div className={`${(valueType[1].name === 'Statistical1' ? ' -active' : '')} statistics-datatable`}>
				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">民眾之臨震反應</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配</td>
							<td>百分比</td>
							<td>次數分配</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>未有任何動作</td>
							<td>628</td>
							<td>29.8%</td>
							<td>975</td>
							<td>47.6%</td>
						</tr>
						<tr>
							<td>往建築物外跑</td>
							<td>311</td>
							<td>14.7%</td>
							<td>414</td>
							<td>20.2%</td>
						</tr>
						<tr>
							<td>其他</td>
							<td>100</td>
							<td>4.8%</td>
							<td>234</td>
							<td>11.4%</td>
						</tr>
						<tr>
							<td>「趴、掩、穩」相近行動</td>
							<td>324</td>
							<td>15.4%</td>
							<td>150</td>
							<td>7.3%</td>
						</tr>
						<tr>
							<td>把門打開，避免出入口損壞</td>
							<td>250</td>
							<td>11.8%</td>
							<td>120</td>
							<td>5.9%</td>
						</tr>
						<tr>
							<td>躲在堅固家具旁</td>
							<td>105</td>
							<td>5.0%</td>
							<td>74</td>
							<td>3.6%</td>
						</tr>
						<tr>
							<td>找家人</td>
							<td>279</td>
							<td>13.2%</td>
							<td>57</td>
							<td>2.8%</td>
						</tr>
						<tr>
							<td>躲在床旁邊</td>
							<td>112</td>
							<td>5.3%</td>
							<td>24</td>
							<td>1.2%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>2109</td>
							<td>100%</td>
							<td>2049</td>
							<td>100.0%</td>
						</tr>
						<tr>
								<td colspan="5" rowspan="1"><br /></td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">禱告、念佛號、往高處避難、躲牆角、躲樑柱下、不知道/很難說。</td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
							</tr>
					</tbody>
				</table>
			
			</div>
		</div>
	</section>

	<hr></hr>

	<section id="content04">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>較多花蓮人震後會去避難，< br/> 約是臺南人的二倍</h2>
				</div>
				<p>調查顯示地震過後，花蓮人有24.4%會移動至空曠處避難，臺南人則僅有13.3%，少一半，可能因為花蓮地震在晚間12點左右，部分民眾並未就寢，較會去外面避難，而臺南地震發生已經是凌晨四點左右，在睡夢中驚醒，至空曠處避難意願或許較低。此外，出去避難的民眾中，花蓮人有13.1%會去政府公告的地點，臺南人較少僅8.6%。</p>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>是否有去空曠處避難？</h4>
				</div>
			</div>
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf2" 
								num="2"
								
								checked={ this.state.valueType[2].name === 'pdf2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical2" 
								num="2"
								
								checked={this.state.valueType[2].name === 'Statistical2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[2].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有去空曠處避難.png")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '03民眾相信的臨震保命策略與臨震反應', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有去空曠處避難.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '03民眾相信的臨震保命策略與臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有去空曠處避難.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '03民眾相信的臨震保命策略與臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
			</div>
		</div>

		<div className="container-c c640">
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat03.png")} width="100%"/>
			</div>
			<div className={`${(valueType[2].name === 'Statistical2' ? ' -active' : '')} statistics-datatable`}>
				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">是否有去空曠處避難</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>有</td>
							<td>281</td>
							<td>13.3%</td>
							<td>499</td>
							<td>24.4%</td>
						</tr>
						<tr>
							<td>沒有</td>
							<td>1828</td>
							<td>86.7%</td>
							<td>1550</td>
							<td>75.6%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>2109</td>
							<td>100.0%</td>
							<td>2049</td>
							<td>100.0%</td>
						</tr>
							<tr>
								<td colspan="5" rowspan="1"><br /></td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
							</tr>
						
					</tbody>
				</table>
				<br /><br /><br />

				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">(有避難者)避難的空曠處是否為政府公告的地震避難地點</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>是</td>
							<td>24</td>
							<td>8.6%</td>
							<td>65</td>
							<td>13.1%</td>
						</tr>
						<tr>
							<td>不是</td>
							<td>235</td>
							<td>83.6%</td>
							<td>397</td>
							<td>79.5%</td>
						</tr>
						<tr>
							<td>不知道/很難說</td>
							<td>22</td>
							<td>7.8%</td>
							<td>37</td>
							<td>7.3%</td>
						</tr>
						<tr>
							<td>拒答</td>
							<td>-</td>
							<td>-</td>
							<td>1</td>
							<td>0.1%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>281</td>
							<td>100.0%</td>
							<td>499</td>
							<td>100.0%</td>
						</tr>

						<tr>
							<td colspan="5" rowspan="1"><br /></td>
						</tr>
						<tr>
							<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
						</tr>
						
					</tbody>
				</table>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<p>兩地民眾九成五以上都不會外宿。非得外宿的人中，花蓮人的住宿地點比較多元，除六成依親以外，剩餘的安頓地點還包含學校、寺廟、教會、旅館等。臺南九成都是依親，其次是旅館跟車上，雖然這次調查結果中，沒有民眾入住官方收容所，但補充說明，依據臺南市政府的統計，當時開設有七處收容所，多數為活動中心及區公所，收容民眾約有202人次。</p>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>是否有去其他地方暫住？</h4>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf3" 
								num="3"
								
								checked={ this.state.valueType[3].name === 'pdf3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical3" 
								num="3"
								
								checked={this.state.valueType[3].name === 'Statistical3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[2].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有沒有去其他地方暫住幾天.png")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '04 有沒有去其他地方暫住幾天', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有沒有去其他地方暫住幾天.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '04 有沒有去其他地方暫住幾天', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有沒有去其他地方暫住幾天.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '04 有沒有去其他地方暫住幾天', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
			</div>
		</div>
		
		<div className="container-c c640">
			<div className={`${(valueType[3].name === 'pdf3' || valueType === null ? ' -active' : '')} statistics-charts chat04`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat04.png")} width="100%"/>
			</div>
			<div className={`${(valueType[3].name === 'Statistical3' ? ' -active' : '')} statistics-datatable`}>
				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">有沒有去其他地方暫住幾天</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>有</td>
							<td>36</td>
							<td>1.7%</td>
							<td>55</td>
							<td>2.7%</td>
						</tr>
						<tr>
							<td>沒有</td>
							<td>2073</td>
							<td>98.3%</td>
							<td>1994</td>
							<td>97.3%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>2109</td>
							<td>100.0%</td>
							<td>2049</td>
							<td>100.0%</td>
						</tr>
							<tr>
								<td colspan="5" rowspan="1"><br /></td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
							</tr>
						
					</tbody>
				</table>
				<br /><br /><br />

				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">曾去哪些地方暫住</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>親友家</td>
							<td>32</td>
							<td>89.7%</td>
							<td>35</td>
							<td>62.8%</td>
						</tr>
						<tr>
							<td>自己找的旅館</td>
							<td>2</td>
							<td>4.7%</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>住在車上</td>
							<td>1</td>
							<td>3.3%</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>公部門開設處所
								<br />（如里民活動中心、學校、體育館、軍營等）</td>
							<td>-</td>
							<td>-</td>
							<td>5</td>
							<td>9.3%</td>
						</tr>
						<tr>
							<td>其他
								<br />（如自有其他住處、付費旅館等）</td>
							<td>-</td>
							<td>-</td>
							<td>5</td>
							<td>8.8%</td>
						</tr>
						<tr>
							<td>免費旅館</td>
							<td>-</td>
							<td>-</td>
							<td>4</td>
							<td>7.9%</td>
						</tr>
						<tr>
							<td>宗教場所</td>
							<td>-</td>
							<td>-</td>
							<td>4</td>
							<td>6.5%</td>
						</tr>
						<tr>
							<td>不知道/很難說</td>
							<td>1</td>
							<td>2.4%</td>
							<td>3</td>
							<td>4.7%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>36</td>
							<td>100.0%</td>
							<td>55</td>
							<td>100.0%</td>
						</tr>
						<tr>
							<td colspan="5" rowspan="1"><br /></td>
						</tr>
						<tr>
							<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
						</tr>
						
					</tbody>
				</table>

			</div>
			
		</div>
	</section>

	<hr></hr>

	<section id="content05">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h2>花蓮人比較有準備防災包的習慣</h2>
				</div>
				<p>調查比較了民眾災前災後對防災包準備的習慣是否改變，我們發現，當時的臺南人七成六不論震前震後都不準備防災包，花蓮人則相對比率較低，但也超過半數約有六成。顯示準備防災包並不是兩地民眾多數人熱衷的行動。但是花蓮似乎又比臺南好一些，因為至少有30%的民眾地震前後還是有準備，反觀臺南地區則僅有一成三，跟花蓮民眾形成差距。</p>
				<p><a href="/statistics/TainanEarthquake#content13">看看防災包可以準備些什麼</a></p>
				< br/>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>有無準備防災包</h4>
				</div>
			</div>
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf4" 
								num="4"
								
								checked={ this.state.valueType[4].name === 'pdf4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical4" 
								num="4"
								
								checked={this.state.valueType[4].name === 'Statistical4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[2].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有無準備防災包.png")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '05 有無準備防災包', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有無準備防災包.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '05 有無準備防災包', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/有無準備防災包.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '05 有無準備防災包', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[4].name === 'pdf4' || valueType === null ? ' -active' : '')} statistics-charts chat05`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat05.png")} width="100%"/>
			</div>
			<div className={`${(valueType[4].name === 'Statistical4' ? ' -active' : '')} statistics-datatable`}>
				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">有無準備防災包</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>地震前後都有準備</td>
							<td>272</td>
							<td>12.9%</td>
							<td>616</td>
							<td>30.0%</td>
						</tr>
						<tr>
							<td>地震前後都沒有準備</td>
							<td>1596</td>
							<td>75.7%</td>
							<td>1216</td>
							<td>59.3%</td>
						</tr>
						<tr>
							<td>地震後開始準備</td>
							<td>106</td>
							<td>5.0%</td>
							<td>110</td>
							<td>5.4%</td>
						</tr>
						<tr>
							<td>地震後就沒再準備</td>
							<td>127</td>
							<td>6.0%</td>
							<td>108</td>
							<td>5.3%</td>
						</tr>
						<tr>
							<td>不知道/很難說</td>
							<td>8</td>
							<td>0.4%</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>2109</td>
							<td>100.0%</td>
							<td>2049</td>
							<td>100.0%</td>
						</tr>
						<tr>
								<td colspan="5" rowspan="1"><br /></td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
							</tr>
						
					</tbody>
				</table>
			</div>
			
		</div>
	</section>

	<hr></hr>

	<section id="content06">	
		<div className="container-c c640">
			<div className='infon'>
				<div className="title">
					<h2>花蓮人八成不保地震險，<br />臺南人無意願的也高達五成</h2>
					<p>花蓮地區地震頻繁，雖然花蓮人總習以為常，但因為地震好發，居民應能為自己的性命財產加 保地震保險，但調查卻發現，花蓮人高達八成沒有保地震險，過去已有投保者約占14.69%，震後投保的比率也相當低僅有1.64%，反觀臺南，地震投保人數也不高，僅17.31%，但至少震後投保意願比花蓮高許多，有16.74%。</p>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className='infon'>
				<div className="title">
					<h4>是否有投保地震險</h4>
				</div>
			</div>
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="green" 
								group="pdf5" 
								num="5"
								
								checked={ this.state.valueType[5].name === 'pdf5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="green" 
								group="Statistical5" 
								num="5"
								
								checked={this.state.valueType[5].name === 'Statistical5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[2].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有投保地震險.png")} target="_blank" onClick={() => gaTracking('台南花蓮 震後調查大解析', '06 是否有投保地震險', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有投保地震險.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '06 是否有投保地震險', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/AnalysisEarthquakeSurvey/download/是否有投保地震險.xlsx")} onClick={() => gaTracking('台南花蓮 震後調查大解析', '06 是否有投保地震險', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
			</div>
		</div>
		

		<div className="container-c c640">
			<div className={`${(valueType[5].name === 'pdf5' || valueType === null ? ' -active' : '')} statistics-charts chat06`}>
				<img src={require("../../../images/AnalysisEarthquakeSurvey/chat06.png")} width="100%"/>
			</div>
			<div className={`${(valueType[5].name === 'Statistical5' ? ' -active' : '')} statistics-datatable`}>
				<table>
					<tbody>
						<tr>
							<td colspan="5" rowspan="1">是否有投保地震險</td>
						</tr>
						<tr>
							<td colspan="1" rowspan="2">
								<div>項目</div>
							</td>
							<td colspan="2" rowspan="1" align="center">臺南</td>
							<td colspan="2" rowspan="1" align="center">花蓮</td>
						</tr>
						<tr>
							<td>次數分配表</td>
							<td>百分比</td>
							<td>次數分配表</td>
							<td>百分比</td>
						</tr>
						<tr>
							<td>震前震後沒有投保
								<br />（含震後無意願）</td>
							<td>1092</td>
							<td>51.80%</td>
							<td>1614</td>
							<td>78.8%</td>
						</tr>
						<tr>
							<td>震前震後都有投保</td>
							<td>365</td>
							<td>17.31%</td>
							<td>301</td>
							<td>14.7%</td>
						</tr>
						<tr>
							<td>震後投保
								<br />（含震後無意願）</td>
							<td>353</td>
							<td>16.72%</td>
							<td>34</td>
							<td>1.6%</td>
						</tr>
						<tr>
							<td>不知道/很難說</td>
							<td>299</td>
							<td>14.16%</td>
							<td>98</td>
							<td>4.8%</td>
						</tr>
						<tr>
							<td>拒答</td>
							<td>-</td>
							<td>-</td>
							<td>2</td>
							<td>0.1%</td>
						</tr>
						<tr>
							<td>總計</td>
							<td>2109</td>
							<td>100.0%</td>
							<td>2049</td>
							<td>100.0%</td>
						</tr>
						
						<tr>
								<td colspan="5" rowspan="1"><br /></td>
							</tr>
							<tr>
								<td colspan="5" rowspan="1">資料來源：國家災害防救科技中心</td>
							</tr>
					</tbody>
				</table>
			</div>
		
		</div>
			
		
	
	</section>

	<section className="svi-section svi-articlelist">
		<div className="container">
			<h2 className="sectiontitle">你可能會想看</h2>
		
			<div className="row subject-list">
				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/TainanEarthquake">
							<img src={require("../../../images/TainanEarthquake/1201.jpg")} width="100%"/>
							
						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/112">2022.11.08</a> */}
							<a className="title" href="/statistics/TainanEarthquake">0206臺南震災天然災害社經影響家戶調查(2016)</a>
							{/* <div className="author"><label>作者 </label> 林美君/NCDR </div>
							<a className="summary" href="/evaluation/svi/article/112">社會脆弱度評估系統微整型</a> */}
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/HualienEarthquake">
							<img src={require("../../../images/HualienEarthquake/1201.jpg")} width="100%"/>

						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/90">2021.08.16</a> */}
							<a className="title" href="/statistics/HualienEarthquake">0206花蓮震災地震感知與避難行為調查(2018)</a>
							{/* <div className="author"><label>作者 </label> 莊睦雄、蕭宜軒 </div>
							<a className="summary" href="/evaluation/svi/article/90">銘傳大學都市規劃與防災學系已經將減災動資料平臺納入大學部三年級規劃設計與災害管理實習課程以及碩士在職專班的教材，本文採用社會脆弱度客製化功能分享教學成果</a> */}
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>


			</div>
		);
	}
}

export default AnalysisEarthquakeSurveyIndex;
