import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './index.scss';

class Breadcrumb extends Component {
	renderBreadcrumbItems() {
		var items = [];

		for (let i = 0; i < this.props.paths.length; i++) {
			items.push(
				<li key={i} className="item">
					<a href={this.props.paths[i].href}>
						{this.props.paths[i].name}
					</a>
				</li>
			)
		};
		
		return items;
	}
	
	render() {
		return (
			<ul className={"breadcrumb-block " + this.props.color}>
				{
					this.props.paths &&
					this.props.paths.map( (item, i) => {
						const {href, name} = item;
						return (
							<li key={i} className="item">
								<Link to={href}><span className="type-text -ch -md">{name}</span></Link>
							</li>
						)
					} )
				}
			</ul>
		);
	}
}

export default Breadcrumb;
