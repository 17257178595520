import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils
import LimitMask from '../../utils/LimitMask';

class EvacuationChartIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('PINK');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
			{ name: '歷史資料查詢與展示', href: '/evaluation/evacuation/intro' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/evacuation" />
				<div className="evacuation-page">
					<div className="container">
						<div className="evacuation-chart-title">
							<div className="title">歷史資料查詢與展示</div>
							{/* <div className="subtitle">Social Vulnerability Index for Disasters</div> */}
						</div>
						<div className="evacuation-subject-selector">
							<p className="description">請選擇要查詢的主題</p>
							<div className="buttonbar">
								<div className="item">
									<Link className="button ghost-button -pink" to="/evaluation/evacuation/evacuate">
										<span className="type-text -ch -md">歷史撤離人次</span>
									</Link>
									<p>查詢各行政區歷年遭遇颱風豪雨事件之撤離人次</p>
								</div>
								<div className="item">
									<Link className="button ghost-button -pink" to="/evaluation/evacuation/contain">
										<span className="type-text -ch -md">歷史收容人次</span>
									</Link>
									<p>查詢各行政區歷年遭遇颱風豪雨事件之收容人次</p>
								</div>
								<div className="item">
									<Link className="button ghost-button -pink" to="/evaluation/evacuation/alert">
										<span className="type-text -ch -md">歷史警戒熱區</span>
									</Link>
									<p>查詢各行政區淹水或土石流警戒熱區</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default EvacuationChartIntro;
