import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import './index.scss';

class CityIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadHeaderColor('GREEN');
    this.props.onLoadHeaderTheme('DARK');
    this.props.onLoadShowLanguage('HIDE');
    const breadcrumb = [
      { name: '首頁', href: '/' },
      { name: '莫拉克風災災後調查', href: '/statistics/morakot' },
      { name: '進階分析', href: '/statistics/morakot/advanced-analytic' },
    ]
    this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
  }
  render() {
    return (
      <div className="morakot-area-page">
        <Helmet>
          <title>減災動資料 - 莫拉克風災災後調查</title>
        </Helmet>
        <div className="container">
          <div className="morakot-area-heading">
            縣市專區
          </div>
          <div className="morakot-area-intro subject-selector">
            <div className="description">
              縣市專區是依據調查範圍的六個縣市（南投縣、嘉義縣、臺南市、高雄市、屏東縣、台東縣）所做的統計圖表專區，幫助你深入了解每個縣市各項調查情況，請先選擇你想看的主題：
            </div>
            <div className="subjects">
              <div className="item"><Link to="/statistics/morakot-city/disaster-reduction" className="button">減災</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/disaster-response" className="button">應變</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/disaster-recover" className="button">復原與重建</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/respondent-demographics" className="button">受訪者特徵</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/household-demographics" className="button">家戶特徵</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/disability" className="button">行動不便者</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/disaster-impact" className="button">災害衝擊</Link></div>
              <div className="item"><Link to="/statistics/morakot-city/resource-acquisition" className="button">資源取得</Link></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CityIntro;
