import React, { Component } from 'react';
import './index.scss';
import dayjs from 'dayjs';
import axios from 'axios';
import { apiHost } from '../utils/Shared/constant';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { update: '' }
  }
  componentDidMount() {
    axios.get(`${apiHost}/api/Setting/GetWebUpdateDatetime`)
    .then(res => {
      this.setState({ update: res.data.update })
    })
  }
  render() {
    const { update } = this.state;
    return (
      <footer className="footer-block">
        <div className="container">
          <p className="copy-right type-text -ch -md">
            © National Science and Technology Center for Disaster Reduction
          </p>
          {
            update &&
            <p className="update">最後更新時間：{ dayjs(update).format('YYYY-MM-DD') }</p>
          }
          <p className="subscribe-links">
            <a className="button text-button -light-yellow type-text -ch -ss" target="_blank" rel="noopener noreferrer" href="http://eepurl.com/gbLE3z">訂閱最新消息</a>
            <a className="button text-button -light-yellow type-text -ch -ss" target="_blank" rel="noopener noreferrer" href="https://fzhan.us17.list-manage.com/unsubscribe?u=36d91258ac674cf9e4edbaacd&id=216d23cc96">取消最新消息訂閱</a>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
