import React, { Component } from 'react';
import Axios from 'axios';
import Helmet from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import './index.scss';

// Utils
import SocialBlock from '../../utils/SocialBlock';
import StatisticsSVG from '../../utils/StatisticsSVG';
import StatisticsCSV from '../../utils/StatisticsCSV';
import CustomDropdown from '../../utils/CustomDropdown';
import chartCapture from '../../utils/Shared/chartCapture';
import CustomRadio from '../../utils/CustomRadio';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { apiHost, sourceHost } from '../../utils/Shared/constant';
import { downloadTrigger } from '../../utils/Shared/methods';

let lang = 'zh_tw';

class SeriesSubject extends Component {
	constructor(params) {
		super(params);
		this.state = {
      redirectUrl: null
    };
    let slug = window.location.pathname.split('/').slice(2, 3);
    this.lang = slug[0] === 'morakot-en' ? 'en' : 'zh_tw';
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('LIGHT');

		// loadData
		const slugs = window.location.pathname.split('/').slice(2, 5);
		// this.loadData(slugs);
    	this.getAllSubjects(slugs);
		this.getTrackingCategory();
	}

	t = (zh_tw, en) => {
		// let slug = window.location.pathname.split('/').slice(2, 3);
		return this.lang === 'en' ? en : zh_tw;
	}
	getTrackingCategory() {
		const slug = window.location.pathname.split('/').slice(2, 5);
		let category;
		switch (slug) {
			case "morakot": category = '莫拉克颱風'; break;
			case "morakot-en": category = '莫拉克颱風-英文'; break;
			case "sfaa-elder": category = '社福機構'; break;
			case "sfaa-disability": category = '社福機構'; break;
			case "svi": category = '社會脆弱度'; break;
			case "evacuation": category = '撤離與收容評估'; break;
			default: category = '莫拉克'; break;
		}

		this.setState({ trackingCategory: category });
	}
  getAllSubjects(slugs) {
    Axios.get(`${apiHost}/api/Survey/SubtopicDiagram?data.categorySlug=${slugs[0]}&data.topicSlug=${slugs[1]}`)
    .then((res) => {
      if (res.status === 200) {
        // const { t } = this;
        this.setState({
          topicCH: res.data.Result.Name,
          topicEN: res.data.Result.NameEn,
          topicItems: res.data.Result.Lists,
        }, () => {
          this.getSubjectContent(slugs);
          this.settingBreadcrumb();
        });
      }
    })
  }
  getSubjectContent(slugs) {
    const { topicItems } = this.state;
    const subjectSlug = slugs[2] !== '' ? slugs[2] : topicItems[0]['Slug'];
    let url = `${apiHost}/api/Survey/Diagram?data.categorySlug=${slugs[0]}&data.topicSlug=${slugs[1]}&data.subtopicSlug=${subjectSlug}`;
    Axios.get(url)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.Result) {
          let items = res.data.Result.Items;
          items.forEach((item, i) => {
            item.showSVG = true;
          } );
          let id = (res.data.Result.ID.length < 2 ? '0' + res.data.Result.ID : res.data.Result.ID);
          this.setState({ subtopicItems: {} }, () =>
            this.setState({
              id: id,
              subject: res.data.Result.Subject,
              subtopicItems: items,
              subSlug: slugs[2]
            })
          )
        }
      };
    })
  }
	settingBreadcrumb = () => {
		let slug = window.location.pathname.split('/').slice(2, 3).join('');
		this.setState({ slug: slug });

		let breadcrumb = [];
		switch (slug) {
			case "morakot":
				this.props.onLoadShowLanguage('SHOW');
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
          { name: '調查主題', href: '/statistics/morakot/intro' },
					{ name: this.state.topicCH, href: window.location },
				];
				break;
			case "morakot-en":
				this.props.onLoadShowLanguage('SHOW');
				breadcrumb = [
					{ name: 'Home Page', href: '/' },
					{ name: 'Morakot Typhoon', href: '/statistics/morakot-en' },
          { name: 'Typhoon Morakot Infographics', href: '/statistics/morakot-en/intro' },
					{ name: this.state.topicCH, href: window.location },
				];
				break;
			case "sfaa-elder":
				this.props.onLoadShowLanguage('HIDE');
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '社福機構', href: '/statistics/sfaa' },
          { name: '老人福利機構', href: '/statistics/sfaa/elder' },
					{ name: this.state.topicCH, href: window.location },
				];
				break;
			case "sfaa-disability":
				this.props.onLoadShowLanguage('HIDE');
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '社福機構', href: '/statistics/sfaa' },
          { name: '身心障礙福利機構', href: '/statistics/sfaa/disability' },
					{ name: this.state.topicCH, href: window.location },
				];
				break;
			default:
			this.props.onLoadShowLanguage('HIDE');
				breadcrumb = [
					{ name: '首頁', href: '/' },
					{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
					{ name: this.state.topicCH, href: window.location },
				];
		}

		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	loadData = (slugs) => {
		Axios.get(`${apiHost}/api/Survey/SubtopicDiagram?data.categorySlug=${slugs[0]}&data.topicSlug=${slugs[1]}`)
		.then(({data, status}) => {
			if (status === 200) {
				// const { t } = this;
				this.setState({
					topicCH: data.Result.Name,
					topicEN: data.Result.NameEn,
					topicItems: data.Result.Lists,
				})
				this.settingBreadcrumb();

    		Axios.get( apiHost + '/api/Survey/Diagram?data.categorySlug=' + slugs[0] +
    			  '&data.topicSlug=' + slugs[1] +
    			  '&data.subtopicSlug=' + slugs[2])
    		.then((res) => {
    			if (res.status === 200) {
    				let items = res.data.Result.Items;
    				items.forEach((item, i) => {
    					item.showSVG = true;
    				} );
    				let id = (res.data.Result.ID.length < 2 ? '0' + res.data.Result.ID : res.data.Result.ID);
    				this.setState({ subtopicItems: {} }, () =>
    					this.setState({
    						id: id,
    						subject: res.data.Result.Subject,
    						subtopicItems: items,
    						subSlug: slugs[2]
    					})
    				)
    			}
    		})
			}
		})
	}
	handleDownloadTracking = (label) => {
		const { slug } = this.state;
		let category;
		switch (slug) {
			case "morakot": category = '莫拉克颱風'; break;
			case "morakot-en": category = '莫拉克颱風-英文'; break;
			case "sfaa-elder": category = '社福機構'; break;
			case "sfaa-disability": category = '社福機構'; break;
			default: category = '莫拉克'; break;
		}
		gaTracking(category, '調查統計', label);
	}
	handleClickGoToSlug = (e) => {
		const target = e.currentTarget;
		const slug = target.value || target.dataset.slug;
		let slugs = window.location.pathname.split('/').slice(1, 5);
		slugs[3] = slug;
    this.setState({ redirectUrl: `/${slugs.join('/')}` });
	}
	handleClickGoTop = (e) => {
		window.scrollTo(0, 0);
	}
	handleClickSwitchPDF = (e) => {
		let btn = e.currentTarget;
		let num = btn.dataset.num;
		let items = this.state.subtopicItems;

		items[num].showSVG = false;
		this.setState({
			items: items,
		});
	}
	handleClickSwitchSVG = (e) => {
		let btn = e.currentTarget;
		let num = btn.dataset.num;
		let items = this.state.subtopicItems;
		
		items[num].showSVG = true;
		this.setState({
			items: items,
		});

	}
	renderTopicListItems = () => {
		const { topicItems, subSlug } = this.state;
    if (topicItems) {
  		return (
  			<React.Fragment>
  				{
  					topicItems.map((item, i) => (
  						<li key={i} className={"switchitem" + this.t('', ' -en')}>
  							<button data-slug={item.Slug} 
                className={
                  `${item.Slug === subSlug ? '-active' : ''} 
                   ${(!subSlug && i === 0) ? '-active' : ''} `
                }
  							onClick={this.handleClickGoToSlug }>
  								<span className={'type-text -md' + this.t(' -ch', ' -en')}>{item.Name}</span>
  							</button>
  						</li>
  					))
  				}
  			</React.Fragment>
  		)
    }
	}
	renderTopicSelectItems = () => {
		const { topicItems } = this.state;
		let slug = window.location.pathname.split('/').slice(3, 4);
		return (
			<React.Fragment>
				<select name="" id="" defaultValue={slug[0]} onChange={this.handleClickGoToSlug}>
					{
						topicItems &&
						topicItems.map((item, i) => (
							<option key={i} className={"switchitem" + this.t('', ' -en')} value={item.Slug}>
								{item.Name}
							</option>
						))
					}
				</select>
			</React.Fragment>
		)
	}
	renderDownloadItems = (items, i) => {
		const {t} = this;
		return (
			<CustomDropdown className="download" title={t('下載', 'Download')}>
				<li><button data-num={i} onClick={ e => this.handleClickDownloadPNG(e) }>{t('下載PNG', 'PNG')}</button></li>
        {
          items &&
          items.map((item, j) => 
            <li key={j}><a className="drop-button" href={sourceHost + item['Url']} target="_blank">{t(`下載${item['Name']}`, item['Name'])}</a></li>
          )
        }
        {
				  // <li><button data-num={i} onClick={ e => this.handleClickDownloadCSV(e, items[2].Url) }>{t('下載CSV', 'CSV')}</button></li>
        }
			</CustomDropdown>
		)
	}
	handleClickDownloadCSV = (e, url) => {
		const { subtopicItems } = this.state;
		const num = e.currentTarget.dataset.num;
		const fileName = subtopicItems[num].Name;
		const sourceUrl = `${sourceHost}${url}`;
		
		Axios.get(sourceUrl).then(res => {
			const csv = res.data;
			const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv);

			downloadTrigger(fileName + '.csv', csvContent);

			this.handleDownloadTracking('原始數據下載');
		})
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();
		this.handleClickSwitchSVG(e);
		
		const { subtopicItems } = this.state;
		const num = e.currentTarget.dataset.num;
		const fileName = `${subtopicItems[num].Name}`;
    setTimeout(() => {
  		const captureArea = document.querySelectorAll(`.capture${num}`);
  		chartCapture(captureArea, fileName);

  		this.handleDownloadTracking('圖表下載');
    }, 100)
	}
	renderChartContent = () => {
		const {t} = this;
		return (
			<React.Fragment>
				{
					this.state.subtopicItems &&
					this.state.subtopicItems.length &&
					this.state.subtopicItems.map( (item, i) => {
						const { Description, Downloads, Name, Note, SVG, JS, CSV } = item;
						const remark = Note ? Note.replace(/\n/g, "<br />") : '';
						return (
							<React.Fragment key={i}>
								<div ref={"subject" + i} className="row chart-content">
                  {
									// <div className="col-md-2 number">
									// 	<span className="type-heading -en -h6">{ ID }</span>
									// </div>
                  }
                  <article className={`col-md-12 article chart-article capture${i}`}>
										<p className={"title type-text -lg" + t(' -ch', ' -en')}>{ Name }</p>
										<p className={"description type-text -md" + t(' -ch', ' -en')} dangerouslySetInnerHTML={{ __html: Description }}></p>
									</article>
									<div className="col-md-12 chart-block">
										<div className="option chart-option" >
											<div className="radiobar">
												<CustomRadio className="radio"
												color="green"
												group={"radio" + i}
												idName={"svg" + i}
												num={i}
												onClick={ (e) => { this.handleClickSwitchSVG(e) } }
												checked={item.showSVG}>
													<span className={"text type-text -md" + t(' -ch', ' -en')}>{t('圖表', 'Charts')}</span>
												</CustomRadio>
												<CustomRadio className="radio"
												color="green"
												group={"radio" + i}
												idName={"pdf" + i}
												num={i}
												onClick={ (e) => { this.handleClickSwitchPDF(e) } }
												checked={!item.showSVG}>
													<span className={"text type-text -md" + t(' -ch', ' -en')}>{t('數據', 'Data')}</span>
												</CustomRadio>
											</div>
											{ this.renderDownloadItems(Downloads, i) }
										</div>
										<div className="chart chart-draw">
                      <StatisticsCSV csvContent={ CSV } csvId={i} className={`${(!item.showSVG ? ' -active' : '')}`} />
											<StatisticsSVG js={ JS } svgId={i} svgData={ SVG }
												className={ `draw statistics-charts capture${i}` + (item.showSVG? ' -active' : '') } />
											<p className={`capture${i} remark type-text -ss ${t(' -ch', ' -en')}`}
												dangerouslySetInnerHTML={{ __html: remark }}></p>
										</div>
									</div>
								</div>
							</React.Fragment>
						)
					} )
				}
			</React.Fragment>
		)
	}
	renderBanner = () => {
		let slugs = window.location.pathname.split('/').slice(2, 4).join('_');
		slugs = '/banner_img/' + slugs + '.png';

		return slugs;
	}
	renderOthersLinks = () => {
		const {t} = this;
		let slug = window.location.pathname.split('/').slice(2, 3);
		return (
			<React.Fragment>
				{
					(slug[0] === 'morakot') &&
					<div className="row subject subject-other">
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/disaster-reduction/">
								<p className={'type-text -md -ch'}>減災</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/disaster-response/">
								<p className={'type-text -md -ch'}>應變</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/disaster-recover/">
								<p className={'type-text -md -ch'}>復原與重建</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/respondent-demographics/">
								<p className={'type-text -md -ch'}>受訪者特徵</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/household-demographics/">
								<p className={'type-text -md -ch'}>家戶特徵</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/disability/">
								<p className={'type-text -md -ch'}>行動不便者</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/disaster-impact/">
								<p className={'type-text -md -ch'}>災害衝擊</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot/resource-acquisition/">
								<p className={'type-text -md -ch'}>資源取得</p>
							</Link>
						</div>
					</div>
				}
				{
					(slug[0] === 'morakot-en') &&
					<div className="row subject subject-other">
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/disaster-reduction/">
								<p className={'type-text -md -en'}>Disaster Risk Reduction</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/disaster-response/">
								<p className={'type-text -md -en'}>Disaster Response</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/disaster-recover/">
								<p className={'type-text -md -en'}>Disaster Recovery</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/respondent-demographics/">
								<p className={'type-text -md -en'}>Respondent demographics</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/household-demographics/">
								<p className={'type-text -md -en'}>Household Demographics</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/disability/">
								<p className={'type-text -md -en'}>Disability</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/disaster-impact/">
								<p className={'type-text -md -en'}>Disaster Impact</p>
							</Link>
						</div>
						<div className="subjectitem">
							<Link className="button" to="/statistics/morakot-en/resource-acquisition/">
								<p className={'type-text -md -en'}>Resource acquisition</p>
							</Link>
						</div>
					</div>
				}
				{
					(slug[0] === "sfaa-elder" || slug[0] === "sfaa-disability") &&
					<div className="subject-sfaa row">
						<div className="offset-md-2 col-md-4 subjectitem -left">
							<p className={'title type-text -lg' + t(' -ch', ' -en')}>老人福利機構</p>
							<Link className="button" to="/statistics/sfaa-elder/disaster-experience/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>受災經驗</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-elder/building-features/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>建物特徵</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-elder/risk-perception/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>減災</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-elder/institutional-and-residential-characteristics/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>機構與院民特徵</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-elder/environmental-characteristics/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>環境特徵</p>
							</Link>
						</div>
						<div className="col-md-4 subjectitem -right">
							<p className={"title type-text -lg" + t(' -ch', ' -en')}>身心障礙福利機構</p>
							<Link className="button" to="/statistics/sfaa-disability/disaster-experience/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>受災經驗</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-disability/building-features/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>建物特徵</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-disability/risk-perception/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>減災</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-disability/institutional-and-residential-characteristics/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>機構與院民特徵</p>
							</Link>
							<Link className="button" to="/statistics/sfaa-disability/environmental-characteristics/">
								<p className={'type-text -md' + t(' -ch', ' -en')}>環境特徵</p>
							</Link>
						</div>
					</div>
				}
			</React.Fragment>
		)
	}
	render() {
		const {t} = this;
		const { topicCH = "", topicEN, subject = "" } = this.state;
		const { trackingCategory, redirectUrl } = this.state;
		return (
			<div className="subject-page">
				<Helmet>
					<title>{"減災動資料 - 統計展示 " + topicCH + " " + subject}</title>
				</Helmet>
        { redirectUrl && <Redirect to={redirectUrl } /> }
				<section className="heading subject-section subject-heading" style={{ backgroundImage: 'url(' + this.renderBanner() + ')' }}>
					<div className={"mainheading type-heading -h1" + t(' -ch', ' -en')}>{topicCH}</div>
					<div className="subheading type-heading -en -h6">{topicEN}</div>
				</section>
				<div className="subject-switch">
					<div className="container">
						<div className="switch-select select">
							{ this.renderTopicSelectItems() }
						</div>
						<ul className="switch-list list">
							{ this.renderTopicListItems() }
						</ul>
					</div>
				</div>
				<section className="subject-section subject-chart">
					<div className="container">
						<div className="row chart-title">
							{/*<div className="col-md-2 number">
								<span className="type-heading -en -h4">{ id }</span>
							</div>*/}
							<div className="col-md-12 title">
								<span className="type-heading -en -h4">{ subject }</span>
							</div>
						</div>
						{ this.renderChartContent() }
					</div>
				</section>
				<SocialBlock lang={t('zh-tw', 'en')} category={trackingCategory} action="調查統計" />
				<div className="article-pager">
					<div className="container">
						<button className="top" onClick={this.handleClickGoTop}>
							<span className="icon"><i className="icon-arrow-l-top"></i></span>
							<span className='text type-text -en -md'>TOP</span>
						</button>
					</div>
				</div>
				{/* <PageNavigation to="/statistics/morakot/" lang={t('zh-tw', 'en')}></PageNavigation> */}
				<section className="subject-selector">
					<div className="container">
						<div className={"title type-text -lg" + t(' -ch', ' -en')}>{t('瀏覽其他主題', 'Read other topics')}</div>
						{ this.renderOthersLinks() }
					</div>
				</section>
			</div>
		);
	}
}

export default SeriesSubject;
