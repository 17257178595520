let init = {
  // TODO: 此Component的Reducer state初始值
  redirect_url: '',
  sn_response: {},
  // someValue: 0
}

export default (state = init, action) => {
  switch (action.type) {

    case 'SET_SN_RESPONSE':
      return {...state, sn_response: action.response}

    case 'SET_ON_LOGIN_REDIRECT':
      return {...state, redirect_url: action.url}

    default:
      return state
  }
}

// TODO: 定義完以上後，記得將此 Reducer Combine 進 `src/components/App/reducer.js` 中. (若你沒有使用auto combine的話)