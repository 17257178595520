import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

import ModalBox from '../../utils/ModalBox';
import { cors, sourceHost } from '../Shared/constant';

class StatisticsCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
      csvData: null,
    }
	}
  componentDidMount() {
    this.handleLoadCsv();
  }
	openModal() {
		this.setState({
			modalOpen: true,
		})
	}
  handleLoadCsv = () => {
    const { csvContent, i } = this.props;
    // const csv = csvContent.find(item => item['Name'] === 'CSV') || null;
    if (csvContent) {
      axios.get(`${cors}${sourceHost}${csvContent}`)
      .then(res => {
        const test = encodeURI(res.data) ;
        const csv = this.getCsvContent(res.data);
        this.setState({ csvData: csv });
      });
    }
  }
  getCsvContent(csvString) {
    const lineRegex = /\n(?=(?:(?:[^"]*"){2})*[^"]*$)/g;
    const cellRegex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/g;
    const lines = csvString.split(lineRegex);
    const cells = lines.map(line => {
      let cellData = line.split(cellRegex);
      cellData = cellData.filter(function (element) {
        return element !== undefined;
      });
      return cellData
    });
    return cells;
  }
  replaceString(string) {
    const replaceRegex = /\"|\“|\”/g;
    return string.replace(replaceRegex, '');
  }
	render() {
    const { className } = this.props;
    const { csvData }= this.state;
		return (
      <div className={`${className} statistics-datatable`}>
        <table>
          <tbody>
            {
              csvData && csvData.map((line, lineIndex) =>
                <tr key={line + lineIndex}>
                  { line.map((cell, cellIndex) => <td key={cell + cellIndex }>{ this.replaceString(cell) }</td>)}
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
		);
	}
}

export default StatisticsCSV;
