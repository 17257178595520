import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Utils
// import chartCapture from '../../utils/Shared/chartCapture';
import CustomRadio from '../../utils/CustomRadio';
import CustomDropdown from '../../utils/CustomDropdown';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

// Resources
import HualienEarthquakePercentPic01 from "../../../images/HualienEarthquake/maintitle01.svg";

const waypointOption = {
	topOffset: '0%',
	bottomOffset: '0%',
}

class HualienEarthquakeIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
			scrollSpyItems: [
				{
					name: 'content03',
					title: '震後反應大解密，只有7.3%的民眾做對了！',
					show: false,
				},
				{
					name: 'content05',
					title: '地震時該怎麼做？近5成的民眾想錯了',
					show: false,
				},
				{
					name: 'content07',
					title: '震後該往哪逃？僅3成民眾知道避難地點',
					show: false,
				},
				,
				{
					name: 'content17',
					title: '震後大解析！沒有準備防災包的原因是這些',
					show: false,
				},
				,
				{
					name: 'content22',
					title: '狡兔總有三窟，地震過後，你想過可以去哪嗎？',
					show: false,
				},
			],
			valueType: [
				{
					name: 'pdf0'
				},
				{
					name: 'pdf1'
				},
				{
					name: 'pdf2'
				},
				{
					name: 'pdf3'
				}
				,
				{
					name: 'pdf4'
				}
				,
				{
					name: 'pdf5'
				}
				,
				{
					name: 'pdf6'
				}
				,
				{
					name: 'pdf7'
				}
				,
				{
					name: 'pdf8'
				}
				,
				{
					name: 'pdf9'
				}
				,
				{
					name: 'pdf10'
				}
				,
				{
					name: 'pdf11'
				}
				,
				{
					name: 'pdf12'
				}
				,
				{
					name: 'pdf13'
				}
				,
				{
					name: 'pdf14'
				},
				{
					name: 'pdf15'
				}
			]
		};
	}
	//檢查登入
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
		this.setState({ isMemberLogin: true });
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '0206花蓮地震調查', href: '/statistics/HualienEarthquake' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	
	handleClickRidio(e) {
		let num    = e.currentTarget.dataset.num;
		let group = e.currentTarget.dataset.group;
		let valueType = this.state.valueType;

		valueType[num].name = group;
		// console.log(num)
		// console.log(group)
		this.setState({
			valueType: valueType,
		})
	}

	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}


	render() {
		const { isMemberLogin } = this.state;
		const { scrollSpyItems, valueType } = this.state;
	
		return (
			
<div className="HualienEarthquake" id="home">
	<Helmet>
		<title>0206花蓮地震 - 與震共存的臺灣人，你不能不知道的事</title>
	</Helmet>
	<div className="scroll-spy">
		{
			scrollSpyItems &&
			scrollSpyItems.map((item, index) => {
				return (
					<div key={index}
						className={"item " + item.name + (item.show ? " -show" : "")}
						onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true }) }}>
						<span>{item.title}</span>
					</div>
				)
			})
		}
	</div>

	<div className="scroll-up">
		<a href="#home" className="pc"> <img src={require("../../../images/HualienEarthquake/Union.svg")}/> </a>
		<a href="#menu" className="mobile"> <img src={require("../../../images/HualienEarthquake/Union_ph.svg")}/> </a>
	</div>

	<div className="header">
		<div className='seismicwave'>
			<img src={require("../../../images/HualienEarthquake/seismicwave.svg")} width="100%"/>
		</div>
		<h1 className="maintitle">
			<img src={HualienEarthquakePercentPic01} alt="0206花蓮地震 - 與震共存的臺灣人，你不能不知道的事"/>
		</h1>
		<div className="mass"></div>
		
		<ul className="slideshow">
			<li><span>Slide One</span> </li>
			<li><span>Slide Two</span> </li>
		</ul>
	</div>

	<section id="content01">
		<div className="container-c c640">
			<div className="infon">
				<p>臺灣，一座因板塊擠壓隆起的島嶼，位處於環太平洋地震帶上，注定要與地震共存，多數臺灣民眾對於地震習以為常，甚至不以為意，但是…</p>
				<p>那晚臺灣人民身處地震帶上的恐懼，重新被喚起了</p>
				<p>2018年02月06日23時50分，在許多民眾已經就寢的時刻，於臺灣花蓮發生芮氏規模 6.4 地震，花蓮市震央最高達到7級，這一次地震是臺灣繼 2016 年高雄美濃大地震後最嚴重的地震，造成知名統帥大飯店、雲門翠堤、白金雙星及吾居吾宿社區大樓建築傾斜倒塌。這起地震造成17人罹難、291人輕重傷，且在主震發生後仍有多起規模 5 以上餘震，造成許多居民生活重大影響。</p>
			</div>
		</div>
	</section>

	<section id="content02">
		
		<div className="container-c c886">
			<div className="pic map01">
				<img src={require("../../../images/HualienEarthquake/map01.gif")} width="100%"/>
			</div>
		</div>
		<div className="container-c c640" id="menu">
			<div className="btn-g">
				<a href="#content03" className="btn">
				震後反應大解密，只有7.3%的民眾做對了！
				</a>
				<a href="#content05" className="btn">
				地震時該怎麼做？近5成的民眾想錯了
				</a>
				<a href="#content07" className="btn">
				震後該往哪逃？僅3成民眾知道避難地點
				</a>
				<a href="#content17" className="btn">
				震後大解析！沒有準備防災包的原因是這些
				</a>
				<a href="#content22" className="btn">
				狡兔總有三窟，地震過後，你想過可以去哪嗎？
				</a>
			</div>
		</div>
	</section>

	<hr></hr>

	<section id="content03">
		<div className="container-c c640">
			<div className="infon">
				
				<div className="title">
					
					<h2>震後反應大解密，< br/>只有7.3%的民眾做對了！</h2>
				</div>
				<p>面對突如其來的天搖地動，最重要的莫過於第一時間的反應行為，正確的反應能夠保障人身安全，本次調查結果第一部分便針對民眾的臨震反應做調查，究竟大家通常的第一反應是什麼？</p>

				<p>根據調查結果發現，有47.6%的民眾在第一時間「沒有任何動作」，包含未感覺到地震，或覺得地震很小，不需要應變，或者有感受到地震很大，但來不及反應。其次有20.2%的人選擇「盡速往建築物外跑」，或採取「其他行為」(11.4%)。另外，則有7.3%的人採取「趴、掩、穩」的相近行為，包含待在床上用枕頭或棉被保護頭部、躲在桌子底下等作為。</p>
			</div>
		</div>
	</section>
	

	<section id="content04">

		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>民眾之臨震反應</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf0" 
								num="0"
								
								checked={ this.state.valueType[0].name === 'pdf0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical0" 
								num="0"
								
								checked={this.state.valueType[0].name === 'Statistical0' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							{/* {this.state.valueType[0].name} */}
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾之臨震反應.png")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾之臨震反應', '圖表下載')} 
									download>下載PNG</Link>
								</li>
								<li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾之臨震反應.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾之臨震反應', '原始數據下載')} 
									download>下載EXCEL</Link>
								</li>

								{/* { isMemberLogin && <li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾之臨震反應.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾之臨震反應', '原始數據下載')} 
									download>下載EXCEL</Link></li> }

								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
						
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="container-c c886">

			<div className={`${(valueType[0].name === 'pdf0' || valueType === null ? ' -active' : '')} statistics-charts chat01`}>
				<img src={require("../../../images/HualienEarthquake/chat01.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[0].name === 'Statistical0' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
					<tbody>
						<tr>
							<td colspan="3" valign="bottom">
								<p align="center">民眾之臨震反應</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">
								<p align="left">項目</p>
							</td>
							<td valign="bottom">
								<p align="right">次數分配</p>
							</td>
							<td valign="bottom">
								<p align="right">百分比</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">
								<p>未有任何動作</p>
							</td>
							<td valign="bottom">
								<p align="right">975</p>
							</td>
							<td valign="bottom">
								<p align="right">47.6%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">
								<p>往建築物外跑</p>
							</td>
							<td valign="bottom">
								<p align="right">414</p>
							</td>
							<td valign="bottom"><p align="right">20.2%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>其他</p>
							</td>
							<td valign="bottom"><p align="right">234</p>
							</td>
							<td valign="bottom"><p align="right">11.4%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>「趴、掩、穩」相近行動</p>
							</td>
							<td valign="bottom"><p align="right">150</p>
							</td>
							<td valign="bottom"><p align="right">7.3%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>把門打開，避免出入口損壞</p>
							</td>
							<td valign="bottom"><p align="right">120</p>
							</td>
							<td valign="bottom"><p align="right">5.9%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>躲在堅固家具旁</p>
							</td>
							<td valign="bottom"><p align="right">74</p>
							</td>
							<td valign="bottom"><p align="right">3.6%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>找家人</p>
							</td>
							<td valign="bottom"><p align="right">57</p>
							</td>
							<td valign="bottom"><p align="right">2.8%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>躲在床旁邊</p>
							</td>
							<td valign="bottom"><p align="right">24</p>
							</td>
							<td valign="bottom"><p align="right">1.2%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>總計</p>
							</td>
							<td valign="bottom"><p align="right">2049</p>
							</td>
							<td valign="bottom"><p align="right">100.0%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom"><p>
									<br />
								</p>
							</td>
							<td valign="bottom"><p>
									<br />
								</p>
							</td>
							<td valign="bottom"><p>
									<br />
								</p>
							</td>
						</tr>
						<tr>
							<td colspan="3" valign="bottom"><p>資料來源：國家災害防救科技中心</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<blockquote>
				<p><b>未有任何動作</b>：沒有感覺地震或覺得地震很小，不需要反應、感覺地震很大想反應卻來不及動作、感覺地震很大但不做應變。</p>

				<p><b>「趴、掩、穩」相近行動</b>：待在床上用枕頭或被子保護頭部、躲在堅固家具下、注意有無掉落物。</p>

				<p><b>其他</b>：禱告、唸佛號、往高處避難、躲牆角、躲樑柱下、不知道/很難說。</p>
				
			</blockquote>
			
		</div>

		<div className="container-c c640">
			<div className="infon">
				<p>由此可知，多數人第一時間沒有動作，可能是評估覺得不需要反應，也可能是不知道面臨地震該如何反應。</p>

				<p>此外，也有部分的人在第一時間選擇先往外跑，不過目前的地震防災觀念，並不鼓勵在第一時間馬上往建築物外跑，因為地震當下可能搖晃劇烈，會有許多物品掉落，這時快速移動反而容易增加受傷風險。</p>

				<p>那麼如果第一時間不建議往外跑，遇到地震時，待在室內的我們該怎麼做呢？根據公部門的地震防災宣導，民眾遇到地震時應採取「趴掩穩」原則：</p>
			</div>
			<div className="pic">
				<img src={require("../../../images/HualienEarthquake/pic00.svg")} width="100%"/>
			</div>
			<div className="infon">
				<p>若地震發生時間為就寢時段，在床附近無易碎物或是掉落物的前提下，應選擇待在床上用枕頭或被子保護頭部，或者在尚未就寢時，躲在家中堅固的家具（如書桌）底下。< br /><br />若是使用輪椅者，在地震時務必鎖住或是固定輪椅，有使用靠墊者，若可行的話，可以拿起來保護頭部。<a href='https://easy2do.ncdr.nat.gov.tw/disability/physical/perm-96-20200526143528.pdf' target='_blank'>（ 參考資料：肢體障礙萬連曆 ）</a></p>
			</div>
			<div className="pic pic01">
				<img src={require("../../../images/HualienEarthquake/pic01.svg")} width="100%"/>
			</div>
		</div>

	</section>
	
	<hr></hr>

	<section id="content05">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h2>地震時該怎麼做？< br/>近5成的民眾想錯了</h2>
				</div>
				<p>從民眾臨震行為的調查結果來看，排名第四高的「待在床上，用枕頭或被子保護頭部，或躲在桌子底下等」較接近政府宣導的「趴掩穩」行為。不過究竟對於「趴掩穩」原則，民眾知多少？經過調查結果發現，認為地震發生時應該「躲在黃金三角」的人有48.6%，其次25.9%才是選擇「趴下、掩護、穩住」。</p>
				<div className="title">
					<h4>民眾相信的臨震保命策略</h4>
				</div>
			</div>
			<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf1" 
								num="1"
								
								checked={ this.state.valueType[1].name === 'pdf1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical1" 
								num="1"
								
								checked={this.state.valueType[1].name === 'Statistical1' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略.png")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾相信的臨震保命策略', '圖表下載')} 
									download>下載PNG</Link>
								</li>
								<li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾相信的臨震保命策略', '原始數據下載')} 
									download>下載EXCEL</Link>
									</li>
								
								{/* { isMemberLogin && <li>
									<Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略.xlsx")} 
									target="_blank" 
									onClick={() => gaTracking('花蓮地震', '民眾相信的臨震保命策略', '原始數據下載')} 
									download>下載EXCEL</Link></li> }

								{ !isMemberLogin && <li><button onClick={loginRedirect}>下載EXCEL</button></li> } */}
						
							</CustomDropdown>
						</div>
					</div>
			</div>
		</div>
		<div className="container-c c886">

			<div className={`${(valueType[1].name === 'pdf1' || valueType === null ? ' -active' : '')} statistics-charts chat02`}>
				<img src={require("../../../images/HualienEarthquake/chat02.gif")} width="100%"/>
			</div>
			<div className={`${(valueType[1].name === 'Statistical1' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
		<tbody>
			<tr>
				<td  colSpan="3" valign="bottom"><p>民眾相信的臨震保命策略</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>項目</p>
				</td>
				<td valign="bottom"><p align="right" >次數分配</p>
				</td>
				<td valign="bottom"><p align="right" >百分比</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>躲在黃金三角</p>
				</td>
				<td valign="bottom"><p align="right" >996</p>
				</td>
				<td valign="bottom"><p align="right" >48.6%</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>趴下、掩護、穩住</p>
				</td>
				<td valign="bottom"><p align="right" >530</p>
				</td>
				<td valign="bottom"><p align="right" >25.9%</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>以上皆對</p>
				</td>
				<td valign="bottom"><p align="right" >39</p>
				</td>
				<td valign="bottom"><p align="right" >1.9%</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>以上皆錯</p>
				</td>
				<td valign="bottom"><p align="right" >30</p>
				</td>
				<td valign="bottom"><p align="right" >1.5%</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>不知道/很難說</p>
				</td>
				<td valign="bottom"><p align="right" >453</p>
				</td>
				<td valign="bottom"><p align="right" >22.1%</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>總計</p>
				</td>
				<td valign="bottom"><br /><p align="right" >2049</p>
				</td>
				<td valign="bottom"><p align="right" >100.0%</p>
				</td>
			</tr>
			<tr>
				<td  colSpan="3"   valign="bottom"><p  >
						<br />
					</p>
				</td>
			</tr>
			<tr>
				<td  colSpan="3"  valign="bottom"><p>資料來源：國家災害防救科技中心</p>
				</td>
			</tr>
		</tbody>
				</table>
			</div>

		</div>
		<div className="container-c c640">
			<div className="infon">
				<p>黃金三角理論是指，若地震時躲在支撐力夠強大的物體旁，就可以頂住倒塌的樑柱，形成三角空間讓人躲避，該理論多年來於網路上廣為流傳，然而因地震發生的場所、建築結構等都不盡相同，難以預估黃金三角生成的空間在哪裡，加上黃金三角理論並無考慮掉下來的非結構掉落物，例如玻璃、輕鋼架天花板、磁磚等，所以不被政府所選用。因此政府目前持續積極推動地震防災演練活動，希望讓每位民眾都對於「趴、掩、穩」原則有更深的認知，在第一時間正確反應。</p>
			</div>
		</div>
	</section>

	<section id="content05">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h3> 危機時刻來不及思考？<br />平日多演練才能做出正確行動</h3>
				</div>
				<p>回顧前面探討的民眾第一時間「真實的」反應和面對地震發生時認為「應該」要怎麼反應，經過交叉比對，觀察出值得探討的結果——不論民眾對於臨震防災行為的認知為何，在真正遇到地震的當下，約有五成的人是不動作的；除此之外，有8.4%的人回答地震時「應該」要躲在黃金三角，但實際行動則是「趴掩穩」的行為；認知與行動相吻合的情況僅佔少數，有6.6%的人會依照正確認知行動(趴掩穩)。</p>
			</div>
		</div>
	</section>	

	<section id="content06">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h4>民眾相信的臨震保命策略 VS 臨震反應</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf2" 
								num="2"
								
								checked={ this.state.valueType[2].name === 'pdf2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical2" 
								num="2"
								
								checked={this.state.valueType[2].name === 'Statistical2' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略vs臨震反應.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '民眾相信的臨震保命策略vs臨震反應', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略vs臨震反應.xlsx")} onClick={() => gaTracking('花蓮地震', '民眾相信的臨震保命策略vs臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/民眾相信的臨震保命策略vs臨震反應.xlsx")} onClick={() => gaTracking('花蓮地震', '03民眾相信的臨震保命策略vs臨震反應', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c1200">
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-1`}>
				<img src={require("../../../images/HualienEarthquake/chat03-1.svg")} width="100%"/>
			</div>
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-2`}>
				<div className="scrollbox">
					<img src={require("../../../images/HualienEarthquake/chat03-2.svg")} width="100%"/>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[2].name === 'pdf2' || valueType === null ? ' -active' : '')} statistics-charts chat03-3`}>
				<img src={require("../../../images/HualienEarthquake/chat03-3.svg")} width="100%"/>
			</div>
		</div>
		<div className="container-c c886">
			<div className={`${(valueType[2].name === 'Statistical2' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
		<tbody>
			<tr>
				<td colSpan="8" valign="middle">
					<p align="center" >臨震反應 VS 民眾相信的臨震保命策略</p>
				</td>
			</tr>
			<tr>
				<td colSpan="3" rowSpan="2"  valign="middle">

					<p align="center" >項目</p>
				</td>
				<td colSpan="5"  valign="bottom">

					<p align="center" >民眾相信的臨震保命策略</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>趴下、掩護、穩住</p>
				</td>
				<td valign="bottom"><p>躲在黃金三角</p>
				</td>
				<td  valign="bottom"><p>以上皆對</p>
				</td>
				<td  valign="bottom"><p>以上皆錯</p>
				</td>
				<td  valign="bottom"><p>不知道/很難說</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="20"  valign="middle"><p>臨震反應</p>
				</td>
				<td rowSpan="2" valign="middle"><p>未有任何動作</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>259</p>
				</td>
				<td valign="bottom"><p>444</p>
				</td>
				<td  valign="bottom"><p>16</p>
				</td>
				<td  valign="bottom"><p>16</p>
				</td>
				<td valign="bottom"><p>240</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>48.9%</p>
				</td>
				<td valign="bottom"><p>44.5%</p>
				</td>
				<td  valign="bottom"><p>40.0%</p>
				</td>
				<td  valign="bottom"><p>51.6%</p>
				</td>
				<td  valign="bottom"><p>53.1%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>趴掩穩相近行動</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>34</p>
				</td>
				<td valign="bottom"><p>84</p>
				</td>
				<td  valign="bottom"><p>4</p>
				</td>
				<td  valign="bottom"><p>0</p>
				</td>
				<td  valign="bottom"><p>27</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>6.4%</p>
				</td>
				<td valign="bottom"><p>8.4%</p>
				</td>
				<td  valign="bottom"><p>10.0%</p>
				</td>
				<td  valign="bottom"><p>0.0%</p>
				</td>
				<td  valign="bottom"><p>6.0%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>盡速起床，往建築物外跑</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>84</p>
				</td>
				<td valign="bottom"><p>237</p>
				</td>
				<td  valign="bottom"><p>6</p>
				</td>
				<td  valign="bottom"><p>8</p>
				</td>
				<td valign="bottom"><p>80</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>15.8%</p>
				</td>
				<td valign="bottom"><p>23.8%</p>
				</td>
				<td  valign="bottom"><p>15.0%</p>
				</td>
				<td  valign="bottom"><p>25.8%</p>
				</td>
				<td  valign="bottom"><p>17.7%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>把門打開，以免出入口損壞</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>37</p>
				</td>
				<td valign="bottom"><p>66</p>
				</td>
				<td  valign="bottom"><p>3</p>
				</td>
				<td  valign="bottom"><p>2</p>
				</td>
				<td  valign="bottom"><p>12</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>7.0%</p>
				</td>
				<td valign="bottom"><p>6.6%</p>
				</td>
				<td  valign="bottom"><p>7.5%</p>
				</td>
				<td  valign="bottom"><p>6.5%</p>
				</td>
				<td  valign="bottom"><p>2.7%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>找家人</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>30</p>
				</td>
				<td valign="bottom"><p>19</p>
				</td>
				<td  valign="bottom"><p>2</p>
				</td>
				<td  valign="bottom"><p>1</p>
				</td>
				<td valign="bottom"><p>6</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>5.7%</p>
				</td>
				<td valign="bottom"><p>1.9%</p>
				</td>
				<td  valign="bottom"><p>5.0%</p>
				</td>
				<td  valign="bottom"><p>3.2%</p>
				</td>
				<td  valign="bottom"><p>1.3%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>躲在床旁邊</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>10</p>
				</td>
				<td valign="bottom"><p>11</p>
				</td>
				<td  valign="bottom"><p>0</p>
				</td>
				<td  valign="bottom"><p>0</p>
				</td>
				<td  valign="bottom"><p>3</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>1.9%</p>
				</td>
				<td valign="bottom"><p>1.1%</p>
				</td>
				<td  valign="bottom"><p>0.0%</p>
				</td>
				<td  valign="bottom"><p>0.0%</p>
				</td>
				<td  valign="bottom"><p>0.7%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>躲在堅固傢具的旁邊</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>18</p>
				</td>
				<td valign="bottom"><p>45</p>
				</td>
				<td  valign="bottom"><p>3</p>
				</td>
				<td  valign="bottom"><p>1</p>
				</td>
				<td valign="bottom"><p>7</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>3.4%</p>
				</td>
				<td valign="bottom"><p>4.5%</p>
				</td>
				<td  valign="bottom"><p>7.5%</p>
				</td>
				<td  valign="bottom"><p>3.2%</p>
				</td>
				<td  valign="bottom"><p>1.5%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>其他</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>36</p>
				</td>
				<td valign="bottom"><p>30</p>
				</td>
				<td  valign="bottom"><p>5</p>
				</td>
				<td  valign="bottom"><p>1</p>
				</td>
				<td  valign="bottom"><p>23</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>6.8%</p>
				</td>
				<td valign="bottom"><p>3.0%</p>
				</td>
				<td  valign="bottom"><p>12.5%</p>
				</td>
				<td  valign="bottom"><p>3.2%</p>
				</td>
				<td  valign="bottom"><p>5.1%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>不知道/很難說</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>22</p>
				</td>
				<td valign="bottom"><p>61</p>
				</td>
				<td  valign="bottom"><p>1</p>
				</td>
				<td  valign="bottom"><p>2</p>
				</td>
				<td valign="bottom"><p>54</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>4.2%</p>
				</td>
				<td valign="bottom"><p>6.1%</p>
				</td>
				<td  valign="bottom"><p>2.5%</p>
				</td>
				<td  valign="bottom"><p>6.5%</p>
				</td>
				<td  valign="bottom"><p>11.9%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle"><p>總計</p>
				</td>
				<td valign="bottom"><p>次數分配</p>
				</td>
				<td valign="bottom"><p>530</p>
				</td>
				<td valign="bottom"><p>997</p>
				</td>
				<td  valign="bottom"><p>40</p>
				</td>
				<td  valign="bottom"><p>31</p>
				</td>
				<td  valign="bottom"><p>452</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom"><p>百分比</p>
				</td>
				<td valign="bottom"><p>100%</p>
				</td>
				<td valign="bottom"><p>100%</p>
				</td>
				<td  valign="bottom"><p>100%</p>
				</td>
				<td  valign="bottom"><p>100%</p>
				</td>
				<td  valign="bottom"><p>100%</p>
				</td>
			</tr>
			<tr>
				<td colSpan="8" valign="bottom">

					<p>
						<br />
					</p>
				</td>
			</tr>
			<tr>
				<td valign="bottom">
					<p >資料來源：國家災害防救科技中心</p>
				</td>
				<td colSpan="7" valign="bottom">
					<p> <br /></p>
				</td>
			</tr>
		</tbody>
				</table>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon">
				<p>一般來說，會推測有正確認知的人在地震發生時，做出適當行為的比率要較高，但實際上並非如此，可以想見當地震這樣突如其來的危機發生時，人類大腦思考判斷的能力會受到影響，反而是讓身體下意識地做出反應。因此平日若有多讓自己進行地震防災演練，就能加強身體記憶，在關鍵時刻保障安全。</p>
			</div>
		</div>
	</section>	
	
	<hr></hr>

	<section id="content07">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h2>震後該往哪逃？<br />僅3成民眾知道避難地點</h2>
				</div>
				<p>在感受到較大的地震，待第一波主地震過後，若持續感到恐慌或擔心後續會有餘震，可以採取疏散避難行動，前往住家附近的空曠處避難，因此接下來，我們也詢問受訪民眾，在0206地震時是否有前往空曠處避難，以及是否知道住家附近的避難公告地點？</p>
			</div>
			<div className="pic pic02">
				<img src={require("../../../images/HualienEarthquake/pic02.gif")} width="100%"/>
			</div>
			<div className="infon">
				<p>調查結果發現，沒有到空曠處避難的占大多數，約75.6%，有到空曠處避難的則占24.4%。至於是否知道住家附近政府公告的避難地點，有70.3%回答不知道，29.7%知道。</p>
			</div>
		</div>
	</section>	

	<section id="content08">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h4>地震發生時有沒有到空曠處避難</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf3" 
								num="3"
								
								checked={ this.state.valueType[3].name === 'pdf3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical3" 
								num="3"
								
								checked={this.state.valueType[3].name === 'Statistical3' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/地震發生時有沒有到空曠處避難.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '地震發生時有沒有到空曠處避難', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/地震發生時有沒有到空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '地震發生時有沒有到空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/地震發生時有沒有到空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '地震發生時有沒有到空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[3].name === 'pdf3' || valueType === null ? ' -active' : '')} statistics-charts chat04`}>
				<img src={require("../../../images/HualienEarthquake/chat04.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[3].name === 'Statistical3' ? ' -active' : '')} statistics-datatable`}>

				<table cellpadding="0" cellspacing="0">
					<tbody>
						<tr>
							<td colSpan="3"  valign="bottom">

								<p align="center" >地震發生時有沒有到空曠處避難</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p align="left" >項目</p>
							</td>
							<td  valign="bottom">

								<p align="right" >次數分配</p>
							</td>
							<td valign="bottom">

								<p align="right" >百分比</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p align="left" >有</p>
							</td>
							<td  valign="bottom">

								<p align="right" >499</p>
							</td>
							<td  valign="bottom">

								<p align="right" >24.4%</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p align="left" >沒有</p>
							</td>
							<td  valign="bottom">

								<p align="right" >1550</p>
							</td>
							<td valign="bottom">

								<p align="right" >75.6%</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p align="left" >總計</p>
							</td>
							<td  valign="bottom">

								<p align="right" >2048.99999999999</p>
							</td>
							<td valign="bottom">

								<p align="right" >100.0%</p>
							</td>
						</tr>
						<tr>
							<td colSpan="3"  valign="bottom">

								<p >
									<br />
								</p>
							</td>
						</tr>
						<tr>
							<td colSpan="3"  valign="bottom">

								<p >資料來源：國家災害防救科技中心</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>


	</section>	

	<section id="content08">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>是否知道住家附近的政府公告避難地點</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf4" 
								num="4"
								
								checked={ this.state.valueType[4].name === 'pdf4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical4" 
								num="4"
								
								checked={this.state.valueType[4].name === 'Statistical4' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點.xlsx")} onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點.xlsx")} onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			<div className={`${(valueType[4].name === 'pdf4' || valueType === null ? ' -active' : '')} statistics-charts chat05`}>
				<img src={require("../../../images/HualienEarthquake/chat05.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[4].name === 'Statistical4' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0">
					<tbody>
						<tr>
							<td colSpan="3"  valign="bottom">

								<p align="center" >是否知道住家附近的政府公告避難地點</p>
							</td>
						</tr>
						<tr>
							<td align="bottom">

								<p >項目</p>
							</td>
							<td valign="bottom">

								<p align="right" >次數</p>
							</td>
							<td  valign="bottom">

								<p align="right" >百分比</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p >知道</p>
							</td>
							<td valign="top">

								<p align="right" >609</p>
							</td>
							<td valign="top">

								<p align="right" >29.7%</p>
							</td>
						</tr>
						<tr>
							<td align="bottom">

								<p >不知道</p>
							</td>
							<td valign="top">

								<p align="right" >1440</p>
							</td>
							<td valign="top">

								<p align="right" >70.3%</p>
							</td>
						</tr>
						<tr>
							<td align="bottom">

								<p >總計</p>
							</td>
							<td valign="top">

								<p align="right" >2049</p>
							</td>
							<td valign="top">

								<p align="right" >100.0%</p>
							</td>
						</tr>
						<tr>
							<td colSpan="3" valign="bottom">

								<p >
									<br />
								</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">

								<p >資料來源：國家災害防救科技中心</p>
							</td>
							<td  valign="bottom">

								<p >
									<br />
								</p>
							</td>
							<td valign="bottom">

								<p > <br /></p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div className="container-c c640">
			<p>我們發現「知道」住家附近有政府公告避難地點的受訪者，在地震發生時去空曠處避難的比率（28.4%）較「不知道」的受訪者(22.6％)高。</p>
		</div>
	</section>

	<section id="content10">
		<div className="container-c c640">
			<div className="infon">
				<div className="title">
					<h4>是否知道住家附近的政府公告避難地點<br />VS<br />有沒有至空曠處避難</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf5" 
								num="5"
								
								checked={ this.state.valueType[5].name === 'pdf5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical5" 
								num="5"
								
								checked={this.state.valueType[5].name === 'Statistical5' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '是否知道住家附近的政府公告避難地點vs有沒有至空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			</div>
			<div className="container-c c886">
				<div className="pic chat06">
					
					<div className={`${(valueType[5].name === 'pdf5' || valueType === null ? ' -active' : '')} statistics-charts chat06`}>
						<img src={require("../../../images/HualienEarthquake/chat06.svg")} width="100%"/>
					</div>
				
					<div className={`${(valueType[5].name === 'Statistical5' ? ' -active' : '')} statistics-datatable`}>
						<table cellpadding="0">
							<tbody>
								<tr>
									<td colSpan="6" valign="bottom">

										<p align="center" >是否知道住家附近的政府公告避難地點vs有沒有到空曠處避難</p>
									</td>
								</tr>
								<tr>
									<td colSpan="3" rowSpan="2" valign="middle">
										<p >項目</p>
									</td>
									<td colSpan="2" valign="middle">

										<p align="center" >有沒有到空曠處避難</p>
									</td>
									<td rowSpan="2" valign="middle">

										<p align="right">總計</p>
									</td>
								</tr>
								<tr>
									<td valign="middle">

										<p align="right">有</p>
									</td>
									<td valign="middle">

										<p align="right">沒有</p>
									</td>
								</tr>
								<tr>
									<td rowSpan="4" valign="middle">

										<p >是否知道住家附近政府公告的避難地點(如防災公園)</p>
									</td>
									<td rowSpan="2" valign="middle">

										<p >知道</p>
									</td>
									<td  valign="middle">

										<p >次數分配</p>
									</td>
									<td  valign="middle">

										<p >173</p>
									</td>
									<td  valign="middle">

										<p >436</p>
									</td>
									<td  valign="middle">

										<p >609</p>
									</td>
								</tr>
								<tr>
									<td valign="middle">

										<p >百分比</p>
									</td>
									<td valign="middle">

										<p >28.4%</p>
									</td>
									<td valign="middle">

										<p >71.6%</p>
									</td>
									<td valign="middle">

										<p >100%</p>
									</td>
								</tr>
								<tr>
									<td rowSpan="2" valign="middle">

										<p >不知道</p>
									</td>
									<td  valign="middle">

										<p >次數分配</p>
									</td>
									<td  valign="middle">

										<p >326</p>
									</td>
									<td  valign="middle">

										<p >1114</p>
									</td>
									<td  valign="middle">

										<p >1440</p>
									</td>
								</tr>
								<tr>
									<td  valign="middle">

										<p >百分比</p>
									</td>
									<td  valign="middle">

										<p >22.6%</p>
									</td>
									<td  valign="middle">

										<p >77.4%</p>
									</td>
									<td  valign="middle">

										<p >100%</p>
									</td>
								</tr>
								<tr>
									<td colSpan="2" rowSpan="2" valign="middle">

										<p >總計</p>
									</td>
									<td  valign="middle">

										<p >次數分配</p>
									</td>
									<td  valign="middle">

										<p >
											<br />
										</p>
									</td>
									<td  valign="middle">

										<p >
											<br />
										</p>
									</td>
									<td  valign="middle">

										<p >2049</p>
									</td>
								</tr>
								<tr>
									<td valign="middle">

										<p >百分比</p>
									</td>
									<td valign="middle">

										<p >
											<br />
										</p>
									</td>
									<td valign="middle">

										<p >
											<br />
										</p>
									</td>
									<td valign="middle">

										<p >100%</p>
									</td>
								</tr>
								<tr>
									<td colSpan="6" valign="bottom">

										<p >
											<br />
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan="6" valign="bottom">

										<p >資料來源：國家災害防救科技中心</p>
									</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>
			<div className="container-c c640">
				<div className="pic pic03">
					<img src={require("../../../images/HualienEarthquake/pic03.gif")} width="100%"/>
				</div>
			</div>
	</section>	

	<section id="content11">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h3>政府防災教育及宣導狀況<br />讓我們一探究竟！</h3>
				</div>
				<p>如果說地震發生當下的臨震行為是人身安全的關鍵因素，那麼有沒有機會學習地震相關防災知識就很重要，究竟政府的地震防災宣導狀況如何呢？<br />
				調查結果顯示，有82.6%的受訪者表示沒有參加過政府地震防災宣導、教育或是相關地震應變演習活動，有15.4％的受訪者表示有參加過，所以相關宣導或教育推廣等，還有努力的空間。</p>
			</div>
		</div>
	</section>	

	<section id="content12">
		<div className="container-c c640">
			
			<div className="infon">
				<div className="title">
					<h4>有沒有參加過政府防災宣導、教育、演習</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf6" 
								num="6"
								
								checked={ this.state.valueType[6].name === 'pdf6' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical6" 
								num="6"
								
								checked={this.state.valueType[6].name === 'Statistical6' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/有沒有參加過政府防災宣導、教育、演習.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '有沒有參加過政府防災宣導、教育、演習', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/有沒有參加過政府防災宣導、教育、演習.xlsx")} onClick={() => gaTracking('花蓮地震', '有沒有參加過政府防災宣導、教育、演習', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/有沒有參加過政府防災宣導、教育、演習.xlsx")} onClick={() => gaTracking('花蓮地震', '有沒有參加過政府防災宣導、教育、演習', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[6].name === 'pdf6' || valueType === null ? ' -active' : '')} statistics-charts chat07`}>
				<img src={require("../../../images/HualienEarthquake/chat07.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[6].name === 'Statistical6' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0" >
					<tbody>
						<tr>
							<td colSpan="3" valign="bottom">

								<p align="center" >有沒有參加過政府防災宣導、教育、演習</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p >項目</p>
							</td>
							<td  valign="bottom">

								<p align="right" >次數分配</p>
							</td>
							<td  valign="bottom">

								<p align="right" >百分比</p>
							</td>
						</tr>
						<tr>
							<td  valign="bottom">

								<p >有</p>
							</td>
							<td valign="bottom">

								<p align="right" >316</p>
							</td>
							<td valign="bottom">

								<p align="right" >15.4%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">

								<p >沒有</p>
							</td>
							<td  valign="bottom">

								<p align="right" >1692</p>
							</td>
							<td  valign="bottom">

								<p align="right" >82.6%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">

								<p >不知道/很難說</p>
							</td>
							<td  valign="bottom">

								<p align="right" >41</p>
							</td>
							<td  valign="bottom">

								<p align="right" >2.0%</p>
							</td>
						</tr>
						<tr>
							<td valign="bottom">

								<p >總計</p>
							</td>
							<td  valign="bottom">

								<p align="right" >2049</p>
							</td>
							<td  valign="bottom">

								<p align="right" >100.0%</p>
							</td>
						</tr>
						<tr>
							<td colSpan="3" valign="bottom">

								<p >
									<br />
								</p>
							</td>
						</tr>
						<tr>
							<td colSpan="3" valign="bottom">

								<p >資料來源：國家災害防救科技中心</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>

	<section id="content13">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h3>參加政府演習或地震教育訓練<br />地震來了攏免驚！</h3>
				</div>
				<p>有參加過政府地震防災宣導、教育或演習的民眾超過一半(56.3%)知道住家附近的避難地點。其中有36.7%的民眾在地震發生時有到空曠處避難。沒參與政府地震防災宣導、教育或演習活動的民眾，僅有24.9%知道住家附近的避難地點，也僅有22.1%的民眾震後有到空曠處避難。</p>
			</div>
		</div>
	</section>

	<section id="content14">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>是否參加過政府防災宣導、教育、演習<br />VS<br />是否知道住家附近的政府公告避難地點</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf7" 
								num="7"
								
								checked={ this.state.valueType[7].name === 'pdf7' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical7" 
								num="7"
								
								checked={this.state.valueType[7].name === 'Statistical7' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點.xlsx")} onClick={() => gaTracking('花蓮地震', '是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點.xlsx")} onClick={() => gaTracking('花蓮地震', '是否參加政府防災宣導、教育、演習 vs 是否知道住家附近的政府公告避難地點', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c886">
	
			<div className={`${(valueType[7].name === 'pdf7' || valueType === null ? ' -active' : '')} statistics-charts chat08`}>
				<img src={require("../../../images/HualienEarthquake/chat08.gif")} width="100%"/>
			</div>
		
			<div className={`${(valueType[7].name === 'Statistical7' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="6"  valign="middle">

				<p align="center" >是否曾參加過政府演習或教育訓練 VS 是否知道住家附近的政府公告避難地點</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" rowSpan="2" valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3"  valign="bottom">

				<p align="center" >是否知道住家附近的政府公告避難地點</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p align="right" >知道</p>
			</td>
			<td  valign="bottom">

				<p align="right" >不知道</p>
			</td>
			<td valign="bottom">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="8"  valign="middle">

				<p >是否曾參加過政府演習或教育訓練</p>
			</td>
			<td rowSpan="2"  valign="middle">

				<p align="right">有參與</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p >178</p>
			</td>
			<td valign="bottom">

				<p >139</p>
			</td>
			<td valign="bottom">

				<p >317</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p >56.2%</p>
			</td>
			<td valign="bottom">

				<p >43.8%</p>
			</td>
			<td valign="bottom">

				<p >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >未參與</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p >417</p>
			</td>
			<td valign="bottom">

				<p >1275</p>
			</td>
			<td valign="bottom">

				<p >1692</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td  valign="bottom">

				<p >24.6%</p>
			</td>
			<td  valign="bottom">

				<p >75.4%</p>
			</td>
			<td valign="bottom">

				<p >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="middle">

				<p >不知道/很難說</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p >14</p>
			</td>
			<td valign="bottom">

				<p >26</p>
			</td>
			<td valign="bottom">

				<p >40</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p >35.0%</p>
			</td>
			<td valign="bottom">

				<p >65.0%</p>
			</td>
			<td valign="bottom">

				<p >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >總計</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p >609</p>
			</td>
			<td valign="bottom">

				<p >1440</p>
			</td>
			<td valign="bottom">

				<p >2049</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td  valign="bottom">

				<p >29.7%</p>
			</td>
			<td  valign="bottom">

				<p >70.3%</p>
			</td>
			<td valign="bottom">

				<p >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6" valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6"valign="middle">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
			
		</div>
	</section>

	<section id="content15">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>是否參加過政府防災宣導、教育、演習<br />VS<br />有沒有至空曠處避難</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf8" 
								num="8"
								
								checked={ this.state.valueType[8].name === 'pdf8' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical8" 
								num="8"
								
								checked={this.state.valueType[8].name === 'Statistical8' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '是否參加過政府防災宣導、教育、演習 vs 有沒有至空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			</div>
			<div className="container-c c886">

			<div className={`${(valueType[8].name === 'pdf8' || valueType === null ? ' -active' : '')} statistics-charts chat09`}>
				<img src={require("../../../images/HualienEarthquake/chat09.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[8].name === 'Statistical8' ? ' -active' : '')} statistics-datatable`}>
				<table cellpadding="0" cellspacing="0" >
		<tbody>
			<tr>
				<td colSpan="6" valign="bottom">

					<p align="center" >是否參加過政府防災宣導、教育、演習 VS 有沒有至空曠處避難</p>
				</td>
			</tr>
			<tr>
				<td colSpan="3" rowSpan="2" valign="middle">

					<p align="center" >項目</p>
				</td>
				<td colSpan="3" valign="bottom">

					<p align="center" >有沒有到空曠處避難</p>
				</td>
			</tr>
			<tr>
				<td valign="middle">

					<p align="right" >有</p>
				</td>
				<td valign="middle">

					<p align="right" >沒有</p>
				</td>
				<td valign="bottom">

					<p align="right" >總計</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="8"  valign="middle">

					<p >是否曾參加過政府演習或教育訓練</p>
				</td>
				<td rowSpan="2" valign="middle">

					<p >有參與</p>
				</td>
				<td  valign="middle">

					<p >次數分配</p>
				</td>
				<td  valign="bottom">

					<p >116</p>
				</td>
				<td valign="bottom">

					<p >200</p>
				</td>
				<td  valign="bottom">

					<p >316</p>
				</td>
			</tr>
			<tr>
				<td  valign="middle">

					<p >百分比</p>
				</td>
				<td  valign="bottom">

					<p >36.7%</p>
				</td>
				<td valign="bottom">

					<p >63.3%</p>
				</td>
				<td  valign="bottom">

					<p >100.0%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2"  valign="middle">

					<p >未參與</p>
				</td>
				<td  valign="middle">

					<p >次數分配</p>
				</td>
				<td  valign="bottom">

					<p >376</p>
				</td>
				<td valign="bottom">

					<p >1316</p>
				</td>
				<td  valign="bottom">

					<p >1692</p>
				</td>
			</tr>
			<tr>
				<td valign="middle">

					<p >百分比</p>
				</td>
				<td valign="bottom">

					<p >22.2%</p>
				</td>
				<td valign="bottom">

					<p >77.8%</p>
				</td>
				<td valign="bottom">

					<p >100.0%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2" valign="middle">

					<p >不知道/很難說</p>
				</td>
				<td  valign="middle">

					<p >次數分配</p>
				</td>
				<td  valign="bottom">

					<p >7</p>
				</td>
				<td valign="bottom">

					<p >34</p>
				</td>
				<td  valign="bottom">

					<p >41</p>
				</td>
			</tr>
			<tr>
				<td  valign="middle">

					<p >百分比</p>
				</td>
				<td  valign="bottom">

					<p >15.0%</p>
				</td>
				<td valign="bottom">

					<p >85.0%</p>
				</td>
				<td  valign="bottom">

					<p >100.0%</p>
				</td>
			</tr>
			<tr>
				<td rowSpan="2"  valign="middle">

					<p >總計</p>
				</td>
				<td  valign="middle">

					<p >次數分配</p>
				</td>
				<td  valign="bottom">

					<p >499</p>
				</td>
				<td valign="bottom">

					<p >1550</p>
				</td>
				<td  valign="bottom">

					<p >2049</p>
				</td>
			</tr>
			<tr>
				<td valign="middle">

					<p >百分比</p>
				</td>
				<td valign="bottom">

					<p >24.4%</p>
				</td>
				<td valign="bottom">

					<p >75.6%</p>
				</td>
				<td valign="bottom">

					<p >100.0%</p>
				</td>
			</tr>
			<tr>
				<td colSpan="6" valign="bottom">

					<p >
						<br />
					</p>
				</td>
			</tr>
			<tr>
				<td colSpan="6"  valign="bottom">

					<p >資料來源：國家災害防救科技中心</p>
				</td>
			</tr>
		</tbody>
				</table>
			
			</div>
		</div>
	</section>

	<section id="content16">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h3>防災演習「動起來」！</h3>
				</div>
				<p>你通常是從什麼地方得知地震防災知識的呢？調查中發現，去空曠處避難的民眾，多數透過參與演習（55%）或者實體課程（34.3%）來獲取防災知識，由此可見身體力行的學習效果看得見！</p>
			</div>
		</div>
	</section>

	<section id="content16">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>震前防災教育管道<br />
						VS<br />
						地震發生時有沒有到空曠處避難
					</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf9" 
								num="9"
								
								checked={ this.state.valueType[9].name === 'pdf9' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical9" 
								num="9"
								
								checked={this.state.valueType[9].name === 'Statistical9' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/震前防災教育管道 vs 地震發生時有沒有到空曠處避難.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '震前防災教育管道 vs 地震發生時有沒有到空曠處避難', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/震前防災教育管道 vs 地震發生時有沒有到空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '震前防災教育管道 vs 地震發生時有沒有到空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/震前防災教育管道 vs 地震發生時有沒有到空曠處避難.xlsx")} onClick={() => gaTracking('花蓮地震', '震前防災教育管道 vs 地震發生時有沒有到空曠處避難', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>

			<div className={`${(valueType[9].name === 'pdf9' || valueType === null ? ' -active' : '')} statistics-charts chat10`}>
				<img src={require("../../../images/HualienEarthquake/chat10.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[9].name === 'Statistical9' ? ' -active' : '')} statistics-datatable`}>
				
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="6"  valign="bottom">

				<p align="center" >震前防災教育管道 VS 地震發生時有沒有到空曠處避難</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" rowSpan="2"  valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3" valign="middle">

				<p align="right" >地震發生時有沒有到空曠處避難</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >有</p>
			</td>
			<td  valign="middle">

				<p align="right" >沒有</p>
			</td>
			<td  valign="middle">

				<p align="right" >總計</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="20" valign="middle">

				<p align="center" >震</p>

				<p align="center" >前</p>

				<p align="center" >防</p>

				<p align="center" >災</p>

				<p align="center" >教</p>

				<p align="center" >育</p>

				<p align="center" >管</p>

				<p align="center" >道</p>
			</td>
			<td rowSpan="2" valign="middle">

				<p >演習</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >12</p>
			</td>
			<td  valign="middle">

				<p align="right" >10</p>
			</td>
			<td  valign="middle">

				<p align="right" >22</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >55.0%</p>
			</td>
			<td  valign="middle">

				<p align="right" >45.0%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >實體課程（學校、社區大學）</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >113</p>
			</td>
			<td  valign="middle">

				<p align="right" >215</p>
			</td>
			<td  valign="middle">

				<p align="right" >328</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >34.4%</p>
			</td>
			<td  valign="middle">

				<p align="right" >65.6%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >平面媒體</p>

				<p >（報章雜誌）</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >62</p>
			</td>
			<td  valign="middle">

				<p align="right" >154</p>
			</td>
			<td  valign="middle">

				<p align="right" >216</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >28.7%</p>
			</td>
			<td  valign="middle">

				<p align="right" >71.3%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >網路</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >222</p>
			</td>
			<td  valign="middle">

				<p align="right" >665</p>
			</td>
			<td  valign="middle">

				<p align="right" >887</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >25.1%</p>
			</td>
			<td  valign="middle">

				<p align="right" >74.9%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >公部門宣導</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >6</p>
			</td>
			<td  valign="middle">

				<p align="right" >19</p>
			</td>
			<td  valign="middle">

				<p align="right" >25</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >25.1%</p>
			</td>
			<td  valign="middle">

				<p align="right" >74.9%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >電視和廣播</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >337</p>
			</td>
			<td  valign="middle">

				<p align="right" >1055</p>
			</td>
			<td  valign="middle">

				<p align="right" >1392</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >24.2%</p>
			</td>
			<td  valign="middle">

				<p align="right" >75.8%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >親戚/朋友/鄰居/同事/志工團體告知</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >19</p>
			</td>
			<td  valign="middle">

				<p align="right" >61</p>
			</td>
			<td  valign="middle">

				<p align="right" >80</p>
			</td>
		</tr>
		<tr>
			<td valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >23.7%</p>
			</td>
			<td valign="middle">

				<p align="right" >76.3%</p>
			</td>
			<td valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >無</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >14</p>
			</td>
			<td  valign="middle">

				<p align="right" >59</p>
			</td>
			<td  valign="middle">

				<p align="right" >73</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >19.3%</p>
			</td>
			<td  valign="middle">

				<p align="right" >80.7%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >不知道/很難說</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >31</p>
			</td>
			<td  valign="middle">

				<p align="right" >142</p>
			</td>
			<td  valign="middle">

				<p align="right" >172</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >17.9%</p>
			</td>
			<td  valign="middle">

				<p align="right" >82.1%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="middle">

				<p >其他</p>
			</td>
			<td  valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td valign="middle">

				<p align="right" >2&nbsp;</p>
			</td>
			<td  valign="middle">

				<p align="right" >5&nbsp;</p>
			</td>
			<td  valign="middle">

				<p align="right" >6&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td  valign="middle">

				<p align="right" >百分比</p>
			</td>
			<td valign="middle">

				<p align="right" >25.6%</p>
			</td>
			<td  valign="middle">

				<p align="right" >74.4%</p>
			</td>
			<td  valign="middle">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6" valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6"  valign="bottom">

				<p >說明：取得防災教育管道為複選題</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6"  valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
	</section>
	
	<hr></hr>

	<section id="content17">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h2>震後大解析！< br/>沒有準備防災包的原因是這些</h2>
				</div>
				<p>當較大的地震發生後，若住家不安全可前往住家附近的公告避難所或空曠處避難，平時如果有準備好防災包，就能發揮快速拿取重要物品及離開危險現場的關鍵作用。我們以地震前後作為分界點，看看地震實際發生後，受訪者會更有意願準備防災包嗎？<br /><br />
				   調查結果發現，地震前有準備防災包的受訪者，在地震後還是有85.2%有準備防災包，不過令人好奇的是，有14.8%的受訪者反而在地震後沒有準備，是什麼原因讓他們沒有延續常備防災包的習慣呢？
				</p>
			</div>
		</div>
	</section>


	<section id="content18">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>震前有防災包的民眾在震後有無防災包</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf10" 
								num="10"
								
								checked={ this.state.valueType[10].name === 'pdf10' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical10" 
								num="10"
								
								checked={this.state.valueType[10].name === 'Statistical10' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/震前有防災包的民眾在震後有無防災包.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '震前有防災包的民眾在震後有無防災包', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/震前有防災包的民眾在震後有無防災包.xlsx")} onClick={() => gaTracking('花蓮地震', '震前有防災包的民眾在震後有無防災包', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/震前有防災包的民眾在震後有無防災包.xlsx")} onClick={() => gaTracking('花蓮地震', '震前有防災包的民眾在震後有無防災包', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[10].name === 'pdf10' || valueType === null ? ' -active' : '')} statistics-charts chat11`}>
				<img src={require("../../../images/HualienEarthquake/chat11.gif")} width="100%"/>
			</div>
		
			<div className={`${(valueType[10].name === 'Statistical10' ? ' -active' : '')} statistics-datatable`}>
				
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p align="center" >震前有防災包民眾在震後有無防災包</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >項目</p>
			</td>
			<td  valign="bottom">

				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >有</p>
			</td>
			<td valign="bottom">

				<p >616</p>
			</td>
			<td  valign="bottom">

				<p >85.1%</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >沒有</p>
			</td>
			<td  valign="bottom">

				<p >108</p>
			</td>
			<td valign="bottom">

				<p >14.9%</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >總計</p>
			</td>
			<td  valign="bottom">

				<p >723</p>
			</td>
			<td valign="bottom">

				<p >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon" >
				<p>進一步詢問箇中原因，發現有27.8%的人表示「怕內容物過期」，其次為「覺得不會再有地震」（13%）、「覺得不重要或沒用」（6.5%）。建議每個人家中都應該常備防災包，並每半年檢查一次防災包的內容物，尤其是像食品、藥品這類保存期限較短的物品要留意效期並更換。並將防災包放置在家中大門口或是玄關等方便取得處，以便在危急時刻可以立即取得，及時逃生。</p>
			</div>
		</div>
	</section>

	<section id="content19">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>震前有準備，現在沒有準備的原因</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf11" 
								num="11"
								
								checked={ this.state.valueType[11].name === 'pdf11' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical11" 
								num="11"
								
								checked={this.state.valueType[11].name === 'Statistical11' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/震前有準備，現在沒有準備的原因.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '震前有準備，現在沒有準備的原因', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/震前有準備，現在沒有準備的原因.xlsx")} onClick={() => gaTracking('花蓮地震', '震前有準備，現在沒有準備的原因', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/震前有準備，現在沒有準備的原因.xlsx")} onClick={() => gaTracking('花蓮地震', '震前有準備，現在沒有準備的原因', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>

			<div className={`${(valueType[11].name === 'pdf11' || valueType === null ? ' -active' : '')} statistics-charts chat12`}>
				<img src={require("../../../images/HualienEarthquake/chat12.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[11].name === 'Statistical11' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="4" valign="bottom">

				<p align="center" >震前有準備，現在沒有準備的原因</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >項目</p>
			</td>
			<td valign="middle">

				<p align="right" >次數分配</p>
			</td>
			<td  valign="bottom">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >不知道/很難說</p>
			</td>
			<td valign="middle">

				<p align="right" >44</p>
			</td>
			<td valign="bottom">

				<p align="right" >41.1%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >怕內容物放到過期</p>
			</td>
			<td valign="middle">

				<p align="right" >30</p>
			</td>
			<td  valign="bottom">

				<p align="right" >28.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >覺得不會再有地震了</p>
			</td>
			<td valign="middle">

				<p align="right" >13</p>
			</td>
			<td  valign="bottom">

				<p align="right" >12.4%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >其他</p>
			</td>
			<td valign="middle">

				<p align="right" >10</p>
			</td>
			<td  valign="bottom">

				<p align="right" >9.1%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >覺得災害不嚴重，不需要準備</p>
			</td>
			<td valign="middle">

				<p align="right" >7</p>
			</td>
			<td valign="bottom">

				<p align="right" >6.8%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >忙碌/沒時間準備</p>
			</td>
			<td valign="middle">

				<p align="right" >3</p>
			</td>
			<td  valign="bottom">

				<p align="right" >3.1%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="2" valign="bottom">

				<p >總計</p>
			</td>
			<td valign="middle">

				<p align="right" >109</p>
			</td>
			<td  valign="bottom">

				<p align="right" >100.6%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4"  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4" valign="bottom">

				<p >有效樣本數：108（此為複選題）</p>
			</td>
		</tr>
		<tr>
			<td colSpan="4" valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>
		</div>
	</section>

	<section id="content20">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h3>降低震後風險就靠「它」</h3>
				</div>
				<p>地震後會對民眾的生活造成不小的衝擊，特別是臺灣處於地震頻繁的斷層帶，地震保險就有其必要性，政府為了讓民眾面臨地震災害後，可以在最短的時間內獲得經濟支援，自民國91年4月1號起便實施「住宅地震保險制度」，協助民眾分散風險與有助於籌措重建經費來降低災害損失。
					<br /><br />
					此外，此種保險制度是規定購買住宅火險就一定要加購地震基本險，也就是說，地震基本險跟火險一樣，只要是貸款購屋的話，基本上銀行都會強制投保，以免房屋有災害損失時，貸款人無力償還。
					<br /><br />
					從本次針對民眾對地震保險的投保狀況做的調查結果顯示，一直都沒有投保的民眾還是占多數(78.8%)；有16.3％的民眾有投保地震險（14.7%是地震前已投保，1.6%是地震後才投保），不過這也可能與房屋貸款有關，只有房貸族會被銀行強制投保地震險，可推測花蓮地區的房屋貸款人口比例不高有關聯。
				</p>
			</div>
		</div>
	</section>

	<section id="content21">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>是否有投保地震保險</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf12" 
								num="12"
								
								checked={ this.state.valueType[12].name === 'pdf12' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical12" 
								num="12"
								
								checked={this.state.valueType[12].name === 'Statistical12' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/是否有投保地震保險.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '是否有投保地震保險', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/是否有投保地震保險.xlsx")} onClick={() => gaTracking('花蓮地震', '是否有投保地震保險', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/是否有投保地震保險.xlsx")} onClick={() => gaTracking('花蓮地震', '是否有投保地震保險', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[12].name === 'pdf12' || valueType === null ? ' -active' : '')} statistics-charts chat13`}>
				<img src={require("../../../images/HualienEarthquake/chat13.gif")} width="100%"/>
			</div>
		
			<div className={`${(valueType[12].name === 'Statistical12' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p align="center" >是否有投保地震險</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >項目</p>
			</td>
			<td valign="bottom">

				<p align="right" >次數分配</p>
			</td>
			<td  valign="bottom">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >有投保</p>
			</td>
			<td valign="bottom">

				<p align="right" >335</p>
			</td>
			<td  valign="bottom">

				<p align="right" >16.3%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >一直沒有投保</p>
			</td>
			<td valign="bottom">

				<p align="right" >1614</p>
			</td>
			<td  valign="bottom">

				<p align="right" >78.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >不知道/很難說</p>
			</td>
			<td valign="bottom">

				<p align="right" >100</p>
			</td>
			<td  valign="bottom">

				<p align="right" >4.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >總計</p>
			</td>
			<td valign="bottom">

				<p align="right" >2048.99999999999</p>
			</td>
			<td  valign="bottom">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >說明：</p>

				<p ><strong>有投保</strong>（地震前已投保、地震後才投保）</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			
			</div>
		</div>
	</section>
	
	<hr></hr>

	<section id="content22">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h2>狡兔總有三窟，<br />地震過後，你想過可以去哪嗎？</h2>
				</div>
				<p>臺灣地震頻繁，對於地震的發生更不該掉以輕心，如果把地震防範的話題加入日常生活中，是否就能提升對地震的警覺心跟應變措施呢？調查中詢問民眾在0206地震發生的前一年內，是否有和家人討論到地震災害的避難方法，結果顯示，有8成的人都沒有跟家人聊過這類的話題。</p>
			</div>
		</div>
	</section>

	<section id="content23">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>在地震發生的前一年<br />有沒有和家人討論地震災害的避難方法</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf13" 
								num="13"
								
								checked={ this.state.valueType[13].name === 'pdf13' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical13" 
								num="13"
								
								checked={this.state.valueType[13].name === 'Statistical13' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/在地震發生的前一年 有沒有和家人討論地震災害的避難方法.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '在地震發生的前一年 有沒有和家人討論地震災害的避難方法', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/在地震發生的前一年 有沒有和家人討論地震災害的避難方法.xlsx")} onClick={() => gaTracking('花蓮地震', '在地震發生的前一年 有沒有和家人討論地震災害的避難方法', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/在地震發生的前一年 有沒有和家人討論地震災害的避難方法.xlsx")} onClick={() => gaTracking('花蓮地震', '在地震發生的前一年 有沒有和家人討論地震災害的避難方法', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c886">
			
			<div className={`${(valueType[13].name === 'pdf13' || valueType === null ? ' -active' : '')} statistics-charts chat14`}>
				<img src={require("../../../images/HualienEarthquake/chat14.gif")} width="100%"/>
			</div>
		
			<div className={`${(valueType[13].name === 'Statistical13' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="3" valign="middle">

				<p >在地震發生的前一年有沒有和家人討論地震災害的避難方法</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >項目</p>
			</td>
			<td valign="bottom">

				<p align="right" >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >沒有討論</p>
			</td>
			<td valign="bottom">

				<p >1640</p>
			</td>
			<td valign="bottom">

				<p align="right" >80.0%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >有討論</p>
			</td>
			<td valign="bottom">

				<p >325</p>
			</td>
			<td valign="bottom">

				<p align="right" >15.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >不知道/很難說</p>
			</td>
			<td valign="bottom">

				<p >84</p>
			</td>
			<td valign="bottom">

				<p align="right" >4.1%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >總計</p>
			</td>
			<td valign="bottom">

				<p >2049</p>
			</td>
			<td valign="bottom">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>
		</div>
		<div className="container-c c640">
			<div className="infon" >
				<p>除此之外，地震後除了避難逃生，如果住家暫時無法返回居住，是否想過可以暫住在哪？要去親友家、旅館還是有公共避難場所？調查結果顯示，有63.6%的人第一時間選擇投靠親友，其次則有14.5%的民眾選擇前往自有其他處所、付費旅館、車上等（14.5%）。</p>
			</div>
		</div>
	</section>
    

	<section id="content22">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>地震後曾去那些地方暫住</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf14" 
								num="14"
								
								checked={ this.state.valueType[14].name === 'pdf14' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical14" 
								num="14"
								
								checked={this.state.valueType[14].name === 'Statistical14' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/地震後曾去哪些地方暫住.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '地震後曾去哪些地方暫住', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/地震後曾去哪些地方暫住.xlsx")} onClick={() => gaTracking('花蓮地震', '地震後曾去哪些地方暫住', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/1地震後曾去哪些地方暫住.xlsx")} onClick={() => gaTracking('花蓮地震', '地震後曾去哪些地方暫住', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			
			<div className={`${(valueType[14].name === 'pdf14' || valueType === null ? ' -active' : '')} statistics-charts chat15`}>
				<img src={require("../../../images/HualienEarthquake/chat15.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[14].name === 'Statistical14' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="3"  valign="middle">

				<p align="center" >地震後曾去哪些地方暫住</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >項目</p>
			</td>
			<td  valign="bottom">

				<p align="right" >次數分配</p>
			</td>
			<td  valign="bottom">

				<p align="right" >百分比</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >親友家</p>
			</td>
			<td  valign="bottom">

				<p align="right" >35</p>
			</td>
			<td  valign="bottom">

				<p align="right" >62.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >公部門開設處所</p>
			</td>
			<td  valign="bottom">

				<p align="right" >5</p>
			</td>
			<td  valign="bottom">

				<p align="right" >9.3%</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >其他</p>
			</td>
			<td valign="bottom">

				<p align="right" >5</p>
			</td>
			<td valign="bottom">

				<p align="right" >8.8%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >免費旅館</p>
			</td>
			<td  valign="bottom">

				<p align="right" >4</p>
			</td>
			<td  valign="bottom">

				<p align="right" >7.9%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >宗教場所</p>
			</td>
			<td  valign="bottom">

				<p align="right" >4</p>
			</td>
			<td  valign="bottom">

				<p align="right" >6.5%</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >不知道/很難說</p>
			</td>
			<td  valign="bottom">

				<p align="right" >3</p>
			</td>
			<td  valign="bottom">

				<p align="right" >4.7%</p>
			</td>
		</tr>
		<tr>
			<td  valign="bottom">

				<p >總計</p>
			</td>
			<td valign="bottom">

				<p align="right" >55</p>
			</td>
			<td valign="bottom">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3"  valign="bottom">

				<p >說明：</p>

				<p >1. 此題為複選題</p>

				<p >2. <strong>公部門開設處所：</strong>如里民活動中心、學校、體育館、軍營等。<strong>其他：</strong>如自有其他住處、付費旅館、車上等。</p>
			</td>
		</tr>
	</tbody>
</table>
			</div>
		</div>
		<div className="container-c c886">
			<blockquote>
				<p>
					<b>公部門開設處所：</b>如里民活動中心，學校，體育館，軍營等。<br />
					<b>其他：</b>如自有其他住處，付費旅館，車上等。
				</p>
			</blockquote>
		</div>
		<div className="container-c c640">
			<p>根據調查結果，沒有與家人討論過避難方法的受訪者，選擇去親友家的比率約70%；相較之下，有與家人討論過的受訪者，去親友家的比率反而略低，為35%，顯示當沒有事前準備時，親友家常是第一時間能夠仰賴的對象，但是事前有討論過時，前往其他避難處所的可能性變得比較多元。在地震發生後，共住家人常希望一起避難，如果平時有討論地震災害發生時的避難逃生應變策略，就能減少當下的慌亂，也能為自己多準備一些選擇，狡兔有三窟並非難事。</p>
		</div>
	</section>

	<section id="content23">
		<div className="container-c c640">
			<div className="infon" >
				<div className="title">
					<h4>地震發生前有沒有和家人討論避難方法< br/>
						VS< br/>
						地震後曾去哪些地方暫住
					</h4>
				</div>
				<div className="chart-block">
					<div className="option chart-option">

						<div className="radiobar">
							<CustomRadio className="radio" color="yellow" 
								group="pdf15" 
								num="15"
								
								checked={ this.state.valueType[15].name === 'pdf15' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-text">圖表</span>
								
							</CustomRadio>
							<CustomRadio className="radio" color="yellow" 
								group="Statistical15" 
								num="15"
								
								checked={this.state.valueType[15].name === 'Statistical15' ? true : false}
								onClick={ (e) => { this.handleClickRidio(e) } }>
								<span className="text type-tex">數據</span>
								
							</CustomRadio>
							
						</div>
						<div className="custom-dropdown download">
							<CustomDropdown title="下載">
								<li><Link to={require("../../../images/HualienEarthquake/download/地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住.png")} target="_blank" onClick={() => gaTracking('花蓮地震', '地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住', '圖表下載')} download>下載PNG</Link></li>
								<li><Link to={require("../../../images/HualienEarthquake/download/地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住.xlsx")} onClick={() => gaTracking('花蓮地震', '地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li>
								
								{/* { isMemberLogin && <li><Link to={require("../../../images/HualienEarthquake/download/地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住.xlsx")} onClick={() => gaTracking('花蓮地震', '地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住', '原始數據下載')} target="_blank" download>下載EXCEL</Link></li> }
								{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載EXCEL</button></li> } */}
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container-c c640">
			
			<div className={`${(valueType[15].name === 'pdf15' || valueType === null ? ' -active' : '')} statistics-charts chat16`}>
				<img src={require("../../../images/HualienEarthquake/chat16.svg")} width="100%"/>
			</div>
		
			<div className={`${(valueType[15].name === 'Statistical15' ? ' -active' : '')} statistics-datatable`}>
			<table cellpadding="0" cellspacing="0" >
	<tbody>
		<tr>
			<td colSpan="6"  valign="middle">

				<p align="center" >&nbsp;地震發生前有沒有和家人討論避難方法 vs 地震後曾去哪些地方暫住</p>
			</td>
		</tr>
		<tr>
			<td colSpan="3" rowSpan="2"  valign="middle">

				<p align="center" >項目</p>
			</td>
			<td colSpan="3"  valign="middle">

				<p align="center" >有沒有和家人討論地震災害避難方法經驗</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p align="right" >有討論</p>
			</td>
			<td  valign="bottom">

				<p align="right" >無討論</p>
			</td>
			<td  valign="bottom">

				<p align="right" >無明確意見</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="14"  valign="middle">

				<p >曾去哪些地方暫住</p>
			</td>
			<td rowSpan="2"  valign="bottom">

				<p >親友家</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >7</p>
			</td>
			<td valign="bottom">

				<p align="right" >25</p>
			</td>
			<td  valign="bottom">

				<p align="right" >2</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >41.2%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >67.6%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >100.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="bottom">

				<p >公部門開設處所</p>
			</td>
			<td valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >4</p>
			</td>
			<td  valign="bottom">

				<p align="right" >2</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >23.5%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >5.4%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="bottom">

				<p >其他</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >0</p>
			</td>
			<td valign="bottom">

				<p align="right" >5</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >0.0%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >13.5%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="bottom">

				<p >免費旅館</p>
			</td>
			<td valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >0</p>
			</td>
			<td  valign="bottom">

				<p align="right" >4</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >0.0%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >10.8%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="bottom">

				<p >宗教場所</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >4</p>
			</td>
			<td valign="bottom">

				<p align="right" >0</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >23.5%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2" valign="bottom">

				<p >不知道/很難說</p>
			</td>
			<td valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >2</p>
			</td>
			<td  valign="bottom">

				<p align="right" >1</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >11.8%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >2.7%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >0.0%</p>
			</td>
		</tr>
		<tr>
			<td rowSpan="2"  valign="bottom">

				<p >總計</p>
			</td>
			<td  valign="bottom">

				<p >次數分配</p>
			</td>
			<td valign="bottom">

				<p align="right" >17</p>
			</td>
			<td valign="bottom">

				<p align="right" >37</p>
			</td>
			<td  valign="bottom">

				<p align="right" >2</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >百分比</p>
			</td>
			<td valign="bottom">

				<p align="right" >100%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >100%</p>
			</td>
			<td  valign="bottom">

				<p align="right" >100%</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6"  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6" valign="bottom">

				<p >說明:&nbsp;</p>

				<p >公部門開設處所（里民中心、體育館、學校、軍營等）</p>

				<p >其他（付費旅館、其他住宅、車上等）</p>
			</td>
		</tr>
		<tr>
			<td valign="bottom">

				<p >
					<br />
				</p>
			</td>
			<td  valign="bottom">

				<p >
					<br />
				</p>
			</td>
			<td valign="bottom">

				<p >
					<br />
				</p>
			</td>
			<td  valign="bottom">

				<p >
					<br />
				</p>
			</td>
			<td  valign="bottom">

				<p >
					<br />
				</p>
			</td>
			<td  valign="bottom">

				<p >
					<br />
				</p>
			</td>
		</tr>
		<tr>
			<td colSpan="6" valign="bottom">

				<p >資料來源：國家災害防救科技中心</p>
			</td>
		</tr>
	</tbody>
</table>
				</div>
		</div>
	</section>

	<section id="content24">
		<div className="container-c c886">
			<blockquote>
				<div className="title">
					<h3>0206花蓮地震調查說明</h3>
				</div>
				<p>由於目前仍難以做到地震的精準預測，更應理解民眾在面對地震時會有什麼樣的避難行為和反應；因此，國家災害防救科技中心於地震災後6個月，利用電話訪問的方式進行調查（調查範圍是以 0206 花蓮地震時，轄內面積超過百分之五十屬於震度五級以上的鄉鎮，包含羅東鎮、冬山鄉、蘇澳鎮、大同鄉、南澳鄉，花蓮縣花蓮市、新城鄉、吉安鄉、壽豐鄉、秀林鄉等 10 個鄉鎮）。由趨勢民意調查股份有限公司執行，調查時間為107年8月，以地震發生時位於調查範圍內並年滿 18 歲以上的民眾做為調查對象，在信賴度 95% 下完成共 2,049 個有效樣本，誤差不超過正負 2.2 的百分點，調查項目區分為地震災害知識與認知、臨震與避難行為、保險意願、受訪者的基本資料等四個面向。</p>
			</blockquote>
		</div>
		

	</section>
	
				
	<section className="svi-section svi-articlelist">
		<div className="container">
			<h2 className="sectiontitle">你可能會想看</h2>
		
			<div className="row subject-list">
				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/TainanEarthquake">
							<img src={require("../../../images/TainanEarthquake/1201.jpg")} width="100%"/>
							
						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/112">2022.11.08</a> */}
							<a className="title" href="/statistics/TainanEarthquake">0206臺南震災天然災害社經影響家戶調查(2016)</a>
							{/* <div className="author"><label>作者 </label> 林美君/NCDR </div>
							<a className="summary" href="/evaluation/svi/article/112">社會脆弱度評估系統微整型</a> */}
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="article-item">
						<a className="image -black" href="/statistics/AnalysisEarthquakeSurvey">
							<img src={require("../../../images/AnalysisEarthquakeSurvey/mainpic.jpg")} width="100%"/>
							
						</a>
						<div className="content">
							{/* <a className="datemonth type-heading -en -lg" href="/evaluation/svi/article/112">2022.11.08</a> */}
							<a className="title" href="/statistics/AnalysisEarthquakeSurvey">同一天不同地點，臺南地震 VS 花蓮地震 調查大解析</a>
							{/* <div className="author"><label>作者 </label> 林美君/NCDR </div>
							<a className="summary" href="/evaluation/svi/article/112">社會脆弱度評估系統微整型</a> */}
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
		
	<section className="into">
		<div className="container">
			<div className="title">
				<h3>資料來源</h3>
				<h6>0206花蓮地震：地震感知與避難行為調查 （2018）</h6>
			</div>
            
            <ul>
				<li>
					
					<div className="link">
						<a href="https://easy2do.ncdr.nat.gov.tw/easy2do/images/ncdr/socialsurvey/NCDR108T15.pdf" target="_blank">調查報告下載	</a>
						
					</div>
				</li>
				<li>
					
					<div className="link">
						<a href="https://datahub.ncdr.nat.gov.tw/dataset?q=0206%E8%8A%B1%E8%93%AE%E5%9C%B0%E9%9C%87" target="_blank"> 調查資料申請</a>
						
					</div>
				</li>
            </ul>
        </div>
    </section>
				
	</div>
		);
	}
}

export default HualienEarthquakeIndex;
