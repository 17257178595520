import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

import LinksSlider from '../../utils/LinksSlider';
import {apiHost} from '../../utils/Shared/constant';

class RelatedLinks extends Component {
    constructor(params) {
        super();
        this.state = {
            linkItems: [],
        };
    }
    componentDidMount() {
        axios.get(`${apiHost}/api/Content/RelatedLink?data.subjectType=0&data.positionType=2`)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        linkItems: res.data.Result ? res.data.Result : [],
                        loading: false,
                    })
                }
            })
    }
    render() {
        return (
            <LinksSlider className="homepage-links" data={this.state.linkItems} theme="yellow"></LinksSlider>
        );
    }
}

export default RelatedLinks;
