import React, { Component } from 'react';
import './index.scss';
import SvgZoomMap from '../../utils/SvgZoomMap';
import LoadingDots from '../../utils/LoadingDots';

class EvacuationShelterMap extends Component {
	constructor(params) {
		super(params);
		this.mapWrap = React.createRef();
		this.state = {
			isMapShow: false,
			isBubbleShow: false,
			bubble: {
				name: null,
				avarge: null,
				sum: null,
				event: null,
				count: null
			},
			bubbleTop: 0,
			bubbleLeft: 0,
			county: '',
			town: '',
			village: '',
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props) {
			this.handleGetShelterLocate();
			if (prevProps.shelters) {
				if (prevProps.shelters.county !== this.props.shelters.county || 
						prevProps.shelters.town !== this.props.shelters.town || 
						prevProps.shelters.village !== this.props.shelters.village ) {
							this.handleCloseBubble();
						}
			}
		}
	}
	handleGetShelterLocate() {
		const { shelters, isShelterMark } = this.props;
		if (shelters) {
			const sheltersLocate = shelters.data.map((shelter, i) => {
				return {
					id: i,
					title: shelter['shelter_name'],
					active: shelter.active,
					location: [Number(shelter['latitude']), Number(shelter['longitude'])]
				}
			})
			this.setState({
				// isBubbleShow: false,
        isBubbleShow: isShelterMark,
				sheltersLocate: sheltersLocate,
				county: shelters.county,
				town: shelters.town,
				village: shelters.village,
			},
			// () => this.handleGetShelterDomPosition() 
			);
		}
	}
	// handleGetShelterDomPosition() {
	// 	const { sheltersLocate } = this.state;
	// 	const pinDom = document.querySelector('.pin.-active');
	// 	if (pinDom) {
	// 		const { shelters } = this.props;
	// 		const index = sheltersLocate.findIndex(shelter => shelter.active);
	// 		const posX = pinDom.getBoundingClientRect().left;
	// 		const posY = pinDom.getBoundingClientRect().top;

	// 		this.setState({
	// 			isBubbleShow: true,
	// 			bubble: shelters.data[index],
	// 		}, () => this.handlePinPosition(posX, posY));
	// 	}
	// }
	handlePinClick = (pin, e) => {
		const index = pin.id;
		const clientX = e.clientX;
		const clientY = e.clientY;
		this.props.handlePinClick(pin);
		
		const { shelters } = this.props;
		this.handlePinPosition(shelters.data[index], clientX, clientY);
	}
	handlePinPosition = (data, clientX, clientY) => {
		const mapWrap = this.mapWrap.current;
		const boxWidth = 350;
		const boxHeight = 450;
		const posX = clientX - mapWrap.getBoundingClientRect().x;
		const posY = clientY - mapWrap.getBoundingClientRect().y;
	
		this.setState({
			isBubbleShow: true,
			bubble: data,
			bubbleTop: posY,
			bubbleLeft: posX,
			bubbleOppositeX: (clientX + boxWidth) > window.innerWidth,
			bubbleOppositeY: (window.scrollY + clientY + boxHeight) > document.body.offsetHeight
		})
	}
	handleCloseBubble = () => {
		this.setState({ isBubbleShow: false })
	}
	transferValue(value) {
		return (value === '' || value === 0) ? '-' : value;
	}
	render() {
		const { isMemberLogin } = this.props;
		const { sheltersLocate } = this.state;
		const { isMapShow, isBubbleShow, bubble, bubbleTop, bubbleLeft, bubbleOppositeX, bubbleOppositeY } = this.state;
		const { county, town, village } = this.state;
		return (
			<div className="shelter-map" ref={this.mapWrap}>
				<div className={`bubble-info ${isBubbleShow ? '-show': ''}`}
				style={{
					'position': 'absolute',
					'top': `${bubbleTop}px`,
					'left': `${bubbleLeft}px`,
					'zIndex': 99
				}}>
					<div className={`bubble ${bubbleOppositeX ? '-left' : '-right'} ${bubbleOppositeY ? '-top' : '-bottom'}`}>
						<button className="close" onClick={this.handleCloseBubble}><i className="icon-close"></i></button>
						<p className="name">{ bubble.name }</p>
						{ bubble['shelter_id']            ? <p className="value">災民收容所編號： { this.transferValue(bubble['shelter_id']) }</p> : '' }
						{ bubble['shelter_name']          ? <p className="value">災民收容所名稱： { this.transferValue(bubble['shelter_name']) }</p> : '' }
						{ bubble['management_level']      ? <p className="value">管理層級： { this.transferValue(bubble['management_level']) }</p> : '' }
						{ bubble['shelter_address']       ? <p className="value">收容所地址： { this.transferValue(bubble['shelter_address']) }</p> : '' }
						{ bubble['serve_village']         ? <p className="value">服務里別： { this.transferValue(bubble['serve_village']) }</p> : '' }
						{ bubble['shelter_inside_count']  ? <p className="value">室內可收容人數： { this.transferValue(bubble['shelter_inside_count']) }</p> : '' }
						{ bubble['shelter_outside_count'] ? <p className="value">室外可收容人數： { this.transferValue(bubble['shelter_outside_count']) }</p> : '' }
						{ bubble['shelter_count']         ? <p className="value">可收容人數總計： { this.transferValue(bubble['shelter_count']) }</p> : '' }
						{ bubble['shelter_area']          ? <p className="value">室內容納面積(平方公尺)： { this.transferValue(bubble['shelter_area']) }</p> : '' }
						{ bubble['for_weak']              ? <p className="value">適合避難弱者安置： { this.transferValue(bubble['for_weak']) }</p> : '' }
						{ bubble['for_disaster']          ? <p className="value">適用災別： { this.transferValue(bubble['for_disaster']) }</p> : '' }
						{ bubble['shelter_feature']       ? <p className="value">空間特性： { this.transferValue(bubble['shelter_feature']) }</p> : '' }
						{ bubble['contact_name']          ? <p className="value">聯絡人姓名： { this.transferValue(bubble['contact_name']) }</p> : '' }
						{ bubble['contact_tel']           ? <p className="value">聯絡人電話： { this.transferValue(bubble['contact_tel']) }</p> : '' }
						{ bubble['contact_mobile']        ? <p className="value">聯絡人手機號碼： { this.transferValue(bubble['contact_mobile']) }</p> : '' }
						{ bubble['manage_department']     ? <p className="value">收容所管理單位： { this.transferValue(bubble['manage_department']) }</p> : '' }
						{ bubble['mange_name']            ? <p className="value">管理人姓名： { this.transferValue(bubble['mange_name']) }</p> : '' }
						{ bubble['mange_tel']             ? <p className="value">管理人電話： { this.transferValue(bubble['mange_tel']) }</p> : '' }
						{ bubble['mange_mobile']          ? <p className="value">管理人手機號碼： { this.transferValue(bubble['mange_mobile']) }</p> : '' }
						{ bubble['notice']                ? <p className="value">備註： { this.transferValue(bubble['notice']) }</p> : '' }
						{ !isMemberLogin ? <p className="value">（欲查看收容所聯絡方式，請先登入會員）</p> : ''}
					</div>
				</div>
				{!isMapShow && <LoadingDots theme="red" />}
				<div className={`taiwan-map ${isMapShow && '-show'}`}>
					<SvgZoomMap
						countyJsonSrc="/MapJson/taiwan-county.json"
						townJsonSrc="/MapJson/taiwan-town.json"
						villageJsonSrc="/MapJson/taiwan-village.json"
						// onAreaHover={this.handleMouseOverMap}
						county={county}
						town={town}
						village={village}
						pins={sheltersLocate}
						onPinClick={(pinItem, e) => { this.handlePinClick(pinItem, e)}}
						handlePinClick={this.handlePinClick}
						isMapInit={() => { this.setState({ isMapShow: true }) }}
					/>
				</div>
			</div>
		);
	}
}

export default EvacuationShelterMap;
