import React, { Component } from 'react';
import Axios from 'axios';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Helmet from 'react-helmet';
import './index.scss';

// Utils
// import CountiesTownsData from '../../../../public/CountiesTownsData';
// import CountiesTownsData from '../../config/CountiesTownsData';
import CustomInput from '../../utils/CustomInput';
import CustomSegment from '../../utils/CustomSegment';
import CustomSelect from '../../utils/CustomSelect';
import ModalBox from '../../utils/ModalBox';
import { apiHost, facebookAppId, facebookPageId } from '../../utils/Shared/constant';

const organization = [
	'中央政府人員',
	'縣市政府人員',
	'鄉鎮市區公所人員',
	'研究人員',
	'社福機構從業人員',
	'村里長(幹事)或鄰長',
	'其他'
]

class Editor extends Component {
	constructor(params) {
		super(params);
		this.state = {
			modalOpen: false,
			isSegmentLeft: true,

			input_username: '',
			input_usertitle: '',
			input_orgtitle: '',
			input_town: '',
			input_county: '',
			input_orgsort: '',

			input_pw: '',
			input_repw: '',
			msg_pw: '',

			taiwan_area: null,
			taiwan_nowCity: -1,

			inputDisabled: true,
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		this.props.onLoadShowBreadcrumb('HIDE');
		if (window.localStorage.getItem('user-token') === null) {
			window.location = "/";
		}

		Axios.get('/config/CountiesTownsData.json')
		.then(result => {
			this.setState({
				taiwan_area: result.data
			}, () => this.getUserInfo());
		})

	}

	getUserInfo = () => {
		Axios.interceptors.request.use(function (config) {
			config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('user-token');
			return config;
		});
		Axios({
			method: 'post',
			url: `${apiHost}/api/Account/Info`,
			"async": true,
			"crossDomain": true,
			"headers": {
				"Content-Type": "application/x-www-form-urlencoded",
				"cache-control": "no-cache",
			},
			"data": {
				"Flag": "true"
			}
		})
		.then(res => {
			if (res.data.Outstring === "0000") {
				this.setState({
					input_username: res.data.Result.Name,
					input_usertitle: res.data.Result.JobTitle,
					input_email: res.data.Result.Email,
					input_orgtitle: res.data.Result.CompanyName,
					input_orgsort: res.data.Result.CompanyType,
					input_county: res.data.Result.County,
					input_town: res.data.Result.Area,

					inputDisabled: false,
				},
					() => {
						setTimeout(
							() => this.setState({ input_county: res.data.Result.County }),
							100
						)
					})
			}
		});
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState !== this.state) {
			this.renderForm();
		}
	}


	model = (state) => {

		const varToString = varObj => Object.keys(varObj)[0]
		const getValue = varObj => Object.values(varObj)[0]

		let rtn = {
			disabled: this.state.inputDisabled,
			value: getValue(state),
			onChange: v => {
				let newState = {};
				newState[varToString(state)] = v;
				this.setState(newState);
			},
		};

		return rtn;
	}

	handleClickSendData = (e) => {
		e.preventDefault();
		this.openModal();

		// get location
		let location = e.currentTarget.getAttribute('to')
		setTimeout(() => {
			window.location = location;
		}, 2000);
	}

	handleCancelCllick = () => {
		window.location = '/';
	}

	handleUpdateProfileClick = () => {

		const { input_username, input_usertitle, input_email, input_orgtitle, input_orgsort, input_county, input_town } = this.state;

		this.setState({ inputDisabled: true });

		Axios.post(
			`${apiHost}/api/Account/UpdateInfo`,
			{
				Name: input_username,
				CompanyName: input_orgtitle,
				CompanyType: input_orgsort,
				JobTitle: input_usertitle,
				Email: input_email,
				Area: input_town,
				County: input_county
			},
			{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + window.localStorage.getItem('user-token'),
				}
			},
		)
			.then(res => {
				if (res.data.Outstring === "0000") {
					window.location = '/';
				} else {
					this.setState({
						inputDisabled: false,
					})
				}
			});
	}

	handleUpdatePasswordClick = () => {
		const { input_pw, input_repw } = this.state;
		this.setState({ inputDisabled: true });

		if (input_pw !== input_repw) {
			this.setState({
				msg_pw: '兩次密碼必須相同',
				inputDisabled: false,
			})
		}
		else if (input_pw === '') {
			this.setState({
				msg_pw: '密碼不可為空白',
				inputDisabled: false,
			})
		}
		else {
			Axios.post(
				`${apiHost}/api/Account/UpdateInfo`,
				{
					LoginKey: input_pw
				},
				{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + window.localStorage.getItem('user-token'),
					}
				},
			)
				.then(res => {
					if (res.data.Outstring === "0000") {
						window.location = '/';
					} else {
						this.setState({
							inputDisabled: false,
						})
					}
				});
		}
	}

	openModal() {
		this.setState({
			modalOpen: true,
		})
	}

	handleClickSwitchSegment = (e) => {
		e.preventDefault();
		let target = e.currentTarget;
		switch (target.getAttribute('data-target')) {
			case 'member':
				this.setState({
					isSegmentLeft: true,
				})
				break;
			case 'password':
				this.setState({
					isSegmentLeft: false,
				})
				break;
			default: return;
		}
	}

	renderForm = () => {
		if (this.state.isSegmentLeft) {
			return this.renderMemberInput();
		}
		else {
			return this.renderPasswordInput();
		}
	}

	renderMemberInput = () => {
		const { model } = this;
		const { input_username, input_usertitle, input_orgtitle, input_email, input_orgsort, input_town, input_county, taiwan_area } = this.state;
		return (
			<form className="form login-form" action="">
				{
					input_orgsort !== '' &&
					<div className="formitem">
						<CustomSelect id="orgsort" label="組織類別*" {...this.model({ input_orgsort })} >
							{
								organization.map(item =>
									<option value={item} key={item}>{item}</option>
								)
							}
						</CustomSelect>
					</div>
				}
				<div className="formitem">
					<CustomInput status="normal" id="username" type="input" label="聯絡人姓名*" placeholder="張冠軍" {...model({ input_username })} />
				</div>

				<div className="formitem">
					<CustomInput status="normal" id="usertitle" type="input" label="職稱*" placeholder="研究員" {...model({ input_usertitle })} />
				</div>

				{/* <div className="formitem">
					<CustomInput status="normal" id="useremail" type="email" label="E-mail*" placeholder="user@ncdr.org" {...model({ input_email })} />
				</div> */}

				<div className="formitem">
					<CustomInput status="normal" id="orgtitle" type="input" label="服務單位*" placeholder="國家災害防救科技中心" {...model({ input_orgtitle })} />
				</div>

				<div className="row">
					<div className="formitem col-md-6">
						<CustomSelect id="location" label="單位所在縣市*"
						value={ input_county }
						onChange={value => this.setState({ input_county: value, input_town: '' })}>
							<option value="">請選擇</option>
							
							{
								taiwan_area && taiwan_area.counties.map(item =>
									<option value={item.county} key={item.county}>{item.county}</option>
								)
							}
						</CustomSelect>
					</div>
					<div className="formitem col-md-6">
						<CustomSelect id="county" label="單位所在鄉鎮市*"
						value={ input_town }
						onChange={value => this.setState({ input_town: value })}>
							<option value="">請選擇</option>
							{
								taiwan_area && taiwan_area.counties.map(countyItem =>
									countyItem.county === input_county &&
									countyItem.towns.map(townItem => 
										<option value={townItem.town} key={townItem.town}>{townItem.town}</option>
									)
								)
							}
						</CustomSelect>
					</div>
				</div>

				<div className="row formfooter">
					<div className="formitem col-lg-8">
						<button type="button" to="/member/edit" className="fill-button -fill -yellow"
							onClick={this.handleUpdateProfileClick}>
							<span className="type-text -ch -md">確認</span>
						</button>
					</div>
					<div className="formitem col-lg-4">
						<button type="button" to="/member/edit" className="fill-button -fill -dark"
							onClick={this.handleCancelCllick}>
							<span className="type-text -ch -md">取消</span>
						</button>
					</div>
				</div>
			</form>
		)
	}

	renderPasswordInput = () => {
		const { model } = this;
		const { input_pw, input_repw, msg_pw } = this.state;
		return (
			<form className="form login-form" action="">
				<div className="formitem">
					<CustomInput status={msg_pw === '' ? 'normal' : 'danger'} id="password" type="password" label="密碼*" {...model({ input_pw })} />
				</div>
				<div className="formitem">
					<CustomInput status={msg_pw === '' ? 'normal' : 'danger'} msg={msg_pw === '' ? '' : msg_pw} id="repassword" type="password" label="再次確認密碼*" {...model({ input_repw })} />
				</div>
				<div className="row formfooter">
					<div className="formitem col-lg-8">
						<button type="button" to="/member/edit" className="fill-button -fill -yellow"
							onClick={this.handleUpdatePasswordClick}>
							<span className="type-text -ch -md">確認</span>
						</button>
					</div>
					<div className="formitem col-lg-4">
						<button type="button" to="/member/edit" className="fill-button -fill -dark"
							onClick={this.handleCancelCllick}>
							<span className="type-text -ch -md">取消</span>
						</button>
					</div>
				</div>
			</form>
		)
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 會員資料修改</title>
				</Helmet>
				<ModalBox
					open={this.state.modalOpen}
					theme="-yellow"
					onRequestClose={() => {
						this.setState({ modalOpen: false })
					}}
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">您的會員資料已修改</p>
					</div>
					<div className="modalfooter"></div>
				</ModalBox>
				<div className="member-page">

					<MessengerCustomerChat
						pageId={facebookPageId}
						appId={facebookAppId}
						themeColor="#FDC100"
						language="zh-TW"
					/>
					<div className="container">
						<div className="row">
							<section className="member-edit member-section col-lg-6 offset-lg-3">
								<div className="heading">
									<span className="type-heading -ch -h3">會員專區</span>
								</div>
								<CustomSegment className="segment">
									<button data-target="member" onClick={this.handleClickSwitchSegment} >
										<span className="type-text -ch -md">修改會員資料</span>
									</button>
									<button data-target="password" onClick={this.handleClickSwitchSegment} >
										<span className="type-text -ch -md">修改密碼</span>
									</button>
								</CustomSegment>
								{this.renderForm()}
							</section>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Editor;
