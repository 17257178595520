import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Container
import ArticleList from '../../Series/ArticleList';
import ResourceLinks from '../../Series/ResourceLinks';

// Resources
import MorakotBackground1 from '../../../images/morakot-bg1.png';
import MorakotBackground2 from '../../../images/morakot-bg2.png';
import MorakotPercentSVG from "../../../images/morakot-percent.svg";

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}

class MorakotIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
			scrollSpyItems: [
				{
					name: 'info',
					title: '莫拉克颱風社會衝擊與復原調查',
					show: false,
				},
				{
					name: 'articles',
					title: '主題館',
					show: false,
				},
				{
					name: 'resource',
					title: '資料來源',
					show: false,
				},
			]
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}
	handleClickToggle = (e) => {
		const controller = e.currentTarget;
		const content = e.currentTarget.nextSibling;

		if (content.classList.contains('-show')) {
			controller.classList.remove('-open');
			content.classList.remove('-show');
		}
		else {
			controller.classList.add('-open');
			content.classList.add('-show');
		}
	}
	handleClickMorakotSideModalOpen = () => {
		this.props.onClickOpenSideModal('MORAKOT');
	}

	render() {
		const { scrollSpyItems } = this.state;
		return (
			<div className="new-morakot-page">
				<Helmet>
					<title>減災動資料 - 莫拉克風災災後調查</title>
				</Helmet>
				<div className="scroll-spy">
					{
						scrollSpyItems &&
						scrollSpyItems.map((item, index) => {
							return (
								<div key={index}
									className={"item " + item.name + (item.show ? " -show" : "")}
									onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true }) }}>
									<span>{item.title}</span>
								</div>
							)
						})
					}
				</div>
				<section className="morakot-heading">
					<Link className="block" to="/statistics/morakot/intro">
						<div className="heading">
							<h2 className="type-heading -ch title">主題圖表</h2>
							<p className="subtitle">依主題展示莫拉克颱風調查結果</p>
						</div>
						<img className="background" src={MorakotBackground1} alt="" />
					</Link>
					<Link className="block" to="/statistics/morakot/advanced-intro">
						<div className="heading">
							<h2 className="type-heading -ch title">客製化專區</h2>
							<p className="subtitle">可自選調查問題製作統計圖表</p>
						</div>
						<img className="background" src={MorakotBackground2} alt="" />
					</Link>
				</section>
				<Scroll.Element name="info">
					<Waypoint onEnter={() => { this.handleWaypointEnter('info') }} {...waypointOption}>
						<section className="morakot-section morakot-info">
							<div className="container">
								<h1 className="sectiontitle">莫拉克颱風社會衝擊與復原調查(2010~2019)</h1>
								<div className="sectioncontent">
									<p className="strong">背景說明</p>
									<p className="">2009年8月莫拉克颱風襲台，造成西南部地區嚴重災情。國家災害防救科技中心自2010年起，針對因莫拉克颱風災害而房屋毀損不勘居住的家戶進行追蹤調查。</p>
									<ul className="morakot-list">
										<li>
											<label className="type-text -ch -md" htmlFor="">調查對象</label>
											<p className="type-text -ch -md">2009年底前向社會局申請安遷救助金的家戶</p>
										</li>
										<li>
											<label className="type-text -ch -md" htmlFor="">調查家戶數</label>
											<p className="type-text -ch -md">1754戶</p>
										</li>
										<li>
											<label className="type-text -ch -md" htmlFor="">調查時間</label>
											<p className="type-text -ch -md">2010、2011、2012、2015、2019</p>
										</li>
										<li>
											<label className="type-text -ch -md" htmlFor="">調查範圍</label>
											<p className="type-text -ch -md">南投縣、嘉義縣、臺南市、高雄市、屏東縣、臺東縣</p>
										</li>
										<li>
											<label className="type-text -ch -md" htmlFor="">調查規劃</label>
											<p className="type-text -ch -md">國家災害防救科技中心</p>
										</li>
										<li>
											<label className="type-text -ch -md" htmlFor="">調查執行</label>
											<p className="type-text -ch -md">主計處基層調查網</p>
										</li>
									</ul>
									<div className="toggle morakot-toggle">
										<button className="controller -open" onClick={this.handleClickToggle}>
											<span className="type-text -ch -md">完訪率</span>
											<div className="icon">
												<span className="open">展開<i className="icon-plus"></i></span>
												<span className="close">收合<i className="icon-minus"></i></span>
											</div>
										</button>
										<div className="content -show">
											<p className="image"><img src={MorakotPercentSVG} alt="" /></p>
										</div>
									</div>
									<div className="toggle morakot-toggle">
										<button className="controller -open" onClick={this.handleClickToggle}>
											<span className="type-text -ch -md">調查項目一覽表</span>
											<div className="icon">
												<span className="open">展開<i className="icon-plus"></i></span>
												<span className="close">收合<i className="icon-minus"></i></span>
											</div>
										</button>
										<div className="content -show">
											<div className="morakot-summarytable">
												<table>
													<tbody>
														<tr>
															<th className="sort">分類<span className="type-text -ch -sm"></span></th>
															<th className="issue">分類議題<span className="type-text -ch -sm"></span></th>
															<th className="research" colSpan="5">調查年度<span className="type-text -ch -sm"></span></th>
														</tr>
														<tr>
															<th></th>
															<th></th>
															<th><span className="type-text -ch -ss">2010</span></th>
															<th><span className="type-text -ch -ss">2011</span></th>
															<th><span className="type-text -ch -ss">2012</span></th>
															<th><span className="type-text -ch -ss">2015</span></th>
															<th><span className="type-text -ch -ss">2019</span></th>
														</tr>
														<tr>
															<td className="subject"><span className="type-text -ch -ss">減災</span></td>
															<td className="item"><span className="type-text -ch -ss">風險認知、減災行為</span></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject" rowSpan="2"><span className="type-text -ch -ss">應變</span></td>
															<td className="item"><span className="type-text -ch -ss">收容、信任度</span></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">撤離</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject" rowSpan="7"><span className="type-text -ch -ss">復原與重建</span></td>
															<td className="item"><span className="type-text -ch -ss">NGOs評價</span></td>
															<td></td>
															<td></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">住宅重建</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">居住品質</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">社區重建、復原所需時間</span></td>
															<td></td>
															<td></td>
															<td></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">信任度</span></td>
															<td></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">風險溝通</span></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">就業</span></td>
															<td></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
															<td></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject" rowSpan="2"><span className="type-text -ch -ss">受訪者特徵</span></td>
															<td className="item"><span className="type-text -ch -ss">年齡、身心健康、宗教信仰、性別、婚姻、學歷</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">職業</span></td>
															<td><i className="icon-star"><i className="icon-oino"></i></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject" rowSpan="5"><span className="type-text -ch -ss">家戶特徵</span></td>
															<td className="item"><span className="type-text -ch -ss">土地持有</span></td>
															<td></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">幼年人口、老年人口、家庭人口</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">房屋自有、房屋特徵、家戶型態</span></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">原住民家庭</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">家庭收入、家庭開銷</span></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject"><span className="type-text -ch -ss">行動不便者</span></td>
															<td className="item"></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject" rowSpan="4"><span className="type-text -ch -ss">災害衝擊</span></td>
															<td className="item"><span className="type-text -ch -ss">人員傷亡、受災原因、受災經驗</span></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">社會網絡</span></td>
															<td><i className="icon-star"></i><i className="icon-oino"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">財物損失及花費</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td className="item"><span className="type-text -ch -ss">失業</span></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
														<tr>
															<td className="subject"><span className="type-text -ch -ss">資源取得</span></td>
															<td className="item"></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
															<td><i className="icon-star"></i></td>
														</tr>
													</tbody>
												</table>
												<p className="remark">
													<i className="icon-star"></i>
													<span className="type-text -ch -ss">各議題調查年度</span>
													<i className="icon-oino"></i>
													<span className="type-text -ch -ss">含調查災前情況</span>
												</p>
											</div>
										</div>
									</div>
									<ul className="morakot-list">
										<li>
											<label className="type-text -ch -md" htmlFor="">原始資料申請</label>
											<p className="type-text -ch -md">詳情請見 
                        防災易起來 > 
                        <a href="https://easy2do.ncdr.nat.gov.tw/survey" target="_blank" rel="noopener noreferrer"> 社會經濟調查資料專區</a>
                      </p>
										</li>
									</ul>
									<p className="type-text -ch -md">主題圖表封面照作者：Jihnnan</p>
								</div>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
				<Scroll.Element name="articles">
					<Waypoint onEnter={() => { this.handleWaypointEnter('articles') }} {...waypointOption}>
						<section className="morakot-section morakot-subject -gray">
							<div className="container">
								<h1 className="sectiontitle">主題館</h1>
								<ArticleList color="green" categoryID={1} pathname="/statistics/morakot"></ArticleList>
								<Link className="sectionbutton fill-button -green" to="/statistics/morakot/categories">
									<span className="type-text -ch -md">進入主題館</span>
								</Link>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
				<Scroll.Element name="resource">
					<Waypoint onEnter={() => { this.handleWaypointEnter('resource') }} {...waypointOption}>
						<section className="morakot-section morakot-source">
							<div className="container">
								<h1 className="sectiontitle">資料來源</h1>
								<ResourceLinks category="morakot" theme="green"></ResourceLinks>
							</div>
						</section>
					</Waypoint>
				</Scroll.Element>
			</div>
		);
	}
}

export default MorakotIndex;
