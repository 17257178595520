import React, { Component } from 'react';
import './index.scss';
import { shareToFB, shareToLine, shareToTwitter} from '../Shared/methods';
import gaTracking from '../Shared/gaTracking';

class SocialBlock extends Component {
	
	t = (zhtw, en) => {
		if (!this.props.lang) {
			return zhtw;
		} else {
			return this.props.lang === 'zh-tw' ? zhtw : en;
		}
	}

	handleSocialTracking = (label) => {
		const { category, action } = this.props;
		gaTracking(category, action, label);
	}

	render() {
		const {t} = this;
		const { theme } = this.props;
		return (
			<section className={`social-block ${theme ? '-'+ theme : ''}`}>
				<p className={'type-text -md' + t(' -ch', ' -en')}>{t('分享至', 'Share')}</p>
				<div className="buttonbar">
					<button onClick={ () => { shareToFB(); this.handleSocialTracking('分享至Facebook') } }><i className="icon-facebook"></i></button>
					<button onClick={ () => { shareToTwitter(); this.handleSocialTracking('分享至Twitter') } }><i className="icon-twitter"></i></button>
					<button onClick={ () => { shareToLine(); this.handleSocialTracking('分享至Line') } }><i className="icon-line-app"></i></button>
				</div>
			</section>
		);
	}
}

export default SocialBlock;
