import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import ReactEcharts from 'echarts-for-react';
import Axios from 'axios';
import { Parser } from 'json2csv';
import './index.scss';

// Utils
import ModalBox from '../../utils/ModalBox';
import CustomDropdown from '../../utils/CustomDropdown';
import CustomRadio from '../../utils/CustomRadio';
import chartCapture from '../../utils/Shared/chartCapture';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { loginRedirect } from '../../utils/Shared/methods';
import { apiHost } from '../../utils/Shared/constant';
import { downloadTrigger } from '../../utils/Shared/methods';

class AdvanceCharts extends Component {
	constructor(params) {
		super(params);
		this.state = {
			isHintModalOpen: false,
      reportOptions: null,
			echartsOption: {},
			echartsHeight: 600,
			redirectUrl: '',
			nowYear: null,
      availableLocations: null,
      nowLocation: null,
      nowStatistic: null
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
      { name: '客製化專區', href: '/statistics/morakot/advanced-intro' },
      { name: '進階分析', href: '/statistics/morakot/advanced-analytic' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);

		const report = queryString.parse(window.location.search);
		this.loadOptions(report);
		this.checkMemberLogin();
	}

	checkMemberLogin = () => {
		if (!window.localStorage.getItem('user-token')) {
			this.setState(
				{ isLoginModalOpen: true },
				() => setTimeout(() => { loginRedirect() }, 3000)
			);
		}
	}

	openModal = () => {
		this.setState({
			isHintModalOpen: true,
		})
	}

  loadOptions = (report) => {
    const { q1, q2, q3, year, location, statistic } = report;
    Axios.get(`${apiHost}/api/Survey/GetUsableYearsAndLocation?data.titleID=${q1}&data.title2ID=${q2}&data.title3ID=${q3}`)
      .then((res) => {
        const options = res.data.Result.sort((a, b) => a.Year - b.Year);
        this.setState({
          reportOptions: options,
          nowYear: year || options[0].Year,
          availableLocations: options[0].Locations,
          nowLocation: location || '',
          nowStatistic: Number(statistic)
        }, () => this.handleChangeParam());
      })
  }
	loadReport = (report) => {
		const { q1, q2, q3, statistic, type, location, year } = report;
		this.setState({ isLoading: true });
		Axios.get(`${apiHost}/api/Survey/Report?` +
			'data.titleID=' + q1 +
			'&data.title2ID=' + q2 +
			'&data.title3ID=' + q3 +
			'&data.year=' + year +
			'&data.locationID=' + location +
			'&data.statisticsType=' + statistic)
			.then((res) => {
        let chartTitle = [
            res.data.Result.title,
            res.data.Result.title2,
            res.data.Result.title3,
        ];
        if (statistic === '3') {
          chartTitle = [
            res.data.Result.title3,
            res.data.Result.title,
            res.data.Result.title2,
          ];
        };

				this.setState({
					chartData: res.data.Result,
          chartTitle: chartTitle,
					isLoading: false,
				}, () => {
					if (res.data.Result.values.length !== 0) {
						this.renderChartType(type);
            this.handleLoadTransferCSV();
					}
					else {
						this.openModal();
					}
				});
			})
	}
  getLocationId = (str) => {
    const list = [
      {name: "高雄市", id: 1},
      {name: "臺南市", id: 2},
      {name: "南投縣", id: 3},
      {name: "嘉義縣", id: 4},
      {name: "屏東縣", id: 5},
      {name: "臺東縣", id: 6}
    ];
    const index = list.findIndex(item => item.name === str);
    return list[index].id;
  }
	handleLoadTransferCSV = () => {
		if (this.state.chartData) {
			let chartData = this.state.chartData;
			let valueArray = [];
			let infoArray = [];

			if (chartData.values.length < 2) {
				chartData.values[0].data.forEach((valueItem, i) => {
					let obj = {};
					obj[chartData.title] = valueItem.name;
					obj['數值'] = valueItem.value;

					valueArray.push(obj);
				})
			}
			else {
				chartData.values.forEach((item, i) => {
					item.data.forEach((valueItem, j) => {
						let obj = {};
						obj[chartData.title] = valueItem.name;
						obj[chartData.title2] = chartData.values[i].name;
						obj['數值'] = valueItem.value;
						valueArray.push(obj);
					})
				})
			}
			chartData.infos.forEach((item, i) => {
				let str = "";
				str = '題目,' + item.title + ',\n註解,' + item.remark + ',\n'

				infoArray.push(str);
			})

			let fields = Object.keys(valueArray[0]);
			let json2csvParser = new Parser({ fields });
			let csv = json2csvParser.parse(valueArray);

      const report = queryString.parse(window.location.search);
      const { year, location } = report;
      const yearNames = [
        {'id': 'all', 'name': '所有年份'},
        {'id': '2010', 'name': '2010'},
        {'id': '2011', 'name': '2011'},
        {'id': '2012', 'name': '2012'},
        {'id': '2015', 'name': '2015'},
        {'id': '2019', 'name': '2019'},
      ]
      const locationNames = [
        {'id': '', 'name': '所有地區'},
        {'id': 'all', 'name': '所有地區'},
        {'id': '1', 'name': '高雄市'},
        {'id': '2', 'name': '臺南市'},
        {'id': '3', 'name': '南投縣'},
        {'id': '4', 'name': '嘉義縣'},
        {'id': '5', 'name': '屏東縣'},
        {'id': '6', 'name': '臺東縣'}
      ];
      const locationName = locationNames[locationNames.findIndex(item => item.id === location)].name || null;
      const yearName = yearNames[yearNames.findIndex(item => item.id === year)].name || null;

			csv = csv + '\n\n' +
        `${locationName ? `調查地區,${locationName}\n` : ''}` +
        `${yearName ? `調查年份,${yearName}\n` : ''}` +
				infoArray.join('') + '\n' +
				'有效N,' + chartData.numbern + '\n' +
				'遺漏值,' + chartData.missing
			this.setState({
				csv: csv,
			})
		}
	}
	handleClickDownloadCSV = (e) => {
		const csv = this.state.csv;
		const copyright = '\n資料來源：國家災害防救科技中心減災動資料。';
		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;

		let fileName = [];
		this.state.chartData.infos.forEach((item) => {
			fileName.push(item.title);
		});

		fileName = fileName.join('-');

		downloadTrigger(fileName + '.csv', csvContent);
		gaTracking('莫拉克颱風', '進階分析', '原始數據下載');
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();

		const captureArea = document.querySelectorAll('.capture');
		let fileName = [];
		this.state.chartData.infos.forEach((item) => {
			fileName.push(item.title);
		});
		fileName = fileName.join('-');

		chartCapture(captureArea, fileName);
		gaTracking('莫拉克颱風', '進階分析', '圖表下載');
	}
  handleChangeYear = (year) => {
    const { reportOptions, nowLocation } = this.state;
    const index = reportOptions.findIndex(option => year === `${option.Year}`);
    this.setState({
      nowYear: year,
      availableLocations: reportOptions[index].Locations,
      nowLocation: nowLocation === 'all' ? nowLocation : ''
    }, () => this.handleChangeParam());
  }
  handleChangeLocation = (location) => {
    this.setState({
      nowLocation: location
    }, () => this.handleChangeParam());
  }
  handleChangeStatistic = (statistic) => {
    this.setState({
      nowStatistic: statistic
    }, () => this.handleChangeParam());
  }
  handleChangeParam = () => {
    const { nowLocation, nowYear, nowStatistic } = this.state;
    const report = queryString.parse(window.location.search);
    report.year = nowYear;
    report.location = nowLocation;
    report.statistic = nowStatistic;
    const params = queryString.stringify(report);
    window.history.pushState({}, document.title, '?' + params);
    this.loadReport(report);
  }
	handleClickReturn = () => {
		this.setState({ redirectUrl: '/statistics/morakot/advanced-analytic' });
	}
	renderChartType = (type) => {
		switch (type) {
			case 'bar':
				this.renderBarChart();
				break;

			case 'line':
				this.renderLineChart();
				break;

			case 'pie':
				this.renderPieChart();
				break;

			default:
				this.renderBarChart();
		}
	}
	renderPieChart() {
    const report = queryString.parse(window.location.search);
		let { chartData } = this.state;
		let option = {
			title: {
				right: 0,
				bottom: 0,
				textStyle: {
					fontSize: 16,
					fontWeight: 'normal'
				}
			},
      label: {
        fontSize: 16,
      },
			color: ['#0B6735','#2FC172','#9BF3B4','#CC345B','#F27C9A','#FFCAD8','#EE6A2B','#FDC100','#747474','#D3D3D3','#003678','#3677C6','#A1CBFF','#531B8D','#8D65B6','#D5BEEE','#8D4C1B','#C4804C','#F5D1B6','#AC8C29'],
			legend: {},
			tooltip: {
				trigger: 'axis'
			},
		};

		// label
		const label = [];
		chartData.values[0].data.forEach((item, i) => {
			label.push(item.name)
		})

		let legend = [];
		let series = chartData.values;

		// legend
		chartData.values.forEach((item, i) => {
			legend.push(item.name);
		})

		// series
		series.forEach((item, i) => {
			item.type = 'pie';
			item.radius = ['30%', '70%'];
			item.label = {
				formatter: `{b}: {c} ${report.statistic === '1' ? '%' : ''}`
			}
		})

		option.legend.data = legend;
		option.series = series;
		this.setState({
			echartsOption: option,
		});
	}
	renderLineChart() {
    let { chartData } = this.state;

		// Default
		const fontFamily = ['Poppins', 'Pingfang TC', 'Poppins', '微軟正黑體', 'Microsoft JhengHei', 'sans-serif'];
		const fontColor = 'hsl(0, 0%, 20%)';
		const fontSize = 16;

		// Percent
		let report = queryString.parse(window.location.search);
		
		// Data
    const isQ3 = report.q3 !== '';
    const isQ2 = report.q2 !== '';
    const isVtype = ['3', '4', '5'].indexOf(report.statistic) !== -1;
    const unit = report.statistic === '1' ? '%' : '';

    let legendName;

    if (isVtype && isQ3) {
      legendName = chartData.title2;
    }
    else if (isVtype) {
      legendName = '';
    }
    else if (isQ2) {
      legendName = chartData.title;
    }
    else {
      legendName = '';
    }

    // Title
    const title = {
      text: legendName,
      left: 'center',
      top: 'top',
    }

		let option = {
			title: {
				right: 0,
				bottom: 0,
				subtextStyle: { lineHeight: 15 },
				textStyle: {
					fontSize: fontSize,
					fontFamily: fontFamily,
					fontWeight: 'normal'
				}
			},
			color: ['#0B6735','#2FC172','#9BF3B4','#CC345B','#F27C9A','#FFCAD8','#EE6A2B','#FDC100','#747474','#D3D3D3','#003678','#3677C6','#A1CBFF','#531B8D','#8D65B6','#D5BEEE','#8D4C1B','#C4804C','#F5D1B6','#AC8C29'],
			grid: {
				containLabel: true,
				left: '0%',
				right: '0%',
				top: '15%',
				bottom: '15%',
			},
			legend: {},
			tooltip: {
				trigger: 'axis',
				// formatter: '{b}: {c}' + unit,
				textStyle: {
					fontSize: fontSize,
					fontFamily: fontFamily,
					fontWeight: 'normal'
				}
			},
			xAxis: {
				type: 'category',
				offset: 0,
				axisLabel: {
					padding: [0, 40, 0, 0],
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				},
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				},
			},
		};

		// xAxis data
		let xAxis = [];
		chartData.values.forEach((item) => {
			xAxis.push(item.name)
		});

		// label
		let label = [];
		chartData.values.forEach((item, i) => {
			label.push(item.name)
		})


		// Rebuild Series Data
		let newSeries = [];
		let tempData = [];
		let tempNames = [];
		chartData.values.forEach((item, i) => {
			tempData[i] = item.data;
			item.data.forEach((subitem) => {
				// let obj = {};
				// obj.from = subitem.name;
				// obj.name = item.name;
				// obj.value = subitem.value;
				// if (tempNames.indexOf(subitem.name) === -1) {
        if (tempNames.indexOf(subitem.name.replace(/\s/g, '')) === -1) {
					tempNames.push(subitem.name);
				}
				// tempData.push(obj);
			})
		})
		tempNames.forEach((tempName, i) => {
			let newData = {};
			newData.name = tempName;
			newData.data = [];
			tempData.forEach((data, j) => {
				// if (data[i].value)
				newData.data[j] = data[i] ? data[i].value : null;
			})
			newSeries[i] = newData;
		})

		let series = newSeries;
		series.forEach((item) => {
			item.type = 'line';
			item.label = {
				show: true,
				formatter: '{c}' + unit,
				color: fontColor,
				fontSize: fontSize,
				fontFamily: fontFamily,
			};
		})


		// legend
		let legend = {
			top: '28px',
			data: [],
			textStyle: {
				fontSize: fontSize
			}
		};
		newSeries.forEach((item) => {
			legend.data.push(item.name)
		});


		option.title = title;
		option.xAxis.data = label;
		option.legend = legend;
		option.series = series;
		option.xAxis.data = xAxis;
		this.setState({
			echartsOption: option,
		});
	}
	renderBarChart() {
    const { chartData } = this.state;

		// Default
		const fontFamily = ['Poppins', 'Pingfang TC', 'Poppins', '微軟正黑體', 'Microsoft JhengHei', 'sans-serif'];
		const fontColor = 'hsl(0, 0%, 20%)';
		const fontSize = 16;

		// Percent
		const report = queryString.parse(window.location.search);

    const isQ3 = report.q3 !== '';
    const isQ2 = report.q2 !== '';
    const isVtype = ['3', '4', '5'].indexOf(report.statistic) !== -1;
    const unit = report.statistic === '1' ? '%' : '';

    let legendName;

    if (isVtype && isQ3) {
      legendName = chartData.title3;
    }
    else if (isVtype) {
      legendName = chartData.title2;
      // legendName = '';
    }
    else if (isQ2) {
      legendName = chartData.title2;
    }
    else {
      legendName = '';
    }


    let yName = 'Y軸：';
    if (isVtype && isQ3) {
      yName += chartData.title2;
    }
    else if (isVtype) {
      yName = '';
    }
    else if (isQ2) {
      yName = '';
    }
    else {
      yName = '';
    }

    let tooltipFormatter;
    if (isVtype && isQ3) {
      tooltipFormatter = `${chartData.title2}：{b}<br/>${chartData.title3}：{a}<br/>{c}${unit}`;
    }
    else if (isVtype) {
      tooltipFormatter = `${chartData.title2}：{a}<br/>${chartData.title}：{c}${unit}`;
    }
    else if (isQ2) {
      tooltipFormatter = `${chartData.title}：{b}<br/>${chartData.title2}：{a}<br/>{c}${unit}`;
    }
    else {
      tooltipFormatter = `${chartData.title}：{c}${unit}`;
    }

    const paddingFix = (yName.length) * (fontSize*1.8) /2;

    // Title
    const title = {
      text: legendName,
      left: 'center',
      top: 'top'
    }



		let option = {
			color: ['#0B6735','#2FC172','#9BF3B4','#CC345B','#F27C9A','#FFCAD8','#EE6A2B','#FDC100','#747474','#D3D3D3','#003678','#3677C6','#A1CBFF','#531B8D','#8D65B6','#D5BEEE','#8D4C1B','#C4804C','#F5D1B6','#AC8C29'],
			grid: {
				containLabel: true,
				left: '15px',
				right: '100px',
				top: '80px',
				bottom: '80px',
			},
			yAxis: {
				type: 'category',
        nameLocation: 'start',
        name: yName,
        nameTextStyle: {
          align: 'left',
          padding: [20, 0, 0, paddingFix],
          fontSize: fontSize,
          fontFamily: fontFamily
        },
				offset: 16,
				axisLabel: {
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				},
			},
			xAxis: {
				type: 'value',
				nameLocation: 'middle',
				boundaryGap: [0, '5%'],
				axisLabel: {
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				},
			},
			tooltip: {
				trigger: 'item',
				axisPointer: {
					type: 'shadow'
				},
			},
			legend: {
				type: 'scroll',
				textStyle: {
					color: fontColor,
					fontFamily: fontFamily,
					fontSize: fontSize,
				}
			},
		};

		// label
		let arr = [];
		chartData.values.forEach((item) => {
			arr.push(item.data.length);
		});
		const currentIndex = arr.indexOf(Math.max(...arr));

		let label = [];
		chartData.values[currentIndex].data.forEach((item) => {
			label.push(item.name)
		});


		// legend
		let legend = {
			top: '28px',
			data: [],
			textStyle: {
				fontSize: fontSize
			}
		};
		chartData.values.forEach((item) => {
			legend.data.push(item.name);
		});

		// Series
		let series = chartData.values;
		series.forEach((item) => {
			item.type = 'bar';
			item.barMaxWidth = '28px';
			item.label = {
				show: true,
				position: 'right',
				color: fontColor,
				fontSize: fontSize,
				fontFamily: fontFamily,
			}
      item.data.forEach(subitem => {
				subitem.value = Number(subitem.value);
				subitem.tooltip = {
					fontFamily: fontFamily,
					fontSize: fontSize,
					fontWeight: 600,
					formatter: tooltipFormatter,
				}
			}
			)
			item.label.formatter = '{c}' + unit;
			if (series.length > 20) {
				item.barMaxWidth = '14px'
			}
		});
		console.log(JSON.stringify(series))


		option.title = title;
		option.yAxis.data = label;
		option.legend = legend;
		option.series = series;

		if (series.length > 20) {
			option.grid.top = series.length / 4 * 40
		}

		// Height setting
		let echartsHeight;
		if (series.length > 20) {
			echartsHeight = series.length * series[0].data.length * 25 + 300;
		}
		else {
			echartsHeight = series.length * series[0].data.length * 25 + 500;
		}
		this.setState({
			echartsOption: option,
			echartsHeight: echartsHeight
		});
	}
	renderResultInfo = () => {
		return (
			<React.Fragment>
				{
					this.state.chartData &&
					this.state.chartData.infos.map((item, i) => {
						return (
							<div className="item" key={i}>
								<p className="type-text -ch -xs">
									<span>題目：</span>
									<span>{item.title}</span>
								</p>
								{
									item.remark &&
									<p className="type-text -ch -xs">
										<span>註解：</span>
										<span>{item.remark}</span>
									</p>
								}
							</div>
						)
					})
				}
				{
					this.state.chartData &&
					<div className="item" key="99">
						{
							this.state.chartData.refused !== 0 &&
							<p className="type-text -ch -xs">
								<span>拒答：</span>
								<span>{this.state.chartData.refused}</span>
							</p>
						}
						{
							this.state.chartData.unknown !== 0 &&
							<p className="type-text -ch -xs">
								<span>不知道：</span>
								<span>{this.state.chartData.unknown}</span>
							</p>
						}
						<p className="type-text -ch -xs">
							<span>有效N：</span>
							<span>{this.state.chartData.numbern}</span>
						</p>
						<p className="type-text -ch -xs">
							<span>遺漏值：</span>
							<span>{this.state.chartData.missing}</span>
						</p>
					</div>
				}
			</React.Fragment>
		)
	}
	renderOptions = () => {
		const report = queryString.parse(window.location.search);
		const { year, location } = report;
		const { chartData, redirectUrl, nowYear, nowLocation, availableLocations, nowStatistic, reportOptions } = this.state;
		return (
			<React.Fragment>
				{
					redirectUrl &&
					<Redirect push to={redirectUrl} />
				}
				{
					chartData &&
					<div className="item subject">
          <p className="text type-heading -ch -h5">{chartData.title}</p>
					</div>
				}
				{
					(reportOptions && year !== 'all') &&
					<div className="item year">
						<span className="itemtitle type-text -ch -lg">年份</span>
            <div className="radiobar">
              {
                reportOptions.map((option, i) =>
                  <CustomRadio className="radio" color="green" group="year" key={i}
                    idName={`year${option.Year}`}
                    checked={nowYear == `${option.Year}`}
                    onClick={ () => this.handleChangeYear(`${option.Year}`) }>
                    <span className="text type-heading -en -h7">{ option.Year }</span>
                  </CustomRadio>
                )
              }
            </div>
					</div>
				}
				{
					location !== 'all' &&
					<div className="item location">
						<span className="itemtitle type-text -ch -lg">縣市</span>
            <div className="radiobar">
              <CustomRadio className="radio" color="green" group="location" idName={'all'}
                checked={nowLocation == ''} 
                onClick={ () => this.handleChangeLocation('')}>
                <span className="text type-text -ch -md">全部</span>
              </CustomRadio>
              {
                availableLocations && 
                availableLocations.map((item, i) =>
                  <CustomRadio className="radio" color="green" group="location" idName={`location${this.getLocationId(item)}`} key={i}
                    checked={nowLocation == `${this.getLocationId(item)}`} 
                    onClick={ () => this.handleChangeLocation(`${this.getLocationId(item)}`)}>
                    <span className="text type-text -ch -md">{item}</span>
                  </CustomRadio>
                )
              }
            </div>
					</div>
				}
				{
					(nowStatistic === 1 || nowStatistic === 2) &&
					<div className="item statistic">
						<span className="itemtitle type-text -ch -lg">統計值</span>
            <div className="radiobar">
  						<CustomRadio className="radio" color="green" group="statistic" checked={nowStatistic === 1} onClick={() => this.handleChangeStatistic(1)}>
  							<span className="text type-text -ch -md">百分比</span>
  						</CustomRadio>
  						<CustomRadio className="radio" color="green" group="statistic" checked={nowStatistic === 2} onClick={() => this.handleChangeStatistic(2)}>
  							<span className="text type-text -ch -md">計數</span>
  						</CustomRadio>
            </div>
					</div>
				}
				{
					(nowStatistic === 3 || nowStatistic === 4 || nowStatistic === 5) &&
					<div className="item statistic">
						<span className="itemtitle type-text -ch -lg">統計值</span>
            <div className="radiobar">
  						<CustomRadio className="radio" color="green" group="statistic" checked={nowStatistic === 3} onClick={() => this.handleChangeStatistic(3)}>
  							<span className="text type-text -ch -md">平均數</span>
  						</CustomRadio>
  						<CustomRadio className="radio" color="green" group="statistic" checked={nowStatistic === 4} onClick={() => this.handleChangeStatistic(4)}>
  							<span className="text type-text -ch -md">中位數</span>
  						</CustomRadio>
  						<CustomRadio className="radio" color="green" group="statistic" checked={nowStatistic === 5} onClick={() => this.handleChangeStatistic(5)}>
  							<span className="text type-text -ch -md">計數</span>
  						</CustomRadio>
            </div>
					</div>
				}
				<div className="item download capture-hide">
					<CustomDropdown title="下載">
						<li><button onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
						<li><button onClick={this.handleClickDownloadCSV}>下載CSV</button></li>
					</CustomDropdown>
				</div>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				<div className={'loading-popup' + (this.state.isLoading ? '' : ' -hide')}>
					<i className="icon-refresh"></i>
				</div>
				<ModalBox
					open={this.state.isHintModalOpen}
					theme="-yellow"
					onRequestClose={() => {
						this.setState({ isHintModalOpen: false })
					}}
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">查無資料<br />請選擇其他選項或重新查找</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -fit -yellow" onClick={() => { this.setState({ isHintModalOpen: false }) }}>關閉視窗</button>
					</div>
				</ModalBox>
				<ModalBox
					open={this.state.isLoginModalOpen}
					theme="green"
					onRequestClose={() => loginRedirect() }
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">請先登入再進行操作</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -green" onClick={ () => loginRedirect() }>登入</button>
					</div>
				</ModalBox>
				<div className="advance-chart">
					<section className="advance-result">
						<div className="container">
							<div className="option result-option capture" data-capture="0">
								{this.renderOptions()}
							</div>
							<div className="result">
								<div ref="draw" className="result-draw capture" data-capture="1">
									<ReactEcharts
										option={this.state.echartsOption}
										notMerge={true}
										lazyUpdate={true}
										opts={{ renderer: 'svg' }}
										style={
											{ height: this.state.echartsHeight }
										}
									/>
								</div>
								<div className="result-info capture" data-capture="2">
                  <p>**項目名稱未特別註明時，項目原始單位皆為家戶數</p>
									{this.renderResultInfo()}
								</div>
							</div>
							<div className="result-back">
								<button className="fill-button -green" onClick={this.handleClickReturn}>
									<span className="type-text -ch -md">重新製作圖表</span>
								</button>
							</div>
						</div>
					</section>
				</div>

			</React.Fragment>
		)
	}
}

export default AdvanceCharts;
