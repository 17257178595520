import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils
import LimitMask from '../../utils/LimitMask';
import { downloadHost } from '../../utils/Shared/constant';

// methods
import gaTracking from '../../utils/Shared/gaTracking';

class SviAdvanceIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
			step: 1
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('BLUE');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社會脆弱度評估', href: '/evaluation/svi' },
			{ name: '客製化社會脆弱度評估', href: '/evaluation/svi/advance' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}

	handleClickToggle = (e) => {
		const target = e.currentTarget.nextSibling;
		
		if (target.classList.contains('-show')) {
			target.classList.remove('-show');
		}
		else {
			target.classList.add('-show');
		}
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/svi" />
				<div className="svi-page">
					<div className="container">
						<div className="page-link">
							<Link  className="item" to="/evaluation/svi/z">
								<span>社會脆弱度指標查詢與展示</span>
							</Link>
						</div>
						<div className="svi-advancetitle">
							客製化社會脆弱度評估
						</div>
						<div className="svi-advance row">
							<div className="col-md-12 head">您手上有其他評估指標嗎？現在，您也可以上傳自己的數據，用自己收集到的行政區資料做社會脆弱度評估！</div>
							<div className="col-md-3 advance-side">
								<div className="step">
									<button className={"button" + (this.state.step === 1 ? ' -active': '')}
									onClick={() => {
										this.setState({
											step: 1
										})
									}}>步驟一：檢視數據資料</button>
									{
										this.state.step === 1 &&
										<div className="toggle">
											<div className="title">*將手上的資料歸入以下四類： (1) 暴露量、(2) 減災整備、(3) 應變能力、(4) 復原能力<br/>每個分類至少需有一個指標項目！<br/>*選擇評估的方向：<br/>(1)「單一年度行政區比較」，如：2018年22縣市社會脆弱度地圖。<br/>(2)「單一行政區歷史趨勢」，如：高雄市社會脆弱度趨勢(折線)圖。<br/>*請下載表格將數據填入</div>
										</div>
									}
								</div>
								<div className="icon"><i className="icon-double-arrow"></i></div>
								<div className="step">
									<button className={"button" + (this.state.step === 2 ? ' -active' : '')}
									onClick={() => {
										this.setState({
											step: 2
										})
									}}>步驟二：單一年度行政區比較</button>
									{
										this.state.step === 2 &&
										<div className="toggle">
											<div className="title">*確定需求年份：如：2018年。<br/>*確定每個分類至少有一項評估指標，如：(1) 農林漁牧產值、(2) 水利設施毀損率、(3) 獨居老人比率、(4) 颱洪險投保率。<br/>*確定行政區至少有兩個以上有數據，如：臺北市、新北市、宜蘭縣、高雄市。<br/>請依序填寫指標項目的基本資訊後，上傳數據，就可獲得單一年度各行政區社會脆弱度地圖。</div>
										</div>
									}
								</div>
									<div className="icon"><i className="icon-double-arrow"></i></div>
								<div className="step">
									<button className={"button" + (this.state.step === 3 ? ' -active' : '')}
									onClick={() => {
										this.setState({
											step: 3
										})
									}}>步驟三：單一行政區歷史趨勢</button>
									{
										this.state.step === 3 &&
										<div className="toggle">
											<div className="title">*確定需求年份：至少兩年以上，如：2010-2019。<br/>*確定每個分類至少有一評估項指標，如：(1) 農林漁牧產值、(2) 水利設施毀損率、(3) 獨居老人比率、(4) 颱洪險投保率。<br/>*確定行政區，如：臺北市。<br/>請依序填寫指標項目的基本資訊後，上傳數據，就可獲得單一行政區社會脆弱度歷史趨勢圖。</div>
										</div>
									}
								</div>
							</div>
							<div className="col-md-9 advance-info">
								{
									this.state.step === 1 &&
									<div className="image step1">
                    <video autoPlay={true} loop={true}><source src="/files/svi-intro_1.mp4" /></video>
									</div>
								}
								{
									this.state.step === 2 &&
									<div className="image step2">
                    <video autoPlay={true} loop={true}><source src="/files/svi-intro_2.mp4" /></video>
									</div>
								}
								{
									this.state.step === 3 &&
									<div className="image step3">
                    <video autoPlay={true} loop={true}><source src="/files/svi-intro_3.mp4" /></video>
									</div>
								}
							</div>
							<div className="offset-md-2 col-md-8 advance-enter">
								{/* <a href={`${downloadHost}/Upload/download/sheets.zip`} onClick={() => gaTracking('社會脆弱度', '客製化社會脆弱度評估', '我要下載表格')} download="範例表格">我要下載表格</a> */}
								<a href="/files/sheets.zip" onClick={() => gaTracking('社會脆弱度', '客製化社會脆弱度評估', '我要下載表格')} download="範例表格.zip">我要下載表格</a>
								<Link to="/evaluation/svi/advance/app">我要評估社會脆弱度</Link>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default SviAdvanceIntro;
