import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './index.scss';

// utils
import SideModal from '../../utils/SideModal';

// Resouces
import SurveyBackground from '../../../images/survey-background.png';

class SurveySideModal extends Component {
	handleClickCloseModal = () => {
		this.props.onClickCloseModal();
	}

	handleClickOtherModal = () => {
		this.props.onClickCloseModal();
		this.props.onClickOpenSideModal('EVALUATION');
	}

	renderHeader() {
		return (
			<div className="subtitle-block survey-subtitle">
				<p className="ch type-heading -ch -h6">
					調查統計
				</p>
				<p className="en type-text -en -xs">
					Survey statistics
				</p>
			</div>
		)
	}
	
	renderBody() {
		return (
			<div className="survey-links">
				<Link className="link" to="/statistics/morakot" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">莫拉克颱風社會衝擊與復原調查(2010~2019)</p>
					<p className="type-text -en -xs">Social Impacts and Recovery Survey of Typhoon Morakot (2010~2019)</p>
				</Link>
				<Link className="link" to="/statistics/sfaa" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">社福機構自然災害風險調查(2011)</p>
					<p className="type-text -en -xs">Disaster Vulnerability Survey of Social Welfare Facilities for Elderly and Disability</p>
				</Link>
				
				<Link className="link" to="/statistics/TainanEarthquake" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">0206臺南震災天然災害社經影響家戶調查(2016)</p>
					<p className="type-text -en -xs">Survey of Social and Ecconomic Impacts of Household for 0206 Tainan Earthquake (2016)</p>
				</Link>
				<Link className="link" to="/statistics/HualienEarthquake" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">0206花蓮震災地震感知與避難行為調查(2018) </p>
					<p className="type-text -en -xs">Survey of Perception on Earthquake and Evacuation Behaviors for 0206 Hualien  Earthquake (2018)</p>
				</Link>
				<a className="link" href="https://drrstat.ncdr.nat.gov.tw/flood/index" onClick={ this.handleClickCloseModal }>
					<p className="type-text -ch -md">0823水災撤離與收容調查(2018)</p>
					<p className="type-text -en -xs">Survey of Evacuation and Shelter Use for 0823 Flood Disaster (2018)</p>
				</a>
			</div>
		)
	}

	renderFooter() {
		return (
			<div className="survey-buttonbar">
				<button onClick={ this.handleClickCloseModal } className="direction-button -prev">
					<span className="icon"><i className="icon-arrow-l-left"></i></span>
					<span className="text type-text -ch -lg">回首頁</span>
				</button>
				<button onClick={ this.handleClickOtherModal } className="direction-button -next" href="">
					<span className="text type-text -ch -lg">評估系統</span>
					<span className="icon"><i className="icon-arrow-l-right"></i></span>
				</button>
			</div>
		)
	}

	render() {
		return (
			<SideModal
				isModalOpen={ this.props.isModalOpen }
				closeModal={ this.handleClickCloseModal }
				modalStyle={ 'gray' }
				background={ SurveyBackground }
				direction={ 'left' }
				headerContent={ this.renderHeader() }
				bodyContent={ this.renderBody() }
				footerContent={ this.renderFooter() }
			></SideModal>
		);
	}
}

export default SurveySideModal;