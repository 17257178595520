const getChartBasic = (chart) => {
  return {
    filter: chart.filter,
    type: chart.type,
    unit: chart.unit,
    notes: chart.notes,
    direction: chart.direction,
  }
}
const getChartHead = (chartHide, data) => {
  let chartHead;
  if (chartHide) {
    const head = data[0].slice();
    for (let i = 0; i < chartHide.length; i++) {
      const index = head.findIndex((cell) => cell === chartHide[i]);
      head.splice(index, 1);
    }
    chartHead = head;
  }
  else {
    chartHead = data[0];
  }

  return chartHead
}
const getAvailiableData = (chartHide, chartBy, data) => {
  let availiableData;
  let dataAfterIgnore = data.slice();
  
  if (chartHide) {
    const head = data[0].slice();
    for (let i = 0; i < chartHide.length; i++) {
      const index = head.findIndex((cell) => cell === chartHide[i]);
      const dataIgnore = data.map((line) => {
        const lineIgnore = line.slice();
        lineIgnore.splice(index, 1);
        return lineIgnore;
      });
      dataAfterIgnore = dataIgnore;
    }
  };
  
  if (chartBy) {

    if (Array.isArray(chartBy)) {
      const matchData = [];
      dataAfterIgnore.forEach((line) => {
        chartBy.forEach((item) => {
          if (line.some(cell => cell === item)) {
            matchData.push(line);
          };
        })
      })
      availiableData = matchData;
    }
    else if (typeof chartBy === "string") {
      availiableData = dataAfterIgnore.forEach((line) => {
        if (line.some(cell => cell === chartBy)) {
          return line;
        };
      });
    }
    else if (typeof chartBy === "object") {
      const keys = Object.keys(chartBy);
      const matchData = [];
      dataAfterIgnore.forEach((line) => {
        let result = 0;
        keys.forEach((key) => {
          if (line.some(cell => cell === chartBy[key])) {
            result += 1;
          };
        })
        if (result === keys.length) {
          matchData.push(line);
        }
      })
      availiableData = matchData;

    }
  }
  else {
    availiableData = data.slice();
    availiableData.splice(0, 1);
  };

  return availiableData;
}
const getDimensions = (categoryBy, chartHead, availiableData) => {
  let dimensions;
  if (categoryBy) {
    if (Array.isArray(categoryBy)) {
      dimensions = getDimensionsWithReverseData(availiableData);
    }
    else if (typeof categoryBy === 'string') {
      dimensions = getDimensionsWithSingleCategory(categoryBy, chartHead, availiableData);
    }
  }
  else {
    dimensions = getDimensionsWithReverseData(availiableData);
  }
  return dimensions;
}
const getDimensionsWithSingleCategory = (categoryBy, chartHead, availiableData) => {
  const startIndex = availiableData[0].findIndex(cell => typeof cell === 'number');
  const pureDimensions = chartHead.slice();
  pureDimensions.splice(0, startIndex);
  return [categoryBy].concat(pureDimensions);
}
const getDimensionsWithReverseData = (availiableData) => {
  const reverseData = [];
  availiableData.forEach((line) => {
    reverseData.push(line[0]);
  });
  return ['options'].concat(reverseData);
}
const getDimensionsWithLegend = (pureDimensions, legendBy, chartHead, availiableData) => {
  let dimensions;
  if (legendBy) {
    if (Array.isArray(legendBy)) {
      const categoryData = [];
      availiableData.forEach((line) => {
        line.forEach((cell) => {
          legendBy.forEach((legend) => {
            if (cell === legend) {
              categoryData.push(cell);
            };
          });
        });
      });
      dimensions = ['options'].concat(categoryData);
    }
    else if (typeof legendBy === 'string') {
      const categoryIndex = chartHead.findIndex((item) => item === legendBy);
      const categoryData = [];
      availiableData.forEach((line) => {
        if (categoryData.indexOf(line[categoryIndex]) === -1) {
          categoryData.push(line[categoryIndex]);
        };
      });
      dimensions = ['options'].concat(categoryData);
    }
  }
  else {
    dimensions = pureDimensions;
  }
  return dimensions;
};

const getSource = (categoryBy, chartHead, availiableData) => {
  let source;
  if (categoryBy) {
    if (Array.isArray(categoryBy)) {
      source = getSourceWithSomeOptions(categoryBy, chartHead, availiableData);
    }
    else if (typeof categoryBy === 'string') {
      source = getSourceWithCategory(categoryBy, chartHead, availiableData);
    }
  }
  else {
    source = getSourceWithAllOptions(chartHead, availiableData);
  }
  return source;
}
const getSourceWithCategory = (categoryBy, chartHead, availiableData) => {
  const startIndex = availiableData[0].findIndex(cell => typeof cell === 'number');
  const categoryIndex = chartHead.indexOf(categoryBy);
  const allCategoryData = availiableData.map((line) => line[categoryIndex]);

  let resultSource;
  const categoryData = [];
  availiableData.forEach((line) => {
    if (categoryData.indexOf(line[categoryIndex]) === -1) {
      categoryData.push(line[categoryIndex]);
    };
  });

  if (allCategoryData.length !== categoryData.length) {
    const sortingData = categoryData.map((category) => {
      const matchData = [];
      availiableData.forEach((line) => {
        if (line.indexOf(category) !== -1) {
          matchData.push(line)
        };
      });
      return matchData;
    })
  
    const reverseData = sortingData.map(() => []);
    sortingData.forEach((data, i) => {
      data.forEach((line) => {
        const pureLine = line.slice();
        pureLine.splice(0, startIndex);
        reverseData[i].push(pureLine);
      });
    });
    resultSource = categoryData.map((category, i) => [category].concat(reverseData[i]));

  }
  else {
    const pureData = availiableData.map((line) => {
      const pureLine = line.slice();
      pureLine.splice(0, startIndex);
      return pureLine;
    });

    resultSource = categoryData.map((category, i) => [category].concat(pureData[i]));
  }

  return resultSource;
};
const getSourceWithSomeOptions = (categoryBy, chartHead, availiableData) => {
  const optionsIndex = categoryBy.map((legend) => {
    return chartHead.findIndex(cell => cell === legend);
  });
  const optionsData = optionsIndex.map(() => []);
  availiableData.forEach((line) => {
    optionsIndex.forEach((index, i) => {
      optionsData[i].push(line[index]);
    });
  });

  return categoryBy.map((category, i) => [category].concat(optionsData[i]));
}
const getSourceWithAllOptions = (chartHead, availiableData) => {
  const startIndex = availiableData[0].findIndex(cell => typeof cell === 'number');
  const categoryData = chartHead.slice();
  categoryData.splice(0, startIndex);

  const pureData = availiableData.map((line) => {
    const pureLine = line.slice();
    pureLine.splice(0, startIndex);
    return pureLine;
  });
  const reverseData = pureData[0].map(() => []);
  pureData.forEach((line) => {
    line.forEach((cell, i) => {
      reverseData[i].push(cell);
    })
  });

  return categoryData.map((category, i) => [category].concat(reverseData[i]));
};

const getSourceWithLegend = (pureSource, chartHead, categoryBy, legendBy, availiableData) => {
  let source;
  if (legendBy) {
    if (Array.isArray(legendBy)) {
      source = getSourceWithSomeCategory(pureSource, legendBy, availiableData);
    }
    else if (typeof legendBy === 'string') {
      source = getSourceWithSingleCategory(pureSource, chartHead, categoryBy, legendBy, availiableData);
    }
  }
  else {
    source = pureSource;
  }
  return source;
};
const getSourceWithSomeCategory = (pureSource, legendBy, availiableData) => {
  const legendsIndex = []
  availiableData.forEach((line, i) => {
    line.forEach((cell) => {
      legendBy.forEach((legend) => {
        if (legend === cell) {
          legendsIndex.push(i + 1);
        };
      });
    });
  });

  const categorySource = pureSource.map(() => []);
  pureSource.forEach((line, i) => {
    legendsIndex.forEach((index) => {
      categorySource[i].push(line[index]);
    });
  });
  const categorySourceWithName = categorySource.map((line, i) => {
    return [pureSource[i][0]].concat(line);
  });
  
  return categorySourceWithName;
}
const getSourceWithSingleCategory = (pureSource, chartHead, categoryBy, legendBy, availiableData) => {
  const categoryIndex = chartHead.indexOf(categoryBy);
  const pureChartHead = chartHead.slice();
  pureChartHead.splice(categoryIndex, 1);

  const legendIndex = pureChartHead.indexOf(legendBy);
  const resultSource = pureSource.map(() => []);
  pureSource.forEach((line, i) => {
    line.forEach((cell) => {
      if(Array.isArray(cell)) {
        resultSource[i].push(cell[legendIndex]);
      }
      else {
        resultSource[i].push(cell);
      }
    })
  })

  return resultSource;
}
const getCustomOptions = (chartData, chart) => {
  const customOptions = {};
  if (chart.mark) {
    customOptions.mark = chart.mark;
  }
  if (chart.sorting) {
    customOptions.sorting = chart.sorting;
  }
  if (chart.range) {
    customOptions.range = chart.range;
  }
  if (chart.info) {
    customOptions.info = chart.info;
  }
  return Object.assign(chartData, customOptions);
}
const converter = (json, charts) => {
  const { data } = json;
  const result = charts.map((chart) => {
    const { chartHide, chartBy, categoryBy, legendBy } = chart;
    
    const validNData = getValidN(json, chartBy, categoryBy, legendBy) || null;
    const chartBasic = getChartBasic(chart);
    const chartHead = getChartHead(chartHide, data);
    const availiableData = getAvailiableData(chartHide, chartBy, data);

    if (availiableData && availiableData.length !== 0) {
      const pureDimensions = getDimensions(categoryBy, chartHead, availiableData);
      const pureSource = getSource(categoryBy, chartHead, availiableData);
  
      const dimensionsWithLegend = getDimensionsWithLegend(pureDimensions, legendBy, chartHead, availiableData);
      const sourceWithLegend = getSourceWithLegend(pureSource, chartHead, categoryBy, legendBy, availiableData);
      
      let chartData = Object.assign(
        {
          dimensions: dimensionsWithLegend,
          source: sourceWithLegend,
          validNData: validNData
        },
        chartBasic,
      );
      chartData = getCustomOptions(chartData, chart);
      return chartData;
    }
    else {
      return false
    }
  });
  if (result.includes(false)) {
    return false
  }
  else {
    return result;
  }
}

const getCategoryIndex = (item, data) => {
  let itemIndex;
  const availiableData = data.slice();
  availiableData.splice(0, 1);
  if (item) {
    if (Array.isArray(item)) {
      const indexs = [];
      itemIndex = item.forEach((element) => {
        availiableData.forEach((line) => {
          const index = line.findIndex((cell) => cell === element);
          if (index !== -1 && indexs.indexOf(index) === -1) {
            indexs.push(index);
          };
        });
      });
      itemIndex = indexs[0];
    }
    else {
      itemIndex = data[0].findIndex(cell => cell === item);
    };
  }
  else {
    itemIndex = null
  }

  return itemIndex;
}
const getValidNBySomeCategory = (validNIndex, chartBy, categoryBy, legendBy, data) => {
  const validNData = [];
  const legendIndex = getCategoryIndex(legendBy, data);
  const categoryIndex = getCategoryIndex(categoryBy, data);
  
  const availiableData = data.slice();
  availiableData.splice(0, 1);  
  availiableData.forEach((line) => {
    const legendName = line[legendIndex] || '';
    const categoryName = line[categoryIndex] || '';
    const validNValue = line[validNIndex];
    
    chartBy.forEach((item) => {
      if (line.some(cell => cell === item)) {
        validNData.push({
          group: legendName,
          name: categoryName || item,
          value: validNValue
        })
      };
    })
  });

  return validNData;
}
const getValidNByAllCategory = (validNIndex, categoryBy, legendBy, data) => {
  const validNData = [];
  const categoryIndex = data[0].findIndex(cell => cell === categoryBy);
  const legendIndex = getCategoryIndex(legendBy, data);
  
  const availiableData = data.slice();
  availiableData.splice(0, 1);

  availiableData.forEach((line) => {
    const categoryName = line[categoryIndex] || '';
    const legendName = line[legendIndex] || '';
    const validNValue = line[validNIndex];

    validNData.push({
      group: legendName,
      name: categoryName,
      value: validNValue
    });
  })
  return validNData;
}
const getValidNByFilterCategory = (validNIndex, chartBy, categoryBy, legendBy, data) => {
  const keys = Object.keys(chartBy);
  const validNData = [];
  const categoryIndex = getCategoryIndex(categoryBy, data);
  const legendIndex = getCategoryIndex(legendBy, data);

  const availiableData = data.slice();
  availiableData.slice();

  availiableData.forEach((line) => {
    let result = 0;
    const categoryName = line[categoryIndex] || null;
    const legendName = line[legendIndex] || '';

    keys.forEach((key) => {
      if (line.some(cell => cell === chartBy[key])) {
        result += 1;
        if (result === keys.length) {
          validNData.push({
            group: legendName,
            name: categoryName || chartBy[key],
            value: line[validNIndex]
          });
        }
      };
    })
  });
  return validNData;
}
const getValidN = (json, chartBy, categoryBy, legendBy) => {
  const { data } = json;
  const validNIndex = data[0].findIndex(cell => cell === '有效N');
  const validNData = [];
  if(validNIndex !== -1) {
    let result;
    if (chartBy) {
      if (Array.isArray(chartBy)) {
        result = getValidNBySomeCategory(validNIndex, chartBy, categoryBy, legendBy, data);
      }
      else if (typeof chartBy === 'object') {
        result = getValidNByFilterCategory(validNIndex, chartBy, categoryBy, legendBy, data);
      }
    }
    else {
      result = getValidNByAllCategory(validNIndex, categoryBy, legendBy, data);
    }
    const validNGroups = [];
    result.forEach((data) => {
      const groupName = data.group;
      if (validNGroups.indexOf(groupName) === -1) {
        validNGroups.push(groupName);
        validNData.push({
          name: groupName,
          items: []
        })
      };
    });

    validNGroups.forEach((group, i) => {
      result.forEach((data) => {
        if (data.group === group) {
          validNData[i].items.push(data);
        };
      });
    });
  }
  
  return validNData;
}

export default converter;