import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

import NewsItem from '../../utils/NewsItem';
import { apiHost } from '../../utils/Shared/constant';


const newsCategoryList = [
	{ title: '全部', id: 0 },
	{ title: '調查統計', id: 6 },
	{ title: '社會脆弱度評估', id: 7 },
	{ title: '撤離與收容評估', id: 8 }
]

class HomePageNewsBlock extends Component {
	constructor(params) {
		super(params);
		this.state = {
			newsItems: [],
			nowCategoryID: 0,
		};
	}
	componentDidMount() {
		this.loadCategory(newsCategoryList[0].id);
	}

	loadCategory = (id) => {
		let url = `${apiHost}/api/Content/NewsList?data.perPage=3`;

		if (id) {
			url += `&data.categoryID=${id}`;
		}

		axios.get(url)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						newsItems: res.data.Result,
						nowCategoryID: id,
					})
				}
			})

	}

	render() {
		const { loadCategory } = this;
		const { nowCategoryID } = this.state;
		return (
			<div className="news-block">
				<div className="filter filter-bar">
					{
						newsCategoryList.map(item =>
							<button key={'newsC' + item.id} className={'filterbutton ghost-button -yellow' + (nowCategoryID === item.id ? ' -active' : '')} onClick={() => { loadCategory(item.id) }} >
								<span className="type-text -ch -md">{item.title}</span>
							</button>
						)
					}
				</div>
				<div className="news news-list row">
					{
						this.state.newsItems &&
						this.state.newsItems.length > 0 &&
						this.state.newsItems.map((item, i) => {
							const { Summary, ID, Title, Published } = item;
							return <NewsItem className="col-lg-4" title={Title} content={Summary} href={'/news/' + ID} sorting={''} date={Published} key={i} />
						})
					}
				</div>
			</div>
		);
	}
}

export default HomePageNewsBlock;
