import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils

class NewMorakotSubjectIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
			{ name: '調查主題', href: '/statistics/morakot/intro' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<div className="new-morakot-page">
					<div className="container">
						<div className="morakot-chart-title">
							<div className="title">調查主題</div>
						</div>
						<div className="morakot-subject-selector">
							{/* <p className="description">請選擇要查詢的主題</p> */}

							<div className="subject subject-grid">
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/disaster-reduction/">
										<p className="type-text -ch -md">減災</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/disaster-response/">
										<p className="type-text -ch -md">應變</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/disaster-recover/">
										<p className="type-text -ch -md">復原與重建</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/respondent-demographics/">
										<p className="type-text -ch -md">受訪者特徵</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/household-demographics/">
										<p className="type-text -ch -md">家戶特徵</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/disability/">
										<p className="type-text -ch -md">行動不便者</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/disaster-impact/">
										<p className="type-text -ch -md">災害衝擊</p>
									</Link>
								</div>
								<div className="subjectitem">
									<Link className="button" to="/statistics/morakot/resource-acquisition/">
										<p className="type-text -ch -md">資源取得</p>
									</Link>
								</div>
								<div className="subjectitem -sm">
									<Link className="button" to="/statistics/morakot-house/">
										<p className="type-text -ch -md">永久屋專區</p>
										<div className="bubble">可檢視永久屋社區為主的統計圖表</div>
									</Link>
									<Link className="button" to="/statistics/morakot-city/">
										<p className="type-text -ch -md">縣市專區</p>
										<div className="bubble">可檢視調查地區各縣市為主的統計圖表</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default NewMorakotSubjectIntro;
