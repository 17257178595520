let init = {
  isModalOpen: false,
}

export default (state = init, action) => {
  switch (action.type) {
    case 'OPEN_SURVEY_SIDEMODAL':
      return { ...state, isModalOpen: true }

    case 'CLOSE_SURVEY_SIDEMODAL':
      return { ...state, isModalOpen: false }

    default:
      return state
  }
}
