import React, { Component } from 'react';
import './index.scss';

class CustomRadio extends Component {
	render() {
		return (
			<div className={"custom-radio " + (this.props.color + ' ') + (this.props.className || "")}>
				<input type="radio"
					className={this.props.checked ? '-checked' : ''}
					name={this.props.group}
					id={this.props.idName}
					defaultChecked={this.props.checked}
					onChange={this.handleInputChange}
				/>
				<label className="label"
					data-group={this.props.group}
					data-num={this.props.num}
					htmlFor={this.props.idName}
					onClick={this.props.onClick}>
					<span className="icon"></span>
					{this.props.children}
				</label>
			</div>
		);
	}
}

export default CustomRadio;
