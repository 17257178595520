import React, { Component } from 'react';
import './index.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sourceHost } from '../Shared/constant';
import Slider from "react-slick";

function PrevButton(props) {
	const { onClick, color } = props;
	return (
		<button
			className={"slider-prev round-button -" + color + " -dark"}
			onClick={onClick}
		>
			<i className="icon-arrow-left"></i>
		</button>
	);
}

function NextButton(props) {
	const { onClick, color } = props;
	return (
		<button
			className={"slider-next round-button -" + color + " -dark"}
			onClick={onClick}
		>
			<i className="icon-arrow-right"></i>
		</button>
	);
}

class LinksSlider extends Component {
	constructor(params) {
		super(params);
		this.state = {
			color: '',
			slickSettings: {
				infinite: true,
				prevArrow: <PrevButton color={this.props.theme} />,
				nextArrow: <NextButton color={this.props.theme} />,
				slidesToShow: 3,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1024,
						arrows: true,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 780,
						arrows: true,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '20px',
						}
					}
				]
			}
		}
	}
	renderItems() {
		let data = this.props.data || [];
		if (data.length > 3) {
			return (
				<Slider className="slider" {...this.state.slickSettings}>
					{
						this.props.data &&
						this.props.data.map((item, i) => {
							const { ImageUrl, NameCn, NameEn } = item;
							return (
								<div key={'a' + i} className="slider-item">
									<div className="link-item">
										<a className={"image -" + this.props.theme} href={item.Link} target="_blank" rel="noopener noreferrer">
											<img src={`${sourceHost}/${ImageUrl}`} alt="" />
										</a>
										<div className="title type-text -ch -lg">
											{NameCn}
										</div>
										<div className="content type-text -en -md">
											{NameEn}
										</div>
										{/* <a className={"button text-button -dark-" + this.props.theme} href={item.Link} target="_blank" rel="noopener noreferrer" >
											<span className="type-text -ch -md">VISIT</span>
											<i className="icon-arrow-right"></i>
										</a> */}
									</div>
								</div>
							)
						})
					}
				</Slider>
			)
		}
		else {
			return (
				this.props.data &&
				this.props.data.map((item, i) => {
					const { ImageUrl, NameCn, NameEn } = item;
					return (
						<div key={'b' + i} className="link-item -single">
							<a href={item.Link} target="_blank" rel="noopener noreferrer" className={"image -" + this.props.theme}>
								<img src={`${sourceHost}/${ImageUrl}`} alt="" />
							</a>
							<div className="title type-text -ch -lg">
								{NameCn}
							</div>
							<div className="content type-text -en -md">
								{NameEn}
							</div>
							{/* <a href={item.Link} target="_blank" rel="noopener noreferrer" className={"button text-button -dark-" + this.props.theme}>
								<span className="type-text -en -md">VISIT</span>
								<i className="icon-arrow-right"></i>
							</a> */}
						</div>
					)
				})
			)
		}

	}
	render() {
		return (
			<div className={"list " + this.props.className}>
				{this.renderItems()}
			</div>
		);
	}
}

export default LinksSlider;
