import React, { Component } from 'react';
import './index.scss';

// Utils
import CustomRadio from '../../utils/CustomRadio';

class ChartFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }
  componentDidMount() {
    const { data } = this.props;
    this.setState({ value: data[0] });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { value } = this.props;
      this.setState({ value: value });
    }
  }
  
  render() {
    const { title, data, label } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        {
          data &&
          data.map((item, i) => {
            return (
              <CustomRadio key={i} className="radio" color="green"
                group={label + '-' + title}
                idName={item + '-' + title}
                checked={value === item}
                // checked={i === 0 ? true : false}
                onClick={() => { this.props.getRadio({
                  label: label,
                  item: item
                }) }}
              >
                <span className="text type-text -ch -xs">{ item }</span>
              </CustomRadio>
            )
          })
        }
      </React.Fragment>
    );
  }
}

export default ChartFilter;
