import { connect } from 'react-redux';
import Breadcrumb from './pure';

const mapStateToProps = (state, ownProps) => ({
  // TODO:     將Store的State對應到此Component的Props

  // someValue: state.breadcrumb.someValue
  
})

const mapDispatchToProps = (dispatch) => ({
  // TODO:     將一Dispatch action的Function對應到此Component的Props

  // handleSomething: () => dispatch({type: 'SOME_ACTION_TYPE'}),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumb);
