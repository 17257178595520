import React, { Component } from 'react';
import './index.scss';

class SvgMapIsland extends Component {
	render() {
		const { infoData, onAreaHover } = this.props;
		const kinmenData = infoData.data.find(item => item.name === "金門縣");
		const lianjiangData = infoData.data.find(item => item.name === "連江縣");
		return (
			<div className="taiwan-map-other">
				{
					kinmenData &&
					<div className="kinmen">
						<svg width="120px" height="75px" viewBox="0 0 120 75">
							<g id="金門縣">
								<polygon {...kinmenData.attribute} onMouseEnter={(e) => { onAreaHover(["金門縣"], e) }} points="15.4676948 37.8285809 20.5190109 39.6561514 24.629088 43.0299882 23.342728 51.9557262 21.8367455 54.5910368 19.2640255 53.3261015 15.9383143 55.223495 14.3068333 58.8424389 8.00053177 59.6153903 5.14544004 63.4800051 0 58.5964979 1.28636001 55.609994 1.0039883 51.9908644 4.20420101 47.7038592 7.21616591 40.0076012 12.1106089 38.0043111"></polygon>
								<polygon {...kinmenData.attribute} onMouseEnter={(e) => { onAreaHover(["金門縣"], e) }} points="88.7274661 0 96.8534964 2.35616253 99.7085881 8.82636278 106.454135 11.1118719 105.983515 13.7137349 108.807232 18.108529 108.744483 20.3937004 111.5682 23.9091865 114.737038 25.8074672 112.164318 28.0572071 113.482053 35.4386009 115.364531 38.4260617 118 39.3749901 116.24302 45.4196799 113.482053 45.0331183 110.972082 47.24703 113.638926 50.2339303 108.901356 57.1208313 105.450146 58.2451518 99.7713374 61.9693203 95.7240096 62.4260427 98.516352 57.7532641 96.0063813 54.0639835 88.3823451 51.7097582 84.8056368 48.5120874 77.2443499 48.4418071 71.3772933 50.0582342 62.8433927 54.4856265 57.1332093 61.617993 53.9643712 70.4357147 49.1954267 73 43.2342462 71.0680139 40.7242755 68.0821009 37.2730657 68.2226176 34.0101037 63.5151369 28.6764158 59.4748544 29.9627759 57.4721836 34.4493486 56.5937992 39.5320394 47.8795619 39.5320394 42.9245586 36.5200744 38.0394571 36.4259505 34.8410918 32.3158734 28.6196295 35.2650891 21.0968134 41.9165116 18.1436861 46.7168306 19.4796418 52.6780112 26.5105195 55.9723478 28.549327 61.274661 30.2014156 67.0162191 30.2014156 74.2010104 23.5224926 74.9540016 20.4288563 77.1816006 19.2335469 76.4286094 11.9908918 80.0366924 10.2328398 82.2015421 6.29462472"></polygon>
							</g>
						</svg>
					</div>
				}
				{
					lianjiangData &&
					<div className="lianjiang">
						<svg width="45px" height="75px" viewBox="0 0 45 75">
							<g id="連江縣">
								<polygon {...lianjiangData.attribute} onMouseEnter={(e) => { onAreaHover(["連江縣"], e) }} points="2.90566038 54.12337 7.79100145 56.9424234 6.35413643 61.1885918 14.3047896 62.3660467 17.5936139 58.0485975 22.3193033 57.6917694 23.5007257 62.7228466 20.3076923 66.5761483 14.3367199 70.3221732 10.8882438 69.0735246 5.93904209 71 1.85195936 71 0 67.0399547 3.19303338 62.9012458 1.91582003 60.4749714"></polygon>
								<polygon {...lianjiangData.attribute} onMouseEnter={(e) => { onAreaHover(["連江縣"], e) }} points="39.3381713 22.212298 43.904209 24.3188177 44 28.1032236 40.2002903 30.0667348 39.3062409 32.422862 35.2830189 32.1015775 29.8867925 33.8150747 28.0348331 37.5631767 28.8011611 42.6316599 25.5761974 44.5589971 25.1930334 39.098045 27.6197388 35.4571296 26.6937591 32.1372758 31.1640058 28.3531287 33.2714078 28.8529356"></polygon>
								<polygon {...lianjiangData.attribute} onMouseEnter={(e) => { onAreaHover(["連江縣"], e) }} points="36.2089985 0 37.3904209 3.50024259 34.4847605 7.78597065 31.2597968 6.17885917"></polygon>
							</g>
						</svg>
					</div>
				}
			</div>
		);
	}
}

export default SvgMapIsland;
