import React from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import * as topojson from 'topojson-client'
import anime from 'animejs'
import axios from 'axios'

import './index.css'

export default class ReactSvgZoomMap extends React.Component {

  static propTypes = {
    
    className: PropTypes.string,

    countyJsonSrc: PropTypes.string.isRequired,
    townJsonSrc: PropTypes.string,
    villageJsonSrc: PropTypes.string,
    
    pins: PropTypes.array,
    pinRadiusWithLayer: PropTypes.array,

    onAreaClick: PropTypes.func,
    onAreaHover: PropTypes.func,
    onPinClick: PropTypes.func,
    onPinHover: PropTypes.func,
    onPinLeave: PropTypes.func,

    zoomDelay: PropTypes.number,
    zoomDuration: PropTypes.number,

    county: PropTypes.string,
    town: PropTypes.string,
    village: PropTypes.string,
    infoData: PropTypes.object,
    
    ignoreArea: PropTypes.array,
    disableArea: PropTypes.array,

    isMapInit: PropTypes.func,
  }

  static defaultProps = {
    pinRadiusWithLayer: [2, 0.3, 0.15],
    zoomDelay: 100,
    zoomDuration: 700,
    county: '',
    town: '',
    village: '',
    infoData: {}
  }

  state = {
    isInit: false,
    svgWidth: 1280,
    svgHeight: 720,
    svgScale: 10000,

    countyJsonData: null,
    townJsonData: null,
    villageJsonData: null,

    countyMapData: null,
    townMapData: null,
    villageMapData: null,
    
    nowSelect: [],
    nowScale: 1,
    currentScale: 0,
    animating: false,
    svgDisplayParams: [{ scale: 1, top: 0, left: 0 }],
  }

  mapCompRoot = React.createRef();
  mapSvgRoot = React.createRef();
  mapSvgRootGroup = React.createRef();


  /* Life Cycle */
  componentDidMount() {
    const { loadTopoJson, calcSvg } = this;
    const { countyJsonSrc, townJsonSrc, villageJsonSrc } = this.props;

    countyJsonSrc && loadTopoJson(countyJsonSrc).then( countyJsonData => this.setState({ countyJsonData }, calcSvg))
    townJsonSrc && loadTopoJson(townJsonSrc).then( townJsonData => this.setState({ townJsonData }, calcSvg))
    villageJsonSrc && loadTopoJson(villageJsonSrc).then( villageJsonData => this.setState({ villageJsonData }, calcSvg))

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate( prevProps ) {
    const { infoData, county, town, village } = this.props;
    const { countyMapData, townMapData, villageMapData } = this.state;

    if ( !countyMapData || !townMapData || !villageMapData ) return;
    if (county != prevProps.county || town != prevProps.town || village != prevProps.village) {
      this.handleAreaUpdate(county, town, village);
    }
    if (infoData != prevProps.infoData) {
      this.addMapData();
    }
  }

  /* Event Handler */

  handleResize = () => { this.calcSvg() }

  handleAreaUpdate = (...selectArray) => {
    const { countyMapData, townMapData, villageMapData, nowSelect } = this.state;
    const [ county, town, village ] = selectArray;
    if (!countyMapData || !townMapData || !villageMapData) return;

    if (county && !countyMapData.find( _ => _.countyName === county)) return;
    if (town && !townMapData.find( _ => _.townName === town)) return;
    if (village && !villageMapData.find( _ => _.villageName === village)) return;

    selectArray = selectArray.filter(item => item)

    if (selectArray.length >= 1 && townMapData == null) return;
    if (selectArray.length >= 2 && villageMapData == null) return;
    
    if (this.state.animating) return;
    // if (selectArray.length === 3) selectArray[2] = ''
    
    const isZoomIn = selectArray.length > nowSelect.length;

    this.setState(
      { nowSelect: selectArray },
      () => {
        setTimeout(() => {
          this.executeAnimate(isZoomIn)
        }, 300);
      }
    );
  }

  handleMapItemClick = ( county, town, village, e) => {
    const { onAreaClick } = this.props;
    onAreaClick && onAreaClick([county, town, village], e);
  }

  handleMapItemHover = ( county, town, village, e) => {
    const { onAreaHover } = this.props;
    onAreaHover && onAreaHover([county, town, village], e);
  }

  handleUpperLayerClick = () => {
    if (this.state.animating || this.state.nowSelect.length === 0) return;

    const { nowSelect } = this.state;
    const { onAreaClick } = this.props;

    const selectArray = nowSelect.slice(0, -1).filter(item => item);
    onAreaClick && onAreaClick([selectArray[0] || '', selectArray[1] || '', selectArray[2] || '']);
  }

  handlePinClick = (pinItem, e) => {
    const { onPinClick } = this.props;
    onPinClick && onPinClick(pinItem, e);
  }

  handlePinHover = (pinItem, e) => {
    const { onPinHover } = this.props;
    onPinHover && onPinHover(pinItem, e);
  }
  
  handlePinLeave = (pinItem, e) => {
    const { onPinLeave } = this.props;
    onPinLeave && onPinLeave(pinItem, e);
  }

  /* Methods */

  calcSvg = () => {
    const { countyJsonData, townJsonData, villageJsonData } = this.state;
    if ( !countyJsonData ) return;

    const mapCompRootRect = this.mapCompRoot.current.getBoundingClientRect();
    const svgScale = mapCompRootRect.width > mapCompRootRect.height ?
                      mapCompRootRect.height / 1083.04 * 10000 :
                      mapCompRootRect.width / 1216.83 * 10000;
                      
    this.setState(
      {
        svgWidth: mapCompRootRect.width,
        svgHeight: mapCompRootRect.height,
        svgScale
      }, 
      () => {
        this.setState({
          countyMapData: this.topoSvgConverter(countyJsonData),
          townMapData: townJsonData ? this.topoSvgConverter(townJsonData) : null,
          villageMapData: villageJsonData ? this.topoSvgConverter(villageJsonData) : null
        }, () => {
          this.addMapData();
          this.handleAreaUpdate(this.props.county, this.props.town, this.props.village);
        });
        // this.executeAnimate();
      }
    );
  }
  addMapData() {
    const { countyMapData, townMapData, villageMapData } = this.state;
    const { infoData } = this.props;
    
    if (infoData) {
      switch (infoData.layer) {
        case 'county':
          const countyMapDataWithInfo = countyMapData && this.mergeMapData(infoData, countyMapData);
          this.setState({ countyMapData: countyMapDataWithInfo });
          break;
          
        case 'town':
          const townMapDataWithInfo = townMapData && this.mergeMapData(infoData, townMapData);
          this.setState({ townMapData: townMapDataWithInfo });
          break;

        case 'village':
          const villageMapDataWithInfo = villageMapData && this.mergeMapData(infoData, villageMapData);
          this.setState({ villageMapData: villageMapDataWithInfo });
          break;
      
        default:
          break;
      }
    }
    
  }
  mergeMapData(infoData, mapData) {
    const { layer } = infoData;
    mapData.map(item => {
      const name = item[`${layer}Name`];
      item.attribute = null;
      infoData.data.forEach(subItem => {
        if (name === subItem.name) {
          item.attribute = subItem.attribute;
        }
      })
    });
    return mapData
  }
  loadTopoJson = jsonSrc => {
    return new Promise((resolve, reject) => {
      axios.get(jsonSrc)
        .then( res => {
          resolve(res.data);
        })
        .catch( err => {
          reject(err);
        })
    })
  }

  topoSvgConverter = jsonData => {

    let mapPropertyName = 'map';

    if (!jsonData.objects.map) {
      mapPropertyName = Object.keys(jsonData.objects).filter(item => item.indexOf('MOI') >= 0)[0];
    }

    let topo = topojson.feature(jsonData, jsonData.objects[mapPropertyName]);
    let prj = this.getProjection();
    let path = d3.geoPath().projection(prj)
    
    let temp = []

    topo.features.forEach(feature => {
      temp.push({
        d: path(feature),
        countyName: feature.properties.COUNTYNAME,
        townName: feature.properties.TOWNNAME || '',
        villageName: feature.properties.VILLNAME || '',
        geoJsonObject: feature
      })
    });
    
    return temp
  }

  executeAnimate = (isZoomIn = true) => {
    const { nowSelect, isInit } = this.state;
    const { pinRadiusWithLayer, zoomDuration, zoomDelay } = this.props;

    const svgRect = this.mapSvgRoot.current.getBoundingClientRect();
    const tRect = this.mapSvgRootGroup.current.getBBox();
    const cScale = svgRect.width / tRect.width;

    // this.setState({ currentScale: 9999 });

    anime({
      targets: this.mapSvgRoot.current.querySelectorAll('.map-item-path'),
      keyframes: isZoomIn ? 
        [
          {strokeWidth: 1 / cScale},
          {strokeWidth: 0.5 / cScale},
        ]:
        [
          {strokeWidth: 0.5 / cScale},
          {strokeWidth: 0.5 / cScale},
        ]
      ,
      easing: 'easeOutQuint',
      duration: zoomDuration + zoomDelay,
    });

    anime({
      targets: this.mapSvgRoot.current.querySelectorAll('.pin'),
      r: pinRadiusWithLayer[nowSelect.length] || 0,
      easing: 'easeOutQuint',
      duration: zoomDuration,
      delay: zoomDelay,
    });

    let rootRect = this.mapSvgRoot.current.viewBox.baseVal;
    anime({
      targets: rootRect,
      x: tRect.x,
      y: tRect.y,
      width: tRect.width,
      height: tRect.height,
      easing: 'easeOutQuint',
      duration: zoomDuration,
      delay: zoomDelay,
      complete: () => {
        const svgRect = this.mapSvgRoot.current.getBoundingClientRect();
        const tRect = this.mapSvgRootGroup.current.getBBox();
        let cScale = svgRect.width / tRect.width;
        cScale = cScale === Infinity ? 1 : cScale;
        // this.state.currentScale !== cScale && this.calcSvg();
        if (!isInit) this.setState({ isInit: true }, () => this.props.isMapInit && this.props.isMapInit() );
        this.setState({ 
          animating: false,
          currentScale: cScale
        })
      },
      update: () => {
        // this.mapSvgRoot.current &&
        // this.mapSvgRoot.current.setAttribute('viewBox', `${rootRect.x} ${rootRect.y} ${rootRect.width} ${rootRect.height}`);
      }
    });

    return;
  }
  



  /* Getters */

  getProjection = () => {
    const { svgWidth, svgHeight, svgScale } = this.state;
    return d3.geoMercator()
            .center([120.751864, 23.575998])
            .scale(svgScale)
            .translate([svgWidth/2, svgHeight/2])
  }
  
  getNowSelectString = () => {
    return this.state.nowSelect.length > 0 ? this.state.nowSelect.reduce((acc, curr) => acc + curr) : '';
  }




  /* Renders */

  render() {
    const { 
      isInit,
      svgWidth, svgHeight, 
      countyMapData, townMapData, villageMapData, nowSelect
    } = this.state;

    const loaded = (countyMapData);

    const { className } = this.props;

    const layerName = ['all', 'county', 'town', 'village'];
    
    return (
      <div className={'react-svg-zoom-map' + (className ? ` ${className}` : '') } ref={this.mapCompRoot}>
        
        <div className="controls">
          { loaded && nowSelect.length > 0 && <button onClick={this.handleUpperLayerClick}>上一層</button> }
        </div>

        <div className="labels">
          { this.getNowSelectString() }
          { !loaded ? 'Loading...' : '' }
        </div>

        {
          <svg className={layerName[nowSelect.length]} width={svgWidth} height={svgHeight} ref={this.mapSvgRoot}>
            <g className="map-g" ref={this.mapSvgRootGroup} >
              {
                loaded &&
                <g className="map-items">
                  { nowSelect.length === 0 && this.mapItemsRender(countyMapData, '-county') }
                  { nowSelect.length === 1 && this.mapItemsRender(townMapData, '-town') }
                  { nowSelect.length >= 2 && this.mapItemsRender(villageMapData, '-village') }
                </g>
              }
              <g className="pins">
                { loaded && this.mapPinsRender() }
              </g>
            </g>
          </svg>
        }
      </div>
    )
  }

  mapItemsRender = (mapData, className) => {
    if (mapData) {
      return mapData.filter(item => {
        const { countyName, townName, villageName } = item;
        return (countyName + townName + villageName).indexOf(this.getNowSelectString()) >= 0;
      })
      .map((item, index) => {
        return this.mapItemRender(item, index, className)
      }) 
    }
    return null
  }

  mapItemRender = (item, index, className) => {
    const { ignoreArea, disableArea } = this.props;
    const isItemIgnore = ignoreArea ? (ignoreArea.find(elem => item.countyName === elem || item.townName === elem || item.villageName === elem)) : null;
    const isItemDisable = disableArea ? (disableArea.find(elem => item.countyName === elem || item.townName === elem || item.villageName === elem)) : null;
    if (!isItemIgnore) {
      return (
        <g style={{ fill: '#eee', stroke: '#000'}}
          key={className + index} 
          className={'map-item ' + className + (isItemDisable ? ' -disable' : '')}
          onClick={ e => !isItemDisable && this.handleMapItemClick(item.countyName, item.townName, item.villageName, e) }
          onMouseEnter={ e => this.handleMapItemHover(item.countyName, item.townName, item.villageName, e) }
        >
          <path d={item.d} id={item.location} className="map-item-path" {...item.attribute} >
            <title>{item.countyName + item.townName + item.villageName}</title>
          </path>
        </g>
      )
    }
  }

  mapPinsRender = () => {
    const { nowSelect, countyMapData, townMapData, villageMapData, currentScale } = this.state;
    const { pins, pinsLimit } = this.props;
    
    return (<>
      {
        pins && 
        pins.filter(item => {
          const depth = nowSelect.length;
          let nowArea = {};

          if (depth === 0) {
            return item;
          }
          else if (depth === 1) {
            nowArea = countyMapData.find(item => item.countyName == nowSelect[0]);
          }
          else if (depth === 2) {
            nowArea = townMapData.find(item => item.countyName == nowSelect[0] && item.townName == nowSelect[1]);
          }
          else if (depth === 3) {
            nowArea = villageMapData.find(item => item.countyName == nowSelect[0] && item.townName == nowSelect[1] && item.villageName == nowSelect[2]);
          }
          if (pinsLimit) {
            return d3.geoContains(nowArea.geoJsonObject, [item.location[1], item.location[0]]) ? item : null;
          }
          else {
            return item;
          }
        }).
        map((item, index) => {
          const point = this.getProjection()([item.location[1], item.location[0]]);
          return (
            <circle 
              className={`pin -layer-${nowSelect.length} ${item.active ? '-active' : ''}`} key={`pin${index}`} 
              onClick={ e => {this.handlePinClick(item, e)} }
              onMouseEnter={ e => {this.handlePinHover(item, e)} }
              onMouseLeave={ e => {this.handlePinLeave(item, e)} }
              transform={`translate(${point[0].toFixed(2)} ${point[1].toFixed(2)})`} 
              x="0%" y="0%"
              style={{
                'r': `${1/Math.pow(currentScale, 0.53)}`,
                'strokeWidth': `${1/Math.pow(currentScale, 0.53)*1.1}`
              }}
            >
              <title>{ item.title }</title>
            </circle>
          )
        })
      }
    </>)
  }
}
