import { combineReducers } from 'redux';

// General
import fetchReducer from '../fetchReducer';

// Pages

// Container
import headerReducer from '../Header/reducer';
import homePageSideModalReducer from '../Home/SideModal/reducer';
import surveySideModalReducer from '../Home/SurveySideModal/reducer';
import evaluationSideModalReducer from '../Home/EvaluationSideModal/reducer';
import memberLoginReducer from '../Member/Login/reducer';
import advanceChartsReducer from '../Morakot/AdvanceCharts/reducer';

// Utils
import sideMenuReducer from '../utils/SideMenu/reducer';
import breadcrumbReducer from '../utils/Breadcrumb/reducer';

// News
import newsReducer from '../News/reducer';

import newsArticlePageReducer from '../News/NewsArticlePage/reducer';
// [AUTO_IMPORT_BY_ADD_COMPONENT]

export default combineReducers({
  // General
  fetchedData: fetchReducer,
  
  // Pages

  // Container
  header: headerReducer,
  homePageSideModal: homePageSideModalReducer,
  surveySideModal: surveySideModalReducer,
  evaluationSideModal: evaluationSideModalReducer,
  memberLogin: memberLoginReducer,
  advanceChart: advanceChartsReducer,
  
  // Untils
  sideMenu: sideMenuReducer,
  breadcrumb: breadcrumbReducer,

  // News
  news: newsReducer,

  newsArticlePage: newsArticlePageReducer,
// [AUTO_COMBINE_BY_ADD_COMPONENT]

});
