import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import './index.scss';

class ArticleItem extends Component {
	render() {
		const { image, author, title, content, href, sorting, date, className, color } = this.props;
		return (
			<div className={"article-item" + (className ? ' ' + className : '')}>
				<Link className={`image -${color}`} to={href}>
					<img src={ image } alt="" />
				</Link>
				<div className="content">
					<Link to={href} className="datemonth type-heading -en -lg">
						{dayjs(date).format('YYYY.MM.DD')}
					</Link>
					<Link to={href} className="title">{title}</Link>
					{ author && <div className="author"><label>作者 </label> {author} </div>}
					{
						sorting &&
						<div className="sort">
							<label>分類 </label>
							<span>
								{
									sorting.map((word, i) => 
										<span key={i}
										className="tag"
										onClick={() => { this.props.selectedTag(word) }}
										>{word}</span>
									)
								}
							</span>
						</div>
					}
					<Link to={href} className="summary">{content}</Link>
					{/* <Link className={`read text-button -${color}-dark`} to={href}>
						<span className="type-text -en -xs">READ MORE</span>
						<i className="icon-arrow-right"></i>
					</Link> */}
				</div>
			</div>
		);
	}
}

export default ArticleItem;
