import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import ResourceLinks from '../../Series/ResourceLinks';
import './index.scss';
import Axios from 'axios';

// Container
import ArticleList from '../../Series/ArticleList';

import EvacuationBackground1 from '../../../images/evacuation-bg1.png';
import EvacuationBackground2 from '../../../images/evacuation-bg2.png';
import EvacuationBackground3 from '../../../images/evacuation-bg3.png';

import EvacuationTree from '../../../images/evacuation-tree.png';

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}

class EvacuationIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			scrollSpyItems: [
				{
					name: 'use',
					title: '撤離與收容評估系統',
					show: false,
				},
				{
					name: 'policy',
					title: '資料使用說明與限制',
					show: false,
				},
				{
					name: 'articles',
					title: '主題館',
					show: false,
				},
			],
			isActive: {
				intro: {
				},
				estimate: {
				},
				shelter: {
				}
			},
			isActiveLength: undefined
		};
	}
	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('PINK');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.getIsActive()
	}
	handleClickToggle = (e) => {
		const controller = e.currentTarget;
		const content = e.currentTarget.nextSibling;
		
		if (content.classList.contains('-show')) {
			controller.classList.remove('-open');
			content.classList.remove('-show');
		}
		else {
			controller.classList.add('-open');
			content.classList.add('-show');
		}
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});
		
	}
	getIsActive = () => {
		Axios.get('https://drrstat.ncdr.nat.gov.tw/Nat/api/Content/GetMenuSwitch')
        .then(result => {
			const intro = result.data.Result.find(item => item.Title === '歷史資料查詢與展示') || {IsActive: 0}
			const estimate = result.data.Result.find(item => item.Title === '撤離/收容人口及物資評估') || {IsActive: 0}
			const shelter = result.data.Result.find(item => item.Title === '收容所資訊查詢') || {IsActive: 0}

			let isActiveLength = 0
			for(let item of result.data.Result){
				if(item.IsActive === 1){
					isActiveLength ++
				}
			}
			this.setState({
				isActive: {intro, estimate, shelter},
				isActiveLength
			})
        })
	}
	render() {
		const { scrollSpyItems, isActive, isActiveLength } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<div className="evacuation-page">
					<div className="scroll-spy">
						{
							scrollSpyItems &&
							scrollSpyItems.map((item, index) => {
								return (
									<div key={index}
									className={"item " + item.name + (item.show ? " -show": "")}
									onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true })}}>
										<span>{item.title}</span>
									</div>
								)
							})
						}
					</div>
					<section className="evacuation-heading">
						{
							isActive.intro.IsActive === 1 &&
							<Link className="block " to="/evaluation/evacuation/intro" style={{width: `${100/isActiveLength}%`}}>
								<h2 className="heading">歷史資料查詢與展示</h2>
								<img className="background" src={EvacuationBackground1} alt=""/>
							</Link>
						}
						{
							isActive.estimate.IsActive === 1 &&
							<Link className="block " to="/evaluation/evacuation/estimate" style={{width: `${100/isActiveLength}%`}}>
								<h2 className="heading">撤離／收容人口及物資推估</h2>
								<img className="background" src={EvacuationBackground2} alt="" />
							</Link>
						}
						{
							isActive.shelter.IsActive === 1 &&
							<Link className="block " to="/evaluation/evacuation/shelter" style={{width: `${100/isActiveLength}%`}}>
								<h2 className="heading">收容所資訊查詢</h2>
								<img className="background" src={EvacuationBackground3} alt="" />
							</Link>
						}
					</section>
					<Scroll.Element name="use">
						<Waypoint onEnter={() => { this.handleWaypointEnter('use') }} {...waypointOption}>
							<section className="evacuation-section evacuation-info">
								<div className="container">
									<h1 className="sectiontitle">撤離與收容評估系統</h1>
									<div className="sectioncontent">
										<p className="">國家災害防救科技中心為協助各地方政府於減災階段能有效執行撤離與收容作業規劃，建置本站系統。本站共提供三類服務：</p>
										<p className="strong">1. 歷史資料查詢與展示</p>
										<ul className="evacuation-list -circle">
											<li>
												<p className="label">撤離與收容人口統計</p>
												<p className="description">本站建置自 2011 年以來歷次颱風／豪雨事件中撤離與收容人數資料庫，使用者可依地區	別、歷史事件、颱風路徑、颱風強度或年度的查詢，瀏覽統計圖表及下載。</p>
											</li>
											<li>
												<p className="label">歷史警戒熱區查詢</p>
												<p className="description">本站亦統計各地方歷史上發布過土石流（自 2005 年起）及淹水（自 2011 年起）警戒區域	的統計資訊，以提供使用者災害警戒熱區（hot spot）的空間分布，幫助使用者更有效率的	進行減災整備規劃。</p>
											</li>
										</ul>
										<p className="strong">2. 撤離／收容人口及物資估計</p>
										<img src={EvacuationTree} alt="" />
										<ul className="evacuation-list -circle">
											<li>
												<p className="label">撤離／收容人口估計</p>
												<p className="description">本站提供多元估計方法，進行撤離與收容人口估計，包含：依歷史撤離人口及收容人口、依潛勢人口（政府公告之潛勢／保全人口）、自訂撤離人數及收容人數。估計結果可協助地方政府於平時減災整備作業時，有更多依據可參考應用。</p>
											</li>
											<li>
												<p className="label">所需收容空間估計</p>
												<p className="description">依據系統估計出來的收容人數，本站另提供所需的收容空間資訊，同時，也提供各地方政府	現有規劃的收容空間，使用者可針對兩者進行比對，協助地方政府更有效率的進行收容所整備規劃。</p>
											</li>
											<li>
												<p className="label">所需物資估計</p>
												<p className="description">依據系統估計出來的收容人數，本站同時提供所需的物資儲備量，包含：食品、寢具、日用品、衛生設備及設施設備等項目。</p>
											</li>
										</ul>
										{/* <p className="strong">3. 收容所資訊查詢</p>
										<p className="">為完備地方政府減災整備作業，本站亦提供衛福部「重大災害民生物資及志工人力整合網絡平台管理系統」資料庫中已建置之收容所相關資訊，包含：地址、可收容人數、適用災別、聯絡人姓名及電話（需加入會員）等資訊，以供參用。</p> */}
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="policy">
						<Waypoint onEnter={() => { this.handleWaypointEnter('policy') }} {...waypointOption}>
							<section className="evacuation-section evacuation-info -gray">
								<div className="container">
									<h2 className="sectiontitle">資料使用原則與限制</h2>
									<div className="sectioncontent">
										<ul className="evacuation-list -point">
											<li>本站提供之歷史颱風 / 豪雨事件撤離與收容人次統計，取自內政部統計處與衛福部部統計處，統計最小單位為颱風/豪雨事件。</li>
											<li>本站所指颱風／豪雨事件依據權責單位消防署認定之事件名稱為準。</li>
											<li>撤離與收容人數估計方法中，「依潛勢人口」之推估，所採用之潛勢資料來源為水利署第三代淹水潛勢圖資、保全人口資料來源為水保局「土石流潛勢溪流影響範圍保全對象」及水利署各縣市「水災危險潛勢地區保全計畫」。</li>
											<li>撤離與收容人數估計方法中，「依潛勢人口」之推估模式參數，是依據本中心 107 年度針對全台進行之「民眾災時收容意向調查」統計數據加以建置。</li>
											<li>取自「社政防救災整合平台」，選擇鄉鎮層級時，如遇該鄉鎮收容所資料缺漏超過半數，則不進行該鄉鎮收容所空間估計。選擇村里層級時，如遇該村里收容所資料缺漏超過半數，則不進行該村里收容所空間估計。</li>
											<li>本站提供之颱風路徑／強度取自中央氣象局歷史颱風資料。</li>
											<li>本站提供之歷年颱風/豪雨事件土石流紅、黃警戒資料來源為水保局；淹水一、二級警戒資料來源為水利署，更新自「災害示警公開資料平台(CAP)」。</li>
											<li>針對歷史資料部分，收容人數不等於撤離人數，撤離者可能自行安置，本站僅提供選擇至政府收容所安置之人口統計數據。各行政區所顯示之收容人數可能含有來自其他行政區之人口，使用時也請留意。</li>
											<li>本站之估計結果與現況可能有其誤差，數據僅供參考，請斟酌再利用。</li>
											<li>下載或再製本站提供之統計數據時，請詳閱其資料說明，並註名資料來源（國家災害防救科技中心減災動資料）。</li>
											<li>本站之歷史資料逐年更新，欲知最新資訊歡迎訂閱本站 <Link to="/news">最新消息</Link>。</li>
											<li>由本站下載後加值之數據資料不屬本站權責範圍，須由使用者自行負責。</li>
										</ul>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="articles">
						<Waypoint onEnter={() => { this.handleWaypointEnter('articles') }} {...waypointOption}>
							<section className="evacuation-section evacuation-articlelist">
								<div className="container">
									<h2 className="sectiontitle">主題館</h2>
									<ArticleList color="pink" pathname="/evaluation/evacuation" categoryID={9}></ArticleList>
									<Link className="sectionbutton fill-button -pink" to="/evaluation/evacuation/categories">
										<span className="type-text -ch -md">進入主題館</span>
									</Link>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<section className="evacuation-section evacuation-info -gray">
						<div className="container">
							<h2 className="sectiontitle">資料來源</h2>
							<ResourceLinks theme="pink" category="evacuation"></ResourceLinks>
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}
}

export default EvacuationIndex;
