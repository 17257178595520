import React, { Component } from 'react';
import './index.scss';

class CustomInput extends Component {
    render() {
        return (
            <div className="custom-input">
                <label className="type-text -ch -md" htmlFor={this.props.id}>{this.props.label}</label>
                <input name={this.props.name} className={"-" + this.props.status} type={this.props.type} placeholder={this.props.placeholder} value={this.props.value} disabled={this.props.disabled}
                onChange={e => {
                    e.persist();
                    if (this.props.onChange) {
                        this.props.onChange(e.target.value);
                    }
                }} />
                {
                    this.props.msg &&
                    <p className={'type-text -ch -xs msg ' + (this.props.status ? '-' + this.props.status : '')}>{this.props.msg}</p>
                }
            </div>
        );
    }
}

export default CustomInput;
