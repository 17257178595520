import React, { Component } from 'react';
import queryString from "query-string";
import Axios from 'axios';
import Helmet from 'react-helmet';
import './index.scss';

// Utils
import ModalBox from '../../utils/ModalBox';
import LimitMask from '../../utils/LimitMask';

// methods
import { loginRedirect } from '../../utils/Shared/methods';
import { apiHost } from '../../utils/Shared/constant';

class AdvanceCharts extends Component {
	constructor(params) {
		super(params);
		this.state = {
      version: '2.0',
			unsubmittable: true,
			isLoading: false,
			charts: [
				{
					type: "line",
					selected: false,
					enable: false,
					sets: ["CYN", "VYN", "VCY", "LYN", "VLY"],
				},
				{
					type: "bar",
					selected: false,
					enable: false,
					sets: ["CNN", "CCN", "CLN", "CYN",
						"LNN", "LYN", "LCN",
						"YNN", "YLN", "YCN",
						"VCN", "VCC", "VCY", "VCL", "VLN", "VLY", "VLC", "VYN", "VYC", "VYL"],
				},
				{
					type: "pie",
					selected: false,
					enable: false,
					sets: ["CNN", "YNN", "LNN"],
				},
			],
			chartsFilter: ["N", "N", "N"],
			chartsStatus: "尚未選填項目",
			dropHold: {
				id: "",
				title: "",
				type: "",
        years: []
			},
			dropItems: [
				{
					id: "",
					title: "",
					placehold: "拖移到此欄",
					heading: "1.請選擇要製圖的項目",
					enable: true,
					fill: false,
					hold: false,
					droppable: false,
          years: []
				},
				{
					id: "",
					title: "",
					placehold: "拖移到此欄（選填）",
					heading: "2.可選取新的項目將資料分類",
					enable: false,
					fill: false,
					hold: false,
					droppable: false,
          years: []
				},
				{
					id: "",
					title: "",
					placehold: "拖移到此欄（選填）",
					heading: "3.可繼續選取新的項目作為第二層分類依據",
					enable: false,
					fill: false,
					hold: false,
					droppable: false,
          years: []
				},
			]
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
			{ name: '客製化專區', href: '/statistics/morakot/advanced-intro' },
      { name: '進階分析', href: '/statistics/morakot/advanced-analytic' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.loadPrevReport();
		this.checkMemberLogin();
	}
	checkMemberLogin = () => {
		if (!window.localStorage.getItem('user-token')) {
			this.setState(
				{ isLoginModalOpen: true },
				() => setTimeout(() => { loginRedirect() }, 3000)
			);
		}
	}
	loadPrevReport = () => {
		if (localStorage.prevReport && localStorage.prevReport !== '[object Object]') {
			const prevReport = JSON.parse(localStorage.prevReport);
      if (prevReport && prevReport.version && prevReport.version === this.state.version) {
			 this.setState(prevReport);
      }
		}
    else {
      this.loadTopic(1);
    }
	}
  findMatch = (arr) => {
    const result = arr.shift().filter(function(v) {
      return arr.every(function(a) {
          return a.indexOf(v) !== -1;
      });
    });
    return result;
  };
	loadTopic = (id) => {
		Axios.get(`${apiHost}/api/Survey/Topic?data.categoryID=${id}`)
			.then((res) => {
				if (res.status === 200) {
					let data = res.data.Result;
					data.forEach((subject, i) => {
						subject.enable = false;
						if (i === 0) {
							subject.enable = true;
						}
					})
					this.setState({
						topicItems: res.data.Result,
					})
				}
			})
	}
	renderSubjects = () => {
		return (
			<ul className="subject advance-subject">
				{
					this.state.topicItems &&
					this.state.topicItems.map((item, i) => 
						<li key={i} className="item">
							<button data-index={i} className={"ghost-button" + (item.enable ? " -active" : "") + (i === 8 || i === 9 ? " -yellow" : " -green")}
								onClick={this.handleClickSelectSubject}>
								<span className="type-text -ch -md"> {item.Name} </span>
							</button>
						</li>
					)
				}
			</ul>
		)
	}
	renderItems = () => {
		return (
			<React.Fragment>
				{
					this.state.topicItems &&
					this.state.topicItems.map((item, i) => (
						<ul key={i} className={"advance-primarylist" + (item.enable ? " -active" : "")}>
							{this.renderPrimaryItems(item.Lists)}
						</ul>
					))
				}
			</React.Fragment>
		)

	}
	renderChartsButton = () => {
		return (
			<ul className="list">
				{
					this.state.charts &&
					this.state.charts.map((item, i) => {
						const { enable, selected, type } = item;
						return (
							<li key={i} className={enable ? "" : " -disable"}>
								<button data-index={i} className={"button" + (selected ? " -active" : "")}
									onClick={this.handleClickSelectChartType}>
									<i className={"icon-chart-" + type}></i>
								</button>
							</li>
						)
					})
				}
			</ul>
		)
	}
	renderPrimaryItems = (primaryList) => {
		return (
			<React.Fragment>
				{
					primaryList &&
					primaryList.map((item, i) => {
						const { Name, Items, enable } = item;
						return (
							<li key={i} className="item">
								<p className="title type-text -ch -md">{Name}</p>
								<ul className={"advance-secondarylist secondarylist" + (enable ? " -active" : "")}>
									{this.renderSecondaryItems(Items)}
								</ul>
							</li>
						)
					})
				}
			</React.Fragment>
		)

	}
	renderSecondaryItems = (secondaryList) => {
		return (
			secondaryList &&
			secondaryList.map((item, i) => {
				const { ID, Name, Type, Years } = item;
        const currentYears = Years.map(year => year + '');
        let allYears = this.findMatch([
          this.state.dropItems[0].years,
          this.state.dropItems[1].years
        ]);

        if( allYears.length === 0 ) {
          allYears = [...this.state.dropItems[0].years, ...this.state.dropItems[1].years];
        }


        const isFirst = this.state.chartsFilter[0] === "N";
        const isYearsMatch = this.findMatch([allYears, currentYears]);
        const isTypeV = Type === 'V';


				return (
					<li key={i} className="item"
					>
						<div className="dragger"
							data-id={ID}
							data-year={Years ? Years.join(',') : ''}
              data-type={ID === 265 ? 'L' : ID === 266 ? 'Y' : Type}
							draggable={ 
                isFirst || 
                ((!isFirst && !isTypeV) && (!isFirst && isYearsMatch.length > 0) && this.state.dropItems.findIndex(subitem => Number(subitem.id) === Number(ID)) === -1)
              }
							onDragStart={this.handleDragDroppable}
							onDragEnd={this.handleDragRelease}
						>
							<span className="type-text -ch -xs">{Name}</span>
							<span className="icon icon-drag"></span>
						</div>
					</li>
				)
			})
		)
	}
	renderDropItems = () => {
		return (
			<React.Fragment>
				{
					this.state.dropItems &&
					this.state.dropItems.map((item, i) => (
						<div key={i} className={"itemselector"
							+ (item.enable ? "" : " -disable")
						}>
							<div className="heading type-text -ch -md">{item.heading}</div>
							<div className={"dropper advance-dropper"
								+ (item.fill ? " -fill" : " -empty")
								+ (item.hold ? " -hold" : "")
								+ (item.droppable && !item.fill ? " -droppable" : "")
								+ (item.enable ? "" : " -disable")
							}>
								<div className="zone"
									data-index={i}
									onDragOver={(e) => { e.preventDefault() }}
									onDragEnter={this.handleDragOver}
									onDragLeave={this.handleDragLeave}
									onDrop={this.handleDragInputData}
								></div>
								<span className="type-text -ch -md">{item.title}</span>
								<span className="placehold type-text -ch -md">{item.placehold}</span>
								<button data-index={i} onClick={this.handleClickRemoveData} className="remove">
									<i className="icon-close-fill"></i>
								</button>
							</div>
						</div>
					))
				}
			</React.Fragment>
		)
	}
	handleClickSelectSubject = (e) => {
		let currentIndex = e.currentTarget.dataset.index;
		let topicItems = this.state.topicItems;
		topicItems.forEach((subject) => {
			subject.enable = false;
		})
		topicItems[currentIndex].enable = true;

		this.setState({
			topicItems: topicItems,
		})

	}
	handleClickSelectChartType = (e) => {
		let currentIndex = e.currentTarget.dataset.index;

		let charts = this.state.charts;
		charts.forEach((chart) => {
			chart.selected = false;
		})
		charts[currentIndex].selected = true;

		this.setState({
			unsubmittable: false,
			charts: charts,
			chartType: charts[currentIndex].type
		});
	}
	handleClickRemoveData = (e) => {
		let currentIndex = e.currentTarget.dataset.index

		let dropItems = this.state.dropItems;
		dropItems[currentIndex].id = "";
		dropItems[currentIndex].title = "";
		dropItems[currentIndex].type = "";
		dropItems[currentIndex].fill = false;
		dropItems[currentIndex].hold = false;
    dropItems[currentIndex].years = [];
		dropItems[2].enable = false;
		dropItems[2].id = "";
		dropItems[2].title = "";
		dropItems[2].type = "";
		dropItems[2].fill = false;
		dropItems[2].hold = false;
    dropItems[2].years = [];
		// Clear Charts Filter
		let chartsFilter = this.state.chartsFilter;
		chartsFilter[currentIndex] = "N";
		chartsFilter[2] = "N";

		// Clear Chart Type Select
		let charts = this.state.charts;
		charts.forEach((chart) => {
			chart.selected = false;
		})

		this.setState({
			unsubmittable: true,
			charts: charts,
			chartsFilter: chartsFilter,
			dropItems: dropItems,
		});
		this.handleUpdateFilterDrawType();
	}
	handleDragInputData = (e) => {
		e.preventDefault();
		let currentIndex = Number(e.currentTarget.dataset.index)

		let dropItems = this.state.dropItems;
		dropItems[currentIndex].id = this.state.dropHold.id;
		dropItems[currentIndex].title = this.state.dropHold.title;
    dropItems[currentIndex].years = this.state.dropHold.years;
		dropItems[currentIndex].fill = true;

		// Enable Field after input
		if (currentIndex < (dropItems.length - 1)) {
			dropItems[currentIndex + 1].enable = true;
		}

		// Input available chart type
		let chartsFilter = this.state.chartsFilter;
		chartsFilter[currentIndex] = this.state.dropHold.type;

		// disable the third dropItem if type is not match
		const filter = chartsFilter.join('');
		switch (filter) {
			case "NYN":
			case "NVN":
			case "NCN":
			case "CNN":
			case "VNN":
			case "CCN":
			case "CYN":
			case "CLN":
			case "YCN":
			case "LCN":
			case "YLN":
			case "LYN":
			case "YNN":
			case "LNN":
				dropItems[2].enable = false;
				break;
			default:
				dropItems[2].enable = true;
		}

		// Clear Chart Type Select
		let charts = this.state.charts;
		charts.forEach((chart) => {
			chart.selected = false;
		});

		this.setState({
			unsubmittable: true,
			charts: charts,
			dropItems: dropItems,
			chartsFilter: chartsFilter,
		}, () => {
			this.handleUpdateFilterDrawType();
		});
	}
	handleDragDroppable = (e) => {
		// FireFox need THIS LINE to trigger drag event
		e.dataTransfer.setData("text", '');

		let currentDrag = e.currentTarget;
		let dropHold = {
			id: currentDrag.dataset.id,
			title: currentDrag.textContent,
			type: currentDrag.dataset.type,
      years: (currentDrag.dataset.year).split(',')
		};

		let dropItems = this.state.dropItems;
		dropItems.forEach((item) => {
			item.droppable = true;
		});
		this.setState({
			dropItems: dropItems,
			dropHold: dropHold,
		});
	}
	handleDragOver = (e) => {
		let currentIndex = e.currentTarget.dataset.index
		let dropItems = this.state.dropItems;
		dropItems[currentIndex].hold = true;
		this.setState({
			dropItems: dropItems,
		});
	}
	handleDragLeave = (e) => {
		let currentIndex = e.currentTarget.dataset.index
		let dropItems = this.state.dropItems;
		dropItems[currentIndex].hold = false;
		this.setState({
			dropItems: dropItems,
		});
	}
	handleDragRelease = () => {
		let dropHold = {
			id: "",
			title: "",
			type: "",
      years: []
		};

		let dropItems = this.state.dropItems;

		dropItems.forEach((item) => {
			item.droppable = false;
		});

		this.setState({
			dropItems: dropItems,
			dropHold: dropHold,
		});
	}
	handleUpdateFilterDrawType = () => {
		const { charts } = this.state;
		const currentFilter = this.state.chartsFilter.join('');
		let unmatchCount = 0;
		let chartsStatus = '';

		// Match available charts' type
		charts.forEach((chart) => {
			if (chart.sets.indexOf(currentFilter) !== -1 && currentFilter !== "NNN") {
				chart.enable = true;
			}
			else if (chart.sets.indexOf(currentFilter) === -1 && currentFilter !== "NNN") {
				unmatchCount++;
				chart.enable = false;
			}
			else {
				chart.enable = false;
			}
		});

		// Check Status
		if (unmatchCount < charts.length && currentFilter !== "NNN") {
			chartsStatus = "";
		}
		else if (unmatchCount === charts.length && currentFilter !== "NNN") {
			chartsStatus = "此組項目尚無適用圖表，請重新選擇或繼續追加項目";
		}
		else {
			chartsStatus = "尚未選填項目";
		}
		this.setState({
			charts: charts,
			chartsStatus: chartsStatus,
		});
	}
	handleClickGoToResult = () => {
		this.setState({
			isLoading: true,
		});
		const questionsId = [];
		const resultLocation = window.location + '-result?';

		this.state.dropItems.forEach((item) => {
			questionsId.push(item.id);
		});

		const dataParams = this.getDataParams();
		const parse = queryString.stringify(dataParams);
		window.location.href = resultLocation + parse;

		this.setState({
			isLoading: false,
		});
		localStorage.setItem('prevReport', JSON.stringify(this.state));
	}
	getDataParams() {
		const q1 = this.state.dropItems[0].id;
		const q2 = this.state.dropItems[1].id;
		const q3 = this.state.dropItems[2].id;
		const type = this.state.chartType;

		let statistic = '';

		let filter = this.state.chartsFilter.join('');
		switch (filter) {
			case "CNN":
			case "CCN":
			case "CLN":
			case "CYN":
			case "LNN":
			case "LYN":
			case "LCN":
			case "YNN":
			case "YLN":
			case "YCN":
				statistic = '1';
				break;
			case "VYN":
			case "VCY":
			case "VYY":
			case "VYC":
			case "VCN":
			case "VCC":
      case "VCL":
      case "VLN":
      case "VLY":
      case "VLC":
      case "VYN":
      case "VYC":
      case "VYL":
      			statistic = '3';
				break;
			default:
				statistic = '1';
		}
    
		let year = filter.indexOf('Y') !== -1 ? 'all' : '';
		let location = filter.indexOf('L') !== -1 ? 'all' : '';

		return {
			q1: q1,
			q2: q2,
			q3: q3,
			type: type,
			year: year,
			location: location,
			statistic: statistic
		}
	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 進階分析</title>
				</Helmet>
				<ModalBox
					open={this.state.isLoginModalOpen}
					theme="green"
					onRequestClose={() => loginRedirect() }
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">請先登入再進行操作</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -green" onClick={ () => loginRedirect() }>登入</button>
					</div>
				</ModalBox>
				<LimitMask prevPage="/statistics/morakot" />
				<div className="advance-chart">
					<section className="advance-app">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="advance-heading">
										<span className="type-text -ch -lg">STEP1 選擇主題與項目</span>
										<button className="icon-button">
											<i className="icon-info-fill"></i>
											<div className="bubble">
												<span className="type-text -ch -xs">切換分頁標籤可以瀏覽不同主題的項目</span>
											</div>
										</button>
									</div>
									<div className="subjectselector">
										<div className="heading type-text -ch -md">主題</div>
										{this.renderSubjects()}
									</div>
									<div className="listselector" ref="lists">

                    <div className="advance-heading">
                      <span className="type-text -ch -md">項目</span>
                      <button className="icon-button">
                        <i className="icon-info-fill"></i>
                        <div className="bubble">
                          <span className="type-text -ch -xs">可選擇的項目皆為同一期進行調查的項目</span>
                        </div>
                      </button>
                    </div>
										{this.renderItems()}
									</div>
								</div>
								<div className="col-md-6" ref="dropzone">
									<div className="advance-heading">
										<span className="type-text -ch -lg">STEP2 交叉分析</span>
										<button className="icon-button">
											<i className="icon-info-fill"></i>
											<div className="bubble">
												<span className="type-text -ch -xs">依序將項目拖移至下列欄位</span><br/>
												<span className="type-text -ch -xs">可選擇的項目皆為同一期進行調查的項目</span>
											</div>
										</button>
									</div>
									{this.renderDropItems()}

									<div className="advance-heading">
										<span className="type-text -ch -lg">STEP3 選擇圖表類型</span>
                    {
  										// <button className="icon-button">
  										// 	<i className="icon-info-fill"></i>
  										// 	<div className="bubble">
  										// 		<span className="type-text -ch -xs">選擇產出的圖表類型</span>
  										// 	</div>
  										// </button>
                    }
									</div>
									<div className="chartselector">
										<div className="advance-charttype charttype">
											<p>{this.state.chartsStatus}</p>
											{this.renderChartsButton()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="advance-submit">
						<div className="submit">
							<button className="fill-button -dark"
								disabled={this.state.unsubmittable}
								onClick={this.handleClickGoToResult}>
								<span>製作圖表</span>
								<i className={'icon-refresh loading' + (this.state.isLoading ? '' : ' -hide')}></i>
							</button>
						</div>
					</section>
				</div>
			</React.Fragment>
		)
	}
}

export default AdvanceCharts;
