import React, { Component } from 'react';
import Axios from 'axios';
import './index.scss';

import ModalBox from '../../utils/ModalBox';
import { cors, sourceHost } from '../Shared/constant';

class StatisticsSVG extends Component {
	constructor(params) {
		super(params);
		this.state = {
			loadSuccess: false,
			frequency: 0,
		}
	}
	componentDidMount() {
		const url = `${sourceHost}${this.props.svgData}`
		Axios.get(cors + url)
			.then((res) => {
				if (this.state.loadSuccess === false && this.state.frequency < 3) {

					this.setState({
						loadSuccess: true,
            svg: res.data,
					}, () => this.handleLoadShowSVG());
					
				}
			})
			.catch((err) => {
				console.log('SVG load failed.');
				this.openModal();
				if (this.state.loadSuccess === true) {
					let frequency = this.state.frequency + 1;
					this.setState({
						loadSuccess: false,
						frequency: frequency,
					})
				}
			})
		// this.handleAddAnimation();
	}
	componentDidUpdate(prevProps, prevState) {
		// this.handleAddAnimation();
	}

	openModal() {
		this.setState({
			modalOpen: true,
		})
	}

	handleLoadShowSVG() {
		const { svgId } = this.props;

		// Setting SVG
		const DOM_svg = this.refs['svg' + svgId];
		const svg = DOM_svg.querySelector('svg');

    const computedStyleToInlineStyle = require("computed-style-to-inline-style");
    
    computedStyleToInlineStyle(svg, {
      recursive: true,
      properties: ['fill', 'stroke', 'stroke-width', 'stroke-miterlimit', 'opacity']
    });

    svg.querySelector('style') && svg.querySelector('style').remove();

		const width = svg.getAttribute('viewBox').split(' ')[2];
		const height = svg.getAttribute('viewBox').split(' ')[3];
		svg.setAttribute('width', width);
		svg.setAttribute('height', height);

		// Setting Button
		const svgBtns = DOM_svg.querySelectorAll('g[id*="btn"]');
		svgBtns.forEach((svgBtn) => {
			svgBtn.dataset.target = 'svg' + svgId;
			svgBtn.addEventListener('click', this.handleClickControlSVG);
			svgBtn.style.opacity = 0.5;
		});

		// Setting Layer
		let layers = DOM_svg.querySelectorAll('g[id*="layer"]');
    // console.log(layers);
		layers.forEach((layer) => {
      // console.log('work')
			layer.style.opacity = 0;
			layer.setAttribute('class', '');
		})

		// Setting First Layer and Button
		const firstLayer = DOM_svg.querySelector('#layer1') || null;
		const firstBtn = DOM_svg.querySelector('#btn1') || null;
		if (firstLayer) {
			firstLayer.setAttribute('class', ' -active');
			firstLayer.style.opacity = 1;
		}
    if (firstBtn) {
			firstBtn.setAttribute('class', ' -active');
			firstBtn.style.opacity = 1;
    }

	}

	handleClickControlSVG = (event) => {
		// Get Click target
		let currentBtn = event.currentTarget;
		let currentSVG = currentBtn.dataset.target;
		let DOM_currentSVG = this.refs[currentSVG];

		// Cancel active ClassName
		let layers = DOM_currentSVG.querySelectorAll('g[id*="layer"]');
		let btns = DOM_currentSVG.querySelectorAll('g[id*="btn"]');
		btns.forEach((btn) => {
			btn.style.opacity = 0.5;
			btn.setAttribute('class', '');
		})
		layers.forEach((layer) => {
			layer.style.opacity = 0;
			layer.setAttribute('class', '');
		})

		// Get control target
		let num = currentBtn.getAttribute('id').replace('btn', '');
		let currentLayer = '#layer' + num;
		let DOM_currentLayer = DOM_currentSVG.querySelector(currentLayer);

		// Add active ClassName
		currentBtn.setAttribute('class', '-active');
		currentBtn.style.opacity = 1;

		DOM_currentLayer.setAttribute('class', '-active');
		DOM_currentLayer.style.opacity = 1;

	}
	// handleAddAnimation = () => {
	// 	if (this.props.js) {
	// 		try {
	// 			console.log(this.props.js)
	// 			new window.Function(this.props.js);
	// 		} catch (e) {

	// 		}
	// 	}
	// }
	render() {
		const { svgId, svgData, className, js } = this.props;
    // const { svgId, svgData, className } = this.props;
		const { svg, loadSuccess } = this.state;
		return (
			<React.Fragment>
				<ModalBox
					open={this.state.modalOpen}
					theme="-green"
					onRequestClose={() => {
						this.setState({ modalOpen: false })
					}}
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">糟糕！<br />圖表資料載入失誤，請重新載入。</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -fit -yellow" onClick={() => { this.setState({ modalOpen: false }) }}>關閉視窗</button>
					</div>
				</ModalBox>
				{
					(svgData && loadSuccess && svg) &&
					<div className={`svg-block ${className ? className : ''}`}
						ref={"svg" + svgId}
						dangerouslySetInnerHTML={{ __html: svg }}
						key={svgId}
					></div>
				}
				{
					js &&
					<script>{js}</script>
				}
			</React.Fragment>
		);
	}
}

export default StatisticsSVG;
