import { connect } from 'react-redux';
import MorakotArticlePage from './pure';

const mapStateToProps = (state, ownProps) => ({
	color: state.header.color,
})

const mapDispatchToProps = (dispatch) => ({
	onLoadHeaderColor: (COLOR) => dispatch({ type: 'HEADER_COLOR_' + COLOR }),
	onLoadHeaderTheme: (THEME) => dispatch({ type: 'HEADER_THEME_' + THEME }),
	onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
	onLoadShowBreadcrumb: (STATUS, data) => dispatch({ type: STATUS + '_BREADCRUMB', breadcrumbData: data }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MorakotArticlePage);
