import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

class PageSelector extends Component {

	constructor(props) {
		super(props);

		const nowPage = props.nowPage ? props.nowPage : 1
		this.state = {
			pageItems: this.ganeratePageItem(nowPage),
			nowPage: nowPage,
		}
	}

	ganeratePageItem = (page) => {
		return [page-2, page-1, page, page+1, page+2];
	}

	switchToPage = (page) => {
		this.setState({
			pageItems: this.ganeratePageItem(page),
			nowPage: page,
		})
	}

	handleItemClick = (page) => {
		if (this.props.onPageClick) {
			this.props.onPageClick(page);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.nowPage !== this.props.nowPage) {
			this.switchToPage(this.props.nowPage);
		}
	}

	render() {
		const {pageItems, nowPage} = this.state;
		const {theme, maxPage, onPageClick, className} = this.props;
		return (
			<div className={`${className ? className : ''} ${theme ? `-${theme}` : ''}`}>
				{
					nowPage > 0 &&
					maxPage > 0 &&
					<ul className="page-list">
						
						<ItemButton 
							className={'-word ' + (theme ? `-${theme}` : '') + (nowPage === 1 ? ' -disabled' : '')} 
							href={'#1'} 
							onClick={()=>{
								onPageClick(1)
							}}>
								First
						</ItemButton>

						<ItemButton 
							className={(theme ? `-${theme}` : '') + (nowPage-1 <= 0 ? ' -disabled' : '')} 
							href={'#' + (nowPage-1)} 
							onClick={()=>{
								onPageClick(nowPage-1)
							}}>
								<i className="icon-arrow-left"></i>
						</ItemButton>

						{
							pageItems.map((item) =>
								(item > 0 && item <= maxPage) &&
								<ItemButton 
									className={`${theme ? `-${theme}` : ''} ${item === nowPage ? '-active' : ''}`} 
									href={'#' + item} 
									key={'ppp' + item}
									onClick={() => {
										onPageClick(item)
									}}>{ item }</ItemButton>
							)
						}
						
						<ItemButton 
							className={(theme ? `-${theme}` : '') + (nowPage+1 > maxPage ? ' -disabled' : '')} 
							href={'#' + (nowPage+1)} 
							onClick={()=>{
								onPageClick(nowPage+1)
							}}>
								<i className="icon-arrow-right"></i>
						</ItemButton>

						<ItemButton 
							className={'-word ' + (theme ? `-${theme}` : '') + (nowPage >= maxPage ? ' -disabled' : '')} 
							href={'#' + maxPage} 
							onClick={()=>{
								onPageClick(maxPage)
							}}>
								Last
						</ItemButton>

					</ul>
				}
			</div>
		);
	}
}

class ItemButton extends Component {
	render() {
		const {className, children, href, onClick} = this.props;
		return (
			<li className={'type-text -en -xs pageitem' + (className ? ' ' + className : '')}>
				<a href={href} onClick={onClick}>
					{children}
				</a>
			</li>
		)
	}
}

PageSelector.propTypes = {
	nowPage: PropTypes.number,
	maxPage: PropTypes.number.isRequired,
	onPageClick: PropTypes.func.isRequired,
	itemClassName: PropTypes.string,
	className: PropTypes.string,
}

export default PageSelector;
