import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import AnimatedNumber from 'react-animated-number';
import Axios from 'axios';
import './index.scss';

// utils
// import CountiesTownsData from '../../config/CountiesTownsData';
import LoginMask from '../../utils/LoginMask';
import LoadingDots from '../../utils/LoadingDots';
import CustomSelect from '../../utils/CustomSelect';
import LimitMask from '../../utils/LimitMask';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { apiHost, sourceHost } from '../../utils/Shared/constant';
import { loginRedirect, transferHundredPoint } from '../../utils/Shared/methods';

import spaceSuccessSvg from '../../../images/space-success.svg';
import spaceAlertSvg from '../../../images/space-alert.svg';

class EvacuationEstimate extends Component {
	constructor(params) {
		super(params);
		this.state = {
			isMemberLogin: true,
			currentCounty: '縣市',
			currentTown: '鄉鎮',
			countiesTownsData: null,
			selectedCounty: null,
			selectedTown: null,
			selectedVillage: null,
			evacuateTopics: {
				evacuate: { name: '依歷史撤離人口', slug: 'Evacuate', active: false },
				potential: { name: '依潛勢人口', slug: 'Potential', active: false },
			},
			isEvacuateSelected: false,
			evacuateData: {
				Value: 0,
				Max: 0,
				valueRatio: null,
				maxRatio: null,
				loadFail: false
			},
			containTopics: {
				evacuate: { name: '依歷史收容人口', slug: 'Contain', active: false },
				potential: { name: '依潛勢人口', slug: 'Potential', active: false },
				custom: { name: '自訂撤離人口', slug: 'Custom', active: false, value: 0 },
			},
			isContainSelected: false,
			containData: {
				Max: null,
				Ratio: null,
				Value: null,
				maxRatio: null,
				loadFail: false
			},
			spaceCustomNumber: 70,
			isSpaceDataLoaded: false,
			spaceData: {
				reality: 0,
				require: 0
			},
			materialTopics: {
				mountain: { name: '14 天（山區、易形成孤島地區建議儲備天數）', slug: '1', active: false },
				country: { name: '3 天（鄉村、偏遠地區建議儲備天數）', slug: '2', active: false },
				city: { name: '2 天（都會、半都會地區建議儲備天數）', slug: '3', active: false },
			},
			isMaterialSelected: false,
			isMaterialAdvance: false,
			materialOptions: [],
			prevScrollY: 0,
			selectedPotentialRange: null,
			potentialRange: ["200", "350", "500", "650"],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('PINK');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');
		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
			{ name: '撤離與收容人數及物資估計', href: '/evaluation/evacuation/estimate' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.getAvailableCounty();
		this.checkMemberLogin();
		window.addEventListener('scroll', this.handleScrollFixed);
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrollFixed);
	}
	checkMemberLogin = () => {
		if (!window.localStorage.getItem('user-token')) {
			this.setState({ isMemberLogin: false });
		}
	}
	getAvailableCounty = () => {
		Axios.get('/config/CountiesTownsVillagesData.json')
			.then(result => {
				this.setState({ countiesTownsData: result.data });
			})
	}
	handleScrollFixed = () => {
		const spy = this.refs.spy;
		const spyRect = spy.getBoundingClientRect();
		const spySelector = spy.querySelector('.estimate-selector');
		const spySelectorRect = spySelector.getBoundingClientRect();

		if ((spyRect.top + spyRect.height) < 0) {
			if (!spy.classList.contains('-active')) {
				spy.classList.add('-active');
				spy.style.paddingBottom = `${spySelectorRect.height}px`;
			};


			const { prevScrollY } = this.state;
			const currentScrollY = window.pageYOffset;

			if (currentScrollY < prevScrollY) { spy.classList.add('-show') }
			else { spy.classList.remove('-show') }

			this.setState({ prevScrollY: currentScrollY });
		}
		else if ((spyRect.top + spyRect.height + spySelectorRect.height) > 0 && spy.classList.contains('-active')) {
			spy.classList.remove('-active');
			spy.style.paddingBottom = 0;
		}
	}
	handleSetEvacuateTopic = (keyName) => {
		const { evacuateTopics, selectedPotentialRange } = this.state;
		const dataKeys = Object.keys(evacuateTopics);
		dataKeys.forEach(key => {
			evacuateTopics[key].active = false;
		});
		evacuateTopics[keyName].active = true;
		if (keyName === 'potential' && !selectedPotentialRange) {
			return this.setState({
				isEvacuateSelected: false,
				evacuateTopics: evacuateTopics,
			})
		}
		this.setState({
			isEvacuateSelected: true,
			evacuateTopics: evacuateTopics,
		})
	}
	handleEvacuateSubmit = () => {
		const { selectedCounty, selectedTown, selectedVillage, evacuateTopics, selectedPotentialRange } = this.state;
		const dataKeys = Object.keys(evacuateTopics);
		const dataArray = dataKeys.map((key) => { return evacuateTopics[key] });
		const topicSlug = dataArray.find(topic => topic.active).slug;
		const countyCode = selectedCounty.code;
		const townCode = selectedTown.code;
		const villageCode = selectedVillage ?selectedVillage.code :''
		this.setState({ isDataLoading: true });
		if(topicSlug === 'Evacuate'){
			Axios.get(`${apiHost}/api/Estimate/EvacuateEstimateBy${topicSlug}?county=${countyCode}&town=${townCode}&village=${villageCode}`)
			.then(res => {
				if (res.data.Result) {
					const { evacuateData } = this.state;
					this.handleSetContainCustom(res.data.Result['Value']);
					// evacuateData.loadFail = res.data.Result['Value'] === 0 ? true : false;
					this.setState({
						isDataLoading: false,
						evacuateData: res.data.Result,
					})
				}
				else {
					const { evacuateData } = this.state;
					evacuateData.loadFail = true;
					evacuateData['Value'] = null;
					evacuateData['Max'] = null;
					evacuateData['valueRatio'] = null;
					evacuateData['maxRatio'] = null;
					this.setState({
						isDataLoading: false,
						evacuateData: evacuateData
					});
				}
			})
		}else if(topicSlug === 'Potential'){
			Axios.get(`${apiHost}/api/Estimate/EvacuateEstimateBy${topicSlug}?county=${countyCode}&town=${townCode}&village=${villageCode}&potentialRange=${selectedPotentialRange}`)
			.then(res => {
				if (res.data.Result) {
					const { evacuateData } = this.state;
					this.handleSetContainCustom(res.data.Result['Value']);
					// evacuateData.loadFail = res.data.Result['Value'] === 0 ? true : false;
					this.setState({
						isDataLoading: false,
						evacuateData: res.data.Result,
					})
				}
				else {
					const { evacuateData } = this.state;
					evacuateData.loadFail = true;
					evacuateData['Value'] = null;
					evacuateData['Max'] = null;
					evacuateData['valueRatio'] = null;
					evacuateData['maxRatio'] = null;
					this.setState({
						isDataLoading: false,
						evacuateData: evacuateData
					});
				}
			})
		}
	}

	handleSetContainTopic = (keyName) => {
		const { containTopics } = this.state;
		const dataKeys = Object.keys(containTopics);
		dataKeys.forEach(key => {
			containTopics[key].active = false;
		});
		containTopics[keyName].active = true;

		this.setState({
			containTopics: containTopics,
			isContainSelected: true,
		})
	}
	handleSetContainCustom(value) {
		const { containTopics } = this.state;
		containTopics['custom'].value = value;
		this.setState({
			containTopics: containTopics,
		})
	}
	handleContainSubmit = () => {
		const { selectedCounty, selectedTown, selectedVillage, containTopics, selectedPotentialRange } = this.state;
		const dataKeys = Object.keys(containTopics);
		const dataArray = dataKeys.map((key) => { return containTopics[key] });
		const topicSlug = dataArray.find(topic => topic.active).slug;
		const countyCode = selectedCounty.code;
		const townCode = selectedTown.code;
		const villageCode = selectedVillage ?selectedVillage.code :''
		const customNumber = containTopics['custom'].value;
		this.setState({ isDataLoading: true });
		if(topicSlug === 'Potential'){
			Axios.get(`${apiHost}/api/Estimate/ContainEstimateBy${topicSlug}?county=${countyCode}&town=${townCode}&village=${villageCode}&evacuate=${customNumber}&potentialRange=${selectedPotentialRange}`)
			.then(res => {
				if (res.data.Result) {
					const data = res.data.Result;
					if (containTopics['potential'].active) {
						this.handleSetSpaceCustom(Math.max(data['Max'], 50))
					}
					else {
						this.handleSetSpaceCustom(Math.max(data['Value'], 50))
					}

					if (containTopics['custom'].active) {
						data['maxRatio'] = null;
						data['valueRatio'] = null;
					}

					this.setState({
						isDataLoading: false,
						containData: data
					})
				}
				else {
					const { containData } = this.state;
					containData.loadFail = true;
					containData['Max'] = null;
					containData['Ratio'] = null;
					containData['Value'] = null;
					this.setState({
						isDataLoading: false,
						containData: containData
					});
				}
			})
		}else{
			Axios.get(`${apiHost}/api/Estimate/ContainEstimateBy${topicSlug}?county=${countyCode}&town=${townCode}&village=${villageCode}&evacuate=${customNumber}`)
			.then(res => {
				if (res.data.Result) {
					const data = res.data.Result;
					if (containTopics['potential'].active) {
						this.handleSetSpaceCustom(Math.max(data['Max'], 50))
					}
					else {
						this.handleSetSpaceCustom(Math.max(data['Value'], 50))
					}

					if (containTopics['custom'].active) {
						data['maxRatio'] = null;
						data['valueRatio'] = null;
					}

					this.setState({
						isDataLoading: false,
						containData: data
					})
				}
				else {
					const { containData } = this.state;
					containData.loadFail = true;
					containData['Max'] = null;
					containData['Ratio'] = null;
					containData['Value'] = null;
					this.setState({
						isDataLoading: false,
						containData: containData
					});
				}
			})
		}
	}
	handleSetSpaceCustom(value) {
		this.setState({ spaceCustomNumber: value });
	}
	handleSpaceSubmit = () => {
		const { selectedCounty, selectedTown, selectedVillage, spaceCustomNumber } = this.state;
		const countyCode = selectedCounty.code;
		const townCode = selectedTown.code;
		const villageCode = selectedVillage ?selectedVillage.code :''
		this.setState({ isDataLoading: true });
		Axios.get(`${apiHost}/api/Estimate/ContainSpaceEstimate?county=${countyCode}&town=${townCode}&village=${villageCode}&contain=${spaceCustomNumber}`)
			.then(res => {
				res.data.Result &&
					this.setState({
						isDataLoading: false,
						isSpaceDataLoaded: true,
						spaceData: res.data.Result
					})
			})
	}
	handleSetMaterialTopic = (keyName) => {
		const { materialTopics } = this.state;
		const dataKeys = Object.keys(materialTopics);
		dataKeys.forEach(key => {
			materialTopics[key].active = false;
		});
		materialTopics[keyName].active = true;

		this.setState({
			isMaterialSelected: true,
			materialTopics: materialTopics,
		});
	}
	handleMaterialSubmit = () => {
		const { materialTopics, spaceCustomNumber, selectedCounty, selectedVillage, selectedTown } = this.state;
		const dataKeys = Object.keys(materialTopics);
		const dataArray = dataKeys.map((key) => { return materialTopics[key] });
		const locateSlug = dataArray.find(topic => topic.active).slug;
		const countyCode = selectedCounty.code;
		const townCode = selectedTown.code;
		const villageCode = selectedVillage ?selectedVillage.code :''
		this.setState({ isDataLoading: true });
		Axios.get(`${apiHost}/api/Estimate/ContainMaterialEstimate?county=${countyCode}&town=${townCode}&village=${villageCode}&contain=${spaceCustomNumber}&locate=${locateSlug}`)
			.then(res => {
				if (res.data.Result) {
					const materialData = res.data.Result.Data;
					const materialOptions = res.data.Result.Options;
					materialData.forEach(data => {
						data['Items'].forEach(item => item.active = true);
					})
					materialOptions.forEach(option => {
						option['Items'].forEach(item => { item.active = false });
						option['Items'][0].active = true;
						option['current'] = option['Items'][0]["Name"];
					});
					this.setState({
						isMaterialAdvance: true,
						isDataLoading: false,
						materialData: materialData,
						materialOptions: materialOptions
					}, () => this.handleSetMaterialGroup());
				}
			})
	}
	handleSetMaterialGroup() {
		const { materialData, materialOptions } = this.state;
		materialOptions.forEach(option => {
			const groupIndex = materialData.findIndex(data => data['Category'] === option['Category']);
			if (groupIndex) {
				option['Items'].forEach(item => {
					materialData[groupIndex]['Items'].forEach(subItem => {
						if (subItem['Name'] === item['Name']) {
							subItem.active = item.active;
						}
					})
				})
			}
		});

		this.setState({ materialData: materialData })
	}
	handleChangeMaterialGroup = (value) => {
		const { materialOptions } = this.state;
		const groupName = value.split('-')[0];
		const categoryItem = value.split('-')[1];
		materialOptions.forEach(option => {
			if (option['Group_Name'] === groupName) {
				option['Items'].forEach(item => {
					item.active = false;
					if (item['Name'] === categoryItem) {
						item.active = true;
						option['current'] = categoryItem;
					}
				})
			}
		})

		this.setState({ materialOptions: materialOptions }, () => this.handleSetMaterialGroup());
	}
	handleClickToggleInfo = (e) => {
		const controller = e.currentTarget;
		const target = e.currentTarget.dataset.target;
		const targetDom = document.querySelector(`.toggle-${target}`);
		if (controller.classList.contains('-show')) {
			controller.classList.remove('-show');
			targetDom.classList.remove('-open');
		}
		else {
			controller.classList.add('-show');
			targetDom.classList.add('-open');
		}
	}
	handleCountyDistrictPickSelected = selectedCode => {
		const { countiesTownsData } = this.state;
		const selectedDistrict = countiesTownsData.counties.find(({ code }) => code === selectedCode);
		this.setState({
			selectedCounty: selectedDistrict || null,
			selectedTown: null
		});
	}

	handleTownDistrictPickSelected = selectedCode => {
		const { selectedCounty } = this.state
		if (selectedCounty === null) return
		const selectedDistrict = selectedCounty.towns.find(({ code }) => code === selectedCode)
		this.setState({ selectedTown: selectedDistrict, spaceCustomNumber: 70})
	}
	handleVillageDistrictPickSelected = selectedCode => {
		const { selectedTown } = this.state
		if (selectedTown === null ) return
		const selectedDistrict = selectedTown.villages.find(({ code }) => code === selectedCode)
		this.setState({ selectedVillage: selectedDistrict, spaceCustomNumber: 20})
	}
	handleDownloadData = () => {
		const { selectedCounty, selectedTown, evacuateData, containData, spaceData, materialData } = this.state;
		const districtCsv = `縣市,${selectedCounty.county}\n鄉鎮,${selectedTown.town}\n`;
		const evacuateCsv = (evacuateData['Value'] ? `預估撤離人數,${evacuateData['Value']}\n` : '') +
			(evacuateData['Max'] ? `預估最大撤離人數,${evacuateData['Max']}\n` : '') +
			(evacuateData['Ratio'] ? `占戶籍人口比例,${evacuateData['Ratio']}\n` : '');
		const containCsv = (containData['Value'] ? `預估收容人數,${containData['Value']}\n` : '') +
			(containData['Max'] ? `預估最大收容人數,${containData['Max']}\n` : '') +
			(containData['Ratio'] ? `占戶籍人口比例,${containData['Ratio']}\n` : '');
		const spaceCsv = (spaceData['require'] ? `需要空間,${spaceData['require']},平方公尺\n` : '') +
			(spaceData['require'] ? `約相當於,${Math.ceil(spaceData['require'] / 112.5)},間國小教室\n` : '') +
			((spaceData['reality'] && spaceData['reality'] !== -1) ? `現有收容空間,${spaceData['reality']},平方公尺\n` : '') +
			((spaceData['reality'] && spaceData['reality'] === -1) ? `現有收容空間,超過五成的收容所無填報資料，無法計算。\n` : '');

		const materialCsv = materialData ? '收容物資估計\n' + materialData.map(category => {
			const items = [];
			category['Items'].forEach(item => { if (item.active) { items.push(item) } });
			const itemsCsv = items.map(item => `${item['Name']},${Math.floor(item['Value'])},${item['Unit']}\n`).join('');
			return `${category['Category']}\n${itemsCsv}\n`;
		}).join('')
			: '';

		let csv = districtCsv + '\n' +
			evacuateCsv + '\n' +
			containCsv + '\n' +
			spaceCsv + '\n' +
			materialCsv

		const fileName = `原始資料`;
		const copyright = '\n資料來源：國家災害防救科技中心減災動資料。';
		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;

		const downloadTrigger = document.createElement('a');
		downloadTrigger.setAttribute('download', fileName + '.csv');
		downloadTrigger.setAttribute('href', csvContent);
		downloadTrigger.click();
		downloadTrigger.remove();
		gaTracking('撤離與收容', '撤離與收容人數及物資估計', '數據結果下載');
	}
	handleToggleMaterialInfo = (e) => {
		const controllerDom = e.currentTarget;
		const targetDom = e.currentTarget.nextSibling;
		if (controllerDom.classList.contains('-show')) {
			controllerDom.classList.remove('-show');
			targetDom.classList.remove('-open');
		}
		else {
			controllerDom.classList.add('-show');
			targetDom.classList.add('-open');
		}
	}
	handlePotentialRangeSelected = selectedCode => {
		if (selectedCode === '0') {
			return this.setState({
				isEvacuateSelected: false,
				selectedPotentialRange: null
			});
		}
		this.setState({
			isEvacuateSelected: true,
			selectedPotentialRange: selectedCode
		});
	}

	render() {

		const { evacuateTopics, evacuateData,
			containTopics, containData,
			spaceData, isSpaceDataLoaded, spaceCustomNumber,
			materialTopics, materialData, materialOptions, isMaterialAdvance } = this.state;
		const { countiesTownsData, selectedCounty, selectedTown, selectedVillage, potentialRange, selectedPotentialRange } = this.state;
		const { isMemberLogin, isDataLoading, isEvacuateSelected, isContainSelected, isMaterialSelected } = this.state;
		const allMaterialSource = [];
		materialData && materialData.map(data => data["Items"].map(item => {
			const div = document.createElement('div');
			div.innerHTML = item["Source"];
			const dom = div.firstChild || null;
			const content = dom && dom.textContent;
			if (item["Source"] && allMaterialSource.indexOf(content) === -1) {
				allMaterialSource.push(content);
			}
		}));
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 撤離與收容評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/evacuation" />
				<div className="evacuation-page">
					<div className="container">
						<div className="page-link">
							<Link className="item" to="/evaluation/evacuation/evacuate">
								<span>歷史撤離人次查詢</span>
							</Link>
							<Link className="item" to="/evaluation/evacuation/contain">
								<span>歷史收容人次查詢</span>
							</Link>
							<Link className="item" to="/evaluation/evacuation/alert">
								<span>歷史警戒熱區查詢</span>
							</Link>
						</div>
						<div ref="spy" className="estimate-heading">
							<div className="evacuation-chart-title">
								<div className="title">撤離與收容人數及物資估計</div>
							</div>
							<div className="estimate-description">
								面對來勢洶洶的颱風豪雨，要先撤離多少人才能確保居民平安渡過災害？撤離之後，要準備多少收容空間和物資呢？<br />
								本系統提供撤離收容人口、空間、物資估算的功能，您可以依序輸入您所在的位置，並選擇推估方式，來估算撤離或收容的人口，並根據這些數據，進一步估算收容所需要的空間與物資儲備。<br />
								若您已經了解要撤離或收容的人數，也可以點選下方按鈕，直接跳轉到空間與物資估計的單元進行估算。
							</div>
							<div className="estimate-selector">
								<div className="buttonbar">
									<button onClick={() => Scroll.scroller.scrollTo('evacuate', { smooth: true })}>撤離人口估計</button>
									<button onClick={() => Scroll.scroller.scrollTo('contain', { smooth: true })}>收容人口估計</button>
									<button onClick={() => Scroll.scroller.scrollTo('space', { smooth: true })}>收容空間估計</button>
									<button onClick={() => Scroll.scroller.scrollTo('material', { smooth: true })}>收容物資估計</button>
								</div>
							</div>
						</div>
						<Scroll.Element name="evacuate">
							<section className="estimate-section">
								{
									!isMemberLogin &&
									<LoginMask>
										<p>請先登入會員再進行操作</p>
										<button className="fill-button -pink" onClick={() => loginRedirect()}>登入</button>
									</LoginMask>
								}
								<div className="controller-bar">
									<div className="heading">撤離人口估計</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 1</span>
												<span>選擇您所在的位置</span>
											</div>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="county"
												value={selectedCounty ? selectedCounty.code : ''}
												onChange={value => this.handleCountyDistrictPickSelected(value)}>
												<option value="0">選縣市（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map((item, index) =>
														<option key={index} value={item.code}>{item.county}</option>
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="town"
												value={selectedTown ? selectedTown.code : ''}
												onChange={value => this.handleTownDistrictPickSelected(value)}>
												<option value="0">選鄉鎮（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															<option key={index} value={subItem.code}>{subItem.town}</option>
														)
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="village"
												value={selectedVillage ? selectedVillage.code : ''}
												onChange={value => this.handleVillageDistrictPickSelected(value)}>
												<option value="0">選村里（單選）</option>
												{
													selectedTown && 
													<option value="">全選</option> 
												}
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															subItem === selectedTown && subItem.villages.map((village, index) =>
																<option key={index} value={village.code}>{village.village}</option>
															)
														)
													)
												}
											</CustomSelect>
										</div>
									</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 2</span>
												<span>選擇推估方式</span>
											</div>
											<button className="toggle-btn" data-target="evacuate" onClick={this.handleClickToggleInfo}>
												<span className="open">說明</span>
												<span className="close">關閉</span>
												<i className="icon-info"></i>
											</button>
										</div>
										<div className="buttonbar">
											<button className={`${evacuateTopics['evacuate'].active ? '-active' : ''}`}
												onClick={() => this.handleSetEvacuateTopic('evacuate')} disabled={selectedVillage}
											>{evacuateTopics['evacuate'].name}</button>
											<button className={`${evacuateTopics['potential'].active ? '-active' : ''}`}
												onClick={() => this.handleSetEvacuateTopic('potential')}
											>{evacuateTopics['potential'].name}</button>
										</div>
										{
											evacuateTopics.potential.active &&
											<div className="select">
												<CustomSelect status="normal"
													value={selectedPotentialRange ? selectedPotentialRange : ''}
													onChange={value => this.handlePotentialRangeSelected(value)}>
													<option value="0">選潛勢規模（單選）</option>
													{
														potentialRange &&
														potentialRange.map((item, index) =>
															<option key={index} value={item}>{item + 'mm/日'}</option>
														)
													}
												</CustomSelect>
											</div>
										}
									</div>
									<div className="description -toggle toggle-evacuate">
										＊依歷史撤離人口：依據該鄉鎮市區過去颱風豪雨事件之平均撤離人次及最大撤離人次所進行的估計。目前尚無村里統計。<br />
										＊依潛勢人口：依據政府列冊之土石流保全人口與淹水保全或潛勢範圍推估而得。預估撤離人數為最小值，預估最大撤離人數則為最大值。<br />
										＊各地區推估結果因取得資料完整性不同，部分地區可能無撤離人口，建議仍應做好相關整備工作。<br />
									</div>
									<div className="submit">
										<button
											onClick={this.handleEvacuateSubmit}
											disabled={!selectedCounty || !selectedTown || !isEvacuateSelected}
										>確定</button>
									</div>
								</div>
								<div className="result-content">
									{isDataLoading && <LoadingDots theme="red" />}
									<div className="heading">撤離人口估算結果</div>
									<div className="result">
										<div className="estimate-number">
											<div className="label">預估撤離人數：</div>
											<div className="content">
												<AnimatedNumber
													className="type-text -en value"
													duration={300}
													formatValue={n => transferHundredPoint(Math.floor(n))}
													value={evacuateData['Value']}
												/>
												<span className="unit">人</span>
											</div>
										</div>
										<div className="estimate-number">
											<div className="label">預估最大撤離人數：</div>
											<div className="content">
												<AnimatedNumber
													className="type-text -en value"
													duration={300}
													formatValue={n => transferHundredPoint(Math.floor(n))}
													value={evacuateData['Max']}
												/>
												<span className="unit">人</span>
											</div>
										</div>
										{
											evacuateTopics.evacuate.active &&
											evacuateData.loadFail ?
												<div className="estimate-number">
													<div className="label"></div>
													<div className="content">
														<span className="unit">該區域無歷史資料，無法計算。</span>
													</div>
												</div> 
												: ''
										}
									</div>
									{
										(evacuateData['valueRatio'] !== null && evacuateData['maxRatio'] !== null) ?
											<div className="remark">占戶籍人口比例＝{evacuateData['valueRatio']}%～{evacuateData['maxRatio']}%</div> : ''
									}
								</div>
							</section>
						</Scroll.Element>
						<Scroll.Element name="contain">
							<section className="estimate-section">
								{
									!isMemberLogin &&
									<LoginMask>
										<p>請先登入會員再進行操作</p>
										<button className="fill-button -pink" onClick={() => loginRedirect()}>登入</button>
									</LoginMask>
								}
								<div className="controller-bar">
									<div className="heading">收容人口估計</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 1</span>
												<span>選擇您所在的位置</span>
											</div>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="county"
												value={selectedCounty ? selectedCounty.code : ''}
												onChange={value => this.handleCountyDistrictPickSelected(value)}>
												<option value="0">選縣市（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map((item, index) =>
														<option key={index} value={item.code}>{item.county}</option>
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="town"
												value={selectedTown ? selectedTown.code : ''}
												onChange={value => this.handleTownDistrictPickSelected(value)}>
												<option value="0">選鄉鎮（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															<option key={index} value={subItem.code}>{subItem.town}</option>
														)
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="village"
												value={selectedVillage ? selectedVillage.code : ''}
												onChange={value => this.handleVillageDistrictPickSelected(value)}>
												<option value="0">選村里（單選）</option>
												{
													countiesTownsData && 
													<option value="">全選</option> 
												}
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															subItem === selectedTown && subItem.villages.map((village, index) =>
																<option key={index} value={village.code}>{village.village}</option>
															)
														)
													)
												}
											</CustomSelect>
										</div>
									</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 2</span>
												<span>選擇推估方式</span>
											</div>
											<button className="toggle-btn" data-target="contain" onClick={this.handleClickToggleInfo}>
												<span className="open">說明</span>
												<span className="close">關閉</span>
												<i className="icon-info"></i>
											</button>
										</div>
										<div className="buttonbar">
											<button className={`${containTopics['evacuate'].active ? '-active' : ''}`}
												onClick={() => this.handleSetContainTopic('evacuate')} disabled={selectedVillage}
											>{containTopics['evacuate'].name}</button>
											<button className={`${containTopics['potential'].active ? '-active' : ''}`}
												onClick={() => this.handleSetContainTopic('potential')}
											>{containTopics['potential'].name}</button>
											{
												containTopics.potential.active &&
												<div className="select">
													<CustomSelect status="normal"
														value={selectedPotentialRange ? selectedPotentialRange : ''}
														onChange={value => this.handlePotentialRangeSelected(value)}>
														<option value="0">選潛勢規模（單選）</option>
														{
															potentialRange &&
															potentialRange.map((item, index) =>
																<option key={index} value={item}>{item + 'mm/日'}</option>
															)
														}
													</CustomSelect>
												</div>
											}
											<button className={`${containTopics['custom'].active ? '-active' : ''}`}
												onClick={() => this.handleSetContainTopic('custom')}
											>{containTopics['custom'].name}</button>
										</div>
										{
											containTopics['custom'].active &&
											<div className="input">
												<span>自訂撤離</span>
												<input type="number"
													value={containTopics['custom'].value}
													onFocus={() => this.handleSetContainTopic('custom')}
													onChange={(e) => this.handleSetContainCustom(e.currentTarget.value)}
												/>
												<span>人</span>
											</div>
										}
									</div>
									<div className="description -toggle toggle-contain">
										＊依歷史收容人口：依據該鄉鎮市區歷年颱風豪雨事件平均收容人次及最大收容人次所進行的估計。目前尚無村里統計。<br />
										＊依潛勢人口：依據政府列冊之保全人口與潛勢範圍設定撤離人數後，應用收容調查參數推估而得，含淹水及土石流災害。<br />
										＊各地區推估結果因取得資料完整性不同，部分地區可能無收容人口，建議仍應做好相關整備工作。<br />
                    ＊自訂撤離人口：使用者填報的數據，將依據本系統設定之收容調查參數進行推估
									</div>
									<div className="submit">
										<button
											disabled={!selectedCounty || !selectedTown || !isContainSelected}
											onClick={this.handleContainSubmit}>確定</button>
									</div>
								</div>
								<div className="result-content">
									{isDataLoading && <LoadingDots theme="red" />}
									<div className="heading">收容人口估算結果</div>
									<div className="result">
										<div className="estimate-number">
											<div className="label">預估收容人數：</div>
											<div className="content">
												<AnimatedNumber
													className="type-text -en value"
													duration={300}
													formatValue={n => transferHundredPoint(Math.floor(n))}
													value={containData['Value']}
												/>
												<span className="unit">人</span>
											</div>
										</div>
										{
											!containTopics['custom'].active &&
											<div className="estimate-number">
												<div className="label">預估最大收容人數：</div>
												<div className="content">
													<AnimatedNumber
														className="type-text -en value"
														duration={300}
														formatValue={n => transferHundredPoint(Math.floor(n))}
														value={containData['Max']}
													/>
													<span className="unit">人</span>
												</div>
											</div>
										}
										{
											containTopics.evacuate.active && 
											containData.loadFail &&
											<div className="estimate-number">
												<div className="label"></div>
												<div className="content">
													<span className="unit">該區域無歷史資料，無法計算。</span>
												</div>
											</div>
										}
										{
											(containData['valueRatio'] !== null && containData['maxRatio'] !== null) &&
											<div className="remark">占戶籍人口比例＝{containData['valueRatio']}%～{containData['maxRatio']}%</div>
										}
										{
											(containData['valueRatio'] === null && containData['maxRatio'] === null && containData['Ratio'] !== null) &&
											<div className="remark">占戶籍人口比例＝{containData['Ratio']}%</div>
										}
									</div>
								</div>
							</section>
						</Scroll.Element>
						<Scroll.Element name="space">
							<section className="estimate-section">
								{
									!isMemberLogin &&
									<LoginMask>
										<p>請先登入會員再進行操作</p>
										<button className="fill-button -pink" onClick={() => loginRedirect()}>登入</button>
									</LoginMask>
								}
								<div className="controller-bar">
									<div className="heading">收容空間估計</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 1</span>
												<span>輸入預估收容人數</span>
											</div>
										</div>
										<div className="input">
											<span>預計收容</span>
											<input type="number"
												value={spaceCustomNumber}
												onChange={(e) => this.handleSetSpaceCustom(e.currentTarget.value)}
												onBlur={(e) => this.handleSetSpaceCustom(Math.max(e.currentTarget.value, 50))}
											/>
											<span>人</span>
										</div>
									</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 2</span>
												<span>請選擇您所在的位置</span>
											</div>
											<button className="toggle-btn" data-target="space" onClick={this.handleClickToggleInfo}>
												<span className="open">說明</span>
												<span className="close">關閉</span>
												<i className="icon-info"></i>
											</button>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="county"
												value={selectedCounty ? selectedCounty.code : ''}
												onChange={value => this.handleCountyDistrictPickSelected(value)}>
												<option value="0">選縣市（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map((item, index) =>
														<option key={index} value={item.code}>{item.county}</option>
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="town"
												value={selectedTown ? selectedTown.code : ''}
												onChange={value => this.handleTownDistrictPickSelected(value)}>
												<option value="0">選鄉鎮（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															<option key={index} value={subItem.code}>{subItem.town}</option>
														)
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="village"
												value={selectedVillage ? selectedVillage.code : ''}
												onChange={value => this.handleVillageDistrictPickSelected(value)}>
												<option value="0">選村里（單選）</option>
												{
													countiesTownsData && 
													<option value="">全選</option> 
												}
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															subItem === selectedTown && subItem.villages.map((village, index) =>
																<option key={index} value={village.code}>{village.village}</option>
															)
														)
													)
												}
											</CustomSelect>
										</div>
									</div>
									<div className="description -toggle toggle-space">
										＊收容空間估計標準：每人 4 平方公尺（預設為個人使用空間）。<br />
                    ＊個人使用空間參考依據：<br />
                    - Alameda County Operational Area Emergency Management Organization (2003) A Guide for Local Jurisdictions In Care and Shelter Planning, P.57. <br />
                    - American red cross (2012) Sheltering Handbook Disaster Services, P2-10.<br />
                    ＊國小教室計算標準：112.5 平方公尺/間（參考來源：教育部國民中小學設備基準）。<br />
                    ＊現有收容空間之統計依各縣市公告及系統回報(見首頁說明)，無法判別是否已扣除公共使用空間，使用時請留意。<br />
                    ＊本系統收容空間估計依據收容人數而定，系統預設最低收容人數在「鄉鎮」時為70人，在「村里」時為20人，使用者另也可自訂。<br />
									</div>
									<div className="submit">
										<button
											disabled={!selectedCounty || !selectedTown }
											onClick={this.handleSpaceSubmit}>確定</button>
									</div>
								</div>
								<div className="result-content">
									{isDataLoading && <LoadingDots theme="red" />}
									<div className="heading">收容空間估算結果</div>
									<div className="result">
										<div className="estimate-number">
											<div className="label">需要空間：</div>
											<div className="content">
												<AnimatedNumber
													className="type-text -en value"
													duration={300}
													formatValue={n => transferHundredPoint(Math.floor(n))}
													value={spaceData.require}
												/>
												<span className="unit">平方公尺</span>
											</div>
										</div>
										<div className="estimate-number">
											<div className="label">約相當於：</div>
											<div className="content">
												<AnimatedNumber
													className="type-text -en value"
													duration={300}
													formatValue={n => transferHundredPoint(Math.floor(n))}
													value={Math.ceil(spaceData.require / 112.5)}
												/>
												<span className="unit">間國小教室</span>
											</div>
										</div>
										<div className="estimate-number">
											<div className="label">現有收容空間：</div>
											{
												spaceData.reality !== -1 &&
												<div className="content">
													<AnimatedNumber
														className={`type-text -en value ${(spaceData.reality + '').length > 4 && '-sm'}`}
														duration={300}
														formatValue={n => transferHundredPoint(Math.floor(n))}
														value={Math.ceil(spaceData.reality)}
													/>
													<span className="unit">平方公尺</span>
													{(isSpaceDataLoaded && spaceData.reality >= spaceData.require) && <span className="face"><img src={spaceSuccessSvg} alt="" /></span>}
													{(isSpaceDataLoaded && spaceData.reality < spaceData.require) && <span className="face"><img src={spaceAlertSvg} alt="" /></span>}
												</div>
											}
											{
												spaceData.reality === -1 &&
												<div className="content">
													<span className="unit">超過五成的收容所無填報資料，無法計算。</span>
												</div>
											}
										</div>
									</div>
								</div>
							</section>
						</Scroll.Element>
						<Scroll.Element name="material">
							<section className="estimate-section">
								{
									!isMemberLogin &&
									<LoginMask>
										<p>請先登入會員再進行操作</p>
										<button className="fill-button -pink" onClick={() => loginRedirect()}>登入</button>
									</LoginMask>
								}
								<div className="controller-bar">
									<div className="heading">收容物資估計</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 1</span>
												<span>請選擇您所在的位置</span>
											</div>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="county"
												value={selectedCounty ? selectedCounty.code : ''}
												onChange={value => this.handleCountyDistrictPickSelected(value)}>
												<option value="0">選縣市（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map((item, index) =>
														<option key={index} value={item.code}>{item.county}</option>
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="town"
												value={selectedTown ? selectedTown.code : ''}
												onChange={value => this.handleTownDistrictPickSelected(value)}>
												<option value="0">選鄉鎮（單選）</option>
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															<option key={index} value={subItem.code}>{subItem.town}</option>
														)
													)
												}
											</CustomSelect>
										</div>
										<div className="select">
											<CustomSelect status="normal" name="village"
												value={selectedVillage ? selectedVillage.code : ''}
												onChange={value => this.handleVillageDistrictPickSelected(value)}>
												<option value="0">選村里（單選）</option>
												{
													countiesTownsData && 
													<option value="">全選</option> 
												}
												{
													countiesTownsData &&
													countiesTownsData.counties.map(item =>
														item === selectedCounty && item.towns.map((subItem, index) =>
															subItem === selectedTown && subItem.villages.map((village, index) =>
																<option key={index} value={village.code}>{village.village}</option>
															)
														)
													)
												}
											</CustomSelect>
										</div>
									</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 2</span>
												<span>輸入預估收容人數</span>
											</div>
											<button className="toggle-btn" data-target="material" onClick={this.handleClickToggleInfo}>
												<span className="open">說明</span>
												<span className="close">關閉</span>
												<i className="icon-info"></i>
											</button>
										</div>
										<div className="input">
											<span>預計收容</span>
											<input type="number"
												value={spaceCustomNumber}
												onChange={(e) => this.setState({ spaceCustomNumber: e.currentTarget.value })}
												onBlur={(e) => this.setState({ spaceCustomNumber: Math.max(Math.min(e.currentTarget.value, 600000), 50) })}
											/>
											<span>人</span>
										</div>
									</div>
									<div className="controller">
										<div className="title">
											<div className="step">
												<span>Step 3</span>
												<span>選擇您要儲備的天數</span>
											</div>
										</div>
										<div className="buttonbar">
											<button className={`${materialTopics['mountain'].active ? '-active' : ''}`}
												onClick={() => this.handleSetMaterialTopic('mountain')}
											>{materialTopics['mountain'].name}</button>
											<button className={`${materialTopics['country'].active ? '-active' : ''}`}
												onClick={() => this.handleSetMaterialTopic('country')}
											>{materialTopics['country'].name}</button>
											<button className={`${materialTopics['city'].active ? '-active' : ''}`}
												onClick={() => this.handleSetMaterialTopic('city')}
											>{materialTopics['city'].name}</button>
										</div>
									</div>
									<div className="description -toggle toggle-material">
										＊本系統收容物資估計依據收容人數而定，系統預設最低收容人數在「鄉鎮」時為70人，在「村里」時為20人，使用者另也可自訂。
									</div>
									<div className="submit">
										<button
											disabled={!selectedCounty || !selectedTown || !isMaterialSelected}
											onClick={this.handleMaterialSubmit}
										>確定</button>
									</div>
									{
										isMaterialAdvance &&
										<div className="advance">
											<div className="title">依需求切換不同物資</div>
											<div className="description">您也可以依照需求切換不同項目，查看所需要的數量。</div>
											{
												materialOptions &&
												materialOptions.map((option, i) =>
													<React.Fragment key={i}>
														<label className="label" htmlFor="">{option["Group_Name"]}</label>
														<div className="select">
															<CustomSelect status="normal" name={option["Category"]}
																value={`${option["Group_Name"]}-${option["current"]}`}
																onChange={value => this.handleChangeMaterialGroup(value)}>
																{
																	option["Items"].map((item, j) =>
																		<option key={j} value={`${option["Group_Name"]}-${item["Name"]}`}>{item["Name"]}</option>
																	)
																}
															</CustomSelect>
														</div>
													</React.Fragment>
												)
											}
										</div>
									}
								</div>
								<div className="result-content">
									{isDataLoading && <LoadingDots theme="red" />}
									<div className="heading">收容物資估算結果</div>
									<div className="result">
										{
											materialData &&
											materialData.map((data, i) =>
												<div className="estimate-material" key={i}>
													<div className="title">{data['Category']}</div>
													<button onClick={this.handleToggleMaterialInfo} className="info-controller"><i className="icon-plus"></i></button>
													<div className="info-list">
														<div className="list">
															{
																data["Items"].map((item, j) =>
																	(item.active === undefined || item.active) &&
																	<div className="item" key={j}>
																		<p>細項：{item["Name"]}</p>
																		<p>標準：{item["Standard"]}</p>
																	</div>
																)
															}
														</div>
													</div>
													<div className="material-list">
														{
															data["Items"].map((item, j) =>
																(item.active === undefined || item.active) &&
																<div className="item" key={j}>
																	<div className="material-item">
																		<div className="icon"><img src={`${sourceHost}/${item["Icon"]}`} alt="" /></div>
																		<div className="info">
																			<span className="name">{item["Name"]}（{item["Unit"]}）</span>
																			<span className="value">
																				<span className="type-text -en qty">{transferHundredPoint(item["Value"])}</span>
																			</span>
																		</div>
																	</div>
																</div>
															)
														}
													</div>
												</div>
											)
										}
										{
											materialData &&
											<div className="material-source">
												<div className="title">部分物資計算參考資訊</div>
												{
													allMaterialSource.map((item, i) =>
														<div key={i} className="item" dangerouslySetInnerHTML={{ __html: item }}></div>
													)
												}
											</div>
										}
									</div>
								</div>
							</section>
						</Scroll.Element>
						<div className="estimate-download">
							<button className="button fill-button -pink"
								disabled={!selectedCounty || !selectedTown}
								onClick={this.handleDownloadData}>下載數據結果</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default EvacuationEstimate;
