import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import { Link } from "react-router-dom";
import './index.scss';

// Resources
import MorakotBackground1 from '../../../images/morakot-bg1.png';
import MorakotPercentSVG from "../../../images/morakot-percent.svg";

class MorakotIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
			articleItems: null,
			nowCategoryID: 0,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('SHOW');

		const breadcrumb = [
      { name: 'Home Page', href: '/' },
      { name: 'Morakot Typhoon', href: '/statistics/morakot-en' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});

	}
	handleClickToggle = (e) => {
		const controller = e.currentTarget;
		const content = e.currentTarget.nextSibling;

		if (content.classList.contains('-show')) {
			controller.classList.remove('-open');
			content.classList.remove('-show');
		}
		else {
			controller.classList.add('-open');
			content.classList.add('-show');
		}
	}
	handleClickMorakotSideModalOpen = () => {
		this.props.onClickOpenSideModal('MORAKOT');
	}

	render() {
		return (
			<div className="new-morakot-page">
				<Helmet>
					{/* <title>減災動資料 - 莫拉克風災災後調查</title> */}
				</Helmet>
				<section className="morakot-heading -en">
					<Link className="block" to="/statistics/morakot-en/intro">
						<div className="heading">
							<h2 className="type-heading -en">Typhoon Morakot Infographics</h2>
							<div className="hint type-heading -en">click to see more</div>
						</div>
						<img className="background" src={MorakotBackground1} alt="" />
					</Link>
				</section>
				<section className="morakot-section morakot-info">
					<div className="container">
						<h1 className="sectiontitle type-text -en">Social Impacts and Recovery Survey of Typhoon Morakot (2010~2019)</h1>
						<div className="sectioncontent type-text -en">
							<p className="strong type-text -en">Instruction</p>
							<p className="">Typhoon Morakot struck Taiwan on Aug. 2009, caused severe damage and lots of casualties especially to the south-west area of Taiwan. National Science and Technology Center for Disaster Reduction conducted a longitudinal face-to-face social survey since 2010, in order to collect the data from disaster affected households whose residences were partially or fully destroyed.</p>
							<ul className="morakot-list -en">
								<li>
									<label className="type-text -en -md" htmlFor="">Survey population</label>
									<p className="type-text -en -md">households who applied residency relief fund from department of social welfare.</p>
								</li>
								<li>
									<label className="type-text -en -md" htmlFor="">Number of survey population</label>
									<p className="type-text -en -md">1754 (households)</p>
								</li>
								<li>
									<label className="type-text -en -md" htmlFor="">Survey year</label>
									<p className="type-text -en -md">2010、2011、2012、2015、2019</p>
								</li>
								<li>
									<label className="type-text -en -md" htmlFor="">Survey area</label>
									<p className="type-text -en -md">Nantou county, Chiayi county, Tainan city, Kaohsiung city, Pingtung county, Taitung county.</p>
								</li>
								<li>
									<label className="type-text -en -md" htmlFor="">Execution Unit</label>
									<p className="type-text -en -md">The Directorate General of Budget, Accounting and Statistics of Executive Yuan</p>
								</li>
							</ul>
							<div className="toggle morakot-toggle">
								<button className="controller -open" onClick={this.handleClickToggle}>
									<span className="type-text -en -md">Response rate</span>
									<div className="icon">
										<span className="open">Show<i className="icon-plus"></i></span>
										<span className="close">Hide<i className="icon-minus"></i></span>
									</div>
								</button>
								<div className="content -show">
									<p className="image"><img src={MorakotPercentSVG} alt="" /></p>
								</div>
							</div>
							<div className="toggle morakot-toggle">
								<button className="controller -open" onClick={this.handleClickToggle}>
									<span className="type-text -en -md">Survey issues</span>
									<div className="icon">
										<span className="open">Show<i className="icon-plus"></i></span>
										<span className="close">Hide<i className="icon-minus"></i></span>
									</div>
								</button>
								<div className="content -show">
									<div className="morakot-summarytable">
										<table>
											<tbody>
												<tr>
													<th className="sort"><span className="type-text -en -sm">Classification</span></th>
													<th className="issue"><span className="type-text -en -sm">Classification issues</span></th>
													<th className="research" colSpan="5"><span className="type-text -en -sm">Survey year</span></th>
												</tr>
												<tr>
													<th></th>
													<th></th>
													<th className="type-text -en">2010</th>
													<th className="type-text -en">2011</th>
													<th className="type-text -en">2012</th>
													<th className="type-text -en">2015</th>
                          <th className="type-text -en">2019</th>
												</tr>
												<tr>
													<td className="subject"><span className="type-text -en -ss">Disaster Reduction</span></td>
													<td className="item"><span className="type-text -en -ss">Risk perception, Disaster preparedness</span></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
													<td></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject" rowSpan="2"><span className="type-text -en -ss">Disaster Response</span></td>
													<td className="item"><span className="type-text -en -ss">Temporary accommodation, Confidence level</span></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
													<td></td>
                          <td></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Evacuation</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject" rowSpan="6"><span className="type-text -en -ss">Disaster Recovery</span></td>
													<td className="item"><span className="type-text -en -ss">Housing reconstruction</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Quality of residence</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i><i className="icon-oino"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i><i className="icon-oino"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Community reconstruction, Time required for recovery</span></td>
													<td></td>
													<td></td>
													<td></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Confidence level</span></td>
													<td></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Risk communication</span></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
													<td></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">occupation</span></td>
													<td></td>
													<td><i className="icon-star"></i><i className="icon-oino"></i></td>
													<td></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject" rowSpan="2"><span className="type-text -en -ss">Respondent demographics</span></td>
													<td className="item"><span className="type-text -en -ss">Age, Mental health, Religion, Gender, Marital status, Education</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Occupation</span></td>
													<td><i className="icon-star"><i className="icon-oino"></i></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject" rowSpan="5"><span className="type-text -en -ss">Household Demographics</span></td>
													<td className="item"><span className="type-text -en -ss">Landowners</span></td>
													<td></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
                          <td></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Number of Elders, children, and households</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i><i className="icon-oino"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Homeowners, residence duration, building type</span></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
													<td></td>
                          <td></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Indigenous family</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Family income, Household expenses</span></td>
													<td><i className="icon-star"></i><i className="icon-oino"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject"><span className="type-text -en -ss">Disability</span></td>
													<td className="item"></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject" rowSpan="4"><span className="type-text -en -ss">Disaster Impact</span></td>
													<td className="item"><span className="type-text -en -ss">Casualties, Cause of disaster, Disaster experience</span></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
													<td></td>
                          <td></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Social network</span></td>
													<td><i className="icon-star"></i><i className="icon-oino"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Property loss and costs</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td></td>
													<td></td>
                          <td></td>
												</tr>
												<tr>
													<td className="item"><span className="type-text -en -ss">Unemployment</span></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
												<tr>
													<td className="subject"><span className="type-text -en -ss">Resource acquisition</span></td>
													<td className="item"></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
													<td><i className="icon-star"></i></td>
                          <td><i className="icon-star"></i></td>
												</tr>
											</tbody>
										</table>
										<p className="remark">
											<i className="icon-star"></i>
											{/*<span className="type-text -en -ss">Annual survey of each issue</span>*/}
                      <span className="type-text -en -ss">Survey year</span>
											<i className="icon-oino"></i>
											{/*<span className="type-text -en -ss">Survey year including pre-disaster condition</span>*/}
                      <span className="type-text -en -ss">Including pre-disaster condition</span>
										</p>
									</div>
								</div>
							</div>
							<ul className="morakot-list -en">
								<li>
									{/*<label className="type-text -en -md" htmlFor="">Source Request</label>*/}
									<p className="type-text -en -md">For more information see 
                    <a href="https://easy2do.ncdr.nat.gov.tw/survey" target="_blank" rel="noopener noreferrer"> easy2do</a>
                    {/* > <a href="https://easy2do.ncdr.nat.gov.tw/survey" target="_blank" rel="noopener noreferrer">Social economy Survey Data Area</a>*/}
                  </p>
								</li>
							</ul>
							<p className="type-text -en -md">Photo by Jihnnan</p>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default MorakotIndex;
