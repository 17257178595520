import { connect } from 'react-redux';
import EvaluationSideModal from './pure';

const mapStateToProps = (state, ownProps) => ({
  isModalOpen: state.evaluationSideModal.isModalOpen,
})

const mapDispatchToProps = (dispatch) => ({
  onClickOpenSideModal: (MODAL_NAME) => dispatch({ type: 'OPEN_' + MODAL_NAME + '_SIDEMODAL' }),
  onClickCloseModal: () => dispatch({ type: 'CLOSE_EVALUATION_SIDEMODAL' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationSideModal);
