import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import 'bootstrap/scss/bootstrap-grid.scss';

// import * as serviceWorker from './serviceWorker';

import './index.scss';
import App from './App';
import appReducer from './App/reducer';

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: [
      'Poppins:300,400,500,600,700,800,900',
      'Roboto:300,400,500,700,900',
      'Roboto+Mono:300,400,500,700,900',
      'Roboto+Condensed:400, 700',
      'sans-serif']
  }
});


const createStoreWithMiddleware = applyMiddleware(
  ReduxThunk
)(createStore);

const appStore = createStoreWithMiddleware(appReducer, typeof(window) !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

ReactDOM.render(
  <Provider store={appStore}>
    <App />
  </Provider>
  , document.getElementById('root'));

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
