import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './index.scss';
import $ from 'jquery';

$(document).ready(function () {
	$('.wrap .con-two').click(function () {
		$(this).siblings().slideToggle().parent('.wrap-ul>li').siblings().find('.wrap-three').slideUp().siblings('.con-three a')
	})
})

class MorakotIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('YELLOW');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '網站地圖', href: '/sitemap' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}


	render() {
		return (
			<div className="sitemap-nav">
			<div className="sitemap-section">
				<div className="container">
					<div className="heading">
						<span className="type-heading -ch -h3">網站地圖</span>
					</div>
					<ul className="wrap">
            <li>
                <ul className="wrap-ul">
                    <li>
                        <div className="con-one">調查統計</div>
                    </li>
                    <li>
                        <Link to="/statistics/morakot" className="con-one">莫拉克颱風社會衝擊與復原調查(2010~2019)</Link>
                    </li>
                    <li>
                        <a href="#" className="con-two">統計展示 +</a>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li> <Link to="/statistics/morakot/disaster-reduction/risk-perception">減災</Link> </li>
                                <li> <Link to="/statistics/morakot/disaster-response/evacuation-message">應變</Link> </li>
                                <li> <Link to="/statistics/morakot/disaster-recover/current-place-of-residence">復原與重建</Link> </li>
                                <li> <Link to="/statistics/morakot/respondent-demographics/age">受訪者特徵</Link> </li>
                                <li> <Link to="/statistics/morakot/household-demographics/elders-and-children">家戶特徵</Link> </li>
                                <li> <Link to="/statistics/morakot/disability/disability">行動不便者</Link> </li>
                                <li> <Link to="/statistics/morakot/disaster-impact/cause-of-disaster">災害衝擊</Link> </li>
                                <li> <Link to="/statistics/morakot/resource-acquisition/short-term-needs-after-disaster">資源取得</Link> </li>
                                <li> <Link to="/statistics/morakot-house/">永久屋專區</Link> </li>
                                <li> <Link to="/statistics/morakot-city/">縣市專區</Link> </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/statistics/morakot/advanced-analytic" className="con-two">進階分析</Link>
                    </li>
                    <li>
                        <Link to="/statistics/sfaa" className="con-one">社福機構自然災害風險調查(2011)</Link>
                    </li>
                    <li>
                        <Link to="/statistics/sfaa-elder/disaster-experience/flood" className="con-two">統計展示</Link>
                    </li>
                    <li>
                        <div className="con-two">老人福利機構 +</div>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li>
                                    <Link to="/statistics/sfaa-elder/disaster-experience/flood">受災經驗</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-elder/building-features/entrance-and-exit-of-the-main-entrance">建物特徵</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-elder/risk-perception/temporary-shelter">減災</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-elder/institutional-and-residential-characteristics/hospital-property">機構與院民特徵</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-elder/environmental-characteristics/flood">環境特徵</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="con-two">身心障礙福利機構 +</div>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li>
                                    <Link to="/statistics/sfaa-disability/disaster-experience/flood">受災經驗</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-disability/building-features/width-of-the-entrance-and-exit-of-the-main-entrance">建物特徵</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-disability/risk-perception/temporary-shelter">減災</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-disability/institutional-and-residential-characteristics/hospital-property">機構與院民特徵</Link>
                                </li>
                                <li>
                                    <Link to="/statistics/sfaa-disability/environmental-characteristics/flood">環境特徵</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="/statistics/TainanEarthquake" className="con-one">0206臺南震災天然災害社經影響家戶調查(2016)</a>
                    </li>
                    <li>
                        <a href="/statistics/HualienEarthquake" className="con-one">00206花蓮震災地震感知與避難行為調查(2018)</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/index" className="con-one">0823水災撤離與收容調查</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/flooded" className="con-two">淹水情況調查結果</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/result" className="con-two">疏散撤離調查結果</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/risk" className="con-two">風險知覺&減災行為</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/customized" className="con-two">客製化專區</a>
                    </li>
                    <li>
                        <a href="https://drrstat.ncdr.nat.gov.tw/flood/focus?pid=12" className="con-two">聚焦事件報導</a>
                    </li>
                    <li>
                        <div className="con-two">互動小遊戲 +</div>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li>
                                    <a href="https://drrstat.ncdr.nat.gov.tw/flood/question">水災知多少</a>
                                </li>
                                <li>
                                    <a href="https://drrstat.ncdr.nat.gov.tw/flood/foodlingindex">淹水保衛戰</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
            <li>
                <ul className="wrap-ul">
                    <li>
                        <div className="con-one">評估系統</div>
                    </li>
                    <li>
                        <Link to="/evaluation/svi" className="con-one">社會脆弱度評估</Link>
                    </li>
                    <li>
                        <Link to="/evaluation/svi/intro" className="con-two">社會脆弱度指標查詢與展示 +</Link>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li>
                                    <Link to="/evaluation/svi/z">單一年度行政區比較</Link>
                                </li>
                                <li>
                                    <Link to="/evaluation/svi/s">單一行政區趨勢</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/evaluation/svi/advance" className="con-two">客製化社會脆弱度評估</Link>
                    </li>
                    <li>
                        <Link to="/evaluation/evacuation" className="con-one">撤離與收容評估系統</Link>
                    </li>
                    <li>
                        <Link to="/evaluation/evacuation/evacuate/intro" className="con-two">歷史資料查詢與展示 +</Link>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li>
                                    <Link to="/evaluation/evacuation/evacuate">歷史撤離人數查詢</Link>
                                </li>
                                <li>
                                    <Link to="/evaluation/evacuation/contain">歷史收容人數查詢</Link>
                                </li>
                                <li>
                                    <Link to="/evaluation/evacuation/alert">歷史警戒熱區查詢</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/evaluation/evacuation/estimate" className="con-two">撤離與收容人數及物資估計</Link>
                    </li>
                </ul>
            </li>
            <li>
                <ul className="wrap-ul">
                    <li>
                        <Link to="/" className="con-one">首頁</Link>
                    </li>
                    <li>
                        <Link to="/statistics/morakot/categories" className="con-one">主題館</Link>
                    </li>
                    <li>
                        <Link to="/news" className="con-one">最新消息</Link>
                    </li>
                    <li>
                        <div className="con-two con-one">會員系統 +</div>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li> <Link to="/member/register">註冊</Link> </li>
                                <li> <Link to="/member/login">登入</Link> </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="con-two con-one">其他 +</div>
                        <div className="wrap-three">
                            <ul className="con-three">
                                <li> <Link to="/policy">隱私權政策</Link> </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
				</div>
			</div>
			</div>
		);
	}
}

export default MorakotIndex;
