import React, { Component } from 'react';
import './index.scss';

class CustomSelect extends Component {

	componentDidMount() {
		this.selectEle = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			if (this.props.onChange) {
				this.props.onChange(this.selectEle.current.value, this.selectEle.current.selectedIndex);
			}
		}
	}

	render() {
		const { label, id, status, name, disabled, onChange, value, children, msg } = this.props;
		return (
			<div className="custom-select">
				{
					label &&
					<label className="type-text -ch -md" htmlFor={id}>
						{label}
					</label>
				}
				<div className={`wrap ${status ? `-${status}` : ''}`}>
					<i className="icon icon-dropdown"></i>
					<select
						ref={this.selectEle}
						name={name ? name : ''}
						id={id}
						disabled={disabled}
						onChange={e => {
							e.persist();
							if (onChange) {
								onChange(e.target.value, e.target.selectedIndex);
							}
						}}
						value={value || ''}
					>
						{children}
					</select>
				</div>
				{
					msg && <p className={`type-text -ch -xs msg ${status ? `-${status}` : ''}`}>{ msg }</p>
				}
			</div>
		);
	}
}

export default CustomSelect;
