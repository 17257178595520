import React, { Component } from 'react';
import './index.scss';
import ReactEcharts from 'echarts-for-react'; 
class SviChartLine extends Component {
	constructor(params) {
		super(params);
		this.state = {
			echartsOption: {},
			defaultData: [
				{indicator_id: null, indicator_name: null, value: null, year: "2002"},
				{indicator_id: null, indicator_name: null, value: null, year: "2003"},
				{indicator_id: null, indicator_name: null, value: null, year: "2004"},
				{indicator_id: null, indicator_name: null, value: null, year: "2005"},
				{indicator_id: null, indicator_name: null, value: null, year: "2006"},
				{indicator_id: null, indicator_name: null, value: null, year: "2007"},
				{indicator_id: null, indicator_name: null, value: null, year: "2008"},
				{indicator_id: null, indicator_name: null, value: null, year: "2009"},
				{indicator_id: null, indicator_name: null, value: null, year: "2010"},
				{indicator_id: null, indicator_name: null, value: null, year: "2011"},
				{indicator_id: null, indicator_name: null, value: null, year: "2012"},
				{indicator_id: null, indicator_name: null, value: null, year: "2013"},
				{indicator_id: null, indicator_name: null, value: null, year: "2014"},
				{indicator_id: null, indicator_name: null, value: null, year: "2015"},
				{indicator_id: null, indicator_name: null, value: null, year: "2016"},
				{indicator_id: null, indicator_name: null, value: null, year: "2017"},
				{indicator_id: null, indicator_name: null, value: null, year: "2018"},,
				{indicator_id: null, indicator_name: null, value: null, year: "2019"},
				{indicator_id: null, indicator_name: null, value: null, year: "2020"}
			]
		};
	}

	componentDidMount() {
		this.handleLoadSviData()
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.handleLoadSviData();
			if (this.props.sviData.length === 0) {
				this.setState({
					isDataLoad: false
				})
			}
			else {
				this.setState({
					isDataLoad: true
				})
			}
		}
	}
	handleLoadSviData = () => {
		if (this.props.sviData) {
			let { sviData } = this.props;
			if(sviData.length === 0){
				sviData = this.state.defaultData
			}

			if (sviData.length !== 0) {
					let xAxisYears = [];
					const indicators = [];
					const dataSeries = [];

					sviData.forEach((item) => {
						if (xAxisYears.indexOf(item.year) === -1) {
							xAxisYears.push(item.year)
						}
						if (indicators.indexOf(item.indicator_name) === -1) {
							indicators.push(item.indicator_name)
						}
					});
					xAxisYears = xAxisYears.sort(function (a, b) {
						return Number(a) - Number(b)
					});

					indicators.forEach((indicator_name) => {
						const data = [];
						let name = '';
						sviData.forEach((item) => {
							if (indicator_name === item.indicator_name) {
								name = item.indicator_name;
								data.push([item.year, item.value]);
							}
						});
						dataSeries.push({
							type: 'line',
							name: name,
							data: data
						});
					});

					const values = [];
				sviData.forEach(item => item.value !== null && values.push(item.value));
				const max = Math.max(...values);
				const min = Math.min(...values);
				// const limit = Math.max(Math.max(Math.abs(100-max), Math.abs(100-min)), 1);

					const option = {
						color: ['#68AAFF', '#2FC172', '#FDC100', '#FF6F6E', '#CDA374', '#FF0000', '#0000FF', '#4B0080', '#CC5500', '#404040'],
						grid: {
							containLabel: true,
							left: '0%',
							right: '0%',
							top: '10%',
							bottom: '15%',
						},
						legend: {
							show: true,
							data: indicators,
						},
						tooltip: {
							trigger: 'axis',
						},
						xAxis: {
							type: 'category',
							data: xAxisYears,
						axisLabel: { rotate: xAxisYears.length > 15 ? 45 : 0 }
						},
						yAxis: {
							type: 'value',
							minInterval: 2,
							splitNumber: 5,
							min: Math.floor(min-1), //100 - Math.floor(limit*1.5),
							max: Math.ceil(max+1), //100 + Math.ceil(limit*1.5),
							scale: true
						},
						series: dataSeries
					};
					this.setState({
						echartsOption: option,
					});
			}
		}
	}

	render() {
		const { className } = this.props;
		return (
			<div className={`line-chart ${className ? className : ''}`}>
				<ReactEcharts
					option={this.state.echartsOption}
					notMerge={true}
					lazyUpdate={true}
					opts={{ renderer: 'svg' }}
					style={
						{ height: '450px' }
					}
				/>
			</div>
		);
	}
}

export default SviChartLine;
