import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import queryString from "query-string";

import HouseMap from '../HouseMap';

import './index.scss';

class HouseIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isMapShow: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadHeaderColor('GREEN');
    this.props.onLoadHeaderTheme('DARK');
    this.props.onLoadShowLanguage('HIDE');
    const breadcrumb = [
      { name: '首頁', href: '/' },
      { name: '莫拉克風災災後調查', href: '/statistics/morakot' },
      { name: '永久屋專區', href: '/statistics/morakot-house' },
    ]
    this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
  }
  handleClickSelectSubject = (e) => {
    const subject = e.currentTarget.dataset.subject;
    this.setState({
      isMapShow: true,
      subject: subject
    })
  }
  handleClickNextStep = () => {
    const { subject, selected } = this.state;
    const parse = queryString.stringify({ houses: selected });
    window.location.href = `${subject}?${parse}`;
  }
  render() {
    const { isMapShow, subject } = this.state;
    return (
      <div className="morakot-area-page">
        <Helmet>
          <title>減災動資料 - 莫拉克風災災後調查</title>
        </Helmet>
        <div className="container">
          <div className="morakot-area-heading">永久屋專區</div>
          {
            !isMapShow &&
            <div className="morakot-area-intro subject-selector">
              <div className="description">
                政府於莫拉克颱風災後與各個民間團體合作，協助災民進行住所復原。永久屋專區提供 33 個永久屋社區在災後復原上的統計數據，有效調查家戶數為1110，各議題百分比之計算皆依有效家戶數為準，請選擇您想查看的主題：
              </div>
              <div className="subjects">
                <div className="item"><Link to="/statistics/morakot-house/house-quantity?houses=0" className="button">永久屋社區家戶數</Link></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/resource-acquisition" className="button">政府與民間團體資源</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/employment-mediation" className="button">就業與媒合</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/most-dissatisfied" className="button">最不滿意的地方</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/commuting-time" className="button">通勤時間</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/quality-of-residence" className="button">居住品質</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/community-environment" className="button">社區環境</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/time-of-recovery" className="button">生活作息恢復</button></div>
                <div className="item"><button onClick={ this.handleClickSelectSubject } data-subject="/statistics/morakot-house/confidence-level" className="button">信任感</button></div>
              </div>
            </div>
          }
          {
            isMapShow &&
            <div className="morakot-area-intro map-selector">
              <div className="description">
                請點擊縮放地圖，選擇您想查看的永久屋。或選擇右方「看全部永久屋」按鈕，查看全部數據分析。
              </div>
              <HouseMap subjectSelected={subject} getSelected={(element) => { this.setState({ selected: element }) }} />
              <div className="submit">
                <button className="fill-button -dark"
                  disabled={this.state.selected.length === 0}
                  onClick={this.handleClickNextStep}>下一步</button>
              </div>
              <div className="notes">
                <p>* 「神木社區、高士部落、普力姆(puljimu)、嘉蘭村(紅十字會興建)、大竹部落、日好社區、山美社區共8個永久屋社區，因調查戶數小於5，視覺圖表不顯示。」想看更多請至客製化專區。</p>
                <p>* 本調查資料無南投縣紅立新邨、屏東縣九棚永久屋及寶山永久屋三個社區之受訪戶，另雲林縣因非本調查範圍，古坑鄉東興社區亦無資料。</p>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default HouseIntro;
