import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './index.scss';

import ArticleItem from '../../utils/ArticleItem';
import { apiHost, sourceHost } from '../../utils/Shared/constant';

class ArticleList extends Component {
	constructor(params) {
		super(params);
		this.state = {};
	}
	componentDidMount() {
		const { categoryID } = this.props;
		axios.get(`${apiHost}/api/Content/ArticleList?data.currentPage=1&data.categoryID=${categoryID}&data.perPage=2`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						articleItems: res.data.Result ? res.data.Result : [],
						totalPage: res.data.TotalPage,
						loading: false,
					})
				}
			})
	}
	render() {
		const { color, pathname } = this.props;
		return (
			<div className="row subject-list">
				{
					this.state.articleItems &&
					this.state.articleItems.length > 0 &&
					this.state.articleItems.map((item, i) => {
						const { Author, ID, ImageUrl, Summary, Title, Published } = item;
						return (
							<div key={i} className="col-lg-6">
								<ArticleItem 
								color={color} 
								author={Author} 
								image={`${sourceHost}/${ImageUrl}`} 
								title={Title} 
								content={Summary} 
								href={`${pathname}/article/${ID}`}
								date={Published}
								key={i} />
							</div>
						)

					})
				}
			</div>
		);
	}
}

ArticleList.protoTypes = {
	categoryID: PropTypes.number,
}

export default ArticleList;
