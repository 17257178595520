import React, { Component } from 'react';
import './index.scss';

class LogoSVG extends Component {
    render() {
        return (
            <svg className={"logo-svg " + this.props.color} width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
                <path className="left" d="M18.1818182,6.16 L18.1818182,0 C8.08080808,1 -2.48689958e-14,9.62 -2.48689958e-14,20 C-2.48689958e-14,30.38 8.08080808,39 18.1818182,40 L18.1818182,33.84 C12.1212121,32.88 6.06060606,27.04 6.06060606,20 C6.06060606,12.96 12.1212121,7.12 18.1818182,6.16 Z"></path>
                <path className="bottom" d="M21.8181818,33.8787879 C27.8787879,33.010101 32.9090909,27.8787879 33.7777778,21.8181818 L40,21.8181818 C39.0505051,31.9191919 31.9191919,39.0505051 21.8181818,40 L21.8181818,33.8787879 Z"></path>
                <path className="top" d="M33.8787879,18.1818182 L40,18.1818182 C39.0505051,8.08080808 31.9191919,0.949494949 21.8181818,-2.48689958e-14 L21.8181818,6.22222222 C27.8787879,7.09090909 33.010101,12.1212121 33.8787879,18.1818182 Z"></path>
            </svg>
        );
    }
}

export default LogoSVG;
