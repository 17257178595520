import { connect } from 'react-redux';
import MemberLogin from './pure';

const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = (dispatch) => ({
  onLoadHeaderColor: (COLOR) => dispatch({ type: 'HEADER_COLOR_' + COLOR }),
  onLoadHeaderTheme: (THEME) => dispatch({ type: 'HEADER_THEME_' + THEME }),
  onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
  onLoadShowBreadcrumb: (STATUS) => dispatch({ type: STATUS + '_BREADCRUMB' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberLogin);
