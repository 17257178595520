import React, { Component } from 'react';
import queryString from "query-string";
import Axios from 'axios';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// utils
import SviChartTable from '../SviChartTable';
import SviChartLine from '../SviChartLine';
import chartCapture from '../../utils/Shared/chartCapture';
import LoadingDots from '../../utils/LoadingDots';
import CustomSelect from '../../utils/CustomSelect';
import CustomDropdown from '../../utils/CustomDropdown';
import SocialBlock from '../../utils/SocialBlock';
import LimitMask from '../../utils/LimitMask';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import {apiHost} from '../../utils/Shared/constant';
import { loginRedirect, downloadTrigger, urlPushState } from '../../utils/Shared/methods';

class SviChart extends Component {
	constructor(params) {
		super(params);
		this.state = {
			type: 's',
      		isMemberLogin: false,
			availableIndicators: null,
			allIndicators: [],
			indicatorsDetails: [],
			categoryDescription: null,
			currentCounty: '0',
			currentCountyName: '',
			currentIndicators: [],
			currentIndicatorsId: [],
			currentIndicatorId: '0',
			currentIndicatorsName: [],
			currentIndicatorDescription: '',
			isChartShow: true,
			isBasicLoading: false,
			isBasicLoaded: false,
			isAdvanceLoading: false,
			isDataLoaded: false,
			selectedCounty: null,
			selectedTown: null,
			countiesData: null,
			townsData: null,
			sviData: [],
			csvData: [],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('BLUE');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社會脆弱度評估', href: '/evaluation/svi' },
			{ name: '社會脆弱度指標查詢與展示', href: '/evaluation/svi/s' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);

		this.checkMemberLogin();
		this.getQuery();
		this.getCategoryDescription();
    	this.getCountiesTownsData();
		this.getAllIndicators();
	}
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
		this.setState({ isMemberLogin: true });
		}
	}
	getQuery() {
		const report = queryString.parse(window.location.search);
		if (report.indicator && report.county) {
			const county = report.county;
      const town = report.town;
			let indicators = typeof report.indicator === 'string' ? [report.indicator] : report.indicator;
			indicators = indicators.slice(0, 5);

			this.setState({ isChartShow: true, isAdvanceLoading: true });
      this.getCountiesTownsData().then(() => {
  			this.handleCountyDistrictPickSelected(county).then(() => {
          this.handleTownDistrictPickSelected(town).then(() => {
    				this.handleGetAvailableIndicators().then(() => {
    					this.setState({ isBasicLoaded: true, currentIndicatorsId: indicators }, () => {
    						this.handleUpdateIndicators();
    						this.handleGetAdvanceData();
    					})
    				})
          })
  			})
      });
		}
		else if (report.county) {
			const { countiesData } = this.state;
			const county = report.county;
      		const town = report.town;
			this.setState({ isChartShow: true, isBasicLoading: true });
			// countiesData.counties.forEach((item) => {
      		countiesData &&
     		countiesData.forEach((item) => {
				if (county === item.code) {
					this.setState({ currentCountyName: item.county })
				}
			});
      this.getCountiesTownsData().then(() => {
  			this.handleCountyDistrictPickSelected(county).then(() => {
          this.handleTownDistrictPickSelected(town).then(() => {
    				this.handleGetBasicData();
          })
  			})
      })
		}
	}
	getCategoryDescription() {
		Axios.get(`${apiHost}/api/WeakIndex/GetCategoriesDetail`)
		.then(res => {
			res.status === 200 && this.setState({categoryDescription: res.data.Result})
		})
	}
	getAllIndicators() {
		// Axios.get(`${apiHost}/api/WeakIndex/ZIndicatorsByYear?year=2010&county=all`)
		Axios.get(`${apiHost}/api//WeakIndex/GetCompleteIndicator`)
		.then((res) => {
			if (res.status === 200) {
				const { categories, subcategories, indicators } = res.data.Result;
				categories.forEach((item) => {
					item.name = item.category_name;
				});
				subcategories.forEach((item) => {
					item.name = item.subcategory_name;
				});
				const allIndicators = [].concat(categories, subcategories, indicators);
				const indicatorsDetails = indicators
				this.setState({
					indicatorsDetails,
					allIndicators,
				})
			}
		})
	}
  getCountiesTownsData = () => {
    return new Promise(resolve => {
      const { countiesData } = this.state;
      if (countiesData === null) {
        Axios.get('/config/CountiesTownsData.json')
        .then(result => {
          const { counties } = result.data;
          this.setState({ countiesData: counties }, () => resolve());
        })
      }
    })
  }
	handleSelectIndicator = (e) => {
		const id = e.currentTarget.dataset.id;
		const { currentIndicatorsId } = this.state;

		if (currentIndicatorsId.indexOf(id) === -1 && currentIndicatorsId.length < 10) {
			currentIndicatorsId.push(id);
		}
		else if (currentIndicatorsId.indexOf(id) !== -1) {
			const index = currentIndicatorsId.indexOf(id);
			currentIndicatorsId.splice(index, 1);
		}

		this.setState({
			currentIndicatorsId: currentIndicatorsId,
		}, () => this.handleUpdateIndicators());
	}
	handleUpdateIndicators() {
		const { availableIndicators, currentIndicatorsId } = this.state;
		
		availableIndicators.forEach(category => {
			if (currentIndicatorsId.indexOf(category.indicator_id + '') !== -1) { category.selected = true }
			else { category.selected = false }
			category.indicators.forEach(indicator => {
				if (currentIndicatorsId.indexOf(indicator.indicator_id + '') !== -1) { indicator.selected = true }
				else { indicator.selected = false }
			})
		});
		
		this.setState({ availableIndicators: availableIndicators });
	}
	handleClickBasicSubmit = () => {
		const { currentCounty, countiesData } = this.state;
    	countiesData.forEach((item) => {
			if (currentCounty === item.code) {
				this.setState({ currentCountyName: item.county })
			}
		});
		this.setState({ isChartShow: true });
		this.handleGetBasicData();
	}
	handleGetAvailableIndicators() {
		return new Promise(resolve => {
			const { selectedCounty, selectedTown } = this.state;
      let countyCode = selectedCounty ? selectedCounty.code : 'all';
      let townCode = selectedTown ? selectedTown.code : 'all';

			Axios.get(`${apiHost}/api/WeakIndex/SValueCompleteWithIndicaotrs?RegionID=${countyCode}&town=${townCode}`)
				.then((res) => {
					if (res.status === 200) {
						const indicatorsData = res.data.Result.indicators;
						const categoriesData = res.data.Result.categories;
						const availableIndicators = [];

						categoriesData.forEach((category) => {
							category.selected = false;
							category.indicators = [];
							indicatorsData.forEach((indicator) => {
								indicator.selected = false;
								if (category.category_id === indicator.category_id) {
									category.indicators.push(indicator);
								}
							})
							availableIndicators.push(category);
						})

						this.setState({ availableIndicators: availableIndicators }, () => resolve())
					}
				})
		})
	}
	handleGetBasicData() {
		const { selectedCounty, selectedTown } = this.state;
    let countyCode = selectedCounty ? selectedCounty.code : 'all';
    let county = selectedCounty ? selectedCounty.county : '';
    let townCode = selectedTown ? selectedTown.code : 'all';
    let town = selectedTown ? selectedTown.town : '';

		this.setState({ isBasicLoading: true });
		Axios.get(`${apiHost}/api/WeakIndex/SValueCompleteWithIndicaotrs?RegionID=${countyCode}&town=${townCode}`)
			.then(res => {
				if (res.status === 200) {
					const indicatorsData = res.data.Result.indicators;
					const categoriesData = res.data.Result.categories;
					const availableIndicators = [];

					categoriesData.forEach((category) => {
						category.selected = false;
						category.indicators = [];
						indicatorsData.forEach((indicator) => {
							indicator.selected = false;
							if (category.category_id === indicator.category_id) {
								category.indicators.push(indicator);
							}
						})
						availableIndicators.push(category);
					})

					let csvData = (typeof res.data.Result.csv === 'object' && res.data.Result.csv !== null) ? [res.data.Result.csv] : res.data.Result.csv;
                    let sviData = res.data.Result.data;
                    sviData = sviData.filter( data => data.value );

					this.setState({
						isBasicLoading: false,
						isBasicLoaded: true,
						currentTitle: `${county+town} 歷年社會脆弱度趨勢`,
						currentIndicators: [],
            			currentIndicatorsId: [],
						currentIndicatorsName: [],
						isDataLoaded: true,
						availableIndicators: availableIndicators,
						sviData: sviData,
						csvData: csvData,
					}, () => urlPushState(`?county=${countyCode}&town=${townCode}`))
				}
			})
	}
	handleClickAdvanceSubmit = () => {
		this.handleGetAdvanceData();
	}
	handleGetAdvanceData() {
		const { currentCounty, currentIndicatorsId, selectedCounty, selectedTown } = this.state;
		let countyCode = selectedCounty ? selectedCounty.code : 'all';
		let county = selectedCounty ? selectedCounty.county : '';
		let townCode = selectedTown ? selectedTown.code : 'all';
		let town = selectedTown ? selectedTown.town : '';
		this.setState({	isAdvanceLoading: true });
		Axios.get(`${apiHost}/api/WeakIndexCheck/SValueMultipleCheck?region_id=${currentCounty}&indicators=${currentIndicatorsId.join('%2B')}&town=all`)
		.then(res => {
			if (res.status === 200) {
				this.setState({
					test_data: res.data
				})
			}
		})
		Axios.get(`${apiHost}/api/WeakIndex/SValueMultiple?region_id=${countyCode}&indicators=${currentIndicatorsId.join('%2B')}&town=${townCode}`)
		.then((res) => {
			if (res.status === 200) {
				const data = res.data.Result;

				let csvData = [];
				data.forEach((item) => {
					csvData = csvData.concat(item.csv);
				})

				let sviData = [];
				data.forEach((item) => {
					sviData = sviData.concat(item.data);
				})

				const { allIndicators } = this.state;
				const currentIndicators = []
				currentIndicatorsId.forEach(id => {
					allIndicators.forEach((item) => {
						if(town === '') {
							if (item.indicator_id === Number(id) && item.county) {
								currentIndicators.push(item)
							}
							return
						}
						if (item.indicator_id === Number(id) && !item.county) {
							currentIndicators.push(item)
						}
					})
				})


				this.setState({
					currentTitle: `${county+town} 歷年社會脆弱度指標`,
					currentIndicators: currentIndicators,
					isDataLoaded: true,
					isAdvanceLoading: false,
					sviData: sviData,
					csvData: csvData,
				}, () => {
					const indicatorsQuery = currentIndicatorsId.map(indicator => {
						return `&indicator=${indicator}`
					})
					urlPushState(`?county=${countyCode}&town=${townCode}${indicatorsQuery.join('')}`);
				})
			}
		})
	}
	handleClickRemoveIndicators = () => {
		this.setState({
			currentIndicatorsId: [],
		}, () => this.handleUpdateIndicators())
	}
	handleChangeCounty = (value) => {
		return new Promise(resolve => {
			this.setState({
				currentCounty: value,
				currentIndicatorsId: [],
				isBasicLoaded: false
			}, () => resolve());
		})
	}
	handleClickDownloadPNG = (e) => {
		e.preventDefault();
		const { currentCountyName } = this.state;
		const fileName = `${currentCountyName} 歷年社會脆弱度趨勢`;
		const captureArea = document.querySelectorAll('.capture');
		chartCapture(captureArea, fileName);
		gaTracking('社會脆弱度', '社會脆弱度指標查詢與展示', '圖表下載');
	}
	handleClickDownloadCSV = (e) => {
		const { csvData, currentCountyName, currentIndicatorsId, allIndicators, indicatorsDetails } = this.state;
		let csv = '';
		csvData.forEach((item) => {
			csv = csv + '\n' + item.title + '\n' + item.csv;
		});
		let allSource = []
		indicatorsDetails.forEach(indicator =>{
			let description = indicator.description.replace(/<[^>]+>|[a-z]|[\*]/g,'')
			if(indicator.source){
				allSource.push({
					indicator_id: indicator.indicator_id.toString(),
					name: indicator.name,
					description: description,
					source: indicator.source[0]
				})
			}
		})
		let sources = allSource.filter(src =>{
			return currentIndicatorsId.includes(src.indicator_id)
		});

		const fileName = `${currentCountyName}原始資料`;

		let copyright = '\n資料來源：減災動資料'
		if (sources.length !== 0){
			copyright += "\n\n註解:"
			for(let source of sources){
				if(source.name && source.description && source.source){
					copyright += `\n---------------------------------------------------\n指標項:${source.name}\n${source.description}\n原始數據來源:${source.source}\n`
				}
			}
		}
		const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv) + copyright;

		downloadTrigger(fileName + '.csv', csvContent);
		gaTracking('社會脆弱度', '社會脆弱度指標查詢與展示', '原始數據下載');

	}
	handleCountyDistrictPickSelected = selectedCode => {
		return new Promise(resolve => {
		const { countiesData } = this.state;
		const selectedDistrict = countiesData.find( ({ code }) => code === selectedCode );
		this.setState({ 
					availableIndicators: null,
			selectedCounty: selectedDistrict,
			selectedTown: null,
			townsData: selectedDistrict.towns,
		}, () => resolve());
		})
	}
	handleTownDistrictPickSelected = selectedCode => {
		return new Promise(resolve => {
		const { townsData } = this.state;
		const selectedTown = townsData.find( ({ code }) => code === selectedCode );
		this.setState({
			availableIndicators: null,
			selectedTown: selectedTown,
		}, () => resolve());
		})
	}
	
	render() {
    	const { isMemberLogin } = this.state;
		const { availableIndicators, categoryDescription } = this.state;
    	const { countiesData, townsData } = this.state;
		const { currentIndicators, currentIndicatorsId, currentTitle } = this.state;
    	const { selectedCounty, selectedTown } = this.state;
		const { type, sviData, csvData } = this.state;
		const { isChartShow, isBasicLoading, isBasicLoaded, isAdvanceLoading } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<LimitMask prevPage="/evaluation/svi" />
	{/*			{
					test_data &&
					<div className="" style={{background: '#FFF', position: 'fixed', zIndex: 999, left: '10%', top: '10%', padding: '24px', boxShadow: '2px 4px 12px #000'}}>
						<p>計算數據</p>
						{
							test_data['Result'].map((item, i) => 
							<div key={i} style={{ maxHeight: '200px', overflow: 'auto'}}>
								<p>NAME { item['name'] }</p>
								<div style={{ color: 'hsla(0, 80%, 40%)' }}>C_Value <br/>{ item['C_Value'].map(c => 
									<p key={c.year+c.value}> {c.year}: {c.value} </p>
								) }</div>
								<p style={{ color: 'hsla(100, 80%, 40%)' }}>A_Value { item['A_Value'] }</p>
								<div style={{ color: 'hsla(200, 80%, 40%)' }}>B_Value { item['B_Value'].map(b => 
									<p key={b.year+b.value}> {b.year}: {b.value}</p>
								) }</div>
								<hr/>
							</div>)
						}
					</div>
				}*/}
				<div className="svi-page">
					<div className="container">
						<div className="page-link">
							<Link  className="item" to="/evaluation/svi/advance">
								<span>客製化社會脆弱度評估</span>
							</Link>
						</div>
						<div className="svi-charttitle">
							<div className="title">社會脆弱度指標查詢與展示</div>
							<div className="subtitle">Social Vulnerability Index for Disasters</div>
						</div>
						<div className="svi-chart row">
							<div className="col-md-3 chart-controller">
								<div className="type">
									<div className="step">
										<div className="title">
											<span className="en">Step 1 </span>
											<span>選擇想查看的資料主題</span>
										</div>
										<div className="buttonbar">
											<Link to="/evaluation/svi/z"
											onMouseEnter={() => this.setState({ type: 'z' })}
											onMouseOut={() => this.setState({ type: 's' })}>單一年度行政區比較</Link>
											<Link to="/evaluation/svi/s" className="-active"
											onMouseEnter={() => this.setState({ type: 's' })}
											onMouseOut={() => this.setState({ type: 's' })}>單一行政區歷史趨勢</Link>
										</div>
										<div className="info">
											{ type === 'z' &&	<p>單一年度行政區比較：可了解不同縣市之間社會脆弱度的程度。</p> }
											{ type === 's' && <p>單一行政區趨勢：可了解如臺北市 1998 到 2016 年間的社會脆弱度趨勢。</p> }
										</div>
									</div>
								</div>
								<div className="basic">
									<div className="step">
										<div className="title">
											<span className="en">Step 2 </span>
											<span>選擇縣市</span>
										</div>
                    <div className="select">
                      <CustomSelect status="normal" name="year"
                        value={selectedCounty ? selectedCounty.code : ''}
                        onChange={value => this.handleCountyDistrictPickSelected(value)}>
                        <option value="0">縣市</option>
                        {
                          countiesData &&
                          countiesData.map((item, index) =>
                            <option key={index} value={item.code}>{item.county}</option>
                          )
                        }
                      </CustomSelect>
                    </div>
									</div>
                  {
                    <div className="step">
                      <div className="title">
                        <span className="en">Step 3 </span>
                        <span>選擇鄉鎮</span>
                      </div>
                      <div className="select">
                        <CustomSelect status="normal" name="county"
                          value={selectedTown ? selectedTown.code : ''}
                          onChange={(value) => { this.handleTownDistrictPickSelected(value) }}>
                          <option value="0">全選</option>
                          {
                            townsData &&
                            townsData.map((item, index) => 
                              <option key={index} value={item.code}>{item.town}</option>
                            )
                          }
                        </CustomSelect>
                      </div>
                    </div>
                  }
									<div className="submit">
										<button className={"button" + (isBasicLoading ? ' -loading' : '')}
										disabled={selectedCounty === null || isAdvanceLoading}
										onClick={this.handleClickBasicSubmit}><span>看結果</span></button>
									</div>
								</div>
								{
									(isBasicLoaded && availableIndicators) &&
									<div className="advance">
										<div className="step">
											<div className="title">選擇指標項目</div>
											<div className="info">
												<p className="text">複選，10個以內</p>
												<button className="reset"
												onClick={this.handleClickRemoveIndicators}>
													<i className="icon-reset"></i>
													<span>RESET</span>
												</button>
											</div>
											<div className="radio">
												{
													availableIndicators.map((category, index) => {
														return (
															<React.Fragment key={index}>
																<div data-id={category.indicator_id}
																className={"item -primary" + (category.selected ? ' -selected' : '')}
																onClick={this.handleSelectIndicator}><span>{category.category_name}</span></div>
																{
																	category.indicators.map((indicator, subindex) => {
																		return (
																			<React.Fragment key={subindex}>
																				<div data-id={indicator.indicator_id}
																				className={"item -secondary" + (indicator.selected ? ' -selected' : '')}
																				onClick={this.handleSelectIndicator}><span>{indicator.name}</span></div>
																			</React.Fragment>
																		)
																	})
																}
																<hr/>
															</React.Fragment>
														)
													})
												}
											</div>
											<div className="submit">
												<button className={"button" + (isAdvanceLoading ? ' -loading' : '')}
												disabled={currentIndicatorsId.length === 0}
												onClick={this.handleClickAdvanceSubmit}>確定</button>
											</div>
										</div>
									</div>
								}
							</div>
							{
								isChartShow &&
								<div className="col-md-9 chart-result">
									{ 
										isBasicLoading &&
										<LoadingDots theme="blue" >
											<p>系統處理中....請稍待</p>
										</LoadingDots>
									}
									<div className="heading capture">
										<div className="title">{ currentTitle }</div>
										<div className="dropdown" data-html2canvas-ignore>
											<CustomDropdown title="下載">
                        						<li><button onClick={this.handleClickDownloadPNG}>下載PNG</button></li>
											</CustomDropdown>
										</div>
									</div>
									<div className={"chart capture"}>
										{ isAdvanceLoading && <LoadingDots theme="blue" />}
										<SviChartLine sviData={sviData}></SviChartLine>
									</div>
									<React.Fragment>
										{
											currentIndicators.length === 0 &&
											<div className="info capture">
												<div className="title">說明</div>
												<div className="content">上圖展示各縣市社會脆弱度綜合指數（S），依據本站所列之指標項目進行綜合計算而得（詳首頁說明），以第一年為基準值 100，比較每一年的情況，指數大於第一年表示脆弱度升高，反之則降低。部分年度／縣市因缺乏資料，無法計算。</div>
											</div>
										}
										{
											currentIndicators.length !== 0 &&
											<div className="info capture">
												<div className="title">指標定義說明</div>
												{
													currentIndicators.map((indicator, i) =>
														<React.Fragment key={i}>
															<div className="content"><strong>• {indicator.name}</strong></div>
															<div className="content" dangerouslySetInnerHTML={{ __html: indicator.descriptionhistory }}/>
															{
																categoryDescription && categoryDescription.map((category, j) =>
																<React.Fragment key={j}>
																	{ 
																		category.name ===	indicator.name && 
																		<div className="content" dangerouslySetInnerHTML={{ __html: category.description }}/>
																	}
																</React.Fragment>)
															}
															<br/>
														</React.Fragment>
													)
												}
											</div>
										}
										<div className="origin">
											<div className="title">
												<div className="text">原始數據</div>
												<CustomDropdown title="下載">
													{ isMemberLogin && <li><button onClick={this.handleClickDownloadCSV}>下載CSV</button></li> }
													{ !isMemberLogin && <li><button onClick={loginRedirect}>登入會員下載CSV</button></li> }
												</CustomDropdown>
											</div>
											<div className="table">
												<SviChartTable csvData={csvData} />
											</div>
											<p className="remark">*標記*為非公開資料，如有任何問題請 <a href="/#contact" target="_blank" rel="noopener noreferrer">聯絡我們</a></p>
										</div>
									</React.Fragment>
								</div>
							}
						</div>
					</div>
				</div>
				<SocialBlock category="社會脆弱度" action="社會脆弱度指標查詢與展示" theme="blue" />
			</React.Fragment>
		);
	}
}

export default SviChart;
