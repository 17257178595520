import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { KeepaliveRouterSwitch ,KeepaliveRoute ,addKeeperListener } from 'react-keepalive-router'

import './index.scss';

// Pages
import HomePage from '../Home/HomePage';
import HomeArticleList from '../Series/ArticleListPage';

import TainanEarthquakePage from '../TainanEarthquake/TainanEarthquakePage';
import HualienEarthquakePage from '../HualienEarthquake/HualienEarthquakePage';
import AnalysisEarthquakeSurveyPage from '../AnalysisEarthquakeSurvey/AnalysisEarthquakeSurveyPage';

import MorakotPage from '../Morakot/MorakotPage';
import MorakotSubjectIntro from '../Morakot/MorakotSubjectIntro';
import MorakotAdvance from '../Morakot/AdvanceCharts';
import MorakotAdvanceIntro from '../Morakot/AdvanceChartsIntro';
import MorakotAdvanceResult from '../Morakot/AdvanceChartsResult';
import MorakotSubject from '../Series/SubjectPage';
import MorakotArticleList from '../Series/ArticleListPage';
import MorakotArticlePage from '../Series/ArticlePage';
import MorakotCityIntro from '../Morakot/CityIntro';
import MorakotCitySubject from '../Morakot/CitySubject';
import MorakotHouseIntro from '../Morakot/HouseIntro';
import MorakotHouseSubject from '../Morakot/HouseSubject';

import MorakotPageEn from '../MorakotEnglish/MorakotPage';
import MorakotSubjectIntroEn from '../MorakotEnglish/MorakotSubjectIntro';
import MorakotArticleListEn from '../Series/ArticleListPage';
import MorakotArticlePageEn from '../Series/ArticlePage';

import SfaaPage from '../Sfaa/SfaaPage';
import SfaaSubjectIntro from '../Sfaa/SfaaSubjectIntro';
import SfaaArticleList from '../Series/ArticleListPage';
import SfaaArticlePage from '../Series/ArticlePage';
import SfaaSubject from '../Series/SubjectPage';

import MemberLogin from '../Member/Login';
import ForgotPassword from '../Member/ForgotPassword';
import ResetPassword from '../Member/ResetPassword';
import MemberRegister from '../Member/Register';
import MemberRegisterForm from '../Member/RegisterForm';
import MemberEdit from '../Member/Edit';

import SiteMap from '../Others/Sitemap';
import PolicyPage from "../Others/PolicyPage";

// Container
import Header from "../Header";
import Footer from "../Footer";
import HomePageSideModal from "../Home/SideModal";

// News
import News from "../News";
import NewsArticlePage from "../News/NewsArticlePage";

// SVI
import SviPage from "../Svi/SviPage";
import SviArticleList from '../Series/ArticleListPage';
import SviArticlePage from '../Series/ArticlePage';
import SviChartS from "../Svi/SviChartS";
import SviChartZ from "../Svi/SviChartZ";
import SviAdvanceIntro from "../Svi/SviAdvanceIntro";
import SviAdvanceApp from "../Svi/SviAdvanceApp";

// Evacuation and sheltering
import EvacuationPage from "../Evacuation/EvacuationPage";
import EvacuationArticleList from '../Series/ArticleListPage';
import EvacuationArticlePage from '../Series/ArticlePage';
import EvacuationChartIntro from "../Evacuation/EvacuationChartIntro";
import EvacuationChartEvacuate from "../Evacuation/EvacuationChart";
import EvacuationChartContain from "../Evacuation/EvacuationChart";
import EvacuationChartAlert from "../Evacuation/EvacuationChart";
import EvacuationEstimate from "../Evacuation/EvacuationEstimate";
import EvacuationShelter from "../Evacuation/EvacuationShelter";

// Utils
import SideMenu from "../utils/SideMenu";


class App extends Component {

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料</title>
				</Helmet>
				<BrowserRouter>
					<Route render={({ location, history, match }) => {
						return (
							<div id="app">
								<Header />
								<SideMenu />
								<HomePageSideModal></HomePageSideModal>
								<KeepaliveRouterSwitch key={location.key} location={location}>
								{/* <Switch key={location.key} location={location}> */}
									<Route exact path="/statistics/TainanEarthquake" component={TainanEarthquakePage} />
									<Route exact path="/statistics/HualienEarthquake" component={HualienEarthquakePage} />
									<Route exact path="/statistics/AnalysisEarthquakeSurvey" component={AnalysisEarthquakeSurveyPage} />

									<Route exact path="/" component={HomePage} onChange={this.handleRouteUpdate} />
									<Route exact path="/statistics/home/categories" component={HomeArticleList} />
									<Route exact path="/statistics/home/article/:id" component={MorakotArticlePage} />
									<Route exact path="/statistics/morakot" component={MorakotPage} />
									<Route exact path="/statistics/morakot/intro" component={MorakotSubjectIntro} />
									<Route exact path="/statistics/morakot/advanced-intro" component={MorakotAdvanceIntro} />

									<Route exact path="/statistics/morakot/categories" component={MorakotArticleList} />
									<Route exact path="/statistics/morakot/article/:id" component={MorakotArticlePage} />
									<Route exact path="/statistics/morakot/advanced-analytic" component={MorakotAdvance} />
									<Route exact path="/statistics/morakot/advanced-analytic-result" component={MorakotAdvanceResult} />
									<Route exact path="/statistics/morakot/:subject" component={MorakotSubject} />
									<Route exact path="/statistics/morakot/:subject/:slug" component={MorakotSubject} />

									<Route exact path="/statistics/morakot-city" component={MorakotCityIntro} />
									<Route exact path="/statistics/morakot-city/:subject" component={MorakotCitySubject} />

									<Route exact path="/statistics/morakot-house" component={MorakotHouseIntro} />
									<Route exact path="/statistics/morakot-house/:subject" component={MorakotHouseSubject} />
									
									<Route exact path="/statistics/morakot-en" component={MorakotPageEn} />
									<Route exact path="/statistics/morakot-en/intro" component={MorakotSubjectIntroEn} />

									<Route exact path="/statistics/morakot-en/categories" component={MorakotArticleListEn} />
									<Route exact path="/statistics/morakot-en/article/:id" component={MorakotArticlePageEn} />
									<Route exact path="/statistics/morakot-en/:subject" component={MorakotSubject} />
									<Route exact path="/statistics/morakot-en/:subject/:slug" component={MorakotSubject} />

									<Route exact path="/statistics/sfaa" component={SfaaPage} />
									<Route exact path="/statistics/sfaa/elder" component={SfaaSubjectIntro} />
									<Route exact path="/statistics/sfaa/disability" component={SfaaSubjectIntro} />

									<Route exact path="/statistics/sfaa/categories" component={SfaaArticleList} />
									<Route exact path="/statistics/sfaa/article/:id" component={SfaaArticlePage} />
									<Route exact path="/statistics/:sfaaTopic/:subject" component={SfaaSubject} />
									<Route exact path="/statistics/:sfaaTopic/:subject/:slug" component={SfaaSubject} />
									
									<Route exact path="/member/login" component={MemberLogin} />
									<Route exact path="/member/password/forgot" component={ForgotPassword} />
									<Route exact path="/member/password/reset" component={ResetPassword} />
									<Route exact path="/member/edit" component={MemberEdit} />

									<Route exact path="/news" component={News} />
									<Route exact path="/news/:id" component={NewsArticlePage} />

									<Route exact path="/member/register" component={MemberRegister} />
									<Route exact path="/member/register/:type" component={MemberRegisterForm} />

									<Route exact path="/evaluation/svi" component={SviPage} />
									<Route exact path="/evaluation/svi/categories" component={SviArticleList} />
									<Route exact path="/evaluation/svi/article/:id" component={SviArticlePage} />
									<Route exact path="/evaluation/svi/z" component={SviChartZ} />
									<Route exact path="/evaluation/svi/s" component={SviChartS} />
									<Route exact path="/evaluation/svi/advance" component={SviAdvanceIntro} />

		
									<KeepaliveRoute exact path="/evaluation/svi/advance/app" component={SviAdvanceApp}></KeepaliveRoute>
				
									
									{/* <Route exact path="/evaluation/svi/advance/app" component={SviAdvanceApp} /> */}

									<Route exact path="/evaluation/evacuation" component={EvacuationPage} />
									<Route exact path="/evaluation/evacuation/categories" component={EvacuationArticleList} />
									<Route exact path="/evaluation/evacuation/article/:id" component={EvacuationArticlePage} />
									<Route exact path="/evaluation/evacuation/intro" component={EvacuationChartIntro} />
									<Route exact path="/evaluation/evacuation/evacuate" component={EvacuationChartEvacuate} />
									<Route exact path="/evaluation/evacuation/contain" component={EvacuationChartContain} />
									<Route exact path="/evaluation/evacuation/alert" component={EvacuationChartAlert} />
									<Route exact path="/evaluation/evacuation/estimate" component={EvacuationEstimate} />
									<Route exact path="/evaluation/evacuation/shelter" component={EvacuationShelter} />

									<Route exact path="/policy" component={PolicyPage} />
									{/* <Route exact path="/member/person" component={MemberRegisterPerson} /> */}

									<Route exact path="/sitemap" component={SiteMap} />
									<Redirect from="*" to="/" />

								{/* </Switch> */}
								</KeepaliveRouterSwitch>
								<Footer />
							</div>
						)
					}} />
				</BrowserRouter>
			</React.Fragment>
		);
	}

}

export default App;
