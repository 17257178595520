import React, { Component } from 'react';
import './index.scss';

class LoginMask extends Component {
  render() {
    return (
      <div className="login-mask">
        <div className="msg">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default LoginMask;
