import React, { Component } from 'react';
import './index.scss';

class LoadingDots extends Component {
  render() {
    const { theme, children } = this.props;
    return (
      <div className="preload-animation">
        <div className={`animation ${theme ? `-${theme}` : ''}`}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>{ children }</div>
      </div>
    );
  }
}

export default LoadingDots;
