import { connect } from 'react-redux';
import News from './pure';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch) => ({
  handleEnterNewsPage: () => {
    dispatch({type: 'HEADER_THEME_DARK'});
    dispatch({type: 'HEADER_COLOR_YELLOW'});
  },
  onLoadShowBreadcrumb: (STATUS, data) => dispatch({ type: STATUS + '_BREADCRUMB', breadcrumbData: data }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News);
