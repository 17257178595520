import { connect } from 'react-redux';
import HomePage from './pure';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch) => ({
	onLoadHeaderTheme: (THEME) => dispatch({ type: 'HEADER_THEME_' + THEME }),
	onLoadHeaderColor: (COLOR) => dispatch({ type: 'HEADER_COLOR_' + COLOR }),
	onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
	onLoadShowBreadcrumb: (STATUS) => dispatch({ type: STATUS + '_BREADCRUMB' }),
	onLoadCloseSideModal: (MODAL_NAME) => dispatch({ type: 'CLOSE_' + MODAL_NAME + '_SIDEMODAL' }),
	onClickOpenSideModal: (MODAL_NAME) => dispatch({ type: 'OPEN_' + MODAL_NAME + '_SIDEMODAL' }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage);
