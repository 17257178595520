import React, { Component } from 'react';
import Axios from 'axios';
import Helmet from 'react-helmet';
import './index.scss';

// Utils
import CustomInput from '../../utils/CustomInput';
import ModalBox from '../../utils/ModalBox';
import {emailValidation, model} from '../../utils/Shared/methods';
import {apiHost} from '../../utils/Shared/constant';



class ForgotPassword extends Component {
    constructor(params) {
        super(params);
        this.state = {
            modalOpen: false,
            modalMsg: <p></p>,
            inputEmail: '',
        }
    }

    rModel = model.bind(this);

    handleClickSendData = () => {
        const {inputEmail} = this.state;

        if (inputEmail === '') {
            this.openModal(<p>請填入email</p>);
        }
        else if (emailValidation(inputEmail) === false) {
            this.openModal(<p>email格式不正確</p>);
        }
        else {
            Axios.post(`${apiHost}/api/Account/ForgetLoginKey`,
                {
                    Email: inputEmail,
                },
                {
                    headers: {'Content-Type': 'application/json'}
                },
            )
            .then((res)=>{
                if (res.data.Outstring === "0000") {
                    this.openModal(<p className="type-text -ch -md">「重設密碼」郵件已發送到<br/>{inputEmail}<br/>請依照信件內指示重設密碼</p>);
                } 
                else if (res.data.Outstring === "帳號不存在") {
                    this.openModal(<p className="type-text -ch -md">email無效，請確認後再送出</p>);
                }
                else {
                    this.openModal(<p className="type-text -ch -md">系統錯誤，請聯絡管理員</p>);
                }
            })
        }
    }

    openModal(msg) {
        this.setState({
            modalOpen: true,
            modalMsg: msg
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onLoadHeaderColor('YELLOW');
        this.props.onLoadHeaderTheme('DARK');
        this.props.onLoadShowLanguage('HIDE');
        this.props.onLoadShowBreadcrumb('HIDE');
    }
    render() {
        const {inputEmail, modalMsg} = this.state;
        return (
            <React.Fragment>
				<Helmet>
					<title>減災動資料 - 忘記密碼</title>
				</Helmet>
                <ModalBox 
                    open={this.state.modalOpen}
                    theme="-yellow"
                    onRequestClose={ () => {
                        this.setState({ modalOpen: false })
                    } }
                >
                    {modalMsg}
                </ModalBox>
                <div className="member-page">
                    <div className="container">
                        <div className="row">
                            <section className="member-login member-section col-lg-6 offset-lg-3">
                                <div className="heading">
                                    <span className="type-heading -ch -h3">忘記密碼</span>
                                </div>
                                <div className="description -center">
                                    <p className="type-text -ch -xs">請輸入您註冊時的電子郵件</p></div>
                                <form className="form login-form" action="">
                                    <div className="formitem">
                                        <CustomInput status="normal" id="i1" type="email" name="email" label="Email*" placeholder="champion@ncdr.gov.tw" {...this.rModel({inputEmail})}/>
                                    </div>
                                    <div className="formitem -center">
                                        <button 
                                            className="submit fill-button -fit -yellow"
                                            onClick={this.handleClickSendData}
                                            type="button"
                                        >
                                            <span className="type-text -ch -md">提交</span>
                                        </button>
                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ForgotPassword;
