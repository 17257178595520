import React, { Component } from 'react';
import { NavLink, withRouter, Redirect } from "react-router-dom";
import Axios from 'axios';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import './index.scss';

// Utils
import Breadcrumb from '../utils/Breadcrumb';
import LogoSVG from '../utils/LogoSVG';
import {apiHost, loginApiHost} from '../utils/Shared/constant';

import { loginRedirect } from '../utils/Shared/methods';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userLogin: false,
			redirectURL: '',
			nowLang: 'zh-tw',
		}
	}

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.nowLang !== 'zh-tw' && window.location.pathname.split('/').slice(2, 3)[0] !== 'morakot-en') {
      this.setState({ nowLang: 'zh-tw' })
    }
  }

	handleClickOpenSideMenu = () => {
		this.props.onClickOpenMenu();
	}
	
	handleClickOpenSideModal = () => {
		this.props.onClickOpenModal();
	}

	handleClickLogout = () => {
		window.localStorage.removeItem('user-token');
		Axios.post(
            `${loginApiHost}/flood/Authorization`,
            {
              userlogin:0
            },
            {
              headers: { "Content-Type": "application/json" },
            }
            ).then((res) => {
              console.log(res);
            }).catch((err)=>{
              console.log(err);
            });
		
		Axios('https://drrstat.ncdr.nat.gov.tw/flood/clearLogin')
		window.location.reload();
	}

	componentDidMount() {
		this.props.onLoadHeaderReset();

		window.addEventListener("beforeunload", this.removeToken);

		if (window.localStorage.getItem('user-token') !== null) {
			this.extendToken(60*24*7);
		}
		const queryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
		if (queryParams.token !== undefined) {
			window.localStorage.setItem('user-token', queryParams.token);
			this.setState({redirectURL: '/member/password/reset'});
		}
		this.setState({
			nowLang: window.location.pathname.split('/').slice(2, 3)[0] === 'morakot-en' ? 'en' : 'zh-tw',
		})
	}

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.removeToken);
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.state.redirectURL === this.props.location.pathname) {
			this.setState({redirectURL: ''});
		}
	}

	removeToken = (e) => {
		e.preventDefault();
		if (window.location.pathname !== '/member/login' &&
				window.localStorage.getItem('keep-login') && 
				JSON.parse(window.localStorage.getItem('keep-login')) === false) {
			this.extendToken(10);
			// window.localStorage.removeItem('user-token');
			window.localStorage.removeItem('keep-login');
		}
	}

	extendToken = (time) => {
		Axios.post(
			`${apiHost}/api/Account/ExtendToken`,
			{
				Minutes: time
			},
			{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + window.localStorage.getItem('user-token'),
				}
			},
		)
		.then( res => {
			if (res.data.Outstring === "0000") {
				window.localStorage.setItem('user-token', res.data.Result);
				this.setState({userLogin: true});
				this.props.onUserTokenIsExtend();
			} else {
				window.localStorage.removeItem('user-token');
			}
		})
		.catch( err => {
			window.localStorage.removeItem('user-token');
		})
	}

	handleLanguageButtonClick = (lang) => {
		const pathname = window.location.pathname;
		if (lang === 'en') {
			if (pathname.indexOf('/statistics/morakot') >= 0 ) {
				this.setState({redirectURL: pathname.replace('morakot', 'morakot-en'), nowLang: 'en'});
			}
		}
		else if (lang === 'zh-tw') {
			if (pathname.indexOf('/statistics/morakot-en') >= 0 ) {
				this.setState({redirectURL: pathname.replace('morakot-en', 'morakot'), nowLang: 'zh-tw'});
			}
		}
		
	}

	renderBreadcrumb() {
		const { theme, color, breadcrumbData } = this.props;
		return (
			<React.Fragment>	
				<NavLink exact className="logo" to="/">
					<LogoSVG color={ theme + ' ' + color }></LogoSVG>
					<div className="logotext">
						<p className="ch">減災動資料</p>
						<p className="en">DRRstat</p>
					</div>
				</NavLink>
				<Breadcrumb className="breadcrumb" color={color} theme={theme} paths={breadcrumbData}></Breadcrumb>
			</React.Fragment>
		)
	}



	renderLogo() {
		const { theme, color } = this.props;
		return (
			<NavLink exact className="logo" to="/">
				<LogoSVG color={theme + ' ' + color}></LogoSVG>
				<div className="logotext">
					<p className="ch">減災動資料</p>
					<p className="en">DRRstat</p>
				</div>
			</NavLink>
		);
	}



	renderLanguage() {
		const {nowLang} = this.state;
		return (
			<div className="language-list">
				<button data-tip="中文" className={`languageitem ${nowLang === 'zh-tw' ? '-active' : ''}`} onClick={e=> nowLang === 'en' && this.handleLanguageButtonClick('zh-tw')}>
					<i className="icon-ch"></i>
				</button>
				<button data-tip="English" className={`languageitem ${nowLang === 'en' ? '-active' : ''}`} onClick={e=> nowLang === 'zh-tw' && this.handleLanguageButtonClick('en')}>
					<i className="icon-en"></i>
				</button>
				<ReactTooltip className='tooltip type-text -ch -xs' place="bottom" type="dark" effect="solid" />

			</div>
		);
	}



	render() {
		const { theme, color, showBreadcrumb, showLanguage} = this.props;
		const {userLogin, redirectURL} = this.state;

		return (
			<header className={"header-block " + theme + ' ' + color} >
				{
					(redirectURL !== '') &&
					<Redirect to={redirectURL}/>
				}

				<div className="header-logo">
					{showBreadcrumb ? this.renderBreadcrumb() : this.renderLogo()}
				</div>
				<div className="header-navbar">
						{showLanguage ? this.renderLanguage() : null}
						<div className="link-list">
							{
								userLogin === false &&
								<button className="linkitem" data-tip="會員登入或註冊"
								onClick={ () => loginRedirect() }>
									<i className="icon-member"></i>
								</button>
							}
							{
								userLogin === true &&
								<React.Fragment>
									<NavLink className="linkitem" to="/member/edit" data-tip="修改會員資訊">
										<i className="icon-member"></i>
									</NavLink>
									<button className="linkitem" onClick={ this.handleClickLogout } data-tip="登出">
										<i className="icon-logout"></i>
									</button>
									<ReactTooltip className='tooltip type-text -ch -xs' place="bottom" type="dark" effect="solid" />
								</React.Fragment>
							}

							<button className="linkitem" onClick={ () => {window.open('https://www.facebook.com/easy2do4drr/')} }  data-tip="臉書粉專：防災易起來">
								<i className="icon-facebook"></i>
							</button>
							<button className="linkitem" onClick={this.handleClickOpenSideModal} data-tip="關於減災動資料">
								<i className="icon-info"></i>
							</button>
							<button className="linkitem" onClick={this.handleClickOpenSideMenu} data-tip="選單">
								<i className="icon-burger"></i>
							</button>
							<ReactTooltip className='tooltip type-text -ch -xs' place="bottom" type="dark" effect="solid"/>
						</div>
					</div>
			</header>
		);
	}
}

export default withRouter(Header);
