import React, { Component } from 'react';
import Axios from 'axios';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { Waypoint } from 'react-waypoint';
import Scroll from 'react-scroll'
import ResourceLinks from '../../Series/ResourceLinks';
import './index.scss';

// Container
import ArticleList from '../../Series/ArticleList';

import { apiHost } from '../../utils/Shared/constant';
import SviBackground1 from '../../../images/svi-bg1.png';
import SviBackground2 from '../../../images/svi-bg2.png';
import SviCircleImage from '../../../images/svi-info.png';
import SviS11 from '../../../images/svi/SVID_S_1-1.png';
import SviS12 from '../../../images/svi/SVID_S_1-2.png';
import SviS2 from '../../../images/svi/SVID_S_2.png';
import SviS3 from '../../../images/svi/SVID_S_3.png';
import SviS41 from '../../../images/svi/SVID_S_4-1.png';
import SviS42 from '../../../images/svi/SVID_S_4-2.png';
import SviS5 from '../../../images/svi/SVID_S_5.png';
import SviS6 from '../../../images/svi/SVID_S_6.png';
import SviS7 from '../../../images/svi/SVID_S_7.png';
import SviZ1 from '../../../images/svi/SVID_Z_1.png';
import SviZ2 from '../../../images/svi/SVID_Z_2.png';
import SviZ3 from '../../../images/svi/SVID_Z_3.png';
import SviZ4 from '../../../images/svi/SVID_Z_4.png';

const waypointOption = {
	topOffset: '30%',
	bottomOffset: '30%',
}


class SviIndex extends Component {
	constructor(params) {
		super(params);
		this.state = {
      indicatorLevel: 'county',
      defineLevel: 'county',
			scrollSpyItems: [
				{
					name: 'what',
					title: '什麼是社會脆弱度？',
					show: false,
				},
				{
					name: 'use',
					title: '社會脆弱度的用處',
					show: false,
				},
				{
					name: 'which',
					title: '社會脆弱度的評估指標有哪些？',
					show: false,
				},
				{
					name: 'count',
					title: '社會脆弱度是怎麼計算出來的？',
					show: false,
				},
				{
					name: 'about',
					title: '相關參考文獻',
					show: false,
				},
				{
					name: 'policy',
					title: '資料使用規範與原則',
					show: false,
				},
				{
					name: 'articles',
					title: '主題館',
					show: false,
				},
			],
			allIndicators: []
		};
	}
	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('BLUE');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '社會脆弱度評估', href: '/evaluation/svi' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);

		this.getAllIndicators();
	}
	getAllIndicators() {
		Axios.get(`${apiHost}/api/WeakIndex/GetCompleteIndicator`)
			.then((res) => {
				if (res.status === 200) {
					const indicatorsData = res.data.Result.indicators;
					const categoriesData = res.data.Result.categories;
					const subcategoriesData = res.data.Result.subcategories;
					const allIndicators = [];

					categoriesData.forEach((category) => {
						category.tempSubcategories = [];
						subcategoriesData.forEach((subcategory) => {
							if (subcategory.category_id === category.category_id) {
								category.tempSubcategories.push(subcategory);
							}
						});
						category.tempIndicators = [];
						indicatorsData.forEach((indicator) => {
							if (category.category_id === indicator.category_id) {
								category.tempIndicators.push(indicator);
							}
						});
						category.subcategories = [];
						category.tempSubcategories.forEach((subcategory) => {
							subcategory.indicators = [];
							category.tempIndicators.forEach((indicator) => {
								if (indicator.subcategory_id === subcategory.subcategory_id) {
									subcategory.indicators.push(indicator);
								}
							});
							category.subcategories.push(subcategory);
						})
						
						allIndicators.push(category);
					})
					this.setState({
						allIndicators: allIndicators
					})
				}
			})
	}
	handleClickToggle = (e) => {
		const controller = e.currentTarget;
		const content = e.currentTarget.nextSibling;
		
		if (content.classList.contains('-show')) {
			controller.classList.remove('-open');
			content.classList.remove('-show');
		}
		else {
			controller.classList.add('-open');
			content.classList.add('-show');
		}
	}
	handleWaypointEnter = (name) => {
		const { scrollSpyItems } = this.state;

		scrollSpyItems.forEach((item) => {
			item.show = false;
			if (name === item.name) {
				item.show = true;
			}
		});

		this.setState({
			scrollSpyItems: scrollSpyItems,
		});
		
	}

	render() {
		const { scrollSpyItems } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<div className="svi-page">
					<div className="scroll-spy">
						{
							scrollSpyItems &&
							scrollSpyItems.map((item, index) => {
								return (
									<div key={index}
									className={"item " + item.name + (item.show ? " -show": "")}
									onClick={() => { Scroll.scroller.scrollTo(item.name, { smooth: true })}}>
										<span>{item.title}</span>
									</div>
								)
							})
						}
					</div>
					<section className="svi-heading">
						<Link className="block" to="/evaluation/svi/z">
							<h2 className="heading">
								<p className="title">社會脆弱度指標查詢與展示</p>
								<p className="subtitle">社會脆弱度圖表展示區，初次使用請進</p>
							</h2>
							<img className="background" src={SviBackground1} alt=""/>
						</Link>
						<Link className="block" to="/evaluation/svi/advance">
							<h2 className="heading">
								<p className="title">客製化社會脆弱度評估</p>
								<p className="subtitle">進階使用者可上傳其他指標數據計算社會脆弱度</p>
							</h2>
							<img className="background" src={SviBackground2} alt="" />
						</Link>
					</section>
					<Scroll.Element name="what">
						<Waypoint onEnter={() => { this.handleWaypointEnter('what') } } {...waypointOption}>
								<section className="svi-section svi-intro">
									<div className="container">
										<h1 className="sectiontitle">什麼是社會脆弱度(Social Vulnerability)？</h1>
										<div className="sectioncontent">利用一系列評估指標量化一地區的社會情境（政府治理/經濟/人口結構等），在面對淹水、土石流、地震等天然災害衝擊時，可能遭受損害的程度，以及該地區可能具有的因應、抵抗及調適能力。當一地區社會脆弱度越高時，即表示該地區可能遭受的損害越高，同時抵抗與調適能力越弱。</div>
									</div>
								</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="use">
						<Waypoint onEnter={() => { this.handleWaypointEnter('use') }} {...waypointOption}>
							<section className="svi-section svi-info -gray">
								<div className="container">
									<h2 className="sectiontitle">社會脆弱度的用處</h2>
									<div className="sectioncontent">
										<p className="strong">完備風險分析</p>
										<p className="">災害管理中，風險分析是相當重要的一環，其結果可幫助災害管理者客觀的評定風險管理的順序與策略。但在進行風險分析時，研究者除針對危害（如：地質、水文、氣象等）方面進行研究外，另也須針對社會脆弱度進行分析。過往研究者因社會層面，如：人口、政治與經濟發展牽涉的因素太廣太多，使得要分析社會脆弱度是相當困難的工作，社會脆弱度指標與數據等資訊，即可幫助相關研究者更確實地進行風險分析的工作。</p>
										<p className="strong">可靠的減災規劃指引</p>
										<p className="">社會脆弱與否，除取決於其暴露量外，也需視其因應與調適的能力而定，而社會脆弱度的定義中，有關後者的強弱，則取決於：</p>
										<ul className="svi-list">
											<li>
												<p className="label">該地區既存的社會特徵</p>
												<p className="description">例：社經情況（低收入戶比率）、人口結構（老年或身障人口比率），此一特徵是決定該地區社會脆弱與否的先天條件。</p>
											</li>
											<li>
												<p className="label">該地區政府治理能力</p>
												<p className="description">例如：減災上，法規執行的決心；應變上，各環節部署、救援人力及機具的準備、醫療可及性等；復原上，政府的社福人力與政策、財力、保險推行等層面都有影響。</p>
											</li>
										</ul>
										<p>換句話說，社會脆弱度可以幫助地方政府在規劃防減災任務時，了解其所轄地區社會情境上的優與劣，對症下藥，找到對的防減災方向。同時，也可以幫助地方政府了解過去到現在於防災治理上仍有哪些可以改善之處。</p>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="which">
						<Waypoint onEnter={() => { this.handleWaypointEnter('which') }} {...waypointOption}>
							<section className="svi-section svi-info -white">
								<div className="container">
									<h2 className="sectiontitle">社會脆弱度的評估指標有哪些？</h2>
									<div className="sectioncontent">
										<p>社會脆弱度評估指標(Social Vulnerability Index for Disasters, SVID)會依據評估的內容而有不同，針對災害來說，社會脆弱度評估是希望能針對一地區的暴露量、減災整備、應變及復原各層面進行評估，因此指標的選擇會依據第一層與第二層分類來進行，第三層指標細項的選取，皆以具代表性的政府統計為準（請參考下方指標細項選取原則），指標細項是可變動且更替的，本站會不定期更新指標細項，俾利提供使用者最有效的評估指標。</p>
										<div className="hint">
											<i className="icon-arrow-l-left"></i>
											<span>左右滑動表格</span>
											<i className="icon-arrow-l-right"></i>
										</div>
										<div className="table">
											<div className="svi-indicator-table">
                        <div className="tabs">
                          <button className={`county ${this.state.indicatorLevel === 'county' ? '-active' : ''}`}
                          onClick={() => this.setState({ indicatorLevel: 'county'})}>
                            <span>縣市層級指標</span>
                          </button>
                          <button className={`town ${this.state.indicatorLevel === 'district' ? '-active' : ''}`}
                          onClick={() => this.setState({ indicatorLevel: 'district'})}>
                            <span>鄉鎮層級指標</span>
                          </button>
                        </div>
												<ul className="head -row">
													<li>分類</li>
													<li>次分類</li>
													<li>指標細項</li>
													<li>統計單位</li>
												</ul>
												<ul className="body">
													{
														this.state.allIndicators &&
														this.state.allIndicators.map((category, categoryIndex) => 
															<li className="svi-1 primary -row" key={categoryIndex}>
																<div className="item">{category.category_name}</div>
																<ul className="layer list">
																	{
																		category.subcategories.map((subcategory, subcategoryIndex) => 
																			<li className="secondary -row" key={subcategoryIndex}>
																				<div className="item">{subcategory.subcategory_name}</div>
																				<ul className="layer list">
																					{
																						subcategory.indicators.map((indicator, indicatorIndex) =>
                                              <React.Fragment key={indicatorIndex}>
                                              {
                                                indicator[this.state.indicatorLevel] === true &&
  																							<li className="thirdary -row">
  																								<div className="layer">{indicator.name}</div>
  																								<div className="item">{indicator.unit}</div>
  																							</li>
                                              }
                                              </React.Fragment>
																						)
																					}
																				</ul>
																			</li>
																		)
																	}
																</ul>
															</li>
														)
													}
												</ul>
											</div>
										</div>
										<div className="toggle svi-toggle">
											<button className="controller" onClick={this.handleClickToggle}>
												<span className="text">指標細項的選取原則</span>
												<div className="icon">
													<span className="open">展開<i className="icon-plus"></i></span>
													<span className="close">收合<i className="icon-minus"></i></span>
												</div>
											</button>
											<div className="content">
												<ul className="svi-list">
													<li>
														<p className="label">可及性</p>
														<p className="description">指標細項的選取以政府定期公告之統計資料為主，除較具公信力外，也較易累積各年度統計資料。缺點是政府公告之統計資料主要是以縣市統計為準，缺乏鄉鎮或村里等地區性的統計，且統計項目無法自由決定，受限於政府現有統計項目。</p>
													</li>
													<li>
														<p className="label">相對性</p>
														<p className="description">除暴露量外，其他層面指標選定盡量以「相對數值」表示（比率/百分比/萬分比、平均值等）。例：選擇獨居老人戶百分比（%），而非獨居老人總數。以「相對數值」表示優於「絕對數值」的原因在於，只看人口數時，會發現都會型地區弱勢人口永遠是最多的，事實上，都會型地區因人口過度集中，各類人口都是最多，但資源也可能是最多，而利用「相對數值」可幫助解釋該地方人口結構上脆弱的原因，而非僅只是針對人口數量上的多寡做判定，可照顧到非都會型地區在人口結構上脆弱的情況。</p>
													</li>
													<li>
														<p className="label">單向性</p>
														<p className="description">採用之統計指標應與脆弱度成單一方向性，盡量避免可能同時被詮釋為正向或負向之指標。例：受災次數高，可解釋為其脆弱度較高，但也可解釋因較具受災經驗，所以應變能力較強，脆弱度較低。</p>
													</li>
													<li>
														<p className="label">全面性</p>
														<p className="description">選用指標應以適用各種災害類別為主，特定災別之指標可作為次要替代性指標以為輔助。例：「防災宣導次數」適用性大過於「土石流防災宣導次數」。但此項原則在針對某種災害進行社會脆弱度評估時，較不適用。</p>
													</li>
												</ul>
											</div>
										</div>
										<div className="toggle svi-toggle">
											<button className="controller" onClick={this.handleClickToggle}>
												<span className="text">指標細項定義</span>
												<div className="icon">
													<span className="open">展開<i className="icon-plus"></i></span>
													<span className="close">收合<i className="icon-minus"></i></span>
												</div>
											</button>
											<div className="content">
												<div className="table">
													<div className="svi-detial-table">
														<div className="tabs">
														<button className={`county ${this.state.defineLevel === 'county' ? '-active' : ''}`}
														onClick={() => this.setState({ defineLevel: 'county'})}>
															<span>縣市層級指標</span>
														</button>
														<button className={`town ${this.state.defineLevel === 'district' ? '-active' : ''}`}
														onClick={() => this.setState({ defineLevel: 'district'})}>
															<span>鄉鎮層級指標</span>
														</button>
														</div>
														<ul className="head -row">
															<li>分類</li>
															<li>指標細項</li>
															<li>脆弱度方向性</li>
															<li>統計單位</li>
															<li>資料來源</li>
															<li>指標說明與定義</li>
														</ul>
														<ul className="body">
															{
																this.state.allIndicators &&
																this.state.allIndicators.map((category, categoryIndex) => 
																	<li className="svi-1 primary -row" key={categoryIndex}>
																		<div className="item">{category.category_name}</div>
																		<ul className="layer list">
																			{
																				category.subcategories.map((subcategory, subcategoryIndex) => 
																					<React.Fragment key={subcategoryIndex}>
																						{
																							subcategory.indicators.map((indicator, indicatorIndex) => 
																								<React.Fragment key={indicatorIndex}>
																									{
																										indicator[this.state.defineLevel] === true &&
																										<li className="secondary -row">
																											<div className="item name">{indicator.name}</div>
																											<div className="item related">{indicator.related === 1 ? '(+)' : '(-)'}</div>
																											<div className="item unit">{indicator.unit}</div>
																											<div className="item source" dangerouslySetInnerHTML={{ __html: indicator.source ? indicator.source.join('<br>') : null }}></div>
																											<div className="item description" dangerouslySetInnerHTML={{ __html: indicator.description }}></div>
																										</li>
																									}
																								</React.Fragment>
																							)
																						}
																					</React.Fragment>
																				)
																			}
																		</ul>
																	</li>
																)
															}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="count">
						<Waypoint onEnter={() => { this.handleWaypointEnter('count') }} {...waypointOption}>
							<section className="svi-section svi-info -blue">
								<div className="container">
									<h2 className="sectiontitle">社會脆弱度怎麼計算出來的？</h2>
									<div className="sectioncontent">
										<p>社會脆弱度可分：</p>
										<div className="toggle svi-toggle -white">
											<button className="controller" onClick={this.handleClickToggle}>
												<span className="text">單一年度行政區比較</span>
												<div className="icon">
													<span className="open">展開<i className="icon-plus"></i></span>
													<span className="close">收合<i className="icon-minus"></i></span>
												</div>
											</button>
											<div className="content">
												<p>依據架構，評估層級共三層，包括四分類，12 次項及 31 指標細項，每個層級所計算出來之指標意義略有不同，SVID 即是將 12 次項底下之指標細項各別平均後，得到 12 個 Z<span className="sm">f（t）</span>（參見 2 式），再依據四分類各別平均，得到 4 個Z<span className="sm">g（t）</span>（參見 3 式），最後再將四個 Z<span className="sm">g（t）</span>平均得綜合指數 SVID_Z<span className="sm">（t）</span> （參見以下 4 式），以顯示各縣市單一年度社會脆弱度。</p>
												<p>1、轉換指標細項為 Z<span className="sm">j（t）</span>：例如2018年台北市每萬人醫師人數為d<span className="sm">ij</span>，則d<span className="sm">j</span>為各縣市平均每萬人醫師人數，SD則為各縣市每萬人醫師人數之標準差，再乘上該指標細項之於社會脆弱度之正負方向（-1）。<br />
													<img src={SviZ1} alt="" /></p>
												<p>2、將一地區指標細項依次項平均為 Z<span className="sm">f（t）</span>：例如新竹市次項指數Z<span className="sm">救援（2019）</span>，即為其中兩項指標細項「每萬人消防人數(含義消)」及「每萬人救災車輛數」之平均。<br />
													<img src={SviZ2} alt="" /></p>
												<p>3、將一地區次項平均為 Z<span className="sm">g（t）</span>：同上一步驟，四分類下有數個次項，為避免次項過多的分類貢獻較多的效果，先將其平均。例如：台南市復原能力指數Z<span className="sm">復原能力（2015）</span>即為「產值」與「人口」兩次項之平均值。<br />
													<img src={SviZ3} alt="" /></p>
												<p>4、最後計算社會脆弱度綜合指數SVID_Z<span className="sm">（t）</span>，將四分類平均：<br />
													<img src={SviZ4} alt="" /></p>
											</div>
										</div>
										<div className="toggle svi-toggle -white">
											<button className="controller" onClick={this.handleClickToggle}>
												<span className="text">單一行政區歷史趨勢</span>
												<div className="icon">
													<span className="open">展開<i className="icon-plus"></i></span>
													<span className="close">收合<i className="icon-minus"></i></span>
												</div>
											</button>
											<div className="content">
												<p>將指標數據利用基準年的設定（通常設定為100），將每個指標細項在每個年度的變動率標準化，不論上升或下降逐年疊加，如此即可與預設的基準年及其他年度進行比較，且趨勢線較易理解，適合閱讀。某縣市歷年的 SVID_S 計算方式說明如下：</p>
												<p>1、計算單項指標細項的對稱變動率 C<span className="sm">ij（t）</span>，以了解各年度與前一年度的變動幅度：<br />
													指標細項為實際數值或指數時（如：每萬人消防人力、保全人口數等）<br />
													<img src={SviS11} alt="" /><br />
													指標細項為比率時（如低耐震建物率、颱洪險投保率等）<br />
													<img src={SviS12} alt="" />
												</p>
												<p>2、計算標準化因子 A<span className="sm">ij（t）</span>，以全部年度平均對稱變動率的量（取絕對值）作為基數：<br />
													<img src={SviS2} alt="" /></p>
												<p>3、計算標準化變動率 B<span className="sm">ij（t）</span>，上述兩式之比值即是，可呈現各年度標準化的變動情況以利後續計算標準化指數。<br />
													<img src={SviS3} alt="" /></p>
												<p>4、計算標準化指數 S<span className="sm">ij（t）</span>：設定某一年為基準年，將其基準指數訂為 100，其後之年度得分，則依據標準化變動率上升或下降，形成標準化指數 S，可與基準年比較，也可與其他年度比較，較適合閱讀及較易理解。<br />
													• 指標細項為實際數值或指數時：<br/>
													<img src={SviS41} alt="" /><br/>
													• 指標細項為比率時：<br />
													<img src={SviS42} alt="" /><br/>
												</p>
												<p>5、計算一地區各次項標準化指數S<span className="sm">f(t)</span>：例如新竹市次項指數S<span className="sm">救援（2019）</span>，即為其中兩項指標細項「每萬人消防人數(含義消)」及「每萬人救災車輛數」之平均<br />
													<img src={SviS5} alt="" /></p>
                        <p>6、計算一地區四分類標準化指數S<span className="sm">g(t)</span>：例如台南市復原能力指數S<span className="sm">復原能力（2015）</span>即為「產值」與「人口」兩次項之平均值。<br />
                          <img src={SviS6} alt="" /></p>
                        <p>7、最後計算社會脆弱度綜合指數SVID_S<span className="sm">(t)</span>，將四分類S<span className="sm">g(t)</span>平均。<br />
                          <img src={SviS7} alt="" /></p>
											</div>
										</div>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="about">
						<Waypoint onEnter={() => { this.handleWaypointEnter('about') }} {...waypointOption}>
							<section className="svi-section svi-info -gray">
								<div className="container">
									<h2 className="sectiontitle">相關參考文獻</h2>
									<div className="sectioncontent">
										<div className="svi-download">
											<span className="text">災害社會脆弱度指標(SVI)歷年趨勢評估(NCDR 101-T12).pdf</span>
											<a className="icon" rel="noopener noreferrer" target="_blank" href="/files/101T12_NCDR.pdf" download="101T12_NCDR災害社會脆弱度指標歷年趨勢評估技術報告.pdf">
												<span>下載</span>
												<i className="icon-arrow-down"></i>
											</a>
										</div>
										<div className="svi-download">
											<span className="text">縣市防災力資料分析與應用(NCDR 102-T03).pdf</span>
											<a className="icon" rel="noopener noreferrer" target="_blank" href="/files/NCDR_102-T03.pdf" download="縣市防災力資料分析與應用(NCDR 102-T03).pdf">
												<span>下載</span>
												<i className="icon-arrow-down"></i>
											</a>
										</div>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="policy">
						<Waypoint onEnter={() => { this.handleWaypointEnter('policy') }} {...waypointOption}>
							<section className="svi-section svi-info -blue">
								<div className="container">
									<h2 className="sectiontitle">資料使用原則與限制</h2>
									<div className="sectioncontent">
										<ul className="svi-list -white">
											<li>社會脆弱度所評估的是全台22個縣市的整體社會情況，本站提供之數據並非針對某一個特定主題或特定災別，而是以行政區空間單位（縣市／鄉鎮）為主的整體性綜合評估結果。</li>
											<li>因為指標細項的蒐集不易，且很多層面無法量化，因此本站之評估結果與現況必定有其誤差，數據僅供參考，請斟酌再利用。</li>
											<li>本站提供SVID原始數據，但部分指標細項原始數據之取得並非自公開資訊管道，將不提供開放瀏覽及下載。</li>
											<li>SVID各項數據不定期會進行更新，評估結果會因採用的指標細項不同而有不同，也會因為計算方式的更新而有變動，請隨時至減災動資料查詢最新數據。</li>
											<li>下載或再製SVID任一指標細項時，請詳閱其資料定義，並註名資料來源（國家災害防救科技中心減災動資料）。</li>
											<li>由本站下載後加值之數據資料不屬本站權責範圍，須由使用者自行負責。</li>
										</ul>
									</div>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<Scroll.Element name="articles">
						<Waypoint onEnter={() => { this.handleWaypointEnter('articles') }} {...waypointOption}>
							<section className="svi-section svi-articlelist">
								<div className="container">
									<h2 className="sectiontitle">主題館</h2>
									<ArticleList color="blue" pathname="/evaluation/svi" categoryID={4}></ArticleList>
									<Link className="sectionbutton fill-button -blue" to="/evaluation/svi/categories">
										<span className="type-text -ch -md">進入主題館</span>
									</Link>
								</div>
							</section>
						</Waypoint>
					</Scroll.Element>
					<section className="svi-section svi-articlelist">
						<div className="container">
							<h2 className="sectiontitle">資料來源</h2>
							<ResourceLinks theme="blue" category="svi"></ResourceLinks>
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}
}

export default SviIndex;
