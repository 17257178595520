import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import './index.scss';

// methods
import gaTracking from '../../utils/Shared/gaTracking';
import { loginRedirect, registerRedirect } from '../../utils/Shared/methods';

class MorakotAdvanceIntro extends Component {
	constructor(params) {
		super(params);
		this.state = {
			step: 1,
			isMemberLogin: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onLoadHeaderColor('GREEN');
		this.props.onLoadHeaderTheme('DARK');
		this.props.onLoadShowLanguage('HIDE');

		this.checkMemberLogin();

		const breadcrumb = [
			{ name: '首頁', href: '/' },
			{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
			{ name: '客製化專區', href: '/statistics/morakot/advanced-intro' },
		]
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
	}
	checkMemberLogin = () => {
		if (window.localStorage.getItem('user-token')) {
			this.setState({ isMemberLogin: true });
		}
	}
	handleClickToggle = (e) => {
		const target = e.currentTarget.nextSibling;
		
		if (target.classList.contains('-show')) {
			target.classList.remove('-show');
		}
		else {
			target.classList.add('-show');
		}
	}

	render() {
		const { isMemberLogin } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>減災動資料 - 社會脆弱度評估</title>
				</Helmet>
				<div className="new-morakot-page">
					<div className="container">
						<div className="morakot-advancetitle">客製化專區</div>
						<div className="morakot-advance row">
							<div className="col-md-12 head">
								<p className="title">加入會員就能試試客製化專區</p>
								<p>莫拉克風災完整的調查數據，僅需三個簡單的操作步驟，即可產出精美的客製化圖表，最後還能一鍵打包下載數據及圖表。</p>
							</div>
							<div className="col-md-3 advance-side">
								<div className="step">
									<button className={"button" + (this.state.step === 1 ? ' -active': '')}
									onClick={() => {
										this.setState({
											step: 1
										})
									}}>步驟一：拖曳選擇統計主題和圖表類型</button>
									{
										this.state.step === 1 &&
										<div className="toggle">
											<div className="description">畫面左側為可供查找的統計主題和子項目，您可以從左側選擇您想要製圖的統計項目，將項目拖曳到右側「1. 請選擇要製圖的項目」欄位</div>
                      <div className="description">如果您想要進一步查看兩不同項目間的關係，您可以選擇另一個統計項目當作資料分類依據，並將項目拖曳到右側「2. 可選取新的項目將上述項目分類」欄位. 請選擇要製圖的項目」欄位</div>
                      <div className="description">最後再選擇要製作的圖表類型，就能成功產出圖表囉！. 請選擇要製圖的項目」欄位</div>
										</div>
									}
								</div>
								<div className="icon"><i className="icon-double-arrow"></i></div>
								<div className="step">
									<button className={"button" + (this.state.step === 2 ? ' -active' : '')}
									onClick={() => {
										this.setState({
											step: 2
										})
									}}>步驟二：獲得互動式圖表輕鬆查看各縣市數據</button>
									{
										this.state.step === 2 &&
										<div className="toggle">
											<div className="title">生成圖表後，您可以在網頁上查看這個統計項目下不同年份、行政區的資料</div>
										</div>
									}
								</div>
								<div className="icon"><i className="icon-double-arrow"></i></div>
								<div className="step">
									<button className={"button" + (this.state.step === 3 ? ' -active' : '')}
									onClick={() => {
										this.setState({
											step: 3
										})
									}}>步驟三：下載圖片和數據，立刻自由應用</button>
									{
										this.state.step === 3 &&
										<div className="toggle">
											<div className="title">除了在網站上查看數據，您也可以下載圖表或原始數據自由運用</div>
										</div>
									}
								</div>
							</div>
							<div className="col-md-9 advance-info">
								{
									this.state.step === 1 &&
									<div className="image step1">
                    <video autoPlay={true} loop={true}><source src="/files/morakot-intro_1.mp4" /></video>
									</div>
								}
								{
									this.state.step === 2 &&
									<div className="image step2">
                    <video autoPlay={true} loop={true}><source src="/files/morakot-intro_2.mp4" /></video>
									</div>
								}
								{
									this.state.step === 3 &&
									<div className="image step3">
                    <video autoPlay={true} loop={true}><source src="/files/morakot-intro_3.mp4" /></video>
									</div>
								}
							</div>
							{
								isMemberLogin === true &&
								<div className="offset-md-2 col-md-8 advance-enter">
									<Link to="/statistics/morakot/advanced-analytic" className="button fill-button -green">
										<span className="type-text -ch -md">開始使用</span>
									</Link>
								</div>
							}
							{
								isMemberLogin === false &&
								<div className="offset-md-2 col-md-8 advance-enter">
									<p>立刻加入會員，即可享有此功能！</p>
									<button className="button fill-button -green" onClick={() => registerRedirect()} >加入會員</button>
									<button className="button fill-button -gray" onClick={() => loginRedirect()} >登入</button>
								</div>
							}
							</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MorakotAdvanceIntro;
