import { connect } from 'react-redux';
import SviChartMap from './pure';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SviChartMap);
