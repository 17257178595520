import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';


class SearchBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      focusing: false,
      submited: false,
    }
  }
  

  handleCancleClick = (e) => {
    this.setState({searchText: '', submited: false});
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleTextChange = (e) => {
    e.persist();
    this.setState({searchText: e.target.value, submited: false});
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  handleKeyDown = (e) => {
    e.persist();
    if (e.key === "Enter") {
      this.setState({submited: true});
      if (this.props.onSubmit) {
        this.props.onSubmit(this.state.searchText);
      }
    }
  }

  render() {
    const {className} = this.props;
    const {searchText, focusing, submited} = this.state;
    return (
      <div className={'search-bar icon-search' + ((searchText || focusing) ? '' : ' -focus') + (className ? ' ' + className : '') + (submited ? ' -submited' : '')}>
        <input className={submited ? '-submited' : ''} type="text" name="" onChange={this.handleTextChange} onKeyDown={this.handleKeyDown} onFocus={()=>this.setState({focusing: true})} onBlur={()=>this.setState({focusing: false})} value={searchText}/>
        {searchText && <button className={'button icon-close button-cancel'} onClick={this.handleCancleClick}></button>}
      </div>
    );
  }
}

SearchBar.propType = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default SearchBar;