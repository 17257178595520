import React, { Component } from 'react';
import axios from 'axios';
import './index.scss';

import LinksSlider from '../../utils/LinksSlider';
import { apiHost } from '../../utils/Shared/constant';

class ResourceLink extends Component {
	constructor(params) {
		super();
		this.state = {
			linkItems: [],
		};
	}
	componentDidMount() {
		let categoryID;

		switch (this.props.category) {
			case 'morakot':
				categoryID = '1';
				break;
			case 'sfaa':
				categoryID = '2';
				break;
			case 'svi':
				categoryID = '3';
				break;
			case 'evacuation':
				categoryID = '4';
				break;
			default:
				categoryID = '1';
				break;
		}

		axios.get(`${apiHost}/api/Content/RelatedLink?data.subjectType=${categoryID}&data.positionType=1`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						linkItems: res.data.Result ? res.data.Result : [],
						loading: false,
					})
				}
			})
	}
	render() {
		return (
			<LinksSlider className="resource-links" data={this.state.linkItems} theme={this.props.theme}></LinksSlider>
		);
	}
}

export default ResourceLink;
