import React, { Component } from 'react';
import Axios from 'axios';
import './index.scss';

// Until
import ModalBox from '../../utils/ModalBox';
import CustomInput from '../../utils/CustomInput';
import CustomSelect from '../../utils/CustomSelect';
import CustomTextarea from '../../utils/CustomTextarea';
import { apiHost } from '../../utils/Shared/constant';

class HomePageContactForm extends Component {
	constructor(params) {
		super(params);
		this.state = {
			sendable: false,
			inputFail: false,
			inputEmail: '',
			inputName: '',
			inputPhone: '',
			inputContent: '',
			inputCategory: '',
			modalOpen: false,
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState !== this.state && prevState.sendable === false) {
			const { inputEmail, inputContent } = this.state;
			if (inputEmail !== '' && inputContent.length < 300) {
				this.messenageSendable()
			}
		}
	}
	messenageSendable = () => {
		this.setState({ sendable: true })
	}
	componentDidMount() {
		Axios.get(`${apiHost}/api/Content/ParameterResult?data.name=聯絡我們選項`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						category: JSON.parse(res.data.Result[0].Value),
					})
				}
			})
	}
	handlePostContactData = () => {
		const { inputName, inputEmail, inputPhone, inputCategory, inputContent } = this.state;
		if (!inputEmail) {
			this.setState({
				inputFail: true,
			})
		}
		else {
			Axios.post(
				`${apiHost}/api/Content/ContactUs`,
				{
					Name: inputName,
					Phone: inputPhone,
					Email: inputEmail,
					Content: inputContent,
					Type: inputCategory,
				},
				{
					headers: { 'Content-Type': 'application/json' }
				},
			)
				.then(res => {
					if (res.data.Outstring === "0000") {
						this.setState({
							postMsg: '已成功發送訊息！'
						})
						this.openModal();
						setTimeout(() => {
							window.location = '/';
						}, (2000));
					} else {
						this.setState({
							postMsg: '糟糕，發送訊息時發生錯誤，請稍後再試。'
						})
						this.openModal();
					}
				});
		}

	}

	openModal() {
		this.setState({
			modalOpen: true,
		})
	}
	render() {
		const { category, inputFail, inputName, inputEmail, inputPhone, inputCategory, inputContent } = this.state;
		let wordCount = 0 + inputContent.length;
		let wordOverflow = wordCount > 300;

		return (
			<React.Fragment>
				<ModalBox
					open={this.state.modalOpen}
					theme="-yellow"
					onRequestClose={() => {
						this.setState({ modalOpen: false })
					}}
				>
					<div className="modalbody -center">
						<p className="type-text -ch -md">{this.state.postMsg}</p>
					</div>
					<div className="modalfooter -center">
						<button className="fill-button -fit -yellow" onClick={() => { this.setState({ modalOpen: false }) }}>關閉視窗</button>
					</div>
				</ModalBox>
				<div className="offset-lg-2 col-lg-8 contact-form">
					<p className="info type-text -ch -md">對於減災動資料有任何疑問或建議都歡迎來信，我們也會盡快回覆您的問題，謝謝！</p>
					<div className="info-block">
						<p className="info type-text -ch -md">Email: easy2do@ncdr.nat.gov.tw</p>
						<p className="info type-text -ch -md">住 址: 新北市新店區北新路三段200號九樓</p>
					</div>
				
					{/* <p className="info type-text -ch -md">對於減災動資料有任何疑問或建議都歡迎來信，我們也會盡快回覆您的問題，謝謝！<br />*表示必須填寫</p> */}
					{/* <div className="row">
						<div className="formitem col-md-6">
							<CustomInput id="username" type="text" status="normal" label="姓名" placeholder="輸入姓名" value={inputName} onChange={t => this.setState({ inputName: t })} />
						</div>
						<div className="formitem col-md-6">
							<CustomInput id="email" type="email" status={inputFail ? 'danger' : 'normal'} msg={inputFail ? 'E-mail 錯誤！' : '請輸入可正常收發之電子信箱，以便聯繫。'} label="電子郵件*" placeholder="輸入電子郵件" value={inputEmail} onChange={t => this.setState({ inputEmail: t })} />
						</div>
					</div> */}
					{/* <div className="row">
						<div className="formitem col-md-6">
							<CustomInput id="phone" type="text" status="normal" label="聯絡電話" placeholder="例：0976400833" value={inputPhone} onChange={t => this.setState({ inputPhone: t })} />
						</div>
						<div className="formitem col-md-6">
							<CustomSelect id="category" status="normal" label="問題類別" value={inputCategory} onChange={t => this.setState({ inputCategory: t })}>
								<option value="0">選擇問題類別</option>
								{
									category &&
									category.map((item, i) => {
										return (
											<option key={i} value={i + 1}>{item}</option>
										)
									})
								}
							</CustomSelect>
						</div>
					</div> */}
					{/* <div className="row">
						<div className="formitem col-lg-12">
							<CustomTextarea id="content" status={wordOverflow ? 'overflow' : 'normal'} msg={`字數限制 ${wordCount}/300`} label="問題描述" placeholder="請輸入您的問題反饋..." value={inputContent} onChange={t => this.setState({ inputContent: t })} />
						</div>
						<div className="col-lg-12">
							<button className="submit fill-button -dark" onClick={this.handlePostContactData}>
								<span className="type-text -ch -md">送出</span>
							</button>
						</div>
					</div> */}
				</div>
			</React.Fragment>
		);
	}
}

export default HomePageContactForm;
