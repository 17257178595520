import React, { Component } from 'react';
import './index.scss';

// modules
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class SideModal extends Component {

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate() {
    if (this.props.isModalOpen) {
      disableBodyScroll(document.getElementById('root'));
    }
    else {
      enableBodyScroll(document.getElementById('root'));
    }
  }

  renderBackground() {
    if (this.props.background) {
      return (
        <div className={"image"}
          style={{ backgroundImage: 'url(' + this.props.background + ')' }}
        ></div>
      )
    }
  }

  render() {
    return (
      <div className={'side-modal ' + (this.props.isModalOpen ? '-open' : '-close')}>
        <div className={`side side-content ${this.props.modalStyle ? `-${this.props.modalStyle}` : ''} ${this.props.direction ? `-${this.props.direction}` : ''}`}>
          <button onClick={this.props.closeModal} className="close">
            <i className="icon-close"></i>
          </button>
          <div className="modal-main main">
            <div className="modalheader">
              {this.props.headerContent}
            </div>
            <div className="modalbody article-format">
              {this.props.bodyContent}
            </div>
            <div className="modalfooter">
              {this.props.footerContent}
            </div>
          </div>
        </div>
        <div onClick={this.props.closeModal} className="mask">
          {this.renderBackground()}
        </div>
      </div>
    );
  }
}

export default SideModal;
