let init = {
	color: '-white',
	theme: '-light',
	showBreadcrumb: false,
	showLanguage: false,
	isLogin: false,
  nowLang: 'zh-tw'
}

export default (state = init, action) => {
	switch (action.type) {
		case 'HEADER_THEME_LIGHT':
			return { ...state, theme: '-light' }

		case 'HEADER_THEME_DARK':
			return { ...state, theme: '-dark' }

		case 'HEADER_COLOR_WHITE':
			return { ...state, color: '-white' }

		case 'HEADER_COLOR_BLUE':
			return { ...state, color: '-blue' }

		case 'HEADER_COLOR_YELLOW':
			return { ...state, color: '-yellow' }

		case 'HEADER_COLOR_GREEN':
			return { ...state, color: '-green' }

		case 'HEADER_COLOR_RED':
			return { ...state, color: '-red' }

		case 'HEADER_COLOR_PINK':
			return { ...state, color: '-pink' }

		case 'SHOW_LANGUAGE':
			return { ...state, showLanguage: true }

		case 'HIDE_LANGUAGE':
			return { ...state, showLanguage: false }
			
    case 'SET_LANGUAGE_EN':
      return { ...state, nowLang: 'en' }

    case 'SET_LANGUAGE_ZH':
      return { ...state, nowLang: 'zh-tw' }
			
		case 'SHOW_BREADCRUMB':
			return { ...state, showBreadcrumb: true, breadcrumbData: action.breadcrumbData }
			
		case 'HIDE_BREADCRUMB':
			return { ...state, showBreadcrumb: false }

		case 'SET_LOGIN_STATE':
			return { ...state, isLogin: action.isLogin }

		case 'HEADER_STYLE_DEFAULT':
			return { ...state}

			
		default:
			return state
	}
}
