import { csv } from 'd3-fetch';
import React, { Component } from 'react';
import './index.scss';
import arrowUp from '../../../images/arrowUp.png'
import arrowDown from '../../../images/arrowDown.png'

class SviChartTable extends Component {
	constructor(params) {
		super(params);
		this.state = {
			csvData: [],
			ascendingSort: {
				0:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				1:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				2:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				3:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				4:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				5:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				6:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				7:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				8:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
				9:{
					0: true,
					1: true,
					2: true,
					3: true,
				},
			}
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.handleLoadCsvData();
		}
	}
	componentDidMount() {
		this.handleLoadCsvData();
	}
	handleLoadCsvData() {
		if (this.props.csvData) {
			const { csvData } = this.props;
			const lineRegex = /\n(?=(?:(?:[^"]*"){2})*[^"]*$)/g;
			const cellRegex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/g;

			csvData.forEach((data) => {
				data.table = data.csv.split(lineRegex).map(row => {
					return row.split(cellRegex);
				})
			})

			this.setState({
				csvData: csvData,
			})
		}
		else {
			this.setState({
				csvData: [],
			})
		}
	}

	sortCsvData = (indicator, column) =>{
		const { csvData, ascendingSort } = this.state;
		if(ascendingSort[indicator][column]){//升冪
			if(column === 1){
				csvData[indicator] && csvData[indicator].table.sort((a,b) =>{
					if(a[column] === '縣市' || a[column] === '鄉鎮'){
						return -1
					}else if(b[column] === '縣市' || b[column] === '鄉鎮'){
						return 1
					}
					if(a[column] && b[column]){
						return -a[column].localeCompare(b[column], "zh-Hant"); 
					}
					return 0; 
				});
			}else{
				csvData[indicator] && csvData[indicator].table.sort((a, b) =>{
					if(a[column] === '-' || a[column] === '\r') return 1
					else if(b[column] === '-' || b[column] === '\r') return -1
					let c = a[column];
					let d = b[column];
					if(a[column] && b[column]){
						c = Number(c.replace(/[,"]/g ,''));
						d = Number(d.replace(/[,"]/g ,''));
					}
					return  d - c
				})
			}
		}else{//降冪
			if(column === 1){
				csvData[indicator] && csvData[indicator].table.sort((a,b) =>{ 
					if(a[column] === '縣市' || a[column] === '鄉鎮'){
						return -1
					}else if(b[column] === '縣市' || b[column] === '鄉鎮'){
						return 1
					}
					if(a[column] && b[column]){
						return a[column].localeCompare(b[column], "zh-Hant"); 
					}
					return 0; 
				});
			}else{
				csvData[indicator] && csvData[indicator].table.sort((a, b) =>{
					if(a[column] === '-' || a[column] === '\r') return 1
					else if(b[column] === '-' || b[column] === '\r') return -1
					let c = a[column];
					let d = b[column];
					if(a[column] && b[column]){
						c = Number(c.replace(/[,"]/g ,''));
						d = Number(d.replace(/[,"]/g ,''));
					}
					return c - d
				})
			}
		}
		ascendingSort[indicator][column] = !ascendingSort[indicator][column]
		this.setState({csvData, ascendingSort});
	}
	render() {
		const { csvData, ascendingSort } = this.state;
		return (
			<React.Fragment>
				{
					csvData &&
					csvData.map((indicator, indicatorIndex) => {
						return (
							<React.Fragment key={indicatorIndex}>
								<p className="svi-datatitle">{indicator.title}</p>
								<table className="svi-datatable">
									<tbody>
										{
											indicator &&
											indicator.table.map((tableRow, tableRowIndex) => {
												if(tableRowIndex === 0){
													return <React.Fragment key={tableRowIndex}>
																<tr>{
																	tableRow.length > 1 &&
																	tableRow.map((tableData, tableDataIndex) => {
																		return (
																			<td key={tableDataIndex} style={{cursor:"pointer"}} onClick={()=>this.sortCsvData(indicatorIndex ,tableDataIndex)}>
																				<div>{tableData.replace(/"/g, '').trim() || '-'}</div>
																				{
																					!ascendingSort[indicatorIndex][tableDataIndex] &&
																					<img className="arrow" src={arrowDown} alt=""/>
																				}
																				{
																					ascendingSort[indicatorIndex][tableDataIndex] &&
																					<img className="arrow" src={arrowUp} alt=""/>
																				}
																			</td>
																		)
																	})
																}</tr>
															</React.Fragment>
												}
												return (
													<React.Fragment key={tableRowIndex}>
														<tr>{
															tableRow.length > 1 &&
															tableRow.map((tableData, tableDataIndex) => {
																return (
																	<td key={tableDataIndex}>{tableData.replace(/"/g, '').trim() || '-'}</td>
																)
															})
														}</tr>
													</React.Fragment>
												)
											})
										}
									</tbody>
								</table>
							</React.Fragment>
						)
					})
				}
			</React.Fragment>
		);
	}
}

export default SviChartTable;
