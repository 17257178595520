import { connect } from 'react-redux';
import SviPage from './pure';

const mapStateToProps = (state, ownProps) => ({
	color: state.header.color,
	theme: state.header.theme,
	showLanguage: state.header.showLanguage,
	showBreadcrumb: state.header.showBreadcrumb,
})

const mapDispatchToProps = (dispatch) => ({
	onLoadHeaderColor: (COLOR) => dispatch({ type: 'HEADER_COLOR_' + COLOR }),
	onLoadHeaderTheme: (THEME) => dispatch({ type: 'HEADER_THEME_' + THEME }),
	onLoadShowLanguage: (STATUS) => dispatch({ type: STATUS + '_LANGUAGE' }),
	onLoadShowBreadcrumb: (STATUS, data) => dispatch({ type: STATUS + '_BREADCRUMB', breadcrumbData: data }),
	onClickOpenSideModal: (MODAL_NAME) => dispatch({ type: 'OPEN_' + MODAL_NAME + '_SIDEMODAL' }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SviPage);
