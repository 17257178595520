import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import queryString from "query-string";
import Axios from 'axios';
import './index.scss';
import Scroll from 'react-scroll'
import SubjectItem from "../HouseSubjectItem";

// import Houses from '../../config/PermanentHouse';

// Utils
import SocialBlock from '../../utils/SocialBlock';
import { sourceHost, apiHost } from '../../utils/Shared/constant';

// methods
import gaTracking from '../../utils/Shared/gaTracking';

class CitySubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicID: 1,
      isAllHousesShow: false,
      subjectData: [],
      housesData: [],
      subjectTitle: ''
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onLoadHeaderColor('GREEN');
    this.props.onLoadHeaderTheme('DARK');
    this.props.onLoadShowLanguage('HIDE');
    const breadcrumb = [
      { name: '首頁', href: '/' },
      { name: '莫拉克風災災後調查', href: '/statistics/morakot' },
      { name: '永久屋專區', href: '/statistics/morakot-house' },
    ]
    this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
    this.setSubject();
  }
  setSubject = () => {
    const pathname = window.location.pathname.split('/');
    const topics = [
      { id: 1, name: 'house-quantity' },
      { id: 2, name: 'resource-acquisition' },
      { id: 3, name: 'employment-mediation' },
      { id: 4, name: 'most-dissatisfied' },
      { id: 5, name: 'commuting-time' },
      { id: 6, name: 'quality-of-residence' },
      { id: 7, name: 'community-environment' },
      { id: 8, name: 'time-of-recovery' },
      { id: 9, name: 'confidence-level' }
    ];
    topics.forEach((topic) => {
      if (pathname[3] === topic.name) {
        this.setState({
          topicId: topic.id
        }, () => {
          this.loadTopic();
          this.getHouses();
        })
      }
    });
  }
  loadTopic = () => {
    const { topicId } = this.state;
    Axios.get(`${apiHost}/api/Survey/HouseTopic?topic_id=${topicId}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            subjectData: res.data.Result[0]
          })
        }
      })
  }
  getHouses = () => {
    Axios.get('/config/PermanentHouse.json')
    .then(result => {
      const Houses = result.data;
      const query = queryString.parse(window.location.search);
      const housesId = Array.isArray(query.houses) ? query.houses : [query.houses];
      const housesData = [];
      if (query.houses === "0") {
        Houses.data.forEach((item) => {
          if (item.enable) {
            housesData.push(item.name);
          };
        });
        this.setState({
          isAllHousesShow: true
        })
      }
      else {
        housesId.forEach((id) => {
          Houses.data.forEach((item) => {
            if (Number(id) === item.id && item.enable) {
              housesData.push(item.name);
            };
          });
        });
      };
      this.setState({
        housesData: housesData
      });
    })
  }
  handleClickSetQueryString = () => {
    this.setState({
      redirectUrl: `${window.location.pathname}?houses=0`
    })
  }
  render() {
    const { housesData, subjectData, isAllHousesShow, redirectUrl } = this.state;
    const { title, raw_data_url, resource, description, subjects } = subjectData;
    return (
      <div className="morakot-area-page">
				{ redirectUrl && <Redirect push to={redirectUrl}/> }
        <Helmet>
          <title>減災動資料 - 莫拉克風災災後調查</title>
        </Helmet>
        <div className="container">
          <div className="morakot-area-heading">永久屋專區 - { title }</div>
          <div className="morakot-area-subject">
            <div className="description">{ description }</div>
            <div className="subject-buttonbar">
            {
              !isAllHousesShow &&
              <React.Fragment>
                <div className="head">
                  <p>所選永久屋：</p>
                  <button className="more"
                  onClick={ this.handleClickSetQueryString }>看全部永久屋</button>
                </div>
                {
                  housesData.length !== 0 &&
                  housesData.map((house, i) => 
                    <button className="button ghost-button -green" key={i}
                    onClick={() => Scroll.scroller.scrollTo(house, { smooth: true })}>
                      { house }
                    </button>
                  )
                }
              </React.Fragment>
            }
            </div>
            {
              (subjects && housesData.length !== 0) &&
              subjects.map((subject, i) => {
                return (
                  <section className="subject-section" key={i}>
                    <SubjectItem
                    title={subject.title}
                    description={subject.description}
                    id={i}
                    houses={housesData}
                    data={subject.data}></SubjectItem>
                  </section>
                )
              })
            }
            <div className="subject-resource">{ resource }</div>
            <div className="subject-download">
              <a href={`${sourceHost}${raw_data_url}`} onClick={ () => gaTracking('莫拉克颱風', '永久屋專區', '下載永久屋原始數據')} className="button fill-button -dark">下載永久屋原始數據</a>
            </div>
          </div>
        </div>
        <SocialBlock category="莫拉克颱風" action="永久屋專區" theme="green" />
        <section className="subject-selector">
          <div className="container">
            <div className="title type-text -lg -ch">瀏覽其他主題</div>
            <div className="subjects">
              <div className="item"><Link to="/statistics/morakot-house/house-quantity?houses=0" className="button">永久屋社區家戶數</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/resource-acquisition${window.location.search}`} className="button">政府與民間團體資源</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/employment-mediation${window.location.search}`} className="button">就業與媒合</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/most-dissatisfied${window.location.search}`} className="button">最不滿意的地方</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/commuting-time${window.location.search}`} className="button">通勤時間</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/quality-of-residence${window.location.search}`} className="button">居住品質</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/community-environment${window.location.search}`} className="button">社區環境</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/time-of-recovery${window.location.search}`} className="button">生活作息恢復</Link></div>
              <div className="item"><Link to={`/statistics/morakot-house/confidence-level${window.location.search}`} className="button">信任感</Link></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CitySubject;
