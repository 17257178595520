import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import './index.scss';

// Utils
import SocialBlock from '../../utils/SocialBlock';
import PageNavigation from '../../utils/PageNavigation';
import { apiHost, sourceHost } from '../../utils/Shared/constant';

class MorakotArticlePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			article: null,
			heading: ''
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let slug = window.location.pathname.split('/').slice(2, 3).join('');
		this.setState({slug});
		let breadcrumb = [];
		switch (slug) {
			case "morakot":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({ 
					theme: 'green',
					sort: '莫拉克颱風',
					trackingCategory: '莫拉克颱風',
					pathname: '/statistics/morakot',
					language: 'ch',
				}, () => {
					breadcrumb = [
						{ name: '首頁', href: '/' },
						{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
						{ name: '主題館', href: '/statistics/morakot/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			case "morakot-en":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'green',
					sort: 'Morakot',
					trackingCategory: '莫拉克颱風-英文',
					pathname: '/statistics/morakot-en',
					language: 'en',
				}, () => {
					breadcrumb = [
						{ name: 'Home Page', href: '/' },
						{ name: 'Morakot Typhoon', href: '/statistics/morakot-en' },
						{ name: 'Theme Pavilion', href: '/statistics/morakot-en/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			case "sfaa":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'green',
					sort: '社福機構',
					trackingCategory: '社福機構',
					pathname: '/statistics/sfaa',
					language: 'ch',
				}, () => {
					breadcrumb = [
						{ name: '首頁', href: '/' },
						{ name: '社福機構', href: '/statistics/sfaa' },
						{ name: '主題館', href: '/statistics/sfaa/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			case "svi":
				this.props.onLoadHeaderColor('BLUE');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'blue',
					sort: '社會脆弱度評估',
					trackingCategory: '社會脆弱度',
					pathname: '/evaluation/svi',
					language: 'ch',
				}, () => {
					breadcrumb = [
						{ name: '首頁', href: '/' },
						{ name: '社會脆弱度', href: '/evaluation/svi' },
						{ name: '主題館', href: '/evaluation/svi/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			case "evacuation":
				this.props.onLoadHeaderColor('PINK');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({
					theme: 'pink',
					sort: '撤離與收容',
					trackingCategory: '撤離與收容',
					pathname: '/evaluation/evacuation',
					language: 'ch',
				}, () => {
					breadcrumb = [
						{ name: '首頁', href: '/' },
						{ name: '撤離與收容評估', href: '/evaluation/evacuation' },
						{ name: '主題館', href: '/evaluation/evacuation/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			case "home":
				this.props.onLoadHeaderColor('GREEN');
				this.props.onLoadHeaderTheme('DARK');
				this.props.onLoadShowLanguage('HIDE');
				this.setState({ 
					theme: 'green',
					sort: '',
					trackingCategory: '',
					pathname: '/statistics/home',
					language: 'ch',
				}, () => {
					breadcrumb = [
						{ name: '首頁', href: '/' },
						{ name: '主題館', href: '/statistics/home/categories' },
					];
					this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
				});
				break;
			default: 
				// breadcrumb = [
				// 	{ name: '首頁', href: '/' },
				// 	{ name: '莫拉克風災災後調查', href: '/statistics/morakot' },
				// 	{ name: '主題館', href: '/statistics/morakot/categories' },
				// ];
		}
		this.props.onLoadShowBreadcrumb('SHOW', breadcrumb);
		this.loadArticle(this.props.match.params.id);
	}
	
	loadArticle = (id) => {
		axios.get(`${apiHost}/api/Content/Article?data.iD=${id}`)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						article: res.data.Result,
					})
				}
			})
	}
 	render() {
		const { color } = this.props;
		const { article, sort, pathname, trackingCategory, theme } = this.state;
		return (
			<React.Fragment>
				<div className="article-page">
					{
						article &&
						<React.Fragment>
							<section className="article-heading">
								<div className="container">
									<div className="row">
										<div className="col-2 col-lg-1">
											<div className={"datemonth " + color}>
												<span className="month type-text -en -lg">{dayjs(article.Published).format('MMM')}</span>
												<span className="date type-heading -en -h1">{dayjs(article.Published).format('D')}</span>
											</div>
										</div>
										<div className="col-10 col-lg-9 offset-lg-1">
											<div className="minititle type-text -ch -xs">
												{ sort } 主題館文章
											</div>
											<h1 className="heading type-heading -ch -h4">{article.Title}</h1>
										</div>
									</div>
								</div>
							</section>
							<section className="article-content">
								<div className="container">
									<div className="row">
										<div className="col-10 col-lg-9 offset-2">
											<div className="image">
												<img src={`${sourceHost}/${article.ImageUrl}`} alt="" />
											</div>
											<div className="author">
												<label className="type-text -ch -md" htmlFor="">作者 </label>
												<span className="type-text -ch -md">{article.Author}</span>
											</div>
											<div className="sorting">
												<label className="type-text -ch -md" htmlFor="">分類 </label>
												<span className="type-text -ch -md">
													{
														article["Tag_List"].map((word, i) =>
															<Link key={i}
																className="tag"
																to={`${pathname}/categories?${word.replace('#', '')}`}
															>{word}</Link>
														)
													}
												</span>
											</div>
											<article className={`content type-text -ch ${color}`} dangerouslySetInnerHTML={{ __html: article.Content }}></article>
										</div>
									</div>
								</div>
							</section>
						</React.Fragment>
					}
					{
						article === null &&
						<React.Fragment>
							<section className="article-heading">
								<div className="container">
									<div className="row">
										<div className="col-2 col-lg-1">
											<div className={'datemonth ' + color}>
												<span className="month type-text -en -lg "></span>
												<span className="date type-heading -en -h1 "></span>
											</div>
										</div>
										<div className="col-10 col-lg-9 offset-lg-1">
											<div className="minititle type-text -ch -xs -loading-block">類別</div>
											<h1 className="heading type-heading -ch -h4 -loading-block">Lorem ipsum dolor sit amet consectetur</h1>
										</div>
									</div>
								</div>
							</section>
							<section className="article-content">
								<div className="container">
									<div className="row">
										<div className="col-10 col-lg-9 offset-2">
											<div className="author">
												<div className="-loading-block empty-content">loading</div>
											</div>
											<div className="sorting">
												<div className="-loading-block empty-content">loading</div>
											</div>
											<article className="content">
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
												<div className="-loading-block empty-content">loading</div>
											</article>
										</div>
									</div>
								</div>
							</section>
						</React.Fragment>
					}
				</div>
				<SocialBlock category={trackingCategory} action="主題館" theme={theme} />
				{
					article &&
					<PageNavigation prev={article.PreviousID} next={article.NextID} 
					toList={`${pathname}/categories`}
					theme={theme}
					></PageNavigation>
				}
			</React.Fragment>
		);
	}
}

export default MorakotArticlePage;
