import ReactGA from 'react-ga';
const tracking = (category, action, label) => {
  ReactGA.initialize('UA-131117635-1');
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export default tracking;