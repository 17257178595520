// export const cors = "https://cors-anywhere.herokuapp.com/"; // develop
// export const apiHost = "https://nat.pmk.com.tw"; // develop
// export const sourceHost = "https://nat.pmk.com.tw:8080"; //develop
// export const ReCAPTCHA_siteKey = "6LfApH4UAAAAAM1mga2iy1UdSuck2Z4g2tIwzsCN"; // develop

export const cors = "";

// let apiHost = "/Nat"; // develop
// let sourceHost = "/Admin"; //develop

export const apiHost = "https://drrstat.ncdr.nat.gov.tw/Nat";
export const loginApiHost = "https://drrstat.ncdr.nat.gov.tw";
export const sourceHost = "https://drrstat.ncdr.nat.gov.tw/Admin";

// apiHost = "https://drrstat.ncdr.nat.gov.tw/Nat";
// sourceHost = "https://drrstat.ncdr.nat.gov.tw/Admin";
export const ReCAPTCHA_siteKey = "6Lf5K8YUAAAAAD9bCyyGeVgqqDoN64EmGU2Nrar8";
// export const ReCAPTCHA_siteKey = "6Lf5K8YUAAAAAC8miEynjTND5xD0K2RaImcZM0C3"

export const facebookAppId = "514340582411788";
export const facebookPageId = "476193419255458";
export const googleClientId = "955461068147-42uf13l021f4stnaf3b4r2e39hu0qr86.apps.googleusercontent.com";

// export { apiHost, sourceHost };